import { MxCellState, MxImageExport } from 'MxGraph';
import { BPMMxSvgCanvas2D } from './BPMMxSvgCanvas2D';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { ExternalLinkBLLService } from '@/services/bll/ExternalLinkBLLService';

class BPMMxImageExport extends MxImageExport {
    includeLinks: boolean = false;

    constructor({ includeLinks = false }) {
        super();
        this.includeLinks = includeLinks;
    }

    getLinkForCellState(state: MxCellState, canvas: BPMMxSvgCanvas2D): string | null {
        if (!this.includeLinks) {
            return null;
        }

        const { cell } = state;
        const { graph } = state.view;

        if (!cell.isVertex()) {
            return null;
        }
        const nodeId = graph.id;
        const { objectDefinitionId, id } = cell.getValue() || {};

        if (!nodeId || !objectDefinitionId || !id) {
            return null;
        }

        const link = ExternalLinkBLLService.createExternalLink(
            window.location.origin,
            TreeItemType.ObjectDefinition,
            nodeId.repositoryId,
            objectDefinitionId,
            id,
        );

        return link;
    }
}

export default BPMMxImageExport;

