import type { StyledAttributeType } from '../../../../../models/bpm/bpm-model-impl';
import type {
    AttributeTypeStyle,
    AttributeTypeStyleRule,
    AttributeTypeStyleRuleTypeEnum,
} from '../../../../../serverapi/api';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Row, Select, Button, Form } from 'antd';
import { FormGroup } from '../../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Icon } from '../../../../UIKit/components/Icon/Icon.component';
import messages from './AttributeRules.messages';
import editorTheme from '../AttributesEditor.scss';
import theme from './AttributeRules.scss';
import icDelete from '../../../../../resources/icons/delete.svg';
import { AttributeRulesInputs } from './AttributeRulesInputs/AttributeRulesInputs.component';
import { useAttributeRules, useUnusedAttributeRules } from '../../../../../hooks/useAttributeRules';
import { floatingAttributesChangeRulesAction } from '../../../actions/FloatingAttributes.actions';

type TAttributeRulesProps = {
    attribute: StyledAttributeType;
    selectedStyle: AttributeTypeStyle;
    disabled?: boolean;
};

export const AttributeRules = ({ attribute, selectedStyle, disabled }: TAttributeRulesProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { availableRuleTypes, currentRules } = useAttributeRules(attribute, selectedStyle?.rules);
    const unusedRules = useUnusedAttributeRules(availableRuleTypes, currentRules);
    const [form] = Form.useForm();

    const changeAttributeRules = (rules: AttributeTypeStyleRule[]): void => {
        dispatch(floatingAttributesChangeRulesAction(rules));
    };

    useEffect(() => {
        form.validateFields();

        const keys = Object.keys(form.getFieldsValue());
        const object = {};

        currentRules.forEach((rule) => {
            if (rule.type && keys.includes(rule.type)) Object.assign(object, { [rule.type]: rule.param });
        });
        form.setFieldsValue(object);
    }, [availableRuleTypes, currentRules, selectedStyle]);

    const handleAddRule = () => {
        changeAttributeRules([...currentRules, unusedRules[0]]);
    };

    const handleChangeRuleType = (
        oldRuleType: AttributeTypeStyleRuleTypeEnum | undefined,
        newRuleType: AttributeTypeStyleRuleTypeEnum,
    ) => {
        changeAttributeRules(currentRules.map((rule) => (rule.type === oldRuleType ? { type: newRuleType } : rule)));
    };

    const handleRuleParams = (
        ruleType: AttributeTypeStyleRuleTypeEnum | undefined,
        value1: string,
        value2?: string,
    ) => {
        changeAttributeRules(
            currentRules.map((rule) => {
                if (rule.type !== ruleType) return rule;
                if (!value2) return { type: ruleType, param: value1, param2: rule.param2 };

                return { type: ruleType, param2: value2, param: value1 };
            }),
        );
    };

    const handleRemove = (index: number) => {
        changeAttributeRules(currentRules.filter((r, i) => i !== index));
    };

    return (
        <Form id={selectedStyle.id} name={selectedStyle.id} form={form}>
            <FormGroup>
                <h3 className={theme.ruleTitle}>{intl.formatMessage(messages.ruleTitle)}</h3>
                {currentRules.map((currentRule, index) => (
                    <Row key={`${currentRule.type}_${selectedStyle.id}`}>
                        <div className={theme.rulesContainer}>
                            <Select
                                data-test="view_rule_name_or_change"
                                className={theme.ruleSelect}
                                popupClassName={theme.attributeRuleSelectDropdown}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                showArrow={!(unusedRules.length === 0)}
                                value={currentRule.type}
                                disabled={disabled}
                                onChange={(newRuleType) => handleChangeRuleType(currentRule.type, newRuleType)}
                            >
                                {[currentRule, ...unusedRules].map((rule) => (
                                    <Select.Option
                                        data-test={`select-rule-from-list_${rule.type!}`}
                                        value={rule.type!}
                                        key={rule.type}
                                    >
                                        {intl.formatMessage(messages[rule.type!])}
                                    </Select.Option>
                                ))}
                            </Select>
                            <AttributeRulesInputs
                                rule={currentRule}
                                valueType={attribute.valueType}
                                attributeStyleId={attribute.id}
                                disabled={disabled}
                                handleRuleParams={handleRuleParams}
                            />
                        </div>
                        {!disabled && (
                            <div
                                className={editorTheme.deleteButton}
                                data-test={`delete-rule_${currentRule.type || ''}`}
                            >
                                <Icon onClick={() => handleRemove(index)} spriteSymbol={icDelete} />
                            </div>
                        )}
                    </Row>
                ))}
                <Row>
                    <Button
                        data-test="properties-window_add-rule_btn"
                        size="small"
                        onClick={handleAddRule}
                        disabled={disabled || unusedRules.length === 0}
                    >
                        {intl.formatMessage(messages.addRule)}
                    </Button>
                </Row>
            </FormGroup>
        </Form>
    );
};
