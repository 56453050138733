import React, { FC, useState } from 'react';
import { Checkbox, Form } from 'antd';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import { SMALL_DIALOG_WIDTH } from '../../config/config';
import { useDispatch } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { useIntl } from 'react-intl';
import messages from '../Editor/messages/EditorPopupMenu.messages';
import { saveGrayScaleToLocalStorage, saveHideDecompositionToLocalStorage } from '@/actions/image.actions';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';
import theme from './ImageDownloadSettingsDialog.scss';

export const ImageDownloadSettingsDialog: FC = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [form] = Form.useForm();
    const [grayScaleChecked, setGrayScaleChecked] = useState<boolean>(LocalStorageDaoService.isGrayScaleActive());
    const [decompositionHiddenChecked, setDecompositionHiddenChecked] = useState<boolean>(
        LocalStorageDaoService.isHiddenDecompositionActive(),
    );

    const handleSubmit = () => {
        dispatch(saveGrayScaleToLocalStorage(grayScaleChecked));
        dispatch(saveHideDecompositionToLocalStorage(decompositionHiddenChecked));
        dispatch(closeDialog(DialogType.IMAGE_DOWNLOAD_SETTINGS_DIALOG));
    };

    const onClose = () => {
        dispatch(closeDialog(DialogType.IMAGE_DOWNLOAD_SETTINGS_DIALOG));
    };

    const handleChangeGrayScale = (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
        setGrayScaleChecked(event.target.checked);
    };

    const handleChangeDecompositionVisability = (event: {
        target: { checked: boolean | ((prevState: boolean) => boolean) };
    }) => {
        setDecompositionHiddenChecked(event.target.checked);
    };

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.settingsDownloadingImage)}
            open
            width={SMALL_DIALOG_WIDTH}
            okText={intl.formatMessage(messages.save)}
            cancelText={intl.formatMessage(messages.cancel)}
        >
            <Form form={form} layout="vertical">
                <Form.Item className={theme.itemCheckboxWrapper}>
                    <Checkbox checked={grayScaleChecked} disabled={false} onChange={handleChangeGrayScale}>
                        {intl.formatMessage(messages.grayScale)}
                    </Checkbox>
                </Form.Item>
                <Form.Item className={theme.item}>
                    <Checkbox
                        checked={decompositionHiddenChecked}
                        disabled={false}
                        onChange={handleChangeDecompositionVisability}
                    >
                        {intl.formatMessage(messages.hideDecomposition)}
                    </Checkbox>
                </Form.Item>
            </Form>
        </Dialog>
    );
};
