import React, { FC, useEffect, useState } from 'react';
import { Button, Tooltip, Upload } from 'antd';
import { CheckCircleTwoTone, InboxOutlined } from '@ant-design/icons';
import style from './SelectFileStep.scss';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import messages from '../../messages/ImportAris.messages';
import {
    clearArisDescriptorFields,
    setArisResponseError,
    uploadFileExportAris,
} from '../../../../../actions/importAris.actions';
import { ArisImportDescriptor, NodeId } from '../../../../../serverapi/api';
import { ImportArisSelectors } from '../../../../../selectors/importAris.selectors';
import { hasModelEditorLicense } from '../../../../../selectors/authorization.selectors';

const { Dragger } = Upload;

type TSelectFileStepProps = {
    nodeId: NodeId;
};

const SelectFileStep: FC<TSelectFileStepProps> = ({ nodeId }) => {
    const { serverId, repositoryId, id } = nodeId;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [file, setFile] = useState<File | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const [xhr, setXhr] = useState<XMLHttpRequest | null>(null);

    const descriptor: ArisImportDescriptor = useSelector(
        ImportArisSelectors.getArisDescriptor(serverId, repositoryId, id),
    );
    const isModelEditor: boolean = useSelector(hasModelEditorLicense);

    const { error, fileId, fileName } = descriptor;

    const dropFile = (e: UploadRequestOption) => {
        const uploadFile = e.file as File;
        setFile(uploadFile);
        if (uploadProgress !== null) setUploadProgress(null);
    };

    const onUploadProgress = (e: ProgressEvent<EventTarget>): void => {
        const percentComplete: number = Math.round((e.loaded / e.total) * 100);
        setUploadProgress(percentComplete);
    };

    const onSubmit = () => {
        if (file) {
            setLoading(true);
            dispatch(clearArisDescriptorFields({ nodeId }));
            dispatch(uploadFileExportAris({ file, nodeId, onUploadProgress, setXhr }));
        }
    };

    const onCancel = () => {
        if (xhr) {
            xhr.abort();
            setUploadProgress(null);
        }
    };

    useEffect(() => {
        if (fileId) {
            setLoading(false);
            dispatch(setArisResponseError({ nodeId, error: undefined }));
        }

        return () => {
            setFile(undefined);
        };
    }, [fileId]);

    useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    const shouldShowCancelBtn = uploadProgress !== null && uploadProgress < 100;

    return (
        <div className={style.container}>
            <Dragger name="file" className={style.dragger} customRequest={dropFile} showUploadList={false}>
                {file || fileId || loading ? (
                    <div className={style.successUpload}>
                        <CheckCircleTwoTone className={style.successUpload_icon} />
                        <div className={style.successUpload_description}>
                            <span>
                                {intl.formatMessage(messages.fileSelect)} {file ? file.name : fileName}
                            </span>
                            {fileId && !file && <p>{intl.formatMessage(messages.fileDownloadCompleted)}</p>}
                        </div>
                    </div>
                ) : (
                    <>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{intl.formatMessage(messages.uploadText)}</p>
                    </>
                )}
            </Dragger>

            {uploadProgress !== null && (
                <div className={style.progress}>
                    <progress className={style.progress_bar} value={uploadProgress} max="100" />
                    <div>{`${uploadProgress}%`}</div>
                </div>
            )}

            {shouldShowCancelBtn ? (
                <Button type="primary" danger onClick={onCancel}>
                    {intl.formatMessage(messages.cancel)}
                </Button>
            ) : (
                <Tooltip title={!isModelEditor ? intl.formatMessage(messages.needLicense) : ''} mouseLeaveDelay={0}>
                    <Button type="primary" disabled={!file || !isModelEditor} onClick={() => onSubmit()}>
                        {intl.formatMessage(messages.fileDownload)}
                    </Button>
                </Tooltip>
            )}

            {error && <div className={style.error}>{error}</div>}
        </div>
    );
};

export default SelectFileStep;
