import React from 'react';
import { Alert, Button, Form, Input } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../UIKit/components/Dialog/Dialog.types';
import messages from './ServerForm.messages';
import theme from './theme/ServerEditDialog.scss';
// import userEditTheme from './theme/UserEdit.component.scss';
import serverUrlSymbol from '../../../resources/icons/ic-input-server.svg';
import serverNameSymbol from '../../../resources/icons/ic-input-server-name.svg';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { TServerConnectInfo, TServerEditDialogProps } from '../ServerForm.types';

class ServerEditDialog extends React.Component<TServerEditDialogProps, TServerConnectInfo> {
    constructor(props: TServerEditDialogProps) {
        super(props);
        const {
            value: { id, url, name, error },
        } = props;
        this.state = { id, url, name, error };
    }

    render() {
        const { intl, open, isEditMode, isConnected, allServers } = this.props;
        const { id, url, name, error } = this.state;
        let title;
        if (isConnected) {
            title = intl.formatMessage(messages.readConnectionTitle);
        } else if (isEditMode) {
            title = intl.formatMessage(messages.changeConnectionTitle);
        } else {
            title = intl.formatMessage(messages.newConnectionTitle);
        }

        const dialogProps: Partial<TDialogProps> = {
            open,
            title,
            closable: true,
            maskClosable: true,
            onCancel: this.props.onCancel,
        };

        const onDelete = () => id && name && this.props.onDelete(id, name, isConnected);
        const onEdit = () =>
            name &&
            url &&
            this.props.onSubmit(
                { id, serverName: name, url, remember: false, password: '', username: '' },
                isConnected,
            );
        const replaceSym = (url: string) => url && url.replace(/\//g, '').replace(/ /g, '');
        const setStateUrl = (url: string) => {
            const serverIsExist =
                allServers && allServers.filter((s) => replaceSym(s.url) === replaceSym(url) && s.id !== id).length;
            if (serverIsExist) {
                this.setState({ url, error: messages.duplicateUrlError });
            } else {
                this.setState({ url, error: undefined });
            }
        };

        const footer = [
            !isConnected && isEditMode && (
                <Button
                    data-test="edit-server_delete"
                    key="delete"
                    className={theme.deleteButton}
                    danger
                    onClick={onDelete}
                >
                    <FormattedMessage {...messages.remove} />
                </Button>
            ),
            <Button data-test="edit-server_cancel" key="cancel" onClick={this.props.onCancel}>
                <FormattedMessage {...messages.cancel} />
            </Button>,
            !isConnected && (
                <Button
                    data-test="edit-server_submite"
                    key="ok"
                    type="primary"
                    onClick={onEdit}
                    disabled={!(url && name) || !!error}
                >
                    <FormattedMessage {...messages.create} />
                </Button>
            ),
        ];

        return (
            <Dialog {...dialogProps} className={theme.dialog} footer={footer}>
                {error && <Alert className={theme.alert} type="error" message={intl.formatMessage(error)} showIcon />}

                <Form layout="vertical">
                    <FormGroup className={theme.group}>
                        <Form.Item
                            className={theme.item}
                            // hasFeedback
                            label={intl.formatMessage(messages.serverName)}
                            rules={[{ required: !isConnected, message: intl.formatMessage(messages.requiredField) }]}
                            name="serverName"
                            initialValue={name}
                        >
                            <Input
                                data-test="edit-server_name-input"
                                disabled={isConnected}
                                className={theme.input}
                                placeholder={intl.formatMessage(messages.serverNamePlaceholder)}
                                prefix={<Icon spriteSymbol={serverNameSymbol} />}
                                onChange={(event) => this.setState({ name: event.target.value })}
                                value={name}
                            />
                        </Form.Item>
                        <Form.Item
                            className={theme.item}
                            // hasFeedback
                            label={intl.formatMessage(messages.serverUrl)}
                            rules={[{ required: !isConnected, message: intl.formatMessage(messages.requiredField) }]}
                            name="serverUrl"
                            initialValue={url}
                        >
                            <Input
                                data-test="edit-server_url-input"
                                disabled={isConnected}
                                className={theme.input}
                                placeholder={intl.formatMessage(messages.serverUrlPlaceholder)}
                                prefix={<Icon spriteSymbol={serverUrlSymbol} />}
                                onChange={(event) => setStateUrl(event.target.value)}
                                value={url}
                            />
                        </Form.Item>
                    </FormGroup>
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ServerEditDialog);

export { IntlComponent as ServerEditDialog };
