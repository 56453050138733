import { ReactNode } from 'react'
import ReactDOM  from 'react-dom';

type TPortalProps = {
    children: ReactNode,
    container: HTMLElement
}

const Portal = ({ children, container }: TPortalProps) => {
    return ReactDOM.createPortal(children, container);
}

export default Portal;