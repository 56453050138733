import { TReducer } from '../../utils/types';
import { SCRIPTS_EXECUTION_SAVE_TO_STORE } from '../../actionsTypes/entities/script.actionTypes';
import { TScriptExecutionState } from './scriptExecution.reducer.types';

const INITIAL_SCRIPT_EXECUTION_STATE: TScriptExecutionState = {};

export const scriptExecutionReducer: TReducer<TScriptExecutionState> = (
    state: TScriptExecutionState = INITIAL_SCRIPT_EXECUTION_STATE,
    action,
) => {
    switch (action.type) {
        case SCRIPTS_EXECUTION_SAVE_TO_STORE: {
            const {
                payload: { serverId, executions },
            } = action;

            return {
                ...state,
                [serverId]: executions,
            };
        }
        default:
            return state;
    }
};
