import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import theme from '../NewMatrixEditor.scss';
import { InternationalString, MatrixDataBPM8764 } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { Locale } from '@/modules/Header/components/Header/header.types';
import messages from './NewMatrixMainHeader.messages';
import { useIntl } from 'react-intl';
import { HeaderResizeDirections, THeaderResizeData } from '../NewMatrix.types';
import cn from 'classnames';
import { MIN_HEADER_SIZE } from '../../NewMatrix.constants';

type TNewMatrixMainHeaderProps = {
    currentLocale: Locale;
    isReadMode: boolean;
    data?: MatrixDataBPM8764;
    setIsHeaderResizing: (isHeaderResizing: boolean) => void;
    setHeaderResizeData: (resizingData: THeaderResizeData) => void;
    saveMatrixData: (data: MatrixDataBPM8764) => void;
    openRenameDialog: (onSubmit: (newName: InternationalString) => void, initName?: InternationalString) => void;
};

export const NewMatrixMainHeader: FC<TNewMatrixMainHeaderProps> = ({
    data,
    currentLocale,
    isReadMode,
    setIsHeaderResizing,
    setHeaderResizeData,
    saveMatrixData,
    openRenameDialog,
}) => {
    const intl = useIntl();

    const columnHeaderHeight = data?.columnHeaderHeight || MIN_HEADER_SIZE;
    const rowHeaderWidth = data?.rowHeaderWidth || MIN_HEADER_SIZE;

    const localeColumnsName =
        LocalesService.internationalStringToString(data?.columnsName, currentLocale) ||
        intl.formatMessage(messages.withoutName);
    const localeRowumnsName =
        LocalesService.internationalStringToString(data?.rowsName, currentLocale) ||
        intl.formatMessage(messages.withoutName);

    const mainHeaderColRename = (newName: InternationalString) => {
        if (data) {
            const newMatrixData: MatrixDataBPM8764 = { ...data, columnsName: newName };
            saveMatrixData(newMatrixData);
        }
    };

    const mainHeaderRowRename = (newName: InternationalString) => {
        if (data) {
            const newMatrixData: MatrixDataBPM8764 = { ...data, rowsName: newName };
            saveMatrixData(newMatrixData);
        }
    };

    const ratio = Math.min(rowHeaderWidth, columnHeaderHeight) / Math.max(rowHeaderWidth, columnHeaderHeight);
    const atan = Math.atan(Math.tan(ratio));
    const rotate = (atan * 180) / Math.PI;

    const titleWidth = rowHeaderWidth > columnHeaderHeight ? rowHeaderWidth * 0.4 : rowHeaderWidth * 0.5;

    return (
        <Table.HeaderCell
            className={theme.activeCell}
            style={{
                height: `${columnHeaderHeight}px`,
                width: `${rowHeaderWidth}px`,
                minHeight: `${columnHeaderHeight}px`,
                minWidth: `${rowHeaderWidth}px`,
                maxHeight: `${columnHeaderHeight}px`,
                maxWidth: `${rowHeaderWidth}px`,
            }}
        >
            <div
                className={theme.headerContainer}
                style={{
                    height: `${columnHeaderHeight}px`,
                    width: `${rowHeaderWidth}px`,
                }}
            >
                <div
                    className={theme.rotateContainer}
                    style={{
                        height: `${columnHeaderHeight * 1.5}px`,
                        width: `${rowHeaderWidth * 1.5}px`,
                        transform: `translate(-50%, -50%) translate(${rowHeaderWidth * 0.5}px, ${
                            columnHeaderHeight * 0.5
                        }px) rotate(${rotate}deg)`,
                    }}
                >
                    <div
                        className={theme.colHeader}
                        onClick={() => openRenameDialog(mainHeaderColRename, data?.columnsName)}
                    >
                        <div
                            style={{
                                width: `${titleWidth}px`,
                                transform: `rotate(${-rotate}deg)`,
                            }}
                            className={theme.mainHeaderText}
                        >
                            {localeColumnsName}
                        </div>
                    </div>
                    <div
                        className={theme.rowHeader}
                        onClick={() => openRenameDialog(mainHeaderRowRename, data?.rowsName)}
                    >
                        <div
                            style={{
                                width: `${titleWidth}px`,
                                transform: `rotate(${-rotate}deg)`,
                            }}
                            className={theme.mainHeaderText}
                        >
                            {localeRowumnsName}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={cn({ [theme.bothSizer]: !isReadMode })}
                onDragStart={(e) => {
                    e.preventDefault();
                }}
                onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                    if (isReadMode) return;

                    setIsHeaderResizing(true);
                    setHeaderResizeData({
                        height: columnHeaderHeight,
                        width: rowHeaderWidth,
                        x: e.clientX,
                        y: e.clientY,
                        direction: HeaderResizeDirections.both,
                    });
                }}
            />
        </Table.HeaderCell>
    );
};
