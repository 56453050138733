import React, { FC } from 'react';
import { ContextMenu } from '@/modules/ContextMenu/components/ContextMenu.component';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { MenuInfo } from 'rc-menu/es/interface';

type TNewMatrixContextMenuProps = {
    visible: boolean;
    menuItems: ItemType[];
    hideContextMenu: () => void;
    handleMenuAction: (event: MenuInfo) => void;
};

export const NewMatrixContextMenu: FC<TNewMatrixContextMenuProps> = ({
    visible,
    menuItems,
    hideContextMenu,
    handleMenuAction,
}) => {
    const onClickHandler = (event: MenuInfo) => {
        event.domEvent.stopPropagation();
        handleMenuAction(event);
        hideContextMenu();
    };

    return (
        <ContextMenu
            menuId="MatrixContextMenu"
            trigger={['click']}
            overlay={menuItems}
            visible={visible}
            onOpenChange={hideContextMenu}
            onClick={onClickHandler}
        >
            <div></div>
        </ContextMenu>
    );
};
