import React, { ChangeEvent } from 'react';
import newTheme from './Select.scss';
import likeAnAntdTheme from './SelectLikeAnAntd.scss';

type TSearchInputProps = {
    showSearch?: boolean;
    // todo: после перехода на новые стили этот пропс будет не нужен
    originalTheme?: boolean;
    onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    searchValue: string;
    dataTest?: string;
    autoFocus?: boolean;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

export const SearchInput = (props: TSearchInputProps) => {
    const { showSearch, originalTheme, onSearch, onFocus, onBlur, searchValue, dataTest, autoFocus } = props;

    const theme = originalTheme ? newTheme : likeAnAntdTheme;

    return (
        <>
            {showSearch && (
                <div className={theme.inputContainer}>
                    <input
                        autoFocus={autoFocus}
                        className={theme.input}
                        onChange={onSearch}
                        value={searchValue}
                        type="text"
                        data-test={dataTest}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </div>
            )}
        </>
    );
};
