import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import messages from './RefreshPageDialog.messages';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';

type TRefreshPageDialogProps = WrappedComponentProps & {
    onSubmit: () => any;
    onClose: () => any;
};

const RefreshPageDialog = (props: TRefreshPageDialogProps) => {
    const { intl, onSubmit, onClose } = props;

    return (
        <Dialog
            open
            closable={false}
            okText={intl.formatMessage(messages.refresh)}
            cancelText={intl.formatMessage(messages.cancel)}
            onOk={onSubmit}
            onCancel={onClose}
        >
            {intl.formatMessage(messages.refreshRequestMessage)}
        </Dialog>
    );
};

const IntlComponent = injectIntl(RefreshPageDialog);

export { IntlComponent as RefreshPageDialog };
