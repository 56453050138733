import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { NodeId } from '../../../serverapi/api';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { deleteWidget } from '../../../actions/dashboard.actions';
import messages from './DeleteWidgetDialog.messages';

type TDeleteWidgetDialogProps = {
    parentNodeId: NodeId;
    widgetId: string;
    widgetName: string;
};

export const DeleteWidgetDialog = (props: TDeleteWidgetDialogProps) => {
    const dispatch = useDispatch();
    const { parentNodeId, widgetId, widgetName } = props;

    const onClose = () => dispatch(closeDialog(DialogType.DASHBOARD_DELETE_WIDGET_DIALOG));
    const onSubmit = () => {
        dispatch(deleteWidget(widgetId, parentNodeId));
        dispatch(closeDialog(DialogType.DASHBOARD_DELETE_WIDGET_DIALOG));
    };

    const intl = useIntl();
    const footer = [
        <Button key="cancel_button" onClick={onClose}>
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button type="primary" danger key="delete_button" onClick={onSubmit}>
            {intl.formatMessage(messages.delete)}
        </Button>,
    ];

    return (
        <Dialog
            open
            onOk={() => onSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.title)}
            footer={footer}
        >
            <div>{intl.formatMessage(messages.message, { name: widgetName })}</div>
        </Dialog>
    );
};
