import { Alert, Table } from 'antd';
import { omit, uniqBy } from 'lodash-es';
import { ColumnProps } from 'antd/es/table';
import React, { useState, ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { TSpriteSymbol } from '../../../../../../models/spriteSymbol.types';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import { DeleteButton, EditButton, RowButtons } from '../../../../Button/RowButtons';
import messages from '../../../messages/Presets.messages';
import theme from './GroupedTypesTable.scss';
import { containsIgnoreCase } from './preset.utils';
import icFolder from '../../../../../../resources/icons/group.svg';
import icArrowRight from '../../../../../../resources/icons/ic-ribbon-arrow-right.svg';
import cx from 'classnames';
import { InternationalString, KanbanBoardType } from '../../../../../../serverapi/api';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { TGroup, TTableDataType, TTableRowRecord } from './GroupedTypesTable.types';
import { LocalesService } from '../../../../../../services/LocalesService';
import { isKanbanBoardType } from './KanbanCardEditor.utils';
import { RowClassName } from 'rc-table/lib/interface';
import { createMessageForDelete } from './metodologyElementsDeleteMessages.utils';

type TCheckedId = { [id: string]: boolean };
type TExpandedId = { [id: string]: boolean };
type TDefineMessages = {
    id: string;
    defaultMessage: string;
};

type TTableRowRecordComponentProps = WrappedComponentProps & {
    types: TTableDataType[];
    typeGroups: TGroup[];
    searchFilter?: string;
    actionsDisabled: boolean;
    icon?: TSpriteSymbol;
    header?: ReactNode;
    hideGUIDColumn?: boolean;
    columns?: Array<ColumnProps<TTableRowRecord<TTableDataType>>>;
    onSelectType?: (checkedTypes: TTableDataType[]) => void;
    onSelectGroup?: (group: TGroup[]) => void;
    rowKey?: (row: TTableRowRecord<TTableDataType>) => string;
    onDeleteType?: (row: TTableDataType) => void;
    onDeleteKanbanType?: (row: KanbanBoardType) => void;
    onDeleteGroup?: (row: TGroup) => void;
    onEditType?: (row) => void;
    onEditKanbanType?: (row) => void;
    onEditGroup?: (row: TGroup) => void;
    titleDeleteMessage?: TDefineMessages;
    deleteElMessage?: TDefineMessages;
    deleteGroupMessage?: TDefineMessages;
    isTableDisabled?: boolean;
    selectedTreeItemType?: string;
    kanbanTypes?: TTableDataType[];
    rowClassName?: string | RowClassName<TTableRowRecord<TTableDataType>>;
};

const GroupedTypesTable = (props: TTableRowRecordComponentProps) => {
    const {
        onDeleteGroup,
        onDeleteType,
        onEditGroup,
        onEditType,
        onSelectType,
        onSelectGroup,
        titleDeleteMessage,
        deleteElMessage,
        deleteGroupMessage,
        types,
        searchFilter,
        isTableDisabled,
        selectedTreeItemType,
        onDeleteKanbanType,
        onEditKanbanType,
        kanbanTypes,
        rowClassName,
    } = props;

    const [checkedIds, setCheckedIds] = useState<TCheckedId>({});
    const [expandedIds, setExpandedIds] = useState<TExpandedId>({});
    const currentLocale = useSelector(getCurrentLocale);

    const searchElements = (type: TTableDataType): boolean => {
        const name = LocalesService.internationalStringToString(type.multilingualName, currentLocale);
        const description = LocalesService.internationalStringToString(type.multilingualDescription, currentLocale);
        const searchText = searchFilter?.toLowerCase().trim() || '';
        const searchFields = [name, description, type.id, type.groupId];
        const found = searchFields.some((field) => field?.toLowerCase().trim().includes(searchText));

        return found;
    };

    const foundTypes = types.filter(searchElements);
    const foundKanbanTypes = kanbanTypes ? kanbanTypes.filter(searchElements) : [];
    // todo возможно придется использовать отдельно типы и типаКанбан
    const allTypes = [...foundTypes, ...foundKanbanTypes];
    const getGroupElements = (group: TGroup): TTableDataType[] => {
        const elements = allTypes.filter((type: TTableDataType) => type.groupId === group.id);

        return uniqBy(elements, 'id');
    };
    const typeGroups = props.typeGroups;

    const getDataSource: TTableRowRecord<TTableDataType>[] = typeGroups
        .map((group: TGroup) => ({
            ...group,
            expandable: true,
            checked: checkedIds[group.id],
            children: getGroupElements(group),
        }))
        .filter(
            (group: TTableRowRecord<TGroup>) =>
                containsIgnoreCase(group.name, searchFilter as string) ||
                group.children.length ||
                !searchFilter?.length,
        );

    // Select rows start
    const addChecked = (expandable: boolean) => (checkedId: TCheckedId, row: TTableRowRecord<TTableDataType>) => {
        checkedId[getRowKey(expandable, row.id)] = true;

        return checkedId;
    };

    const getSelectedIdsAndGroups = (types: TTableDataType[], groups: TGroup[]): TCheckedId => {
        const selectedIds = types.reduce(addChecked(false), {});
        const selectedIdsAndGroups = groups.reduce(addChecked(true), selectedIds);

        return selectedIdsAndGroups;
    };

    const onRowSelect = (row: TTableRowRecord<TTableDataType>, selected: boolean): void => {
        const kanbanTypes = props.kanbanTypes ? props.kanbanTypes : [];
        let checkedTypes = [...types, ...kanbanTypes].filter(
            (type: TTableDataType) => checkedIds[getRowKey(false, type.id)],
        );
        let checkedGroups = typeGroups.filter((group: TGroup) => checkedIds[getRowKey(true, group.id)]);

        if (row.expandable) {
            // проверка является ли группой
            if (selected) {
                // check группы
                const newGroup = typeGroups.find((group: TGroup) => group.id === row.id);
                if (newGroup) {
                    checkedGroups.push(newGroup);
                }
                // при выборе группы check все дочерние елементы
                checkedTypes = [
                    ...checkedTypes,
                    ...allTypes.filter(
                        (type: TTableDataType) => type.groupId === row.id && !checkedTypes.includes(type),
                    ),
                ];
            } else {
                // uncheck группы
                checkedGroups = checkedGroups.filter((group: TGroup) => group.id !== row.id);
                checkedTypes = checkedTypes.filter((type: TTableDataType) => type.groupId !== row.id);
            }
        } else if (selected) {
            // check елемента
            checkedTypes.push(row);
        } else {
            // uncheck елемента
            checkedTypes = checkedTypes.filter((type: TTableDataType) => type.id !== row.id);
            checkedGroups = checkedGroups.filter((group: TGroup) => group.id !== row.groupId);
        }

        setCheckedIds(getSelectedIdsAndGroups(checkedTypes, checkedGroups));
        onSelectGroup && onSelectGroup(checkedGroups);
        onSelectType && onSelectType(checkedTypes);
    };

    const onRowSelectAll = (selected: boolean): void => {
        setCheckedIds(selected ? getSelectedIdsAndGroups(allTypes, typeGroups) : {});
        onSelectType && onSelectType(selected ? allTypes : []);
        onSelectGroup && onSelectGroup(selected ? typeGroups : []);
    };

    const rowSelection = onSelectType && {
        selectedRowKeys: Object.keys(checkedIds),
        onSelect: onRowSelect,
        onSelectAll: onRowSelectAll,
    };
    // Select rows end

    // buttons for columns start
    const editButton = (record: TTableRowRecord<TTableDataType>) => (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (record.expandable) {
            onEditGroup && onEditGroup(record as TGroup);
        } else {
            if (isKanbanBoardType(record)) {
                const kanbanModelType: KanbanBoardType = omit(record, ['name']) as unknown as KanbanBoardType;
                onEditKanbanType && onEditKanbanType(kanbanModelType);
            } else {
                onEditType && onEditType(record);
            }
        }
    };

    const deleteButton = (record: TTableRowRecord<TTableDataType>) => () => {
        if (record.expandable) {
            onDeleteGroup && onDeleteGroup(record as TGroup);
        } else {
            if (isKanbanBoardType(record)) {
                const kanbanModelType: KanbanBoardType = omit(record, ['name']) as unknown as KanbanBoardType;
                onDeleteKanbanType && onDeleteKanbanType(kanbanModelType);
            } else {
                onDeleteType && onDeleteType(record);
            }
        }
    };

    const renderRowButtons = (value: string, record: TTableRowRecord<TTableDataType>) => {
        const elementDeleteMessage =
            deleteElMessage &&
            createMessageForDelete(
                props.intl.formatMessage(deleteElMessage),
                record.children ? record.children : [record],
            );
        const groupDeleteMessage =
            record.expandable &&
            deleteGroupMessage &&
            createMessageForDelete(props.intl.formatMessage(deleteGroupMessage), [record]);
        const deleteContent =
            groupDeleteMessage || elementDeleteMessage ? (
                <Alert
                    message={
                        <>
                            {groupDeleteMessage}
                            {elementDeleteMessage}
                        </>
                    }
                    type="warning"
                />
            ) : (
                ''
            );

        return (
            <RowButtons
                disabled={props.actionsDisabled}
                buttons={[
                    (onEditType && onEditGroup && EditButton.build(editButton(record), props.actionsDisabled)) ||
                        undefined!,
                    (onDeleteType &&
                        onDeleteGroup &&
                        DeleteButton.build(
                            deleteButton(record),
                            props.actionsDisabled,
                            undefined,
                            undefined,
                            props.intl.formatMessage(titleDeleteMessage || messages.deleteDefaultMessage),
                            deleteContent,
                        )) ||
                        undefined!,
                ].filter((btn) => !!btn)}
            />
        );
    };
    // buttons for columns end

    // Columns start
    const renderDefaultColumn = (
        value: InternationalString,
        record: TTableRowRecord<TTableDataType | TGroup>,
    ): React.ReactNode => (
        <span className={record.expandable ? theme.contentName : theme.contentChild}>
            {!record.expandable ? (
                props.icon && <Icon className={theme.notExpandableIcon} spriteSymbol={props.icon} />
            ) : (
                <span className={theme.expandIcon}>
                    <Icon
                        spriteSymbol={icArrowRight}
                        className={cx(theme.togglerIcon, expandedIds[record.id] && theme.togglerIcon_expanded)}
                        dataTest={
                            expandedIds[record.id]
                                ? 'grouped-types-table_row-expanded'
                                : 'grouped-types-table_row-collapsed'
                        }
                    />
                    <Icon className={theme.groupIcon} spriteSymbol={icFolder} />
                </span>
            )}
            <span className={isTableDisabled ? theme.rowDisabled : undefined}>
                {LocalesService.internationalStringToString(value, currentLocale)}
            </span>
        </span>
    );

    const renderGuidColumn = (guid) => <span className={isTableDisabled ? theme.rowDisabled : undefined}>{guid}</span>;

    const defaultColumns: Array<ColumnProps<TTableRowRecord<TTableDataType>>> = [
        {
            title: props.intl.formatMessage(messages.name),
            dataIndex: 'multilingualName',
            className: theme.column,
            render: renderDefaultColumn,
        },
    ];

    const actionsColumn: ColumnProps<TTableRowRecord<TTableDataType>> = {
        dataIndex: 'rowActionButtons',
        render: renderRowButtons,
        className: theme.buttonsColumn,
        width: 75,
    };

    const guidColumn: ColumnProps<TTableRowRecord<TTableDataType>> = {
        title: props.intl.formatMessage(messages.id),
        className: theme.column,
        dataIndex: 'id',
        render: renderGuidColumn,
    };

    if (!props.hideGUIDColumn) {
        defaultColumns.push(guidColumn);
    }

    const additionalColumns: ColumnProps<TTableRowRecord<TTableDataType>>[] | undefined = props?.columns?.map(
        (column) => {
            return { ...column, className: theme.column };
        },
    );

    defaultColumns.push(...(additionalColumns || []));

    if (onDeleteGroup || onDeleteType || onEditGroup || onEditType) {
        defaultColumns.push(actionsColumn);
    }
    // Columns end

    const getRowKey = (expandable: boolean, guid: string): string => {
        return expandable ? `group-${guid}${selectedTreeItemType}` : `elem-${guid}${selectedTreeItemType}`;
    };

    const getTableKeys = (record: TTableRowRecord<TTableDataType>): string => {
        return getRowKey(record.expandable, record.id);
    };

    const onRow = (record: TTableRowRecord<TTableDataType>) => {
        return {
            onClick: () => {
                if (!record.expandable) {
                    const checkedTypes = allTypes.filter(
                        (type: TTableDataType) => checkedIds[getRowKey(false, type.id)],
                    );
                    onRowSelect(record, !checkedTypes.some((item) => item.id === record.id));
                }
                setExpandedIds({ ...expandedIds, [record.id]: !expandedIds[record.id] });
            },
        };
    };

    return (
        <Table
            rowKey={props.rowKey ? props.rowKey : getTableKeys}
            columns={defaultColumns}
            dataSource={getDataSource}
            rowSelection={rowSelection}
            onRow={onRow}
            title={props.header ? () => props.header : undefined}
            className={theme.table}
            expandRowByClick
            pagination={false}
            expandIcon={() => <></>}
            rowClassName={rowClassName}
            scroll={{
                y: 'fit-content',
                x: 'max-content',
            }}
        />
    );
};

const IntlComponent = injectIntl(GroupedTypesTable);

export { IntlComponent as GroupedTypesTable };
