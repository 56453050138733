import type { TBPMMxGraphOptions } from './bpmgraph.types';
import { ComplexSymbolManager } from './ComplexSymbols/ComplexSymbolManager.class';
import { BPMMxStencil } from './BPMMxStencil';
import { BPMMxSvgCanvas2D } from './BPMMxSvgCanvas2D';
import {
    MxCell,
    MxConstants,
    MxEvent,
    MxGeometry,
    MxGraph,
    MxPoint,
    MxRectangle,
    MxCellState,
    MxConnectionConstraint,
    MxPanningHandler,
    MxPopupMenu,
    MxImageExport,
    MxSelectionCellsHandler,
    MxGraphSelectionModel,
    Graph,
    MxEventObject,
    MxSvgCanvas2D,
} from './mxgraph';
import {
    ModelType,
    NodeId,
    ObjectInstance,
    ShapeInstance,
    Node,
    NodeTypeEnum,
    EdgeInstance,
    DiagramElement,
    EdgeType,
    Symbol,
} from '../serverapi/api';
import { EdgeInstanceImpl } from '../models/bpm/bpm-model-impl';
import {
    CancelablePromise,
    hasForbiddenSymbols,
    isCommentCell,
    isIgnoredUndoableEdit,
    isLabelChange,
} from '../utils/bpm.mxgraph.utils';
import { BPMMxConstants, canExtendParentStyleName, INACTIVE_CELL_STYLE_OPACITY, IsMacOS } from './bpmgraph.constants';
import { NotationHelper } from '../services/utils/NotationHelper';
import { BPMMxGraphContext, BPMMxPopupMenuHandler, BPMMxGraphView, BPMMxTooltipHandler } from './BPMGraphClasses';
import { BPMMxVertexHandler } from './handler/BPMMxVertexHandler.class';
import { BPMMxConnectionHandler } from './handler/BPMMxConnectionHandler.class';
import { BPMMxObjectDefinitionNameChangeClass } from './BPMMxObjectDefinitionNameChange.class';
import { DECOMPOSITION_ICON, getPorts } from './util/PortsDefinitions.utils';
import { BPMHoverIcons } from './BPMHoverIcons.class';
import { BPMMxCellEditor } from './BPMMxCellEditor.class';
import { isNullOrUndefined } from 'is-what';
import { objectDefinitionService } from '../services/ObjectDefinitionService';
import { BPMPSDDiagram } from './psdDiagram/BPMPSDDiagramm';
import { BPMMxGraphHandler } from './handler/BPMMxGraphHandler';
import { clone, each, noop } from 'lodash-es';
import { NodeFilterTool } from '../modules/Editor/classes/NodeFilterTool';
import { SymbolType } from '../models/Symbols.constants';
import { BPMMxKeyHandler } from './handler/BPMMxKeyHandler';
import { BPMN2Diagram } from './bpmnDiagram/BPMN2Diagramm';
import { psdCellType } from './psdDiagram/psdTable';
import { BPMMxSelectionCellsHandler } from './handler/BPMMxSelectionCellsHandler.class';
import { EditorMode } from '../models/editorMode';
import { resolveName } from './util/BPMNameResolver';
import { modelService } from '../services/ModelService';
import { MxShape } from './shapes/MxShape';
import { MxUtils } from './util/MxUtils';
import { LocalesService } from '../services/LocalesService';
import { getConnectionHandlerFactoryMethod } from './util/BpmMxEditorHandlerUtils';
import { BPMMxEdgeSegmentHandler } from './handler/BPMMxEdgeSegmentHandler';
import { edgeDefinitionService } from '../services/EdgeDefinitionService';
import { getCellGeometry, getIntersection, getIntersectPercentBySmallerRect } from '../utils/object';
import { addCustomMarkersForEdges } from './shapes/CustomMarkersForEdge';
import { registerShapes, shapes } from './shapes';
import CellOverlayManager from './overlays/CellOverlayManager.class';
import { DROP_TARGET_ATTRIBUTE } from './ComplexSymbols/symbols/ComplexSymbol.constants';
import { getGrayScale, getHideDecomposition, getImageScale } from './util/SvgUtils';
import { BPMMxUndoManager } from './BPMMxUndoManager.class';
import './MxGuide';
import './MxCellRenderer';
import BPMMxImageExport from './BPMMxImageExport.class';

type TGetSvgProps = {
    cellsForCopy: MxCell[];
    cropBounds?: MxRectangle;
    includeLinks?: boolean;
    withoutNegativeCoordinates?: boolean;
    onlySelectedCells?: boolean;
};
type TGetSvgImageProps = {
    cellsForCopy: MxCell[];
    cropBounds?: MxRectangle;
    includeLinks?: boolean;
    withoutNegativeCoordinates?: boolean;
    onlySelectedCells?: boolean;
    withoutDecompositionIcons?: boolean;
    isPrint?: boolean;
};

type TGetSvgAllGraptProps = {
    cropBounds?: MxRectangle;
    includeLinks?: boolean;
    withoutNegativeCoordinates?: boolean;
    withoutDecompositionIcons?: boolean;
    isPrint?: boolean;
};

export const DEFAULT_IMAGE_SCALE = 2.5;

export const IMAGE_MAX_WIDTH = 16000;

export const IMAGE_MAX_HEIGHT = 16000;

MxGraph.prototype.init = function (container) {
    this.container = container;
    this.cellEditor = this.createCellEditor();
    this.view.init();
    this.sizeDidChange();
};
MxImageExport.prototype.drawText = function (state, canvas: MxSvgCanvas2D) {
    if (state.text != null && state.text.checkBounds()) {
        const { boundingBox, bounds } = state.text;
        canvas.additionalSettings = {
            width: boundingBox?.width || bounds?.width || 0,
            height: boundingBox?.height || bounds?.height || 0,
        };
        canvas.save();
        state.text.beforePaint(canvas);
        state.text.paint(canvas);
        state.text.afterPaint(canvas);

        canvas.restore();
    }
};

MxImageExport.prototype.includeOverlays = true;
MxImageExport.prototype.drawOverlays = function (state, canvas) {
    if (state.overlays != null) {
        state.overlays.visit((id, shape) => {
            if (shape instanceof MxShape) {
                canvas.save();
                state.shape.beforePaint(canvas);
                shape.paint(canvas);
                state.shape.afterPaint(canvas);
                canvas.restore();
            }
        });
    }
};

export class SvgImage {
    svgString: string;
    height: number;
    width: number;
}

export class BPMMxGraph extends Graph {
    readonly labelBorderColor = '#3E78FD';
    readonly mainCellIdLenght = 36;
    static readonly MIN_ZOOM_LEVEL = 10;
    static readonly MAX_ZOOM_LEVEL = 500;
    mode: EditorMode = 1; //TODO: Add setter and getter
    edgesSelectable = true;
    foldingEnabled: boolean = false;
    popupMenuHandler: BPMMxPopupMenuHandler;
    tooltipHandler: BPMMxTooltipHandler;
    nodeFilter: NodeFilterTool;
    htmlLabels = false;
    cellEditor: BPMMxCellEditor;
    bpmMxGraphContext: BPMMxGraphContext;
    connectionHandler: BPMMxConnectionHandler;
    keyHandler: BPMMxKeyHandler;
    modelType?: ModelType;
    id: NodeId;

    /**
     * todo: move to state?
     * Corresponding model node id
     * @type {string}
     */
    autoSizeCellsOnAdd: boolean = true;
    allowNegativeCoordinates: boolean = false;
    vertexLabelsMovable: boolean = true;
    isMouseTrigger: boolean = false;
    intl: any; // tslint:disable-line:no-any
    selectionCellsHandler: any; // tslint:disable-line:no-any
    defaultEdgeLength: number = 80;
    connectionArrowsEnabled: boolean = true;
    panningHandler: MxPanningHandler;
    hoverIcons: BPMHoverIcons;
    psdDiagramHandler: BPMPSDDiagram;
    complexSymbolManager: ComplexSymbolManager;
    cellOverlayManager: CellOverlayManager;
    bpmn2DiagramHandler: BPMN2Diagram;
    vertexHandler: BPMMxVertexHandler;
    mouseMovePoint: any = null; // tslint:disable-line:no-any
    defaultEdgeStyle: any = {
        edgeStyle: 'orthogonalEdgeStyle',
        rounded: '1',
        jettySize: 'auto',
        orthogonalLoop: '1',
    };

    currentEdgeStyle: any; // tslint:disable-line:no-any
    dirty: boolean = true;
    undoManager: BPMMxUndoManager;
    DEFAULT_UNDO_HISTORY_DEPTH = 100;

    lastSelectedCells: MxCell[] = [];
    layoutManager;
    // объект, содержащий текущие пересечения передвигаемых ячеек
    intersectionMap: Record<string, string | null> = {};
    intersectableCellTypes?: Array<string> = [];

    isHightLightDropUnavailableCells = false;

    dropFailedHandler(target: MxCell, symbol: Symbol) {
        return;
    }

    handleCheckInvisibleEdgeTypes?: (movedCell: MxCell, intersectedCell: MxCell) => void = noop;
    handleCleanupInvisibleEdges?: (hiddenEdgesToDelete: MxCell[]) => void = noop;

    constructor(options?: TBPMMxGraphOptions) {
        super(options?.container, options?.model, options?.renderHint, options?.stylesheet);

        if (options?.id) {
            this.id = options?.id;
        }

        this.initModelType(options?.modelType);
        this.currentEdgeStyle = MxUtils.clone(this.defaultEdgeStyle);
        this.undoManager = new BPMMxUndoManager(this.DEFAULT_UNDO_HISTORY_DEPTH);
        this.installUndoHandler();

        this.nodeFilter = new NodeFilterTool(this);
        this.intersectableCellTypes = options?.intersectableCellTypes;
        this.handleCheckInvisibleEdgeTypes = options?.handleCheckInvisibleEdgeTypes;
        this.handleCleanupInvisibleEdges = options?.handleCleanupInvisibleEdges;

        this.initDefaultFontFamily();
        this.initComplexSymbolManager();

        this.getSelectionModel().addListener(MxEvent.CHANGE, (sender: MxGraphSelectionModel) =>
            this.redrawCellBoxes(sender.cells),
        );

        this.cellOverlayManager = new CellOverlayManager(this);

        addCustomMarkersForEdges();
        registerShapes(shapes);
        BPMMxStencil.init();
    }

    installUndoHandler() {
        const listener = MxUtils.bind(this, (sender, evt: MxEventObject): boolean | void => {
            const edit = evt.getProperty('edit');

            edit.changes = edit.changes.filter((change: { value: {}; previous: {} }) => {
                if (!change.value || !change.previous) {
                    return true;
                }

                return change.value !== change.previous;
            });

            if (!isIgnoredUndoableEdit(edit) && !isLabelChange(edit)) {
                this.undoManager.undoableEditHappened(edit);
            }
        });

        this.getModel().addListener(MxEvent.UNDO, listener);
        this.getView().addListener(MxEvent.UNDO, listener);
    }

    initSwimPoolStyles() {
        const graph: BPMMxGraph = this;
        const originalStyle = graph.getStylesheet().getDefaultVertexStyle();
        delete originalStyle[MxConstants.STYLE_FILLCOLOR];
        const CustomStyle = { ...originalStyle };

        originalStyle[MxConstants.STYLE_FONTCOLOR] = 'black';
        originalStyle[MxConstants.STYLE_STROKECOLOR] = 'black';
        CustomStyle[MxConstants.STYLE_SHAPE] = MxConstants.SHAPE_SWIMLANE;
        CustomStyle[MxConstants.STYLE_VERTICAL_ALIGN] = 'middle';
        CustomStyle[MxConstants.STYLE_FONTSIZE] = 11;
        CustomStyle[MxConstants.STYLE_STARTSIZE] = 22;
        CustomStyle[MxConstants.STYLE_FONTCOLOR] = 'black';
        CustomStyle[MxConstants.STYLE_STROKECOLOR] = 'black';

        const swimlaneStyle = { ...CustomStyle };
        graph.getStylesheet().putCellStyle('swimlane', swimlaneStyle);

        const poolStyle = { ...CustomStyle };
        graph.getStylesheet().putCellStyle('pool', poolStyle);
    }

    initGuides() {
        MxConstants.GUIDE_COLOR = '#FF0000';
        MxConstants.GUIDE_STROKEWIDTH = 1;
        MxConstants.GUIDE_TOLERANCE = 15;
        this.setGridSize(10);
    }

    getLinkForCell(cell: MxCell) {
        return null;
    }

    isReplacePlaceholders() {
        return false;
    }

    isPool(cell: MxCell): boolean {
        const model = this.getModel();
        const parent = model.getParent(cell);

        return parent != null && model.getParent(parent) === model.getRoot();
    }

    setIntl(intl: any): void {
        // tslint:disable-line:no-any
        this.intl = intl;
    }

    setHoverIcons(hoverIcons: BPMHoverIcons): void {
        this.hoverIcons = hoverIcons;
    }

    setBpmn2DiagramHandler(bpmnDiagramHandler: BPMN2Diagram): void {
        this.bpmn2DiagramHandler = bpmnDiagramHandler;
    }

    setPsdDiagramHandler(psdDiagramHandler: BPMPSDDiagram): void {
        this.psdDiagramHandler = psdDiagramHandler;
    }

    setDirty(value: boolean): void {
        this.dirty = value;
    }

    setMouseMovePoint(evt: Event): void {
        this.mouseMovePoint = MxUtils.convertPoint(this.container, MxEvent.getClientX(evt), MxEvent.getClientY(evt));
    }

    setKeyHandler(keyHandler: BPMMxKeyHandler) {
        this.keyHandler = keyHandler;
    }

    getMouseMovePoint(): MxPoint {
        return this.mouseMovePoint;
    }

    isDirty(): boolean {
        return this.dirty;
    }

    init(container: any) {
        // tslint:disable-line:no-any
        // property override would not work
        // https://github.com/Microsoft/TypeScript/issues/1617
        this.scrollTileSize = new MxRectangle(0, 0, 600, 400);
        super.init(container);
        MxEvent.disableContextMenu(container);
        this.initSwimPoolStyles();
        this.initGuides();
    }

    initModelType(modelType?: ModelType): void {
        if (modelType) {
            this.modelType = modelType;
        }
    }

    createPopupMenuHandler(createPopupMenuFunct?: any): BPMMxPopupMenuHandler {
        // tslint:disable-line:no-any
        const factoryMethod = (menu: any, cell: MxCell, evt: PointerEvent) => {
            // tslint:disable-line:no-any
            if (createPopupMenuFunct) {
                createPopupMenuFunct(this, menu, cell, evt);
            }
        };

        this.popupMenuHandler = new BPMMxPopupMenuHandler(this, factoryMethod);

        return this.popupMenuHandler;
    }

    loadPopupMenu(menu: MxPopupMenu, cell: MxCell, disabled: boolean = false) {
        this.complexSymbolManager.loadPopupMenu(menu, cell);
    }

    createTooltipHandler(): BPMMxTooltipHandler {
        this.tooltipHandler = new BPMMxTooltipHandler(this, 500);

        return this.tooltipHandler;
    }

    hidePopupMenuHandler(menu: MxPopupMenu) {
        const clickEventHandler = () => {
            /**
             * Клик по элементу выпадающего меню не должен закрывать меню.
             * Проверяем, является ли елемент меню выпадающим списком, проверяя по класс, который навешивает
             автоматически при клике ant в методе renderItem() модуля BPMGraphClasses.tsx
            */

            const checkIsSubMenu = () => {
                const elements = document.getElementsByClassName('ant-dropdown-menu-submenu-active');

                return !!elements.length;
            };

            const isSubMenu = checkIsSubMenu();

            if (isSubMenu) {
                return;
            }

            setTimeout(() => {
                menu.hideMenu();
            }, 100);
            document.body.removeEventListener('click', clickEventHandler);
        };
        document.body.addEventListener('click', clickEventHandler);
    }

    createConnectionHandler(): BPMMxConnectionHandler {
        return new BPMMxConnectionHandler(this, getConnectionHandlerFactoryMethod(this));
    }

    createGraphView(): BPMMxGraphView {
        return new BPMMxGraphView(this);
    }

    createVertexHandler(state: any): BPMMxVertexHandler {
        return new BPMMxVertexHandler(state);
    }

    createGraphHandler(): BPMMxGraphHandler {
        return new BPMMxGraphHandler(this);
    }

    createCellEditor(): BPMMxCellEditor {
        // @ts-ignore
        return new BPMMxCellEditor(this);
    }

    /*
     * This method is called in MxGraph.getEdgeValidationError()
     * after successful checking default MxGraph validation mechanics.
     */
    validateEdge(edge: MxCell, source: MxCell, target: MxCell): string | null {
        return NotationHelper.validateEdge(
            <ObjectInstance>source.getValue(),
            <ObjectInstance>target.getValue(),
            this.modelType!,
            this.id.serverId,
            this.connectionHandler.currentEdgeType,
        );
    }

    validateEdgeReconnect(edge: MxCell, source: MxCell, target: MxCell): string | null {
        return NotationHelper.validateEdgeReconnect(edge.getValue()) || this.validateEdge(edge, source, target);
    }

    validationAlert(err: string) {
        console.warn(err);
    }

    // todo это надо куда-то перенести
    private getNode(nodeId: NodeId | undefined, nodeType: NodeTypeEnum): Node | undefined {
        if (nodeType === 'MODEL') {
            return modelService().loadModelFromStore(nodeId);
        }
        if (nodeType === 'OBJECT') {
            return nodeId && objectDefinitionService().getObjectDefinition(nodeId);
        }

        return undefined;
    }

    convertValueToString(cell: MxCell): string {
        const value = cell.getValue();

        if (ComplexSymbolManager.isComplexSymbolCell(cell)) {
            return this.complexSymbolManager?.convertValueToString(cell);
        }

        if (value?.type === 'edge') {
            return edgeDefinitionService().getEdgeNameByInstance(<EdgeInstance>value, this.id);
        }

        if (value?.type === SymbolType.COMMENT) {
            return value.label;
        }

        if (value?.type === 'layout' && value.isPSDCell) {
            return value?.psdCellMetaInfo?.name || '';
        }

        if (value?.type === SymbolType.SHAPE) {
            return 'metaInfo' in value ? value.metaInfo : '';
        }

        const text = value && resolveName(cell, this.id, this.getNode);

        if (text || text === '') {
            return text;
        }

        if (typeof value === 'object') return '';

        return super.convertValueToString(cell);
    }

    cellLabelChanged(cell: MxCell, value: string, autoSize: boolean, isUndoable: boolean = true): void {
        // tslint:disable-line:no-any
        const cellValue = cell.getValue();

        if (!cellValue) {
            MxGraph.prototype.cellLabelChanged.call(this, cell, value, autoSize);

            return;
        }

        if (isUndoable) {
            this.model.execute(new BPMMxObjectDefinitionNameChangeClass(cell, this.getLabel(cell), value, this));
        }

        if (cellValue.type === 'object' || cellValue.type === SymbolType.LABEL) {
            MxGraph.prototype.cellLabelChanged.call(this, cell, cellValue, autoSize);
        } else if (cellValue.type === 'edge') {
            const edge = cellValue as EdgeInstanceImpl;
            edge.name = value;
            const locale = LocalesService.getLocale();
            edge.multilingualName = LocalesService.changeLocaleValue(edge.multilingualName, locale, value);
            MxGraph.prototype.cellLabelChanged.call(this, cell, cellValue, autoSize);
        } else if (cellValue.type === 'layout') {
            if (
                cellValue.isPSDCell &&
                cellValue.psdCellMetaInfo!.name &&
                cellValue.psdCellMetaInfo!.type !== psdCellType.BPMN_LANE &&
                cellValue.psdCellMetaInfo!.type !== psdCellType.BPMN_POOL
            ) {
                cellValue.psdCellMetaInfo!.name = value;
                this.psdDiagramHandler.labelChanged(cell, value);
            } else if (
                cellValue.isPSDCell &&
                cellValue.psdCellMetaInfo!.name &&
                (cellValue.psdCellMetaInfo!.type === psdCellType.BPMN_LANE ||
                    cellValue.psdCellMetaInfo!.type === psdCellType.BPMN_POOL)
            ) {
                cellValue.psdCellMetaInfo!.name = value;
            }
            MxGraph.prototype.cellLabelChanged.call(this, cell, cellValue, autoSize);
        } else if (cellValue.type === SymbolType.SHAPE) {
            (cellValue as ShapeInstance).metaInfo = value;
            MxGraph.prototype.cellLabelChanged.call(this, cell, cellValue, autoSize);
        } else {
            MxGraph.prototype.cellLabelChanged.call(this, cell, value, autoSize);
        }
    }

    getLabel(cell: MxCell): string {
        const style = this.getCellStyle(cell);
        const isLabelShow = this.labelsVisible && style[MxConstants.STYLE_NOLABEL] !== 1;
        const label = isLabelShow ? this.convertValueToString(cell) : '';

        return label;
    }

    getPagePadding(): MxPoint {
        return new MxPoint(0, 0);
    }

    getPageSize(): MxRectangle {
        return this.pageVisible
            ? new MxRectangle(0, 0, this.pageFormat.width * this.pageScale, this.pageFormat.height * this.pageScale)
            : this.scrollTileSize;
    }

    getPageLayout(): MxRectangle {
        const size = this.pageVisible ? this.getPageSize() : this.scrollTileSize;
        const bounds = this.getGraphBounds();

        if (bounds.width === 0 || bounds.height === 0) {
            return new MxRectangle(0, 0, 1, 1);
        }

        // Computes untransformed graph bounds
        const x = Math.ceil(bounds.x / this.view.scale - this.view.translate.x);
        const y = Math.ceil(bounds.y / this.view.scale - this.view.translate.y);
        const w = Math.floor(bounds.width / this.view.scale);
        const h = Math.floor(bounds.height / this.view.scale);

        const x0 = Math.floor(x / size.width);
        const y0 = Math.floor(y / size.height);
        const widthTileCount = Math.ceil((x + w - this.container.clientWidth) / this.scrollTileSize.width);
        const fullWidth = this.container.clientWidth + this.scrollTileSize.width * widthTileCount;
        const newWidthTile = (x + w) / ((fullWidth * 2) / 3) >= 1 ? 1 : 0;
        const w0 = widthTileCount + newWidthTile;

        const heightTileCount = Math.ceil((y + h - this.container.clientHeight) / this.scrollTileSize.height);
        const fullHeight = this.container.clientHeight + this.scrollTileSize.height * heightTileCount;
        const newHeightTile = (y + h) / ((fullHeight * 2) / 3) >= 1 ? 1 : 0;
        const h0 = heightTileCount + newHeightTile;

        return new MxRectangle(x0, y0, w0, h0);
    }

    getPreferredPageSize(): MxRectangle {
        const pages = this.getPageLayout();
        const size = this.getPageSize();

        return new MxRectangle(0, 0, pages.width * size.width, pages.height * size.height);
    }

    sizeDidChange(): void {
        if (this.container != null && MxUtils.hasScrollbars(this.container)) {
            const pages = this.getPageLayout();

            // Updates the minimum graph size
            const minw = Math.ceil(pages.width * this.scrollTileSize.width) + this.container.clientWidth - 10;
            const minh = Math.ceil(pages.height * this.scrollTileSize.height) + this.container.clientHeight - 10;

            const min = this.minimumGraphSize;

            // LATER: Fix flicker of scrollbar size in IE quirks mode
            // after delayed call in window.resize event handler
            if (min === null || min.width !== minw || min.height !== minh) {
                this.minimumGraphSize = new MxRectangle(0, 0, minw, minh);
            }
            super.sizeDidChange();
        }
    }

    setEdgesSelectable(value: boolean) {
        this.edgesSelectable = value;
    }

    isEdgesSelectable() {
        return this.edgesSelectable;
    }

    isCellSelectable(cell: MxCell): boolean {
        const state = this.view.getState(cell, false);
        const style = state != null ? state.style : this.getCellStyle(cell);

        return this.isCellsSelectable() && !this.isCellLocked(cell) && style[BPMMxConstants.STYLE_SELECTABLE] !== 0;
    }

    isCellDisconnectable(cell) {
        const style = this.getCurrentCellStyle(cell);

        return (
            this.isCellsDisconnectable() && !this.isCellLocked(cell) && style[BPMMxConstants.STYLE_DISCONNECTABLE] !== 0
        );
    }

    getAllConnectionConstraints(terminal: MxCellState): MxConnectionConstraint[] {
        const { shape } = terminal;

        if (
            !isNullOrUndefined(terminal) &&
            !isNullOrUndefined(terminal.shape) &&
            shape?.style?.shape?.toString() !== 'connector'
        ) {
            // for stencils with existing constraints...
            let constraints: any = null;

            if (shape.stencil != null) {
                constraints = shape.stencil.constraints;
            }
            if (constraints === null) {
                let ports;

                if (this.getSelectionModel().isSelected(terminal.cell)) {
                    ports = [];
                } else {
                    ports = getPorts();
                }
                constraints = [];

                for (const id in ports) {
                    if (ports.hasOwnProperty(id)) {
                        const port = ports[id];
                        const cstr = new MxConnectionConstraint(new MxPoint(port.x, port.y), port.perimeter);
                        cstr.id = id;
                        constraints.push(cstr);
                    }
                }
            }

            return constraints;
        }

        return [];
    }
    isDropAvailable(source: MxCell[], dropTarget: MxCell, symbolId: string) {
        return true;
    }

    getDropTarget(draggedCells: MxCell[], evt: MouseEvent, hoverCell: MxCell, clone?: boolean) {
        const target: MxCell | null = super.getDropTarget.apply(this, arguments);
        const hasDraggedTable = draggedCells.some((cell) => this.isTable(cell));

        if (isCommentCell(draggedCells[0])) {
            const targetCell: MxCell | null = this.getCellAt(evt.offsetX, evt.offsetY);

            if (targetCell && !targetCell.isEdge()) {
                return ComplexSymbolManager.getComplexSymbolRootCell(hoverCell) || hoverCell;
            }
        }

        if (hasDraggedTable) {
            return null;
        }

        const isValidDropTarget =
            this.isValidCommonDropTarget(target, draggedCells) ??
            (target && this.getModel().getChildCount(target) > 0 && !this.isCellCollapsed(target));

        if (!isValidDropTarget) {
            return null;
        }

        if (target && this.isTable(target)) {
            const pt = MxUtils.convertPoint(this.container, MxEvent.getClientX(evt), MxEvent.getClientY(evt));
            const { x, y } = pt;
            const validTableChildCell = this.getCellAt(x, y);

            return validTableChildCell;
        }

        return target;
    }

    cleanupHiddenEdges(movedCells: MxCell[]) {
        const model = this.getModel();
        const hiddenEdges: MxCell[] = [];
        const hiddenEdgesToDelete: MxCell[] = [];

        movedCells.forEach((cell) => {
            const cellHiddenEdges: MxCell[] = model.getEdges(cell, true, true, false)?.filter((edge) => !edge.visible);

            if (cellHiddenEdges.length > 0) {
                hiddenEdges.push(...cellHiddenEdges);
            }
        });

        if (hiddenEdges.length === 0) return;

        hiddenEdges.forEach((edge) => {
            const { source, target } = edge;
            const sourceCellGeometry = getCellGeometry(source, model);
            const targetCellGeometry = getCellGeometry(target, model);
            const intersectPercent = getIntersectPercentBySmallerRect(sourceCellGeometry, targetCellGeometry);

            if (intersectPercent < 5) hiddenEdgesToDelete.push(edge);
        });

        if (hiddenEdgesToDelete.length === 0) return;
        if (this.handleCleanupInvisibleEdges) this.handleCleanupInvisibleEdges(hiddenEdgesToDelete);
    }

    handleCellIntersection(movedCell: MxCell) {
        const intersectedCell = getIntersection(
            this as BPMMxGraph,
            movedCell,
            this.intersectionMap,
            this.intersectableCellTypes,
        );

        if (intersectedCell && this.handleCheckInvisibleEdgeTypes) {
            this.handleCheckInvisibleEdgeTypes(movedCell, intersectedCell);
        }

        if (intersectedCell !== undefined) {
            this.intersectionMap[movedCell.id] = intersectedCell?.id || null;
        }
    }

    handleCellMoving(movingCell: MxCell) {
        const isCellMoved = this.intersectionMap[movingCell.id] !== undefined;

        if (isCellMoved) return;

        const intersectedCell = getIntersection(
            this as BPMMxGraph,
            movingCell,
            this.intersectionMap,
            this.intersectableCellTypes,
        );

        if (intersectedCell !== undefined) {
            this.intersectionMap[movingCell.id] = intersectedCell?.id || null;
        }
    }

    isValidCommonDropTarget(targetCell: MxCell | null, draggedCells: MxCell[]): boolean {
        if (!targetCell) {
            return false;
        }

        // TODO вынести в CommentSymbol.class
        if (targetCell.children) {
            const hasCommentChild = (targetCell.children as MxCell[]).some(isCommentCell);
            const styleEditableCellDragged = draggedCells.some(ComplexSymbolManager.isCellStyleEditable);

            if (hasCommentChild && styleEditableCellDragged) {
                return false;
            }
        }

        const cellValue = targetCell?.getValue();
        const cellStyle = targetCell?.style || '';

        if (cellValue?.type === 'edge') {
            return false;
        }

        if (!isNullOrUndefined(this.psdDiagramHandler)) {
            if (
                cellValue?.type === 'layout' &&
                cellValue.isPSDCell &&
                !isNullOrUndefined(cellValue.psdCellMetaInfo?.allowedSymbols) &&
                hasForbiddenSymbols(cellValue, draggedCells)
            ) {
                return false;
            }

            return true;
        }

        if (!isNullOrUndefined(this.bpmn2DiagramHandler)) {
            if (cellValue?.type !== 'layout') {
                return false;
            }

            const styleEditableCellDragged = draggedCells.some(ComplexSymbolManager.isCellStyleEditable);

            if (styleEditableCellDragged) {
                return false;
            }

            return true;
        }

        const isDropDisabledOnCell = cellStyle.includes(`${DROP_TARGET_ATTRIBUTE}=0`);

        if (isDropDisabledOnCell) {
            return false;
        }

        // TODO вынести логику в классы символов, основанных на swimlane
        if (cellStyle.includes('swimlane')) {
            if (
                draggedCells.length === 1 &&
                ComplexSymbolManager.isComplexSymbolCell(draggedCells[0]) &&
                ComplexSymbolManager.isCellStyleEditable(draggedCells[0])
            ) {
                return false;
            }

            return true;
        }
        return false;
    }

    getSvgStrAllGraph({
        includeLinks,
        cropBounds,
        withoutNegativeCoordinates,
        withoutDecompositionIcons,
        isPrint,
    }: TGetSvgAllGraptProps = {}): SvgImage {
        const cells: MxCell[] = Object.values(this.getModel().cells);
        const sortedCells = MxUtils.sortCells(cells, false);

        return this.getSvgImage({
            cropBounds,
            includeLinks,
            cellsForCopy: sortedCells,
            withoutNegativeCoordinates,
            onlySelectedCells: false,
            withoutDecompositionIcons,
            isPrint,
        });
    }

    getSvgStrSelectedCells(withoutNegativeCoordinates?: boolean, withoutDecompositionIcons?: boolean): SvgImage {
        const selectedCells = Object.values(this.getSelectionCells());
        const cellsWithParts: MxCell[] = ComplexSymbolManager.getSymbolCells(selectedCells);

        return this.getSvgImage({
            cellsForCopy: cellsWithParts,
            withoutNegativeCoordinates,
            onlySelectedCells: true,
            withoutDecompositionIcons,
        });
    }

    getBoundingBoxWithOverlayOffsets(cells: MxCell[]) {
        const graph = this;
        let borderWidth = 0;
        const scale = this.view.scale;

        cells.forEach((cell) => {
            const cellView = graph.view.getState(cell);

            if (cellView?.shape?.stencil?.desc) {
                borderWidth = Number(cellView.shape.stencil.desc.getAttribute('borderWidth')) || 0;
            }
        });

        const cellsBounds = super.getBoundingBox(cells) || new MxRectangle(0, 0, 0, 0);
        cellsBounds.width += borderWidth;
        cellsBounds.height += borderWidth;
        cellsBounds.x -= borderWidth / 2;
        cellsBounds.y -= borderWidth / 2;
        const overlaysBounds = { ...cellsBounds };
        let maxX = cellsBounds.x + cellsBounds.width;
        let maxY = cellsBounds.y + cellsBounds.height;

        cells
            .filter((cell) => cell?.overlays?.length)
            .forEach((cell) => {
                cell.overlays?.forEach((overlay) => {
                    const state = graph.view.getState(cell, false);
                    const offset = overlay.getBounds(state);
                    overlaysBounds.x = Math.min(overlaysBounds.x, offset.x - 3);
                    overlaysBounds.y = Math.min(overlaysBounds.y, offset.y);
                    maxX = Math.max(maxX, offset.x + offset.width);
                    maxY = Math.max(maxY, offset.y + offset.height);
                });
            });

        overlaysBounds.width = maxX - overlaysBounds.x;
        overlaysBounds.height = maxY - overlaysBounds.y;

        const w = Math.max(1, Math.ceil(overlaysBounds.width * scale));
        const h = Math.max(1, Math.ceil(overlaysBounds.height * scale));
        const viewBoxX = Math.min(overlaysBounds.x, cellsBounds.x) * scale;
        const viewBoxY = Math.min(overlaysBounds.y, cellsBounds.y) * scale;

        const viewBoxWidth = Math.ceil(overlaysBounds.width * scale);
        const viewBoxHeight = Math.ceil(overlaysBounds.height * scale);

        return {
            cellsBounds: new MxRectangle(0, 0, w, h),
            overlaysBounds: new MxRectangle(viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight),
        };
    }

    /**
     * @param cellsForCopy - набор ячеек входящих в svg.
     * @param withoutNegativeCoordinates - флаг учета отрицательных координат viewBox.
     * Отрицательные координаты смещают отображение за пределы графа.
     * @returns `svgString`, `height`, `width`
     *
     * @remarks Метод getSvg используется при гененрации svg модели для печати,
     * генерации svg для редактора связей, для редактора символов и для всех мест,
     * где отображаются списки символов или связей.
     * При расчете полей для печати, отрицательнае координаты viewBox не учитываются,
     * поэтому необходимо исключать их. Но, если связь или символ имеет бордер большой
     * ширины, его графическое отображения выходит за края и появляются отрицательные
     * координаты viewBox.
     * */
    getSvgImage({
        cellsForCopy,
        includeLinks,
        withoutNegativeCoordinates,
        onlySelectedCells,
        withoutDecompositionIcons,
        isPrint,
        cropBounds,
    }: TGetSvgImageProps): SvgImage {
        const svg: HTMLElement | undefined = this.getSvg({
            cropBounds,
            includeLinks,
            cellsForCopy,
            withoutNegativeCoordinates,
            onlySelectedCells,
        });

        if (svg) {
            const images: HTMLCollectionOf<SVGImageElement> = svg.getElementsByTagName('image');
            const decompositionIcons: SVGImageElement[] = Array.from(images).filter(
                (image) => image.href.baseVal === DECOMPOSITION_ICON,
            );
            const isDecompositionHidden: boolean = getHideDecomposition(isPrint);

            if (withoutDecompositionIcons || isDecompositionHidden) {
                decompositionIcons.forEach((icon) => icon.setAttribute('display', 'none'));
            }

            const serializer = new XMLSerializer();
            const originalHeight: number = parseInt(svg.getAttribute('height') || '0', 10);
            const originalWidth: number = parseInt(svg.getAttribute('width') || '0', 10);
            const grayScale: string = getGrayScale(isPrint);
            const scale: number = getImageScale(
                originalWidth,
                originalHeight,
                DEFAULT_IMAGE_SCALE,
                IMAGE_MAX_WIDTH,
                IMAGE_MAX_HEIGHT,
            );
            const height = cropBounds ? originalHeight : originalHeight * scale;
            const width = cropBounds ? originalWidth : originalWidth * scale;

            svg.setAttribute('height', height.toString());
            svg.setAttribute('width', width.toString());
            svg.setAttribute('filter', grayScale);

            const svgString: string = serializer.serializeToString(svg);

            return { svgString, height, width };
        }

        return { svgString: '', height: 0, width: 0 };
    }

    getSvg({ cropBounds, includeLinks, cellsForCopy, withoutNegativeCoordinates, onlySelectedCells }: TGetSvgProps) {
        const initialScale = this.view.scale;

        this.view.setScale(1);

        const { cellsBounds, overlaysBounds } = cropBounds
            ? { cellsBounds: cropBounds, overlaysBounds: cropBounds }
            : this.getBoundingBoxWithOverlayOffsets(cellsForCopy);

        if (cellsBounds == null) {
            this.view.setScale(initialScale);

            return;
        }
        // Prepares SVG document that holds the output
        const svgDoc: Document = MxUtils.createXmlDocument();
        const root =
            svgDoc.createElementNS != null
                ? svgDoc.createElementNS(MxConstants.NS_SVG, 'svg')
                : svgDoc.createElement('svg');

        if (svgDoc.createElementNS == null) {
            root.setAttribute('xmlns', MxConstants.NS_SVG);
            root.setAttribute('xmlns:xlink', MxConstants.NS_XLINK);
        } else {
            // KNOWN: Ignored in IE9-11, adds namespace for each image element instead. No workaround.
            root.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', MxConstants.NS_XLINK);
        }

        root.setAttribute('version', '1.1');
        root.setAttribute('width', `${cellsBounds.width}`);
        root.setAttribute('height', `${cellsBounds.height}`);

        if (withoutNegativeCoordinates) {
            root.setAttribute(
                'viewBox',
                `${overlaysBounds.x < 0 ? 0 : overlaysBounds.x} ${overlaysBounds.y < 0 ? 0 : overlaysBounds.y} ${
                    overlaysBounds.width
                } ${overlaysBounds.height}`,
            );
        } else {
            root.setAttribute(
                'viewBox',
                `${overlaysBounds.x} ${overlaysBounds.y} ${overlaysBounds.width} ${overlaysBounds.height}`,
            );
        }

        svgDoc.appendChild(root);

        // Renders graph. Offset will be multiplied with state's scale when painting state.
        // TextOffset only seems to affect FF output but used everywhere for consistency.
        const group =
            svgDoc.createElementNS != null
                ? svgDoc.createElementNS(MxConstants.NS_SVG, 'g')
                : svgDoc.createElement('g');

        root.appendChild(group);

        const svgCanvas = new BPMMxSvgCanvas2D(group, false);
        svgCanvas.foOffset = 0;
        svgCanvas.textOffset = 0;
        svgCanvas.imageOffset = 0;
        svgCanvas.textEnabled = true;

        const imgExport = new BPMMxImageExport({ includeLinks });
        const selectionStyle = `${MxConstants.STYLE_STROKECOLOR}=${this.labelBorderColor}`;
        const filteredCellsForCopy = onlySelectedCells ? cellsForCopy : cellsForCopy.filter((cell) => cell.id === '1');

        filteredCellsForCopy.forEach((cell) => {
            const state = this.getView().getState(cell);

            // снимаем выделение с уже выделенных лейблов для отображения на Svg
            if (!ComplexSymbolManager.isSelectableCell(cell) && cell.style?.includes(selectionStyle)) {
                state.shape.stroke = undefined;
            }

            imgExport.drawState(state, svgCanvas);
        });

        this.view.setScale(initialScale);
        return root;
    }

    createEdgeSegmentHandler(state: MxCellState) {
        this.segmentHandler = new BPMMxEdgeSegmentHandler(state);

        return this.segmentHandler;
    }

    createSelectionCellsHandler(): MxSelectionCellsHandler {
        // @ts-ignore
        return new BPMMxSelectionCellsHandler(this);
    }

    isCellEditable(cell: MxCell): boolean {
        return super.isCellEditable(cell) && ComplexSymbolManager.isCellEditable(cell);
    }

    startEditingAtCell(cell: MxCell, evt: MouseEvent) {
        const targetCell = ComplexSymbolManager.getCellForEdit(cell);

        super.startEditingAtCell(targetCell, evt);
    }

    createCustomEdge(
        edgeValue: string | EdgeInstanceImpl = '',
        edgeStyle: string = 'startArrow=none;endArrow=block;',
    ): MxCell {
        const geo = new MxGeometry();
        geo.relative = true;
        const currentEdgeStyles = this.createCurrentEdgeStyle();
        const edge = new MxCell(edgeValue, geo, currentEdgeStyles + edgeStyle);
        edge.edge = true;
        edge.setConnectable(false);

        return edge;
    }

    createEdgeStyle(edgeType: EdgeType, exits: string): string {
        let edgeTypeStyle = '';

        if (edgeType.edgeStyle) {
            edgeTypeStyle = edgeType.edgeStyle;
            const edgeStyleLastChar = edgeTypeStyle[edgeTypeStyle.length - 1];

            if (edgeStyleLastChar !== ';') {
                edgeTypeStyle += ';';
            }
        }

        if (edgeTypeStyle.includes('edgeStyle')) {
            return `${edgeTypeStyle}${exits}`;
        }

        const currentEdgeStyles = this.createCurrentEdgeStyle();

        return `${currentEdgeStyles}${edgeTypeStyle}${exits}`;
    }

    moveCells(
        cells: MxCell[],
        dx: number,
        dy: number,
        clone: boolean,
        target?: MxCell | null,
        evt?: Event,
        mapping?: any,
    ): MxCell[] {
        const movableCells = ComplexSymbolManager.getMovableCells(cells);

        return super.moveCells(movableCells, dx, dy, clone, target, evt, mapping);
    }

    getStartEditingCell(cell: MxCell, trigger: MouseEvent) {
        const targetCell = ComplexSymbolManager.getCellForEdit(cell);

        return super.getStartEditingCell(targetCell, trigger);
    }

    insertVertexFromValue(cellValue: DiagramElement) {
        const { parent = this.getDefaultParent(), id, x, y, width, height, style } = cellValue;

        return super.insertVertex(
            this.getModel().getCell(parent),
            id,
            cellValue,
            Number(x) || 0,
            Number(y) || 0,
            Number(width) || 100,
            Number(height) || 100,
            style || '',
        );
    }

    removeCells(cells: MxCell[], includeEdges?: boolean): MxCell[] {
        if (!cells?.length) {
            return [];
        }

        const cellsWithParts: MxCell[] = ComplexSymbolManager.getSymbolCells(cells);

        cells.forEach((cell) => this.complexSymbolManager?.removeManagedCell(cell));

        return super.removeCells(cellsWithParts, includeEdges);
    }

    resizeCells(cells: MxCell[], bounds: MxRectangle[]): MxCell[] {
        const [newCells, newBounds]: [MxCell[], MxRectangle[]] = ComplexSymbolManager.getResizedCells(cells, bounds);

        return super.resizeCells([...cells, ...newCells], [...bounds, ...newBounds]);
    }

    selectCellForEvent(cell: MxCell, evt?: PointerEvent | null, forceLabelSelect?: boolean | null) {
        const cellForSelect: MxCell = forceLabelSelect ? cell : this.getCellForSelect(cell, evt);
        super.selectCellForEvent(cellForSelect, evt);
    }

    setCellsActive(cells: MxCell[]) {
        this.setCellStyles(MxConstants.STYLE_OPACITY, null, cells);
        this.setCellStyles(MxConstants.STYLE_TEXT_OPACITY, null, cells);
    }

    setCellsInactive(cells: MxCell[]) {
        this.setCellStyles(MxConstants.STYLE_OPACITY, INACTIVE_CELL_STYLE_OPACITY, cells);
        this.setCellStyles(MxConstants.STYLE_TEXT_OPACITY, INACTIVE_CELL_STYLE_OPACITY, cells);
    }

    private cancelablePromise = new CancelablePromise();

    setCellGroupStyles(cells: MxCell[] = [], styles: { [key: string]: string | null }) {
        this.cancelablePromise.cancel();
        const model = this.getModel();

        model.beginUpdate();

        const promises = cells.map((cell) => {
            return this.cancelablePromise.get(() => {
                each(styles, (value, key) => {
                    const style = MxUtils.setStyle(model.getStyle(cell), key, value);

                    model.setStyleManual(cell, style);
                });
            });
        });

        Promise.all(promises).finally(() => {
            model.endUpdate();
        });
    }

    destroy() {
        this.undoManager.clear();
        super.destroy();
    }

    importCellsWithIds(originalCells: MxCell[], position: MxPoint, target: MxCell | string) {
        const { x, y } = position;
        const parent = typeof target === 'string' ? this.getModel().getCell(target) : target || this.getDefaultParent();
        const cells = super.importCells(originalCells, x, y, parent);
        const oldCellsList: MxCell[] = this.treeToList(originalCells);
        const newCellsList: MxCell[] = this.treeToList(cells);
        const model = this.getModel();

        if (newCellsList.length !== oldCellsList.length) {
            console.error(
                `Error during insert. Copy cells return ${newCellsList.length} but expect ${oldCellsList.length}`,
            );
        }

        const ids: string[] = [];

        newCellsList.forEach((cell, i) => {
            if (!cell.value) {
                cell.value = '';
            }

            const id = oldCellsList[i].getId();
            const oldId = cell.getId();

            cell.setId(id);
            delete model.cells[oldId];

            model.cells[id] = cell;
            ids.push(id);
        });

        return [cells, ids];
    }

    click(me: Event) {
        MxGraph.prototype.click.call(this, me);
    }

    dblClick(evt: Event, cell: MxCell) {
        MxGraph.prototype.dblClick.call(this, evt, cell);
    }

    isZoomWheelEvent(evt) {
        return MxEvent.isAltDown(evt) || MxEvent.isMetaDown(evt) || MxEvent.isControlDown(evt);
    }

    isScrollWheelEvent(evt) {
        return !this.isZoomWheelEvent(evt);
    }

    distributeCells(horizontal: boolean, cells?: MxCell[]) {
        if (cells == null) {
            cells = this.getSelectionCells();
        }

        if (cells != null && cells.length > 1) {
            const vertices: MxCellState[] = [];

            let max: number = Number.MIN_SAFE_INTEGER;
            let min: number = Number.MAX_SAFE_INTEGER;

            for (let i = 0; i < cells.length; i++) {
                if (this.getModel().isVertex(cells[i]) && cells[i].getValue()?.type !== SymbolType.LABEL) {
                    const state = this.view.getState(cells[i]);

                    if (state != null) {
                        const tmp: number = horizontal ? state.getCenterX() : state.getCenterY();
                        max = Math.max(max, tmp);
                        min = Math.min(min, tmp);
                        vertices.push(state);
                    }
                }
            }

            if (vertices.length > 2) {
                vertices.sort((a, b) => (horizontal ? a.x - b.x : a.y - b.y));

                const t = this.view.translate;
                const s = this.view.scale;
                min = min / s - (horizontal ? t.x : t.y);
                max = max / s - (horizontal ? t.x : t.y);

                this.getModel().beginUpdate();

                try {
                    const dt = (max - min) / (vertices.length - 1);
                    let t0 = min;

                    for (let i = 1; i < vertices.length - 1; i++) {
                        const pstate = this.view.getState(this.getModel().getParent(vertices[i].cell));
                        let geo: MxGeometry = this.getCellGeometry(vertices[i].cell);
                        t0 += dt;

                        if (geo != null && pstate != null) {
                            geo = clone(geo);

                            if (horizontal) {
                                geo.x = Math.round(t0 - geo.width / 2) - pstate.origin.x;
                            } else {
                                geo.y = Math.round(t0 - geo.height / 2) - pstate.origin.y;
                            }

                            // В оригинальном mxGraph использовалась ф-ция setGeometry, но она перемещает объекты без лейблов
                            // this.getModel().setGeometry(vertices[i].cell, geo);
                            this.resizeCell(vertices[i].cell, geo);
                        }
                    }
                } finally {
                    this.getModel().endUpdate();
                }
            }
        }

        return cells;
    }

    private treeToList<T extends { children: T[] }>(cells: T[] = []): T[] {
        return !cells ? [] : cells.reduce((p, c) => [...p, c, ...this.treeToList(c.children)], []);
    }

    private getCellForSelect(cell: MxCell, evt?: PointerEvent | null): MxCell {
        const selectedCells: MxCell[] = this.getSelectionModel().cells;
        const hasUnselectableCells: boolean = !selectedCells.every(ComplexSymbolManager.isSelectableCell);

        if (this.isToggleEvent(evt) && hasUnselectableCells) {
            return selectedCells[0];
        }

        if (!ComplexSymbolManager.isSelectableCell(cell)) {
            const selectableCell = ComplexSymbolManager.getSelectableCell(cell);
            const selectedCellId: string = selectedCells?.length > 0 ? selectedCells[0].id : '';

            if (evt) {
                const childCell = this.getCellAt(evt.offsetX, evt.offsetY, selectableCell);

                if (isCommentCell(childCell)) {
                    return childCell;
                }
            }

            if (
                (this.isToggleEvent(evt) || selectedCellId === '' || selectableCell.id !== selectedCellId) &&
                cell.id !== selectedCellId
            ) {
                return selectableCell;
            }
        }

        return cell;
    }

    private redrawCellBoxes(selectedCells: MxCell[]) {
        this.getModel().beginUpdate();

        try {
            this.lastSelectedCells = this.complexSymbolManager.redrawCellBoxes(selectedCells, this.lastSelectedCells);
        } finally {
            this.getModel().endUpdate(false);
        }
    }

    private initDefaultFontFamily() {
        const defaultStyle = this.getStylesheet().getDefaultVertexStyle();
        defaultStyle[MxConstants.STYLE_FONTFAMILY] = IsMacOS ? 'Helvetica' : 'Arial';
    }

    protected initComplexSymbolManager() {
        this.complexSymbolManager = new ComplexSymbolManager(this);
    }

    static postRender(cb: () => void) {
        return setTimeout(cb, 0); // время рендера ячейки в DOM дереве
    }

    private getOffsetForAlignment(align: string, cells: MxCell[], offset: number | null): number | null {
        let minY;
        let maxY;
        let minX;
        let maxX;
        let x;
        let y;

        for (let i = 0; i < cells.length; i++) {
            const state = this.view.getState(cells[i]);

            if (state) {
                switch (align) {
                    case MxConstants.ALIGN_CENTER:
                        x = state.x + state.width / 2;
                        minX = minX !== undefined ? Math.min(minX, x) : x;
                        maxX = maxX !== undefined ? Math.max(maxX, x) : x;
                        offset = minX + (maxX - minX) / 2;
                        break;
                    case MxConstants.ALIGN_RIGHT:
                        x = state.x + state.width;
                        offset = offset !== null ? Math.max(offset, x) : x;
                        break;
                    case MxConstants.ALIGN_TOP:
                        y = state.y;
                        offset = offset !== null ? Math.min(offset, y) : y;
                        break;
                    case MxConstants.ALIGN_BOTTOM:
                        y = state.y + state.height;
                        offset = offset !== null ? Math.max(offset, y) : y;
                        break;
                    case MxConstants.ALIGN_MIDDLE:
                        y = state.y + state.height / 2;
                        minY = minY !== undefined ? Math.min(minY, y) : y;
                        maxY = maxY !== undefined ? Math.max(maxY, y) : y;
                        offset = minY + (maxY - minY) / 2;
                        break;
                    default:
                        x = state.x;
                        offset = offset !== null ? Math.min(offset, x) : x;
                        break;
                }
            }
        }

        return offset;
    }

    private moveCellsToOffset(align: string, cells: MxCell[], offset: number) {
        const s = this.view.scale;

        this.model.beginUpdate();
        try {
            for (let i = 0; i < cells.length; i++) {
                const state = this.view.getState(cells[i]);
                const geo = this.getCellGeometry(cells[i])?.clone();

                if (state && geo) {
                    switch (align) {
                        case MxConstants.ALIGN_CENTER:
                            geo.x += (offset - state.x - state.width / 2) / s;
                            break;
                        case MxConstants.ALIGN_RIGHT:
                            geo.x += (offset - state.x - state.width) / s;
                            break;
                        case MxConstants.ALIGN_TOP:
                            geo.y += (offset - state.y) / s;
                            break;
                        case MxConstants.ALIGN_MIDDLE:
                            geo.y += (offset - state.y - state.height / 2) / s;
                            break;
                        case MxConstants.ALIGN_BOTTOM:
                            geo.y += (offset - state.y - state.height) / s;
                            break;
                        default:
                            geo.x += (offset - state.x) / s;
                            break;
                    }

                    this.resizeCell(cells[i], geo);
                }
            }
            this.fireEvent(new MxEventObject(MxEvent.ALIGN_CELLS, 'align', align, 'cells', cells));
        } finally {
            this.model.endUpdate();
        }
    }

    alignCells(align: string, cells: MxCell[] | null, defaultOffset: number | null = null): MxCell[] {
        if (!cells) {
            cells = this.getSelectionCells();
        }
        cells = cells.filter((cell) => !cell.isEdge());

        if (cells.length > 1) {
            const offset = this.getOffsetForAlignment(align, cells, defaultOffset);
            if (offset !== null) {
                this.moveCellsToOffset(align, cells, offset);
            }
        }

        return cells;
    }

    getModelTypeSymbols() {
        return this.modelType?.symbols || [];
    }

    createEdge(parent: any, id: any, value: any, source: any, target: any, style: any) {
        // 10 - это значение отвечающее за то на сколько px лейбл располагается выше связи
        const edge = new MxCell(value, new MxGeometry(0, 10), style);
        edge.setId(id);
        edge.setEdge(true);
        edge.geometry.relative = true;
        edge.setConnectable(false);

        return edge;
    }

    extendParent(cell: MxCell): void {
        if (!cell) {
            return;
        }

        if (isCommentCell(cell)) {
            return;
        }

        const canExtendParent = !cell.getStyle()?.includes(`${canExtendParentStyleName}=0`);
        if (!canExtendParent) {
            return;
        }

        const parentCell = this.model.getParent(cell);
        let parentGeo = this.getCellGeometry(parentCell);

        if (!parentCell || !parentGeo) {
            return;
        }

        if (this.isCellCollapsed(parentCell)) {
            return;
        }

        const cellGeo = this.getCellGeometry(cell);
        const cellX = Math.max(cellGeo.x, 0);
        const cellY = Math.max(cellGeo.y, 0);

        if (
            cellGeo != null &&
            !cellGeo.relative &&
            (parentGeo.width < cellX + cellGeo.width || parentGeo.height < cellY + cellGeo.height)
        ) {
            parentGeo = parentGeo.clone();

            parentGeo.width = Math.max(parentGeo.width, cellX + cellGeo.width);
            parentGeo.height = Math.max(parentGeo.height, cellY + cellGeo.height);

            this.cellsResized([parentCell], [parentGeo], false);
        }
    }

    cellsAdded(cells, parent, index, source, target, absolute, constrain, extend) {
        if (cells != null && parent != null && index != null) {
            this.model.beginUpdate();

            try {
                const parentState = absolute ? this.view.getState(parent) : null;
                const o1 = parentState != null ? parentState.origin : null;
                const zero = new MxPoint(0, 0);
                let shiftX = 0;
                let shiftY = 0;

                for (let i = 0; i < cells.length; i++) {
                    if (cells[i] == null) {
                        index--;
                    } else {
                        const previous = this.model.getParent(cells[i]);

                        // Keeps the cell at its absolute location
                        if (o1 != null && cells[i] !== parent && parent !== previous) {
                            const oldState = this.view.getState(previous);
                            const o2 = oldState != null ? oldState.origin : zero;
                            let geo = this.model.getGeometry(cells[i]);
                            if (geo != null) {
                                const dx = o2.x - o1.x;
                                const dy = o2.y - o1.y;

                                // FIXME: Cells should always be inserted first before any other edit
                                // to avoid forward references in sessions.
                                geo = geo.clone();
                                geo.translate(dx, dy);
                                shiftX = Math.min(shiftX, geo.x);
                                shiftY = Math.min(shiftY, geo.y);
                                this.model.setGeometry(cells[i], geo);
                            }
                        }

                        // Decrements all following indices
                        // if cell is already in parent
                        if (parent === previous && index + i > this.model.getChildCount(parent)) {
                            index--;
                        }

                        this.model.add(parent, cells[i], index + i);

                        if (this.autoSizeCellsOnAdd) {
                            this.autoSizeCell(cells[i], true);
                        }

                        // Extends the parent or constrains the child
                        if (
                            (extend == null || extend) &&
                            this.isExtendParentsOnAdd(cells[i]) &&
                            this.isExtendParent(cells[i])
                        ) {
                            this.extendParent(cells[i]);
                        }

                        // Additionally constrains the child after extending the parent
                        if (constrain == null || constrain) {
                            this.constrainChild(cells[i]);
                        }

                        // Sets the source terminal
                        if (source != null) {
                            this.cellConnected(cells[i], source, true);
                        }

                        // Sets the target terminal
                        if (target != null) {
                            this.cellConnected(cells[i], target, false);
                        }
                    }
                }

                for (let i = 0; i < cells.length; i++) {
                    let geo = this.model.getGeometry(cells[i]);

                    if (geo != null) {
                        geo = geo.clone();
                        geo.translate(Math.abs(shiftX), Math.abs(shiftY));
                        this.model.setGeometry(cells[i], geo);
                    }
                    // Extends the parent or constrains the child
                    if (
                        (extend == null || extend) &&
                        this.isExtendParentsOnAdd(cells[i]) &&
                        this.isExtendParent(cells[i])
                    ) {
                        this.extendParent(cells[i]);
                    }
                }

                this.fireEvent(
                    new MxEventObject(
                        MxEvent.CELLS_ADDED,
                        'cells',
                        cells,
                        'parent',
                        parent,
                        'index',
                        index,
                        'source',
                        source,
                        'target',
                        target,
                        'absolute',
                        absolute,
                    ),
                );
            } finally {
                this.model.endUpdate();
            }
        }
    }
}
