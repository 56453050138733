import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'NewMatrixCellSymbolsSettingsDialog.title',
        defaultMessage: 'Варианты символов',
    },
    okButton: {
        id: 'NewMatrixCellSymbolsSettingsDialog.okButton',
        defaultMessage: 'Сохранить',
    },
    cancelButton: {
        id: 'NewMatrixCellSymbolsSettingsDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
});
