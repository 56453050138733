import React, { FC, useEffect, useState } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table, TableCellProps } from 'react-virtualized';
import theme from './approvalTable.scss';
import { useIntl } from 'react-intl';
import messages from '../messages/approval.messages';
import { useDispatch } from 'react-redux';
import { timestampToMomentDate } from '../../../utils/date.time.utils';
import { checkApprovals, editExistingApproval } from '../../../actions/approval.actions';
import { Badge } from 'antd';
import { ElementNames } from './ElementNames.component';
import { TApprovalTableData } from './approvalsTable.types';
import { RowCheckBox } from './RowChexbox.component';
import { HeadCheckBox } from './HeadCheckBox.component';
import { NodeId } from '../../../serverapi/api';
import { cloneDeep } from 'lodash-es';
import { Icon } from '@/modules/UIKit';
import icSorter from '../../../resources/icons/ic-sorter.svg';
import icApprovalEdit from '../../../resources/icons/ic-approval-edit.svg';
import icApprovalCopy from '../../../resources/icons/ic-copy.svg';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';

enum statusColor {
    IN_PROCESS = '#8F8F8F',
    APPROVED = '#00A455',
    NOT_APPROVED = '#FF3733',
    CANCELLED = '#676767',
    DRAFT = '#323232',
}

type TRowData = {
    rowData: TApprovalTableData;
};

type TSortStatusFieldValue = null | 'asc' | 'desc';

type TSortStatus = {
    createdAt: TSortStatusFieldValue;
    status: TSortStatusFieldValue;
};

type TSortableColumnName = 'createdAt' | 'status';

type TLastSortedColumnStatus = TSortableColumnName | null;

const ROW_HEIGHT = 48;

const STATUS_SORT_ORDER = {
    APPROVED: 0,
    NOT_APPROVED: 1,
    CANCELLED: 2,
    DRAFT: 3,
    IN_PROCESS: 4,
};

const sortApprovals = (
    approvalTableData: TApprovalTableData[],
    sortStatus: TSortStatus,
    lastSortedColumnStatus: TLastSortedColumnStatus,
) => {
    if (!lastSortedColumnStatus) return approvalTableData;

    const approvalDataCopy = cloneDeep(approvalTableData);

    const sortByCreatedAt = (data: TApprovalTableData[]) =>
        data.sort((a, b) =>
            sortStatus.createdAt === 'asc' ? b.createdAt! - a.createdAt! : a.createdAt! - b.createdAt!,
        );
    if (!sortStatus.status) {
        return sortByCreatedAt(approvalDataCopy);
    }

    const sortByStatus = (data: TApprovalTableData[]) =>
        data.sort((a, b) =>
            sortStatus.status === 'desc'
                ? STATUS_SORT_ORDER[b.status!] - STATUS_SORT_ORDER[a.status!]
                : STATUS_SORT_ORDER[a.status!] - STATUS_SORT_ORDER[b.status!],
        );
    if (!sortStatus.createdAt) {
        return sortByStatus(approvalDataCopy);
    }

    return lastSortedColumnStatus === 'createdAt'
        ? sortByCreatedAt(sortByStatus(approvalDataCopy))
        : sortByStatus(sortByCreatedAt(approvalDataCopy));
};

type TApprovalTableProps = {
    approvalTableData: TApprovalTableData[];
};

export const ApprovalTable: FC<TApprovalTableProps> = ({ approvalTableData }): JSX.Element => {
    const intl = useIntl();
    // требуется обновить таблицу после раскрытия списка элементов в столбце Элемент(ы) запуска
    // так как данные в сторе не поменялись перерендер не происходит,
    // поэтому используем useState для перерендера
    const [needToRender, setNeedToRender] = useState<boolean>(false);
    const [sortStatus, setSortStatus] = useState<TSortStatus>({ createdAt: null, status: null });
    const [lastSortedColumnName, setLastSortedColumnName] = useState<TLastSortedColumnStatus>(null);

    const tableData: TApprovalTableData[] = sortApprovals(approvalTableData, sortStatus, lastSortedColumnName);

    const dispatch = useDispatch();
    const handleChange = (data: NodeId) => {
        dispatch(checkApprovals([data]));
    };
    const cellHeightCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: ROW_HEIGHT,
    });

    useEffect(() => {
        cellHeightCache.clearAll();
        if (needToRender) setNeedToRender(false);
    }, [needToRender, tableData]);

    function firstColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element {
        const { id } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={0}>
                <RowCheckBox handleChange={handleChange} nodeId={id} />
            </CellMeasurer>
        );
    }

    function nameColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element {
        const { name } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={1}>
                <span>{name}</span>
            </CellMeasurer>
        );
    }

    function descriptionColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element {
        const { description } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={2}>
                <span>{description}</span>
            </CellMeasurer>
        );
    }

    function createdByColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element {
        const { createdBy } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={3}>
                <span>{createdBy}</span>
            </CellMeasurer>
        );
    }

    function createdAtColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element {
        const { createdAt } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={4}>
                <span>{`${timestampToMomentDate(createdAt || -1)?.format(
                    `DD.MM.YYYY ${intl.formatMessage(messages.at)} HH:mm`,
                )}`}</span>
            </CellMeasurer>
        );
    }

    function statusColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element {
        const { status } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={5}>
                {status && (
                    <Badge
                        className={theme.badgeStatus}
                        count={intl.formatMessage(messages[status])}
                        style={{ backgroundColor: statusColor[status] }}
                    />
                )}
            </CellMeasurer>
        );
    }

    function elementsColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps) {
        const { elementNames } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={6}>
                <ElementNames setNeedToRender={setNeedToRender} elementNames={elementNames} />
            </CellMeasurer>
        );
    }

    const handleCopy = (id: NodeId) => {
        dispatch(
            openDialog(DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG, {
                copiedApprovalId: id,
            }),
        );
    };

    function actionsColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps) {
        const { id } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={7}>
                <div className={theme.actionBtnsContainer}>
                    <span data-test='approval_edit_btn'
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(editExistingApproval({ approvalId: id }));
                        }}
                    >
                        <Icon spriteSymbol={icApprovalEdit} />
                    </span>
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCopy(id);
                        }}
                    >
                        <Icon spriteSymbol={icApprovalCopy} />
                    </span>
                </div>
            </CellMeasurer>
        );
    }

    const rowGetter = ({ index }) => tableData[index];
    const headerRender = (): JSX.Element => {
        return <HeadCheckBox tableDataLength={tableData.length} />;
    };

    const handleRowClick = ({ rowData }: TRowData) => {
        const { id } = rowData;
        handleChange(id);
    };

    const handleSortClick = (columnName: TSortableColumnName) => {
        const newSortStatusFieldValue: TSortStatusFieldValue =
            sortStatus[columnName] === null || sortStatus[columnName] === 'desc' ? 'asc' : 'desc';
        setSortStatus({ ...sortStatus, [columnName]: newSortStatusFieldValue });
        setLastSortedColumnName(columnName);
    };

    const createdAtHeaderRenderer = (): JSX.Element => {
        return (
            <div className={theme.sortableColumn}>
                <span>{intl.formatMessage(messages.createdAt)}</span>
                <span data-test="approvals-createdAt-filter" onClick={() => handleSortClick('createdAt')}>
                    <Icon spriteSymbol={icSorter} />
                </span>
            </div>
        );
    };

    const statusHeaderRenderer = (): JSX.Element => {
        return (
            <div className={theme.sortableColumn}>
                <span>{intl.formatMessage(messages.status)}</span>
                <span data-test="approvals-status-filter" onClick={() => handleSortClick('status')}>
                    <Icon spriteSymbol={icSorter} />
                </span>
            </div>
        );
    };

    const hadnleRowDoubleClick = ({ rowData }: TRowData) => {
        const { id } = rowData;
        dispatch(editExistingApproval({ approvalId: id }));
    };

    return (
        <div className={theme.tableContainer}>
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={height}
                        rowHeight={cellHeightCache.rowHeight}
                        className={theme.table}
                        headerHeight={ROW_HEIGHT}
                        rowCount={tableData.length}
                        rowGetter={rowGetter}
                        headerClassName={theme.headerName}
                        rowClassName={theme.rowClassName}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={hadnleRowDoubleClick}
                    >
                        <Column
                            flexGrow={1}
                            maxWidth={40}
                            minWidth={40}
                            width={40}
                            dataKey="check"
                            headerRenderer={headerRender}
                            cellRenderer={firstColumnRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="name"
                            label={intl.formatMessage(messages.name)}
                            cellRenderer={nameColumnRenderer}
                            className={theme.columnClassName}
                        />
                        <Column
                            width={width}
                            dataKey="description"
                            label={intl.formatMessage(messages.description)}
                            cellRenderer={descriptionColumnRenderer}
                            className={theme.columnClassName}
                        />
                        <Column
                            width={width}
                            dataKey="createdBy"
                            label={intl.formatMessage(messages.createdBy)}
                            cellRenderer={createdByColumnRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="createdAt"
                            cellRenderer={createdAtColumnRenderer}
                            headerRenderer={createdAtHeaderRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="status"
                            cellRenderer={statusColumnRenderer}
                            headerRenderer={statusHeaderRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="elements"
                            label={intl.formatMessage(messages.elements)}
                            cellRenderer={elementsColumnRenderer}
                        />
                        <Column width={width} dataKey="actions" cellRenderer={actionsColumnRenderer} />
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
};
