import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TUserAccountState } from '../reducers/userAccount.reducer.types';
import { dateFormat, timestampToStringDate } from '../utils/date.time.utils';
import { TUserAccountData } from '../modules/UserAccountTab/userAccountData.types';

const getState = (state: TRootState): TUserAccountState => state.userAccount;

export namespace UserAccountSelectors {
    export const getFullName = createSelector<TRootState, TUserAccountState, string>(getState, (state) => {
        const { firstName, lastName, middleName } = state.personalProfileData;
        return `${lastName || ''} ${firstName} ${middleName || ''}`.trim().replaceAll('  ', ' ');
    });

    export const getTimeChangePassword = createSelector<TRootState, TUserAccountState, string>(getState, (state) => {
        return timestampToStringDate(state.timeChangePassword, dateFormat);
    });

    export const getAccountData = createSelector<TRootState, TUserAccountState, TUserAccountData>(getState, (state) => {
        const data: TUserAccountData = {
            login: state.personalProfileData.login,
        };
        if (state.personalProfileData.email) data.email = state.personalProfileData.email;

        return data;
    });
}
