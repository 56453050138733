import type { TCopyState } from './copy.reducer.types';
import type { TReducer } from '@/utils/types';
import {
    EDITOR_SET_REPOSITORY_ID_WHERE_COPIED_FROM,
    EDITOR_SET_COPIED_ELEMENTS,
} from '../actionsTypes/editor.actionTypes';

const initial: TCopyState = {
    elements: [],
    whereCopiedFrom: null,
};

export const copyReducer: TReducer<TCopyState> = (state = initial, action) => {
    switch (action.type) {
        case EDITOR_SET_REPOSITORY_ID_WHERE_COPIED_FROM: {
            return {
                ...state,
                whereCopiedFrom: {
                    repositoryId: action.payload.repositoryId,
                    serverId: action.payload.serverId,
                },
            };
        }
        case EDITOR_SET_COPIED_ELEMENTS: {
            return {
                ...state,
                elements: action.payload.elements,
            };
        }
        default:
            return state;
    }
};
