import { connect } from 'react-redux';
import { TDecompositionUnsavedModelDialogProps } from '../components/DecompositionUnsavedModelDialog.types';
import { DecompositionUnsavedModelDialog } from '../components/DecompositionUnsavedModelDialog';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { saveUnsavedDecompositionModel, notSaveUnsavedDecompositionModel } from '../../../actions/save.actions';

const mapStateToProps = (): Partial<TDecompositionUnsavedModelDialogProps> => ({});

const mapDispatchToProps = (dispatch) => ({
    onClose() {
        dispatch(closeDialog(DialogType.DECOMPOSITION_UNSAVED_MODEL_DIALOG));
    },
    onCancel() {
        dispatch(notSaveUnsavedDecompositionModel());
    },
    onSubmit() {
        dispatch(saveUnsavedDecompositionModel());
        dispatch(closeDialog(DialogType.DECOMPOSITION_UNSAVED_MODEL_DIALOG));
    },
});

export const DecompositionUnsavedModelDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DecompositionUnsavedModelDialog);
