import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import icAttribute from '../../../../../../../resources/icons/ic-attribute.svg';
import { AttributeType, AttributeTypeGroup } from '../../../../../../../serverapi/api';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { GroupedTypesTable } from '../../util/GroupedTypesTable.component';
import messages from '../AttributeType.messages';
import { Button } from 'antd';
import { TTableDataType, TTableRowRecord } from '../../util/GroupedTypesTable.types';
import { useAttributeNameWithType } from '../../util/useAttributeNameWithType';
import { filterAttributes } from '../../util/AttributeTypes.utils';
import theme from './AttributesTabDialog.scss';
import { SearchInput } from '../../../../../../UIKit/components/Select/SearchInput.component';

type TAttributesTabDialogIntlProps = {
    attributeTypes: AttributeType[];
    dialogVisible: boolean;
};

type TAttributesTabDialogIntlActionProps = {
    onOk: (attributes: AttributeType[]) => void;
    onCancel: () => void;
};

type TAttributesTabDialogIntlFullProps = TAttributesTabDialogIntlProps &
    TAttributesTabDialogIntlActionProps &
    WrappedComponentProps;

const AttributesTabDialog = (props: TAttributesTabDialogIntlFullProps) => {
    const [newAttributes, setNewAttributes] = useState<AttributeType[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchFilter(e.target.value);
    }

    const attributeTypeGroups: AttributeTypeGroup[] = props.attributeTypes
        .map((at) => at.attributeTypeGroup)
        .reduce((acc: AttributeTypeGroup[], atg: AttributeTypeGroup) => {
            if (!acc.some((attributeTypeGroup) => atg.id === attributeTypeGroup.id)) {
                acc.push(atg);
            }

            return acc;
        }, []);

    const attributeTypes: AttributeType[] = props.attributeTypes?.map((type) => ({
        ...type,
        groupId: type.attributeTypeGroup?.id,
    }));
    const attributeNameWithType = useAttributeNameWithType(attributeTypes);

    const onSelectType = (attributes: TTableDataType[]) =>
        setNewAttributes(filterAttributes(attributeTypes, attributes));

    const footer = [
        <Button className={theme.cancelButton} key="cancel" size="large" onClick={() => props.onCancel()}>
            <div data-test="new-attribute-type-window_cancel-button">{props.intl.formatMessage(messages.cancel)}</div>
        </Button>,
        <Button
            className={theme.okButton}
            key="ok"
            size="large"
            type="primary"
            onClick={() => props.onOk(newAttributes)}
        >
            <div data-test="new-attribute-type-window_save-button">{props.intl.formatMessage(messages.ok)}</div>
        </Button>,
    ];

    const rowClassName = (record: TTableRowRecord<TTableDataType>) => {
        const isGroup = attributeTypeGroups.find(attributeTypeGroup => attributeTypeGroup.id === record.id);

        return isGroup ? '' : theme.rowChild;
    };

    return (
        <div>
            <Dialog
                onCancel={props.onCancel}
                open={props.dialogVisible}
                title={props.intl.formatMessage(messages.addAttributes)}
                footer={footer}
                className={theme.dialog}
            >
                <SearchInput showSearch originalTheme onSearch={handleSearch} searchValue={searchFilter} />
                <div>
                    <div className={theme.title}>{props.intl.formatMessage(messages.name)}</div>
                </div>
                <GroupedTypesTable
                    types={attributeNameWithType}
                    typeGroups={attributeTypeGroups}
                    searchFilter={searchFilter}
                    actionsDisabled={false}
                    onSelectType={onSelectType}
                    icon={icAttribute}
                    hideGUIDColumn
                    rowClassName={rowClassName}
                />
            </Dialog>
        </div>
    );
};

const AttributesTabDialogIntl = injectIntl(AttributesTabDialog);

export { AttributesTabDialogIntl as AttributesTabDialog };
