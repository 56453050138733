// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WidgetSourceInput__header__uvTbt{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.WidgetSourceInput__deleteIcon__oq_nr{width:12px;height:12px;cursor:pointer}.WidgetSourceInput__button__asWGr{border-radius:4px;width:100%}.WidgetSourceInput__label__W5AG1{font-size:12px;line-height:18px;margin-bottom:4px}.WidgetSourceInput__input___8odI{border-radius:4px;margin-top:4px}.WidgetSourceInput__title__flj_W{font-size:14px;font-weight:600;line-height:22px}.WidgetSourceInput__inputContainer__XnWNX{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/modules/Dashboard/WidgetSettingsPanel/LineChartSourceInput/WidgetSourceInput.scss"],"names":[],"mappings":"AAAA,kCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,UAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAGJ,sCACI,UAAA,CACA,WAAA,CACA,cAAA,CAGJ,kCACI,iBAAA,CACA,UAAA,CAEJ,iCACI,cAAA,CACA,gBAAA,CACA,iBAAA,CAEJ,iCACI,iBAAA,CACA,cAAA,CAEJ,iCACI,cAAA,CACA,eAAA,CACA,gBAAA,CAGJ,0CACI,iBAAA","sourcesContent":[".header {\r\n    display: flex;\r\n    align-items: center;\r\n    width: 100%;\r\n    justify-content: space-between;\r\n}\r\n\r\n.deleteIcon {\r\n    width: 12px;\r\n    height: 12px;\r\n    cursor: pointer;\r\n}\r\n\r\n.button {\r\n    border-radius: 4px;\r\n    width: 100%;\r\n}\r\n.label {\r\n    font-size: 12px;\r\n    line-height: 18px;\r\n    margin-bottom: 4px;\r\n}\r\n.input {\r\n    border-radius: 4px;\r\n    margin-top: 4px;\r\n}\r\n.title {\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    line-height: 22px;\r\n}\r\n\r\n.inputContainer {\r\n    margin-bottom: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "WidgetSourceInput__header__uvTbt",
	"deleteIcon": "WidgetSourceInput__deleteIcon__oq_nr",
	"button": "WidgetSourceInput__button__asWGr",
	"label": "WidgetSourceInput__label__W5AG1",
	"input": "WidgetSourceInput__input___8odI",
	"title": "WidgetSourceInput__title__flj_W",
	"inputContainer": "WidgetSourceInput__inputContainer__XnWNX"
};
export default ___CSS_LOADER_EXPORT___;
