import React, { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './CloneModelDialog.scss';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import messages from './CloneModelDialog.messages';
import { TreeItemType } from '../../Tree/models/tree';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TreeNode } from '../../../models/tree.types';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { withWindowResize } from '../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import { TWithWindowResizeProps } from '../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import { NodeId, NodeTypeEnum } from '../../../serverapi/api';
import { Tree } from '../../Tree/components/Tree/Tree.component';
import { useSelector } from 'react-redux';
import { SelectedNodesSelector } from '../../../selectors/selectedNodes.selectors';

export enum ModelCloneStrategy {
    USE_EXISTING_DEFINITION_NODES = 'USE_EXISTING_DEFINITION_NODES',
    CREATE_NEW_NODES_IN_MODEL_FOLDER = 'CREATE_NEW_NODES_IN_MODEL_FOLDER',
    CREATE_NEW_NODES_IN_SOURCE_FOLDERS = 'CREATE_NEW_NODES_IN_SOURCE_FOLDERS',
}

enum Validation {
    FIRST_VALIDATION,
    VALIDATION_SUCCESS,
    VALIDATION_UNSUCCESS,
}

type TCopyModelComponentProps = TWithWindowResizeProps &
    WrappedComponentProps & {
        modelName: String;
        type: NodeTypeEnum;
        treeLocation: TreeNode[];
        onClose: () => void;
        onSubmit: (strategy: ModelCloneStrategy, parentNodeIdOfModelCopy?: NodeId) => void;
    };

const cloneModelDialogComponent: FC<TCopyModelComponentProps> = (props) => {
    const { intl, onClose, onSubmit, type } = props;
    const [validation, setValidation] = useState(Validation.FIRST_VALIDATION);
    const [cloneStrategy, setCloneStrategy] = useState(ModelCloneStrategy.USE_EXISTING_DEFINITION_NODES);
    const parentOfModelCopy: TreeNode | undefined = useSelector(
        SelectedNodesSelector.getNode(DialogType.CLONE_MODEL_DIALOG),
    );

    useEffect(() => {
        if (validation === Validation.FIRST_VALIDATION) return;
        if (parentOfModelCopy && validation === Validation.VALIDATION_UNSUCCESS)
            setValidation(Validation.VALIDATION_SUCCESS);
    }, [parentOfModelCopy]);

    const onOk = () => {
        if (parentOfModelCopy) {
            onSubmit(cloneStrategy, parentOfModelCopy?.nodeId);
            setValidation(Validation.VALIDATION_SUCCESS);
        } else setValidation(Validation.VALIDATION_UNSUCCESS);
    };

    return (
        <Dialog
            onOk={onOk}
            onCancel={onClose}
            okText={intl.formatMessage(messages.confirm)}
            cancelText={intl.formatMessage(messages.cancel)}
            title={intl.formatMessage(messages.title)}
            open
            width={DEFAULT_DIALOG_WIDTH}
        >
            <div>
                <p>{`${intl.formatMessage(messages.secondLevelTitle)}: "${props.modelName || ''}".`}</p>
            </div>
            <div className={theme.treeLocation}>
                <p>
                    {intl.formatMessage(messages.pathLabel)}: <b>{parentOfModelCopy?.name}</b>
                </p>
                {validation === Validation.VALIDATION_UNSUCCESS && (
                    <div className={theme.alert} role="alert">
                        {intl.formatMessage(messages.pathRequiredError)}
                    </div>
                )}
                <Tree
                    data={props.treeLocation}
                    treeName={DialogType.CLONE_MODEL_DIALOG}
                    excludeFilterForSelect={[TreeItemType.Server]}
                    disableContextMenu
                />
            </div>
            {type === TreeItemType.Model && (
                <div>
                    <Select className={theme.selectStrategy} onSelect={setCloneStrategy} defaultValue={cloneStrategy}>
                        {Object.keys(ModelCloneStrategy).map((option) => (
                            <Select.Option key={option} value={option}>
                                {intl.formatMessage(messages[option])}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            )}
        </Dialog>
    );
};

const ResizableModelFormWithIntl = withWindowResize(cloneModelDialogComponent);
const ComponentResizeIntl = injectIntl(ResizableModelFormWithIntl);

export { ComponentResizeIntl as CloneModelDialog };
