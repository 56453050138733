import { defineMessages } from 'react-intl';

export default defineMessages({
    clear: {
        id: 'Select.clear',
        defaultMessage: 'Очистить',
    },
    error: {
        id: 'Select.error',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    emptyData: {
        id: 'Select.emptyData',
        defaultMessage: 'Нет данных',
    },
    defaultPlaceholder: {
        id: 'Select.defaultPlaceholder',
        defaultMessage: 'Выберите значение',
    },
    selectAll: {
        id: 'Select.selectAll',
        defaultMessage: 'выбрать все',
    },
});
