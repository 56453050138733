import { TReducer } from '../utils/types';
import {
    AUTHORIZATION_FAILED,
    AUTHORIZATION_REQUEST,
    AUTHORIZATION_SUCCESS,
} from '../actionsTypes/authorization.actionTypes';
import { TAuthorizationState } from './authorization.reducer.types';
import { UPDATE_TRINSITIVE_PROFILE_IDS } from '../actionsTypes/serverProfile.actionTypes';

const initial: TAuthorizationState = {
    authorized: false,
    pending: false,
    success: true,
};

export const authorizationReducer: TReducer<TAuthorizationState> = (state = initial, action) => {
    switch (action.type) {
        case AUTHORIZATION_REQUEST:
            return {
                user: state.user,
                usersProperties: state.usersProperties,
                authorized: false,
                pending: true,
                success: true,
            };

        case AUTHORIZATION_FAILED:
            return {
                user: state.user,
                usersProperties: state.usersProperties,
                authorized: false,
                pending: false,
                success: false,
                error: action.payload,
            };

        case AUTHORIZATION_SUCCESS: {
            const { user, property } = action.payload;

            return {
                user,
                usersProperties: property,
                authorized: true,
                pending: false,
                success: true,
            };
        }

        case UPDATE_TRINSITIVE_PROFILE_IDS: {
            const { ids } = action.payload;

            return {
                ...state,
                user: state.user && {
                    ...state.user,
                    transitiveProfilesIds: [...ids],
                },
            };
        }
        default:
            return state;
    }
};
