import React, { ChangeEvent, FC } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteUsers, setUsersBlock, openUserDataEditingTab } from '../../../../actions/users.actions';
import { UserDataEdititngTabMode } from '../../../../actions/users.actions.types';
import { newUserState } from '../../../../models/users';
import { UserDTO } from '../../../../serverapi/api';
import { DropdownButton } from '../../DropdownButton/DropdownButton';
import messages from '../messages/userManagment.messages';
import theme from './UserListControls.scss';
import { showConfirmationDialog } from './ConfirmationDialog';
import { TButtonAction } from '../../DropdownButton/DropdownButton.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TUserListControls = {
    checkedUsers: UserDTO[];
    setCheckedUsers: (users: UserDTO[]) => void;
    handleChangeStatusFilter: (newStatus: string) => void;
    handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
};

const UserListControls: FC<TUserListControls> = (props) => {
    const { checkedUsers, setCheckedUsers, handleSearch, handleChangeStatusFilter } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const okText = intl.formatMessage(messages.yes);
    const cancelText = intl.formatMessage(messages.no);
    const users: string[] = checkedUsers.map((user) => user.login);

    const onAddClicked = () => {
        const userData: UserDTO = newUserState;
        dispatch(openUserDataEditingTab({ userData, mode: UserDataEdititngTabMode.ADD }));
    };

    const blockStatusHadler = (block: boolean) => {
        const onOk = () => {
            dispatch(
                setUsersBlock(
                    checkedUsers.map((user) => user.id),
                    block,
                ),
            );
            setCheckedUsers([]);
        };
        const action = block ? intl.formatMessage(messages.block) : intl.formatMessage(messages.unblock);
        const title = block ? intl.formatMessage(messages.blockTitle) : intl.formatMessage(messages.unblockTitle);
        showConfirmationDialog({ action, title, users, okText, cancelText, onOk });
    };

    const deleteUserHandler = () => {
        const onOk = () => {
            dispatch(deleteUsers(checkedUsers.map((user) => user.id)));
            setCheckedUsers([]);
        };
        const action = intl.formatMessage(messages.delete);
        const title = intl.formatMessage(messages.deleteTitle);
        showConfirmationDialog({ action, title, users, okText, cancelText, onOk });
    };

    const massUserActions: TButtonAction[] = [
        {
            title: intl.formatMessage(messages.block),
            onClick: () => blockStatusHadler(true),
        },
        {
            title: intl.formatMessage(messages.unblock),
            onClick: () => blockStatusHadler(false),
        },
        {
            title: intl.formatMessage(messages.delete),
            onClick: () => deleteUserHandler(),
        },
    ];

    return (
        <div className={theme.searchDiv}>
            <div className={theme.searchInput}>
                <Input
                    suffix={<SearchOutlined />}
                    data-test="user-management-list-search-input"
                    onChange={handleSearch}
                />
            </div>

            <div data-test="userList_filter-status" className={theme.labelSelectContainer}>
                <label className={theme.label}>{intl.formatMessage(messages.status)}</label>
                <Select defaultValue="all" className={theme.select} onChange={handleChangeStatusFilter}>
                    <Select.Option data-test="userList_filter-status-all" value="all">
                        {intl.formatMessage(messages.activeAndNot)}
                    </Select.Option>
                    <Select.Option data-test="userList_filter-status-notBlocked" value="false">
                        {intl.formatMessage(messages.notBlocked)}
                    </Select.Option>
                    <Select.Option data-test="userList_filter-status-Blocked" value="true">
                        {intl.formatMessage(messages.blocked)}
                    </Select.Option>
                </Select>
            </div>
            <DropdownButton disabled={checkedUsers.length === 0} actions={massUserActions} />
            <div className={theme.actionButtons}>
                <Button
                    dataTest="user-managment_add-user-button"
                    key="ok"
                    size="large"
                    visualStyle="primary"
                    onClick={onAddClicked}
                >
                    {intl.formatMessage(messages.addBtnLabel)}
                </Button>
            </div>
        </div>
    );
};

export default UserListControls;
