import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearContextMenuParentId, setContextMenuParentId } from '@/actions/contextMenu.actions';
import { ContextMenuSelectors } from '../selectors/contextMenu.selectors';
import { NodeId } from '../serverapi/api';
import { activeContextMenuChange } from '../actions/contextMenu.actions';

/**
 * Закрывает все остальные контекстные меню при открытии нового
 * */
export const useContextMenuDublicateDelete = (
    contextMenuAreaName: string,
    parentId?: NodeId,
): [boolean, (isVisible: boolean) => void] => {
    if (!parentId) {
        return [false, () => {}];
    }
    const dispatch = useDispatch();
    const isAllContextMenuClosed = useSelector(ContextMenuSelectors.isAllContextMenuClosed);

    /** Закрывает контекстное меню при закрытии модалки/панели навигатора/вкладки */
    useEffect(() => {
        return () => {
            if (!isAllContextMenuClosed) dispatch(clearContextMenuParentId());
        };
    }, [isAllContextMenuClosed]);

    const isContextMenuVisible = useSelector(ContextMenuSelectors.isOpenOnThisItem(parentId, contextMenuAreaName));

    const setContextMenuVisible = (isVisible: boolean) => {
        if (isVisible) {
            dispatch(activeContextMenuChange());
            dispatch(setContextMenuParentId(parentId, contextMenuAreaName));

            return;
        }
        dispatch(clearContextMenuParentId());
    };

    return [isContextMenuVisible, setContextMenuVisible];
};
