import { EdgeType, EdgeTypeGroup } from '../../serverapi/api';
import { apiBundle } from '../api/api-bundle';

export class EdgeTypesDaoService {
    public static async edgeTypeBulkDelete(serverId: string, edgeTypes: EdgeType[]): Promise<void> {
        await apiBundle(serverId).edgeType.bulkDelete({ body: edgeTypes });
    }

    public static async edgeTypeGroupBulkDelete(serverId: string, edgeTypeGroup: EdgeTypeGroup[]): Promise<void> {
        await apiBundle(serverId).edgeTypeGroup.bulkDelete({ body: edgeTypeGroup });
    }

    public static async getEdgeTypeById(serverId: string, presetId: string, id: string): Promise<EdgeType> {
        const givenEdgeType: EdgeType = await apiBundle(serverId).edgeType.byId({ presetId, id });

        return givenEdgeType;
    }

    public static async setEdgeTypesSave(serverId: string, edgeTypes: EdgeType[]): Promise<void> {
        await apiBundle(serverId).edgeType.save({ body: edgeTypes });
    }

    public static async createEdgeType(serverId: string, edgeType: EdgeType): Promise<void> {
        await apiBundle(serverId).edgeType.create({ body: edgeType });
    }
    // public static async deleteAttributeTypes(serverId: string, attributeTypes: AttributeType[]): Promise<void> {
    //     const attributeTypeList: AttributeType[] = attributeTypes.map((at) => {
    //         return {
    //             id: at.id,
    //             presetId: at.presetId,
    //             name: '',
    //         };
    //     });
    //
    //     await apiBundle(serverId).attributeType.bulkDelete({ body: attributeTypeList });
    // }
    //
    // public static async attributeTypeGroupBulkDelete(
    //     serverId,
    //     attributeTypeGroups: AttributeTypeGroup[],
    // ): Promise<void> {
    //     await apiBundle(serverId).attributeTypeGroup.bulkDelete({ body: attributeTypeGroups });
    // }
    //
    // public static async saveAttributeType(serverId, attributeTypes: AttributeType[]): Promise<void> {
    //     await apiBundle(serverId).attributeType.bulkSave({ body: attributeTypes });
    // }
    //
    // public static async deleteAttributeTypesAndGroupsRequest(
    //     serverId,
    //     attributeTypes: AttributeType[],
    //     attributeTypeGroups: AttributeTypeGroup[],
    // ): Promise<void> {
    //     await this.deleteAttributeTypes(serverId, attributeTypes);
    //     await this.attributeTypeGroupBulkDelete(serverId, attributeTypeGroups);
    // }
}
