import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { NodeId } from '../../../serverapi/api';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { createDashboard } from '../../../actions/dashboard.actions';
import messages from './NewDashboardDialog.messages';

type TNewDashboardDialogProps = {
    parentNodeId: NodeId;
};

type TFormValues = {
    dashboardTitle: string;
};

export const NewDashboardDialog = (props: TNewDashboardDialogProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { parentNodeId } = props;
    const [form] = Form.useForm();
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const handleSubmit = () => {
        form.validateFields()
            .then((values: TFormValues) => {
                setSubmitDisabled(true);
                dispatch(createDashboard(values.dashboardTitle.trim(), parentNodeId));
            })
            .catch(() => undefined);
    };

    const onClose = () => dispatch(closeDialog(DialogType.DASHBOARD_CREATE_DIALOG));

    return (
        <Dialog
            open
            onOk={handleSubmit}
            onCancel={onClose}
            okText={
                <span>
                    {intl.formatMessage(messages.create)}
                </span>
            }
            cancelText={
                <span>
                    {intl.formatMessage(messages.cancel)}
                </span>
            }
            title={intl.formatMessage(messages.title)}
            okButtonProps={{ disabled: isSubmitDisabled }}
        >
            <Form form = {form} layout="vertical">
                <Form.Item
                    label={intl.formatMessage(messages.name)}
                    name="dashboardTitle"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: intl.formatMessage(messages.requiredField),
                        },
                    ]}
                >
                    <Input
                        placeholder={intl.formatMessage(messages.namePlaceholder)}
                        autoComplete="off"
                        autoFocus
                    />
                </Form.Item>
            </Form>
        </Dialog>
    );
};
