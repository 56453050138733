// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardEditorToolbar__modeGroup_full__f3Xc3{margin-top:15px}.DashboardEditorToolbar__modeButton__ZMYXU svg{height:35px;width:35px}.DashboardEditorToolbar__gridButton__h0Y7z svg{height:12px;width:12px}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/DashboardEditorToolbar/DashboardEditorToolbar.scss"],"names":[],"mappings":"AAAA,+CACI,eAAA,CAIA,+CACI,WAAA,CACA,UAAA,CAKJ,+CACI,WAAA,CACA,UAAA","sourcesContent":[".modeGroup_full {\r\n    margin-top: 15px;\r\n}\r\n\r\n.modeButton {\r\n    svg {\r\n        height: 35px;\r\n        width: 35px;\r\n    }\r\n}\r\n\r\n.gridButton {\r\n    svg {\r\n        height: 12px;\r\n        width: 12px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modeGroup_full": "DashboardEditorToolbar__modeGroup_full__f3Xc3",
	"modeButton": "DashboardEditorToolbar__modeButton__ZMYXU",
	"gridButton": "DashboardEditorToolbar__gridButton__h0Y7z"
};
export default ___CSS_LOADER_EXPORT___;
