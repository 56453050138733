import React from 'react';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { LocalesService } from '@/services/LocalesService';
import { Modal, List } from 'antd';
import { useDispatch } from 'react-redux';
import messages from './EPCSelectRowTitleDialog.messages';
import theme from './EPCSelectRowTitleDialog.scss';

type TEPCSelectRowTitleDialogComponentProps = {
    open: boolean;
    onSelect: (name: string) => void;
};

const EPCSelectRowTitleDialogComponent = ({ open, onSelect }: TEPCSelectRowTitleDialogComponentProps) => {
    const dispatch = useDispatch();
    const handleCancelClick = () => {
        dispatch(closeDialog(DialogType.EPC_SELECT_MODEL_ROW_NAME_DIALOG));
    };

    const handleOkClick = () => {
        dispatch(closeDialog(DialogType.EPC_SELECT_MODEL_ROW_NAME_DIALOG));
    };
    const intl = LocalesService.useIntl();
    const newRowNames: string[] = [
        messages.newRowTitle1,
        messages.newRowTitle2,
        messages.newRowTitle3,
        messages.newRowTitle4,
        messages.newRowTitle5,
        messages.newRowTitle6,
        messages.newRowTitle7,
        messages.newRowTitle8,
        messages.newRowTitle9,
        messages.newRowTitle10,
        messages.newRowTitle11,
        messages.newRowTitle12,
    ].map(intl.formatMessage as any);

    const handleOnClick = (element: string) => () => {
        dispatch(closeDialog(DialogType.EPC_SELECT_MODEL_ROW_NAME_DIALOG));
        onSelect(element);
    };

    return (
        <Modal
            open
            onCancel={handleCancelClick}
            onOk={handleOkClick}
            okButtonProps={{ disabled: true }}
            okText={intl.formatMessage(messages.add)}
            cancelText={intl.formatMessage(messages.cancel)}
        >
            <List
                dataSource={newRowNames}
                renderItem={(item, i) => (
                    <List.Item className={theme.listItem} onClick={handleOnClick(item)}>
                        {item}
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default EPCSelectRowTitleDialogComponent;

