import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'NewMatrixCreateDefinitionDialog.title',
        defaultMessage: 'Создание определения связи',
    },
    edgeType: {
        id: 'NewMatrixCreateDefinitionDialog.edgeType',
        defaultMessage: 'Вид связи',
    },
    submit: {
        id: 'NewMatrixCreateDefinitionDialog.submit',
        defaultMessage: 'Создать',
    },
    cancel: {
        id: 'NewMatrixCreateDefinitionDialog.cancel',
        defaultMessage: 'Отмена',
    },
    source: {
        id: 'NewMatrixCreateDefinitionDialog.source',
        defaultMessage: 'Источник:',
    },
    target: {
        id: 'NewMatrixCreateDefinitionDialog.target',
        defaultMessage: 'Цель:',
    },
});
