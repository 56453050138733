//@test-ignore_ru

export const defaultPSDGridLayout = {
    cells: [],
    rowHeaderWidth: 20,
    columnHeaderHeight: 20,
    columns: [
        {
            id: 'c1',
            name: { en: 'General process', ru: 'Основной процесс' },
        },
        {
            id: 'c2',
            name: { en: 'Consist of', ru: 'Состоит из' },
        },
        {
            id: 'c3',
            name: { en: 'Consist of', ru: 'Состоит из' },
        },
        {
            id: 'c4',
            name: { en: 'Consist of', ru: 'Состоит из' },
        },
        {
            id: 'c5',
            name: { en: 'Consist of', ru: 'Состоит из' },
        },
        {
            id: 'c6',
            name: { en: 'Consist of', ru: 'Состоит из' },
        },
        {
            id: 'c7',
            name: { en: 'Consist of', ru: 'Состоит из' },
        },
    ],
    rows: [
        {
            id: 'r1',
            name: { en: 'Script', ru: 'Сценарий' },
        },
        {
            id: 'r2',
            name: { en: 'Belongs to', ru: 'Принадлежит к' },
        },
        {
            id: 'r3',
            name: { en: 'Belongs to', ru: 'Принадлежит к' },
        },
        {
            id: 'r4',
            name: { en: 'Belongs to', ru: 'Принадлежит к' },
        },
        {
            id: 'r5',
            name: { en: 'Belongs to', ru: 'Принадлежит к' },
        },
    ],
};

export const firstColumnAvailableSymbols = ['ST_MAIN_PRCS']; // hardcoded from: https://confluence.silaunion.ru/pages/viewpage.action?pageId=2463238439
export const firstRowAvailableSymbols = [
    //TODO: move to config
    'ST_SCENARIO',
    'SAP 478e24e0-c1a5-11e4-3864-ff0f8fe73e88',
    '1bea43c0-c768-11e2-69e4-ac8112d1b401',
    '1647b400-c1a5-11e4-3864-ff0f8fe73e88',
];

export const contentAvailableSymbols = [
    'ST_PRCS_1',
    'f46e6b10-c1a1-11e4-3864-ff0f8fe73e88',
    '9ad2efb0-c1a4-11e4-3864-ff0f8fe73e88',
    '22e410f0-e498-11e2-69e4-ac8112d1b401',
];
