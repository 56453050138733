import React, { useState } from 'react';
import { TreeNode } from '../../../models/tree.types';
import { TPreset } from '../../../models/preset.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { useDispatch } from 'react-redux';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { shouldCallModalSubmitHandler } from '../../../services/utils/ModalHelper';
import { createModelType } from '../../../actions/presetSettings/presetSettingsModelType.actions';
import { createKanbanModelType } from '../../../actions/presetSettings/presetSettingsKanbanModelType.actions';
import { GeneralModelTypeDiscriminator } from '../../../models/ModelTypes';
import { GeneralModelTypeDiscriminatorEnum } from '../../../serverapi/api';
import { useIntl } from 'react-intl';
import messages from './SelectModelTypeDialog.messages';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { Select } from '../../UIKit/components/Select/Select.component';
import theme from './SelectModelTypeDialog.scss';
import { Button } from 'antd';
import { createMatrixModelType } from '@/actions/presetSettings/presetSettingsMatrixModelType.actions';

type TSelectModelTypeDialog = {
    serverNode: TreeNode;
    preset: TPreset;
};

export const SelectModelTypeDialog = ({ serverNode, preset }: TSelectModelTypeDialog) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [modelType, setModelType] = useState<GeneralModelTypeDiscriminatorEnum>(
        GeneralModelTypeDiscriminator.MODEL_TYPE,
    );

    const handleCancelClick = () => {
        dispatch(closeDialog(DialogType.SELECT_MODEL_TYPE));
    };

    const handleOkClick = (): void => {
        dispatch(closeDialog(DialogType.SELECT_MODEL_TYPE));

        if (modelType === GeneralModelTypeDiscriminator.MODEL_TYPE) {
            dispatch(
                createModelType({
                    serverNode,
                    preset,
                }),
            );
        }

        if (modelType === GeneralModelTypeDiscriminator.KANBAN) {
            dispatch(
                createKanbanModelType({
                    serverNodeId: serverNode.nodeId,
                    presetId: preset.id,
                }),
            );
        }

        if (modelType === GeneralModelTypeDiscriminator.MATRIX) {
            dispatch(
                createMatrixModelType({
                    serverNodeId: serverNode.nodeId,
                    presetId: preset.id,
                }),
            );
        }
    };

    const footer = [
        <Button className={theme.cancelButton} key="cancel" size="large" onClick={handleCancelClick}>
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button className={theme.okButton} key="ok" size="large" type="primary" onClick={handleOkClick}>
            {intl.formatMessage(messages.create)}
        </Button>,
    ];

    const selectValues = {
        [GeneralModelTypeDiscriminator.MODEL_TYPE]: intl.formatMessage(messages.defaultModel),
        [GeneralModelTypeDiscriminator.KANBAN]: intl.formatMessage(messages.kanbanBoard),
        [GeneralModelTypeDiscriminator.MATRIX]: intl.formatMessage(messages.matrix),
    };

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    handleOkClick();
                }
            }}
        >
            <Dialog
                open
                title={intl.formatMessage(messages.selectModelType)}
                onCancel={handleCancelClick}
                className={theme.dialog}
                footer={footer}
            >
                <Select
                    originalTheme
                    value={selectValues[modelType]}
                    onChange={(val: GeneralModelTypeDiscriminator) => setModelType(val)}
                >
                    <Select.Option
                        key="1"
                        value={GeneralModelTypeDiscriminator.MODEL_TYPE}
                        label={intl.formatMessage(messages.defaultModel)}
                    />
                    <Select.Option
                        key="2"
                        value={GeneralModelTypeDiscriminator.KANBAN}
                        label={intl.formatMessage(messages.kanbanBoard)}
                    />
                    <Select.Option
                        key="3"
                        value={GeneralModelTypeDiscriminator.MATRIX}
                        label={intl.formatMessage(messages.matrix)}
                    />
                </Select>
            </Dialog>
        </div>
    );
};
