import { defineMessages } from 'react-intl';

export default defineMessages({
    appErrorCommonTitle: {
        id: 'App.errorCommonTitle',
        defaultMessage: 'Ошибка',
    },
    appErrorFileMaxSizeMessage: {
        id: 'App.appErrorFileMaxSizeMessage',
        defaultMessage: 'Превышен максимальный размер файла',
    },
    appErrorCommonMessage: {
        id: 'App.errorCommonMessage',
        defaultMessage: 'Ошибка системы.',
    },
    appNetworkErrorTitle: {
        id: 'App.networkErrorTitle',
        defaultMessage: 'Ошибка сети.',
    },
    appNetworkErrorMessage: {
        id: 'App.networkErrorMessage',
        defaultMessage: 'Данные не загружены.',
    },
    unexpectedJSON: {
        id: 'App.unexpectedJSON',
        defaultMessage: 'Сервер передал данные неизвестного формата.',
    },
    serverErrorTitle: {
        id: 'App.serverErrorTitle',
        defaultMessage: 'Ошибка сервера',
    },
    appServerErrorMessage: {
        id: 'App.serverErrorMessage',
        defaultMessage: 'Ошибка при обращении к серверу.',
    },
    lowServerTitle: {
        id: 'App.lowServerTitle',
        defaultMessage: 'Низкая скорость соединения',
    },
    lowServerMessage: {
        id: 'App.lowServerMessage',
        defaultMessage: 'Сервер не успевает сохранять данные',
    },
    reconnectTitle: {
        id: 'App.reconnectTitle',
        defaultMessage: 'Подключение к серверу',
    },
    serverEditTitle: {
        id: 'App.serverEditTitle',
        defaultMessage: 'Редактирование подключения',
    },

    modelRestore: {
        id: 'App.modelRestore',
        defaultMessage: 'Восстановление диаграммы',
    },
    modelRestoreSuccess: {
        id: 'App.modelRestoreSuccess',
        defaultMessage: 'Диаграмма восстановлена',
    },
    modelRestoreError: {
        id: 'App.modelRestoreError',
        defaultMessage: 'Ошибка восстановление диаграммы',
    },
    modelRefresh: {
        id: 'App.modelRefresh',
        defaultMessage: 'Обновление диаграммы',
    },
    modelRefreshSuccess: {
        id: 'App.modelRefreshSuccess',
        defaultMessage: 'Диаграмма обновлена',
    },
    modelRefreshError: {
        id: 'App.modelRefreshError',
        defaultMessage: 'Ошибка при обновлении диаграммы',
    },
    modelToEditNotFound: {
        id: 'App.modelToEditNotFound',
        defaultMessage: 'Редактируемая модель не найдена',
    },
    objectToEditNotFound: {
        id: 'App.objectToEditNotFound',
        defaultMessage: 'Редактируемый объект не найден',
    },
    appErrorDiagramLockTitle: {
        id: 'App.ErrorDiagramLockTitle',
        defaultMessage: 'Ошибка редактирования',
    },
    appErrorDiagramLockMessage: {
        id: 'App.ErrorDiagramLockMessage',
        defaultMessage: 'Диаграмма редактируется пользователем {ownerName}, доступен только режим чтения.',
    },
    appErrorDiagramLockModelTypeIsEmptyMessage: {
        id: 'App.appErrorDiagramLockModelTypeIsEmptyMessage',
        defaultMessage: 'Ошибка методологии, не найден тип диаграммы.',
    },
    appErrorObjectLockTitle: {
        id: 'App.ErrorObjectLockTitle',
        defaultMessage: 'Элемент заблокирован.',
    },
    appErrorLicenseMessage: {
        id: 'App.ErrorLicenseMessage',
        defaultMessage: 'Для этого действия вам необходима лицензия',
    },
    appErrorLicenseTitle: {
        id: 'App.ErrorLicenseTitle',
        defaultMessage: 'Требуется лицензия',
    },
    appErrorObjectLockMessage: {
        id: 'App.ErrorObjectLockMessage',
        defaultMessage: 'На элементе установлена блокировка пользователя.',
    },
    appErrorDuplicateTitle: {
        id: 'App.appErrorDuplicateTitle',
        defaultMessage: 'Ошибка',
    },
    appErrorObjectDuplicateMessage: {
        id: 'App.appErrorObjectDuplicateMessage',
        defaultMessage: 'Такой элемент уже существует.',
    },
    appErrorDeniedByProfileTitle: {
        id: 'App.appErrorDeniedByProfileTitle',
        defaultMessage: 'Ошибка',
    },
    appErrorDeniedByProfileMessage: {
        id: 'App.appErrorDeniedByProfileMessage',
        defaultMessage: 'Действие запрещено профилем пользователя.',
    },
    appErrorSymbolMoveDisabledMessage: {
        id: 'App.appErrorSymbolMoveDisabledMessage',
        defaultMessage: 'Нельзя перемещать запрещенный символ',
    },
    appErrorSymbolMoveDisabledTitle: {
        id: 'App.appErrorSymbolMoveDisabledTitle',
        defaultMessage: 'Ошибка',
    },
    appErrorConnectionLostTitle: {
        id: 'App.ErrorConnectionLostTitle',
        defaultMessage: 'Сетевая ошибка',
    },
    appErrorConnectionLostMessage: {
        id: 'App.ErrorConnectionLostMessage',
        defaultMessage: 'Потеряна связь с {host}.',
    },

    appErrorUploadImageTitle: {
        id: 'App.UploadImageTitle',
        defaultMessage: 'Ошибка загрузки изображения',
    },
    appErrorUploadImageMessage: {
        id: 'App.ErrorUploadImageMessage',
        defaultMessage: 'Неверный тип изображения, поддерживаются следующие форматы: (*.jpg, *.gif, *.png).',
    },
    appErrorUploadImageMessageNotSvg: {
        id: 'App.appErrorUploadImageMessageNotSvg',
        defaultMessage: 'Неверный тип изображения, поддерживаются следующие форматы: .svg',
    },

    appNotificationConnectionOpenTitle: {
        id: 'App.NotificationConnectionOpenTitle',
        defaultMessage: 'Связь восстановлена',
    },
    appNotificationSuccessfullySubscribedTitle: {
        id: 'App.appNotificationSuccessfullySubscribedTitle',
        defaultMessage: 'Установлена подписка на обновления дерева',
    },
    appNotificationSuccessfullySubscribedOnDBMessage: {
        id: 'App.appNotificationSuccessfullySubscribedOnDBMessage',
        defaultMessage: 'Вы будете получать актуальное состояние дерева, если его изменят другие пользователи',
    },
    appNotificationSuccessfullyUnSubscribedTitle: {
        id: 'App.appNotificationSuccessfullyUnSubscribedTitle',
        defaultMessage: 'Подписка на обновления дерева разорвана',
    },
    appNotificationSuccessfullyUnsubscribedOnDBMessage: {
        id: 'App.appNotificationSuccessfullyUnsubscribedOnDBMessage',
        defaultMessage:
            'Вы больше не будете получать актуальное состояние дерева, если его изменят другие пользователи',
    },
    appNotificationStaleGraphTitle: {
        id: 'App.NotificationStaleGraphTitle',
        defaultMessage: 'Версия диаграммы устарела',
    },
    appNotficationDataTitle: {
        id: 'App.NotificationObjectTitle',
        defaultMessage: 'Объект не существует',
    },
    appNotificationStaleGraphMessage: {
        id: 'App.NotificationStaleGraphMessage',
        defaultMessage: 'Пожалуйста, закройте и откройте диаграмму "{diagramName}"',
    },
    appNotificationStaleGraphShortMessage: {
        id: 'App.appNotificationStaleGraphShortMessage',
        defaultMessage: 'Пожалуйста, закройте и откройте диаграмму',
    },
    importModelTypeSuccess: {
        id: 'App.importModelTypeSuccess',
        defaultMessage: 'Модель "{type}" была успешно импортирована',
    },
    modelSaveFail: {
        id: 'App.modelSaveFail',
        defaultMessage: 'Модель "{name}" не сохранена',
    },
    importFileSuccess: {
        id: 'App.importFileSuccess',
        defaultMessage: 'Файл успешно импортирован',
    },
    importFileSuccessTitle: {
        id: 'App.importFileSuccessTitle',
        defaultMessage: 'Файл импортирован',
    },
    importFileFail: {
        id: 'App.importFileFail',
        defaultMessage: 'Ошибка при импорте файла',
    },
    importFileFailTitle: {
        id: 'App.importFileFailTitle',
        defaultMessage: 'Файл не импортирован',
    },
    importFileFailNodeMustBeDB: {
        id: 'App.importFileFailNodeMustBeDB',
        defaultMessage: 'Импортируемый узел должен быть базой данных',
    },
    importFileFailNodeCantBeDB: {
        id: 'App.importFileFailNodeCantBeDB',
        defaultMessage: 'Импортируемый узел не может быть базой данных',
    },
    importFileFailWrongFormat: {
        id: 'App.importFileFailWrongFormat',
        defaultMessage: 'Ошибка данных в импортируемом файле',
    },
    importFileFailConverterError: {
        id: 'App.importFileFailConverterError',
        defaultMessage: 'Ошибка конвертации',
    },
    deniedImportTarget: {
        id: 'App.deniedImportTarget',
        defaultMessage:
            'Не удается импортировать в этот узел. ' +
            'Импортируемый файл уже содержит такой узел как дочерний.' +
            ' Попробуйте выбрать другой узел для импорта',
    },
    deletedImportTarget: {
        id: 'App.deletedImportTarget',
        defaultMessage: 'Не удается импортировать в удаленный узел. ' + ' Попробуйте выбрать другой узел для импорта',
    },
    importFileFailAccessDenied: {
        id: 'App.importFileFailAccessDenied',
        defaultMessage: 'Недостаточно прав доступа к изменяемому во время импорта узлу',
    },
    importDefaultPresetFail: {
        id: 'App.importDefaultPresetFail',
        defaultMessage: 'Нельзя изменять стандартную методологию',
    },
    concurrentEditOfNotationAndModelWarn: {
        id: 'App.concurrentEditOfNotationAndModelWarn',
        defaultMessage: 'Одновременное редактирование методологии и модели',
    },
    concurrentEditOfNotationWarnDescription: {
        id: 'App.concurrentEditOfNotationWarnDescription',
        defaultMessage: 'Для изменения методологии необходимо закрыть редактируемые модели',
    },
    concurrentEditOfModelWarnDescription: {
        id: 'App.concurrentEditOfModelWarnDescription',
        defaultMessage: 'Для изменения модели необходимо закрыть редактируемые методологии',
    },
    importFileReadFail: {
        id: 'App.importFileReadFail',
        defaultMessage: 'Не удалось прочитать выбранный файл',
    },
    moveNodeFailed: {
        id: 'App.moveNodeFailed',
        defaultMessage: 'Выбранные элементы дерева (или некоторые из них) не могут быть перенесены',
    },
    moveNodeForbidden: {
        id: 'App.moveNodeForbidden',
        defaultMessage: 'Для добавления объекта на полотно выделите только 1 объект',
    },
    importProcess: {
        id: 'App.importProcess',
        defaultMessage: 'Импорт',
    },
    appNotificationConnectionOpenMessage: {
        id: 'App.NotificationConnectionOpenMessage',
        defaultMessage: 'Сервер по адресу {host} онлайн.',
    },

    appScriptChangingStatusTitle: {
        id: 'App.ScriptChangingStatusTitle',
        defaultMessage: 'Выполнение скрипта',
    },
    appScriptPrepareStatusTitle: {
        id: 'App.appScriptPrepareStatusTitle',
        defaultMessage: 'Подготовка скрипта',
    },
    appScriptChangingStatusMessage: {
        id: 'App.ScriptChangingStatusMessage',
        defaultMessage: 'Выполнение "{scriptName}" перешло на статус "{scriptStatus}".',
    },
    appScriptErrorStatusMessage: {
        id: 'App.ScriptErrorStatusMessage',
        defaultMessage: 'Выполнение "{scriptName}" перешло на статус "{scriptStatus}". {error}',
    },
    appScriptErrorLockedMessage: {
        id: 'App.ScriptErrorLockedMessage',
        defaultMessage: 'Объект, изменяемый скриптом заблокирован.',
    },
    appScriptErrorForbiddenMessage: {
        id: 'App.ScriptErrorForbiddenMessage',
        defaultMessage: 'Недостаточно прав доступа на действие, выполняемое скриптом.',
    },
    appScriptErrorLicenseMessage: {
        id: 'App.ScriptErrorLicenseMessage',
        defaultMessage: 'Недостаточно лицензий на действие, выполняемое скриптом.',
    },
    appScriptErrorCreationMessage: {
        id: 'App.ScriptErrorCreationMessage',
        defaultMessage: 'Ошибка инициализации скрипта.',
    },
    appScriptCompilationErrorMessage: {
        id: 'App.ScriptCompilationErrorMessage',
        defaultMessage: 'Ошибка при компиляции кода скрипта "{scriptName}".',
    },
    appScripEngineUnavailableErrorMessage: {
        id: 'App.appScripEngineUnavailableErrorMessage',
        defaultMessage: 'Сервер исполнения скриптов не доступен',
    },
    appScriptSecurityExceptionMessage: {
        id: 'App.ScriptSecurityExceptionMessage',
        defaultMessage: 'Попытка выполнения запрещенной операции.',
    },

    appNotificationFileDownloadedTitle: {
        id: 'App.FileDownloadedTitle',
        defaultMessage: 'Файл загружен',
    },
    appNotificationFileDownloadedMessage: {
        id: 'App.FileDownloadedMessage',
        defaultMessage: 'Скачивание файла "{fileName}" завершено.',
    },

    appOpenFileMessage: {
        id: 'App.OpenFileMessage',
        defaultMessage: 'Открыть файл',
    },
    appErrorForbiddenTitle: {
        id: 'App.ErrorForbiddenTitle',
        defaultMessage: 'Ошибка',
    },
    appErrorForbiddenMessage: {
        id: 'App.ErrorForbiddenMessage',
        defaultMessage: 'Недостаточно прав доступа.',
    },
    appErrorNotFoundTitle: {
        id: 'App.ErrorNotFoundTitle',
        defaultMessage: 'Ошибка',
    },
    appErrorNotFoundMessage: {
        id: 'App.ErrorNotFoundMessage',
        defaultMessage: 'Элемент отсутствует в дереве.',
    },
    appErrorGotoTitle: {
        id: 'App.ErrorGotoTitle',
        defaultMessage: 'Ошибка',
    },
    appErrorGotoMessage: {
        id: 'App.ErrorGotoMessage',
        defaultMessage: 'Перейти на элемент нельзя, возможно, элемент удален либо доступ к нему закрыт.',
    },
    appErrorUnauthorizedTitle: {
        id: 'App.ErrorUnauthorizedTitle',
        defaultMessage: 'Ошибка',
    },
    appErrorUnauthorizedMessage: {
        id: 'App.ErrorUnauthorizedMessage',
        defaultMessage: 'Вы не авторизованы, необходимо авторизоваться.',
    },

    appDownloadMessage: {
        id: 'App.DownloadMessage',
        defaultMessage: 'Скачать',
    },

    appPsdDeleteFirstRowErrorMessage: {
        id: 'App.PsdDeleteFirstRowErrorMessage',
        defaultMessage: 'Нельзя удалить первую строку',
    },

    appPsdDropNotSupportedErrorMessage: {
        id: 'App.appPsdDropNotSupportedErrorMessage',
        defaultMessage: 'Ячейка не поддерживает размещение такого типа объекта',
    },
    appGridOutsideTableDropErrorMessage: {
        id: 'App.appGridOutsideTableDropErrorMessage',
        defaultMessage: 'Символ нельзя вынести за пределы таблицы',
    },
    appPsdDeleteFirstColumnErrorMessage: {
        id: 'App.PsdDeleteFirstColumnErrorMessage',
        defaultMessage: 'Нельзя удалить первую колонку',
    },
    appEPCDeleteEmptyColumnErrorMessage: {
        id: 'App.EPCDeleteEmptyColumnErrorMessage',
        defaultMessage: 'Нельзя удалить столбец без заголовка',
    },
    appEPCDeleteLastRowErrorMessage: {
        id: 'App.EPCDeleteLastRowErrorMessage',
        defaultMessage: 'Нельзя удалить все строки в модели',
    },
    appEPCDeleteLastColumnErrorMessage: {
        id: 'App.appEPCDeleteLastColumnErrorMessage',
        defaultMessage: 'Нельзя удалить все колонки',
    },
    importLicenseTitle: {
        id: 'App.importLicenseTitle',
        defaultMessage: 'Импорт лицензии',
    },
    importLicenseSuccess: {
        id: 'App.importLicenseSuccess',
        defaultMessage: 'Лицензия успешно добавлена',
    },
    importLicensefileCorrupted: {
        id: 'App.importLicensefileCorrupted',
        defaultMessage: 'Файл лицензии поврежден',
    },
    importLicenselicenseExist: {
        id: 'App.importLicenselicenseExist',
        defaultMessage: 'Лицензия уже загружена',
    },

    importLicenseimportFail: {
        id: 'App.importLicenseimportFail',
        defaultMessage: 'Ошибка при импорте лицензии',
    },

    copyFail: {
        id: 'App.copyFail',
        defaultMessage: 'Копирование запрещено',
    },

    copyIdFail: {
        id: 'App.copyIdFail',
        defaultMessage: 'Буфер обмена недоступен, возможно, необходимо использовать https протокол',
    },

    copyIdSuccess: {
        id: 'App.copyIdSuccess',
        defaultMessage: 'Идентификатор скопирован в буфер обмена',
    },

    copyIdSuccessTitle: {
        id: 'App.copyIdSuccessTitle',
        defaultMessage: 'Копирование выполнено',
    },

    copyLink: {
        id: 'App.copyLink',
        defaultMessage: 'Ссылка скопирована в буфер обмена',
    },

    copyLinkError: {
        id: 'App.copyLinkError',
        defaultMessage: 'Ошибка копирования ссылки',
    },

    searchTextLess: {
        id: 'App.searchTextLess',
        defaultMessage: 'В поисковом запросе менее двух символов',
    },

    systemPropsErrorTitle: {
        id: 'App.systemPropsErrorTitle',
        defaultMessage: 'Ошибка сохранения параметров сервера',
    },

    LdapMandatoryFieldsErrorTitle: {
        id: 'App.LdapMandatoryFieldsErrorTitle',
        defaultMessage: 'Обязательные поля Ldap должны быть заполнены',
    },

    LdapDefaultDomainErrorTitle: {
        id: 'App.LdapDefaultDomainErrorTitle',
        defaultMessage: 'Домен Ldap по умолчанию должен быть один или отсутствовать',
    },

    LdapServerUrlErrorTitle: {
        id: 'App.LdapServerUrlErrorTitle',
        defaultMessage: 'URL-адрес сервера Ldap и альтернативный URL-адрес должны отличаться',
    },

    LdapDomainsErrorTitle: {
        id: 'App.LdapDomainsErrorTitle',
        defaultMessage: 'Домены Ldap должны быть разными',
    },

    LdapNetBiosErrorTitle: {
        id: 'App.LdapNetBiosErrorTitle',
        defaultMessage: 'имена Ldap и NetBios должны отличаться',
    },

    repeatEdgeDefinition: {
        id: 'App.repeatEdgeDefinition',
        defaultMessage: 'Данная связь уже имеется на модели',
    },

    noneExistingEdgeType: {
        id: 'App.noneExistingEdgeType',
        defaultMessage: 'Данная связь не существует',
    },

    loadingPage: {
        id: 'App.loading',
        defaultMessage: 'Загрузка',
    },

    successfulImportVisio: {
        id: 'App.successfulImportVisio',
        defaultMessage: 'Импорт из VISIO успешно завершен',
    },

    invalidFailImportVisio: {
        id: 'App.invalidFailImportVisio',
        defaultMessage: 'Ошибка загрузки файла',
    },

    pasteError: {
        id: 'pasteError',
        defaultMessage: 'Тип модели не поддерживает размещение такого типа объекта',
    },

    profileCreationErrorTitle: {
        id: 'profileCreationErrorTitle',
        defaultMessage: 'Ошибка добавления профиля',
    },

    profileCreationErrorTitleMessage: {
        id: 'profileCreationErrorTitleMessage',
        defaultMessage: 'Такое название профиля уже существует',
    },
    incorrectObjectTypeTitle: {
        id: 'incorrectObjectTypeTitle',
        defaultMessage: 'Ошибка',
    },

    incorrectObjectTypeMessage: {
        id: 'incorrectObjectTypeMessage',
        defaultMessage: 'Тип модели не поддерживает размещение такого типа объекта',
    },

    incorrectSymbolTypeTitle: {
        id: 'incorrectSymbolTypeTitle',
        defaultMessage: 'Ошибка формата файла',
    },

    incorrectSymbolTypeMessage: {
        id: 'incorrectSymbolTypeMessage',
        defaultMessage: 'Допускается только SVG формат файлов',
    },

    exportFailTitle: {
        id: 'exportFailTitle',
        defaultMessage: 'Экспорт не удался',
    },
    exportError: {
        id: 'exportError',
        defaultMessage: 'Ошибка при экспорте',
    },
    exportBpmnErrorModelNotFound: {
        id: 'exportBpmnErrorModelNotFound',
        defaultMessage: 'Модель не найдена',
    },
    exportBpmnErrorModelNotBpmn: {
        id: 'exportBpmnErrorModelNotBpmn',
        defaultMessage: 'Экспортируемая модель не является моделью BPMN',
    },
    exportBpmnErrorNoAccess: {
        id: 'exportBpmnErrorNoAccess',
        defaultMessage: 'Недостаточно прав для чтения модели',
    },

    insertDnDElementErrorTitle: {
        id: 'insertDnDElementErrorTitle',
        defaultMessage: 'Ошибка вставки элемента',
    },
    insertDnDElementErrorDiagramIsLocked: {
        id: 'insertDnDElementErrorDiagramIsLocked',
        defaultMessage: 'Диаграмма заблокирована',
    },
    insertDnDElementErrorAnotherRepository: {
        id: 'insertDnDElementErrorAnotherRepository',
        defaultMessage: 'Нельзя добавить элемент из другой базы данных',
    },
    insertDnDElementErrorIsFolder: {
        id: 'insertDnDElementErrorIsFolder',
        defaultMessage: 'Нельзя добавить папку',
    },
    insertDndElementErrorIsSpreadsheet: {
        id: 'insertDndElementErrorIsSpreadsheet',
        defaultMessage: 'Нельзя добавить таблицу',
    },
    insertDndElementErrorIsSimulationModeling: {
        id: 'insertDndElementErrorIsSimulationModeling',
        defaultMessage: 'Нельзя добавить имитационное моделирование',
    },
    insertDndElementErrorDefaultMessage: {
        id: 'insertDndElementErrorDefaultMessage',
        defaultMessage: 'Неверный тип элемента для переноса на холст',
    },
    insertDnDElementErrorCantCreateDecomposition: {
        id: 'insertDnDElementErrorCantCreateDecomposition',
        defaultMessage: 'Невозможно создать декомпозицию',
    },
    insertDnDElementErrorCantMoveDeletedNode: {
        id: 'insertDnDElementErrorCantMoveDeletedNode',
        defaultMessage: 'Невозможно перенести удаленный элемент',
    },
    itemTypeIsNotExistTitle: {
        id: 'App.itemTypeIsNotExistTitle',
        defaultMessage: 'Ошибка',
    },
    itemTypeIsNotExistMessage: {
        id: 'App.itemTypeIsNotExistMessage',
        defaultMessage: 'Тип элемента не содержится в методологии',
    },
    userAlreadyExistsTitle: {
        id: 'App.userAlreadyExistsTitle',
        defaultMessage: 'Ошибка',
    },
    userAlreadyExistsMessage: {
        id: 'App.userAlreadyExistsMessage',
        defaultMessage: 'Пользователь с таким логином уже существует',
    },
    groupAlreadyExistsTitle: {
        id: 'App.groupAlreadyExistsTitle',
        defaultMessage: 'Ошибка',
    },
    groupAlreadyExistsMessage: {
        id: 'App.groupAlreadyExistsMessage',
        defaultMessage: 'Группа с таким идентификатором уже существует',
    },
    settingsSavedTitle: {
        id: 'App.settingsSavedTitle',
        defaultMessage: 'Настройки сохранены',
    },
    successSavingSystemProperty: {
        id: 'App.successSavingSystemProperty',
        defaultMessage: 'Настройки сервера успешно сохранены',
    },
    emptyClipboardTitle: {
        id: 'App.emptyClipboardTitle',
        defaultMessage: 'Буфер обмена пуст',
    },
    emptyClipboardText: {
        id: 'App.emptyClipboardText',
        defaultMessage: 'Содержимое для вставки отсутствует',
    },
    cannotPasteFromAnotherRepository: {
        id: 'App.cannotPasteFromAnotherRepository',
        defaultMessage: 'Нельзя вставить элемент из другой базы данных',
    },
    methodologyIsOpened: {
        id: 'App.methodologyIsOpened',
        defaultMessage: 'Данная методология открыта на редактирование',
    },
    methodologyIsOpenedDescription: {
        id: 'App.methodologyIsOpenedDescription',
        defaultMessage: 'Для удаления методологии необходимо закрыть вкладку редактирования',
    },
    insertDnDElementErrorInvalidParent: {
        id: 'App.insertDnDElementErrorInvalidParent',
        defaultMessage: 'Недопустимый родитель для элемента данного типа',
    },
    insertDnDElementErrorExistInRepository: {
        id: 'App.insertDnDElementErrorExistInRepository',
        defaultMessage: 'Элемент уже находится в этом репозитории',
    },
    moveNodeErrorTitle: {
        id: 'App.moveNodeErrorTitle',
        defaultMessage: 'Ошибка перемещения',
    },
    moveNodeErrorDescription: {
        id: 'App.moveNodeErrorDescription',
        defaultMessage: 'Тип папки "{folderTypeName}" не может содержать {nodeTypeName} "{nodeName}"',
    },
    insertErrorTitle: {
        id: 'App.insertErrorTitle',
        defaultMessage: 'Ошибка вставки',
    },
    invalidObjectTypeForThisFolder: {
        id: 'App.invalidObjectTypeForThisFolder',
        defaultMessage: 'Недопустимый тип объекта для данной папки',
    },
    selectedElementsCantBeMoved: {
        id: 'App.selectedElementsCantBeMoved',
        defaultMessage: 'Выбранные элементы дерева (или некоторые из них) не могут быть перенесены',
    },
    restartAppToApplyChangesMessage: {
        id: 'App.restartAppToApplyChangesMessage',
        defaultMessage: 'Изменения вступят в силу после перезапуска приложения',
    },
    invalidIconFormatErrorTitle: {
        id: 'App.invalidIconFormatErrorTitle',
        defaultMessage: 'Доступен только формат svg',
    },
    chooseIconErrorTitle: {
        id: 'App.chooseIconErrorTitle',
        defaultMessage: 'Необходимо выбрать иконку',
    },
    errorSaveArisInExcel: {
        id: 'errorSaveArisInExcel',
        defaultMessage: 'Ошибка конвертации в Excel',
    },
    errorLoadArisFromExcel: {
        id: 'errorLoadArisFromExcel',
        defaultMessage: 'Ошибка загрузки файла из Excel',
    },
    eraseNodeError: {
        id: 'App.eraseNodeError',
        defaultMessage: 'Не удалось безвозвратно удалить узел',
    },
    restoreNodeError: {
        id: 'App.restoreNodeError',
        defaultMessage: 'Не удалось восстановить удаленный узел',
    },
    nodeDeletedError: {
        id: 'App.nodeDeletedError',
        defaultMessage: 'Действие запрещено для удаленного узла',
    },
    unsupportedOperationError: {
        id: 'App.unsupportedOperationError',
        defaultMessage: 'Невозможно совершить опреацию с элементом',
    },
    emptyLoginError: {
        id: 'App.emptyLoginError',
        defaultMessage: 'Логин не может быть пустой',
    },
    incorrectPasswordLengthError: {
        id: 'App.incorrectPasswordLengthError',
        defaultMessage: 'Короткий пароль',
    },
    passwordHasNotLatinLetterError: {
        id: 'App.passwordHasNotLatinLetterError',
        defaultMessage: 'Пароль должен содержать хотя бы одну латинскую букву',
    },
    passwordOnlyLatinLettersError: {
        id: 'App.passwordOnlyLatinLettersError',
        defaultMessage: 'Пароль может содержать только латинские буквы',
    },
    passwordCaseLettersError: {
        id: 'App.passwordCaseLettersError',
        defaultMessage: 'Пароль должен содержать буквы верхнего и нижнего регистра',
    },
    passwordHasNotDigitsError: {
        id: 'App.passwordHasNotDigitsError',
        defaultMessage: 'Пароль должен содержать цифры',
    },
    passwordHasSpecialLettersError: {
        id: 'App.passwordHasSpecialLettersError',
        defaultMessage: 'Пароль должен содержать специальные символы',
    },
    newPasswordError: {
        id: 'App.newPasswordError',
        defaultMessage: 'Новый пароль не должен совпадать с одним из старых',
    },
    recentlyChangedPasswordError: {
        id: 'App.recentlyChangedPasswordError',
        defaultMessage: 'Недавно пользователь уже сменил пароль. Частая смена пароля запрещена',
    },
    tryNoAuthorAddMarcer: {
        id: 'App.tryNoAuthorAddMarcer',
        defaultMessage: 'Размещать маркер на холсте может только автор комментария',
    },
    invalidChractersLogindError: {
        id: 'App.invalidChractersLogindError',
        defaultMessage: 'В логине пользователя обнаружены недопустимые символы',
    },
    checkServerErrorTitle: {
        id: 'App.checkServerErrorTitle',
        defaultMessage: 'Ошибка при проверке сервера',
    },
    invalidRepositoryId: {
        id: 'App.invalidRepositoryId',
        defaultMessage: 'id должен совпадать с repositoryId',
    },
    nodeMissingParent: {
        id: 'App.nodeMissingParent',
        defaultMessage: 'Отсутствует родительский узел',
    },
    parentAndChildIdUnequal: {
        id: 'App.parentAndChildIdUnequal',
        defaultMessage: 'Не совпадение по repositoryId у дочернего и родительского узла',
    },
    incorrectResourceForNode: {
        id: 'App.incorrectResourceForNode',
        defaultMessage: 'Не корректный ресурс для создания узла',
    },
    repositoryParentError: {
        id: 'App.repositoryParentError',
        defaultMessage: 'Родительский узел у репозитория должен быть не задан',
    },
    changeModelTypeOfOpenModelWarn: {
        id: 'App.changeModelTypeOfOpenModelWarn',
        defaultMessage: 'Изменение типа открытой модели',
    },
    changeModelTypeOfOpenModelWarnDescription: {
        id: 'App.changeModelTypeOfOpenModelWarnDescription',
        defaultMessage: 'Для изменения типа модели необходимо закрыть модель',
    },
    noUserProfileAssigned: {
        id: 'App.noUserProfileAssigned',
        defaultMessage: 'Пользователю "{userLogin}" не назначен профиль',
    },
    warning: {
        id: 'App.warning',
        defaultMessage: 'Предупреждение',
    },
    nodeSubscribedMessage: {
        id: 'App.nodeSubscribedMessage',
        defaultMessage: 'Подписка на узел',
    },
    nodeSubscribedDescription: {
        id: 'App.nodeSubscribedDescription',
        defaultMessage: 'Вы подписаны на "{nodeName}"',
    },
    nodeUnsubscribedMessage: {
        id: 'App.nodeUnsubscribedMessage',
        defaultMessage: 'Удаление подписки на узел',
    },
    nodeUnsubscribedDescription: {
        id: 'App.nodeUnsubscribedDescription',
        defaultMessage: 'Подписка на "{nodeName}" удалена',
    },
    nodeAddedToFavoritesMessage: {
        id: 'App.nodeAddedToFavoritesMessage',
        defaultMessage: 'Элемент добавлен в избранное',
    },
    nodeRemovedFromFavoritesMessage: {
        id: 'App.nodeRemovedFromFavoritesMessage',
        defaultMessage: 'Элемент удалён из избранного',
    },
    cannotOpenSvgErrorDescription: {
        id: 'App.cannotOpenSvgErrorDescription',
        defaultMessage: 'Невозможно открыть SVG',
    },
    approvalNotAllowed: {
        id: 'App.approvalNotAllowed',
        defaultMessage: 'Согласование не разрешено для этого типа узла',
    },
    KeycloakMandatoryFieldsErrorTitle: {
        id: 'App.KeycloakMandatoryFieldsErrorTitle',
        defaultMessage: 'Обязательные поля Keycloak должны быть заполнены',
    },
    approvalName: {
        id: 'App.approvalName',
        defaultMessage: 'Необходимо заполнить название согласования',
    },
    approvalTemplateName: {
        id: 'App.approvalTemplateName',
        defaultMessage: 'Необходимо заполнить название шаблона',
    },
    searchAttrFilterError: {
        id: 'App.searchAttrFilterError',
        defaultMessage: 'В фильтрах значения условий должны быть заполнены',
    },
    bpmnDataDescription: {
        id: 'App.bpmnDataDescription',
        defaultMessage:
            'В модели найдены сигнатуры не поддерживаемые на текущий момент. Для продолжения работы без ошибок перенесите данные в новую модель BPMN 2.0',
    },
    approvalUserNotFound: {
        id: 'App.approvalUserNotFound',
        defaultMessage: 'The user specified in the approval was not found',
    },
    blockedEdgeError: {
        id: 'App.blockedEdgeError',
        defaultMessage: 'Ошибка изменения типа связи',
    },
    forbiddenEdgeByProfileMessage: {
        id: 'App.forbiddenEdgeByProfileMessage',
        defaultMessage: 'Изменение типа связи "{edgeTypeName}" запрещено профилем',
    },
    edgeHasDefinitionMessage: {
        id: 'App.edgeHasDefinitionMessage',
        defaultMessage: 'Связь "{edgeTypeName}" имеет определение связи, изменение запрещено',
    },
    edgeCanHabeOnlyOneTypeMessage: {
        id: 'App.edgeCanHabeOnlyOneTypeMessage',
        defaultMessage: 'Изменение типа связи "{edgeTypeName}" невозможно',
    },
    currentPasswordError: {
        id: 'App.currentPasswordError',
        defaultMessage: 'Текущий пароль введён неверно',
    },
    passwordChangeSuccess: {
        id: 'App.passwordChangeSuccess',
        defaultMessage: 'Пароль изменён',
    },
    symbolsOrEdgesNotSupportedOnModelTypeMessage: {
        id: 'symbolsOrEdgesNotSupportedOnModelTypeMessage',
        defaultMessage: 'Вставляемые символы или связи не поддерживаются на данном типе модели',
    },
    noValidSymbolOnModelTypeMessage: {
        id: 'noValidSymbolOnModelTypeMessage',
        defaultMessage: 'Нет доступного символа для данного типа модели',
    },
    edgesOrEdgeBetweenSymbolsNotSupportedOnModelTypeMessage: {
        id: 'edgesOrEdgeBetweenSymbolsNotSupportedOnModelTypeMessage',
        defaultMessage:
            'Вставляемые связи или тип связи между вставляемыми символами не разрешены на данном типе модели',
    },
    passwordChangeProhibited: {
        id: 'App.passwordChangeProhibited',
        defaultMessage: 'Смена пароля запрещена. Обратитесь к администратору',
    },
    copyDatabaseSuccess: {
        id: 'App.copyDatabaseSuccess',
        defaultMessage: 'Копирование базы данных завершено',
    },
    publicationNodeMarkDeleted: {
        id: 'App.publicationNodeMarkDeleted',
        defaultMessage: 'Нельзя опубликовать удаленную модель',
    },
    publicationNodeReadAccessDenied: {
        id: 'App.publicationNodeReadAccessDenied',
        defaultMessage: 'Для публикации необходимо разрешение на чтение модели для всех пользователей',
    },
});
