import React from 'react';
import theme from './InfoButton.scss';
import passwordKey from '../../../../resources/icons/passwordKey.svg';
import arrowright from '../../../../resources/icons/arrow-Right-Big.svg';
import { Icon } from '../../index';

type TInfoButtonProps = {
    titleText: string;
    infoText: string;
    onClick: () => void;
};

export const InfoButton = (props: TInfoButtonProps) => {
    const { titleText, infoText, onClick } = props;
    return (
        <div className={theme.container} onClick={onClick}>
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={passwordKey} />
            </div>
            <div className={theme.textContainer}>
                <div className={theme.titleTextContainer}>{titleText}</div>
                <div className={theme.infoTextContainer}>{infoText}</div>
            </div>
            <Icon spriteSymbol={arrowright} />
        </div>
    );
};
