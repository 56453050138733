import { Form, FormInstance, Tabs } from 'antd';
import React, { ChangeEvent, Component } from 'react';
import { injectIntl } from 'react-intl';
import { differenceBy, uniqBy } from 'lodash-es';
import {
    AttributeType,
    AttributeTypeGroup,
    EdgeType,
    EdgeTypeGroup,
    ModelType,
    ModelTypeGroup,
    InternationalString,
    EdgeTypeDirectionEnum,
    EdgeMatrixStyle,
    EdgeMatrixStyleCustom,
    EdgeMatrixStyleUserIcon,
} from '../../../../../../serverapi/api';
import { AttributesTab } from '../AttributeType/AttributesTab.component';
import { EditorFooterButtons } from '../Footer/EditorFooterButtons.component';
import footerMessages from '../Footer/EditorFooterButtons.messages';
import theme from '../Presets.scss';
import messages from './EdgeType.messages';
import presetMessages from '../../../messages/Presets.messages';
import { EdgeMatrixStyleType, TEdgeTypeEditorFullProps, TEdgeTypeMatrixStyleValidation } from './EdgeTypeEditor.types';
import { GeneralTab } from './EdgeTypeGeneralTab.component';
import { DecompositionsTab } from '../ObjectType/DecompositionsTab.component';
import { LocalesService } from '../../../../../../services/LocalesService';
import { convertStringToArray } from '../../../../../../utils/convertStringToArray';
import { createButtonsCompositeDataTestId } from '../util/createButtonsCompositeDataTestId.utils';
import type { Tab } from 'rc-tabs/lib/interface';
import { StylesTab } from './EdgeTypeStylesTab.component';
import { TButtonProps } from '../../../../../UIKit/components/Button/Button.types';

type TEdgeTypeEditorState = {
    edgeType: EdgeType;
    validDecompositionModelTypes: ModelType[];
    modelTypeGroups: ModelTypeGroup[];
    synonymsIds?: string[];
    matrixStyleValidation: TEdgeTypeMatrixStyleValidation;
};

class EdgeTypeEditor extends Component<TEdgeTypeEditorFullProps, TEdgeTypeEditorState> {
    formRef = React.createRef<FormInstance>();

    constructor(props: TEdgeTypeEditorFullProps) {
        super(props);
        this.state = {
            edgeType: props.edgeType,
            synonymsIds: props.edgeType.synonymsIds,
            validDecompositionModelTypes: props.modelTypes?.filter((mt) =>
                props.edgeType?.validDecompositionModelTypesIds?.includes(mt.id),
            ),
            modelTypeGroups: props.modelTypes
                .map((at) => at.modelTypeGroup)
                .reduce((acc: ModelTypeGroup[], mtg: ModelTypeGroup) => {
                    if (!acc.some((modelTypeGroup) => mtg.id === modelTypeGroup.id)) {
                        acc.push(mtg);
                    }

                    return acc;
                }, []),
            matrixStyleValidation: {
                custom: false,
                userIcon: false,
            },
        };
    }

    onChangeEdgeTypeId = (id: string) => {
        this.setState({
            edgeType: { ...this.state.edgeType, id },
        });
    };

    onChangeEdgeTypeName = (value: InternationalString) => {
        this.setState((state) => ({
            edgeType: {
                ...state.edgeType,
                multilingualName: value,
            },
        }));
    };

    onChangeEdgeTypeDescription = (value: InternationalString) => {
        this.setState((state) => ({
            edgeType: {
                ...state.edgeType,
                multilingualDescription: value,
            },
        }));
    };

    onChangeEdgeTypeStyle = (edgeStyle: string) => {
        this.setState({
            edgeType: {
                ...this.state.edgeType,
                edgeStyle,
            },
        });
    };

    onChangeEdgeTypeDirection = (direction: EdgeTypeDirectionEnum) => {
        this.setState({
            edgeType: {
                ...this.state.edgeType,
                direction,
            },
        });
    };

    onChangeEdgeTypeGroup = (edgeTypeGroup: EdgeTypeGroup) => {
        this.setState({
            edgeType: {
                ...this.state.edgeType,
                edgeTypeGroup,
            },
        });
    };

    onChangeEdgeTypeInvisible = (canBeInvisible: boolean) => {
        this.setState({
            edgeType: {
                ...this.state.edgeType,
                canBeInvisible,
            },
        });
    };

    onChangeEdgeTypeApprovals = (allowApprovals: boolean) => {
        this.setState({
            edgeType: {
                ...this.state.edgeType,
                allowApprovals,
            },
        });
    };

    onChangeEdgeTypeAlwaysCreateDefintion = (alwaysCreateDefinition: boolean) => {
        this.setState({
            edgeType: {
                ...this.state.edgeType,
                alwaysCreateDefinition,
            },
        });
    };

    onChangeEdgeTypeEdgeMatrixStyle = (matrixStyle: EdgeMatrixStyle | undefined) => {
        this.setState({
            edgeType: {
                ...this.state.edgeType,
                matrixStyle,
            },
        });
    };

    onChangeMatrixStyleValidation = (validation: TEdgeTypeMatrixStyleValidation) => {
        this.setState({
            matrixStyleValidation: validation,
        });
    };

    setUniqAttributeTypes = () => {
        const { edgeType } = this.state;

        return [
            {
                ...edgeType,
                name: LocalesService.internationalStringToString(edgeType.multilingualName, this.props.currentLocale),
                validDecompositionModelTypesIds: this.state.validDecompositionModelTypes.map((mt) => mt.id),
                attributeTypes: uniqBy(edgeType.attributeTypes, 'id'),
                synonymsIds: this.state.synonymsIds,
            },
        ];
    };

    deleteAvailableModelTypeGroups = (modelTypeGroups: ModelTypeGroup[]) => {
        this.setState({
            validDecompositionModelTypes: this.state.validDecompositionModelTypes?.filter(
                (mt) => !modelTypeGroups.some((mtg) => mt.modelTypeGroup.id === mtg.id),
            ),
        });
    };

    deleteAvailableModelTypes = (modelTypes: ModelType[]) => {
        this.setState({
            validDecompositionModelTypes: differenceBy(this.state.validDecompositionModelTypes, modelTypes, 'id'),
        });
    };

    addAvailableModelTypes = (modelTypes: ModelType[]) => {
        this.setState({
            validDecompositionModelTypes: modelTypes.concat(this.state.validDecompositionModelTypes || []),
        });
    };

    onChangeAllowAnyDecompositionType = (checked: boolean) => {
        this.setState({
            edgeType: {
                ...this.state.edgeType,
                allowAnyDecomposition: checked,
            },
        });
    };

    onChangeEdgeTypeSynonymsIds = (e: ChangeEvent<HTMLInputElement>): void => {
        const synonymsIds: string[] = convertStringToArray(e.target.value);

        this.setState({ synonymsIds });
    };

    render() {
        const { createMode } = this.props;
        const { edgeTypeGroup, multilingualName, id } = this.state.edgeType;
        const form = this.formRef.current;

        const deleteQuestion: string = this.props.intl.formatMessage(presetMessages.deleteEdge, {
            name: LocalesService.internationalStringToString(this.state.edgeType.multilingualName),
        });
        const buttons: TButtonProps[] = [
            {
                children: this.props.intl.formatMessage(footerMessages.cancel),
                onClick: this.props.onCancel,
                size: 'large',
            },
            {
                children: this.props.intl.formatMessage(createMode ? footerMessages.add : footerMessages.save),
                visualStyle: 'primary',
                size: 'large',
                disabled: !(multilingualName?.en || multilingualName?.ru) || !edgeTypeGroup || !id,
                dataTest: 'footer-button-save',
                onClick: () => {
                    if (form) {
                        form.validateFields().then(() => {
                            const edgeTypes = this.setUniqAttributeTypes();
                            const matrixStyle: EdgeMatrixStyle | undefined = edgeTypes[0].matrixStyle;

                            if (
                                matrixStyle?.type === EdgeMatrixStyleType.CUSTOM &&
                                (matrixStyle as EdgeMatrixStyleCustom)?.text === ''
                            ) {
                                return this.setState({
                                    matrixStyleValidation: {
                                        userIcon: this.state.matrixStyleValidation.userIcon,
                                        custom: true,
                                    },
                                });
                            }

                            if (
                                matrixStyle?.type === EdgeMatrixStyleType.USER_ICON &&
                                (matrixStyle as EdgeMatrixStyleUserIcon)?.iconData === ''
                            ) {
                                return this.setState({
                                    matrixStyleValidation: {
                                        userIcon: true,
                                        custom: this.state.matrixStyleValidation.custom,
                                    },
                                });
                            }

                            this.props.onSubmit({
                                serverNode: this.props.serverNode,
                                preset: this.props.preset,
                                edgeTypes,
                                createMode,
                                tabNodeId: this.props.tab.nodeId,
                            });
                        });
                    }
                },
            },
        ];

        const items: Tab[] = [
            {
                label: <span>{this.props.intl.formatMessage(messages.generalSettings)}</span>,
                key: 'GeneralSettings',
                children: (
                    <div className={theme.tabContent}>
                        <Form ref={this.formRef} layout="vertical">
                            <GeneralTab
                                createMode={createMode}
                                edgeType={this.state.edgeType}
                                edgeTypeGroups={this.props.edgeTypeGroups}
                                exportStyle={() => this.props.exportStyle(this.state.edgeType)}
                                onChangeEdgeTypeName={this.onChangeEdgeTypeName}
                                onChangeEdgeTypeId={this.onChangeEdgeTypeId}
                                onChangeEdgeTypeDescription={this.onChangeEdgeTypeDescription}
                                onChangeEdgeTypeGroup={this.onChangeEdgeTypeGroup}
                                onChangeEdgeTypeInvisible={this.onChangeEdgeTypeInvisible}
                                onChangeEdgeTypeApprovals={this.onChangeEdgeTypeApprovals}
                                onChangeEdgeTypeAlwaysCreateDefintion={this.onChangeEdgeTypeAlwaysCreateDefintion}
                                onChangeEdgeTypeSynonymsIds={this.onChangeEdgeTypeSynonymsIds}
                                generalForm={this.formRef.current!}
                            />
                        </Form>
                        <EditorFooterButtons
                            deleteQuestion={deleteQuestion}
                            buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')}
                        />
                    </div>
                ),
            },
            {
                label: <span>{this.props.intl.formatMessage(messages.stylesSettings)}</span>,
                key: 'StylesSettings',
                children: (
                    <div className={theme.tabContent}>
                        <StylesTab
                            edgeType={this.state.edgeType}
                            exportStyle={() => this.props.exportStyle(this.state.edgeType)}
                            onChangeEdgeTypeStyle={this.onChangeEdgeTypeStyle}
                            onChangeEdgeTypeDirection={this.onChangeEdgeTypeDirection}
                            onChangeEdgeTypeEdgeMatrixStyle={this.onChangeEdgeTypeEdgeMatrixStyle}
                            matrixStyleValidation={this.state.matrixStyleValidation}
                            onChangeMatrixStyleValidation={this.onChangeMatrixStyleValidation}
                        />
                        <EditorFooterButtons
                            deleteQuestion={deleteQuestion}
                            buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')}
                        />
                    </div>
                ),
            },
            {
                label: <span>{this.props.intl.formatMessage(messages.decompositions)}</span>,
                key: 'EdgeTypeDecompositions',
                disabled: createMode,
                children: (
                    <div className={theme.tabContent}>
                        <DecompositionsTab
                            modelTypes={this.props.modelTypes}
                            modelTypeGroups={this.state.modelTypeGroups}
                            availableModelTypes={this.state.validDecompositionModelTypes}
                            allowAnyDecompositionType={this.state.edgeType.allowAnyDecomposition}
                            deleteModelTypeGroups={this.deleteAvailableModelTypeGroups}
                            deleteModelTypes={this.deleteAvailableModelTypes}
                            addModelTypes={this.addAvailableModelTypes}
                            onChangeAllowAnyDecompositionType={this.onChangeAllowAnyDecompositionType}
                        />
                        <EditorFooterButtons
                            deleteQuestion={deleteQuestion}
                            buttons={createButtonsCompositeDataTestId(buttons, 'edge_types_decompositions_tab')}
                        />
                    </div>
                ),
            },
            {
                label: <span>{this.props.intl.formatMessage(messages.attributes)}</span>,
                key: 'EdgeTypeAttributes',
                children: (
                    <div className={theme.tabContent}>
                        <AttributesTab
                            attributeTypes={this.state.edgeType.attributeTypes}
                            availableAttributeTypes={this.props.availableAttributeTypes}
                            addAttributeTypes={(attributeTypes: AttributeType[]) => {
                                this.setState({
                                    edgeType: {
                                        ...this.state.edgeType,
                                        attributeTypes: attributeTypes.concat(this.state.edgeType.attributeTypes || []),
                                    },
                                });
                            }}
                            deleteAttributeTypes={(attributeTypes: AttributeType[]) =>
                                this.setState({
                                    edgeType: {
                                        ...this.state.edgeType,
                                        attributeTypes: this.state.edgeType?.attributeTypes?.filter(
                                            (at: AttributeType) => !attributeTypes.some((a) => a.id === at.id),
                                        ),
                                    },
                                })
                            }
                            deleteAttributeTypeGroups={(attributeTypeGroups: AttributeTypeGroup[]) =>
                                this.setState({
                                    edgeType: {
                                        ...this.state.edgeType,
                                        attributeTypes: this.state.edgeType.attributeTypes?.filter(
                                            (at: AttributeType) =>
                                                !attributeTypeGroups.some(
                                                    (atg) => at.attributeTypeGroup?.id === atg.id,
                                                ),
                                        ),
                                    },
                                })
                            }
                        />
                        <EditorFooterButtons
                            deleteQuestion={deleteQuestion}
                            buttons={createButtonsCompositeDataTestId(buttons, 'edge_types_attributes_tab')}
                        />
                    </div>
                ),
            },
            {
                label: <span>{this.props.intl.formatMessage(messages.elementAttributes)}</span>,
                key: 'ElementAttributes',
                children: (
                    <div className={theme.tabContent}>
                        <AttributesTab
                            attributeTypes={this.state.edgeType.diagramElementAttributes}
                            availableAttributeTypes={this.props.availableAttributeTypes}
                            addAttributeTypes={(diagramElementAttributes: AttributeType[]) => {
                                this.setState({
                                    edgeType: {
                                        ...this.state.edgeType,
                                        diagramElementAttributes: diagramElementAttributes.concat(
                                            this.state.edgeType.diagramElementAttributes || [],
                                        ),
                                    },
                                });
                            }}
                            deleteAttributeTypes={(diagramElementAttributes: AttributeType[]) =>
                                this.setState({
                                    edgeType: {
                                        ...this.state.edgeType,
                                        diagramElementAttributes: this.state.edgeType?.diagramElementAttributes?.filter(
                                            (at: AttributeType) =>
                                                !diagramElementAttributes.some((a) => a.id === at.id),
                                        ),
                                    },
                                })
                            }
                            deleteAttributeTypeGroups={(attributeTypeGroups: AttributeTypeGroup[]) =>
                                this.setState({
                                    edgeType: {
                                        ...this.state.edgeType,
                                        diagramElementAttributes: this.state.edgeType.diagramElementAttributes?.filter(
                                            (at: AttributeType) =>
                                                !attributeTypeGroups.some(
                                                    (atg) => at.attributeTypeGroup?.id === atg.id,
                                                ),
                                        ),
                                    },
                                })
                            }
                        />
                        <EditorFooterButtons
                            deleteQuestion={deleteQuestion}
                            buttons={createButtonsCompositeDataTestId(buttons, 'elements_attributes_tab')}
                        />
                    </div>
                ),
            },
        ];

        return (
            <div className={theme.container}>
                <span className={theme.navigationTitle}>{`${this.props.preset.name} > ${this.props.intl.formatMessage(
                    messages.typeCommunication,
                )} > ${this.props.edgeType.name}`}</span>

                <Tabs className={theme.tabs} tabPosition="left" defaultActiveKey="GeneralSettings" items={items} />
            </div>
        );
    }
}

const EdgeTypeTabIntl = injectIntl(EdgeTypeEditor);

export { EdgeTypeTabIntl as EdgeTypeEditor };
