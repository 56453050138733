//@test-ignore_ru

export const defaultEPCGridLayout = {
    cells: [],
    rowHeaderWidth: 20,
    columnHeaderHeight: 20,
    columns: [
        {
            id: 'c1',
            name: { en: 'General process', ru: 'Организационные элементы и прикладная система' },
            headerSize: 200,
        },
        {
            id: 'c2',
            name: { en: '', ru: '' },
            headerSize: 600,
        },
    ],
    rows: [
        {
            id: 'r1',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r2',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r3',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r4',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r5',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r6',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r7',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r8',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r9',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r10',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r11',
            name: { en: '', ru: 'Прочее' },
        },
    ],
};

