// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DropdownButton__container___aqse{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;margin-right:10px}.DropdownButton__container___aqse button{margin:0}.DropdownButton__buttonLabel__vCRvw{margin-right:10px}.DropdownButton__dropdownButton___3b9I{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.DropdownButton__dropdownButton___3b9I :first-child:not(:last-child){width:120px}.DropdownButton__dropdownMenu__EPL_6{width:146px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/DropdownButton/DropdownButton.scss"],"names":[],"mappings":"AAAA,kCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,gBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,kBAAA,CAAA,mBAAA,CAAA,WAAA,CACA,iBAAA,CACA,yCACE,QAAA,CAIJ,oCACE,iBAAA,CAGF,uCACE,kBAAA,CAAA,mBAAA,CAAA,WAAA,CACA,qEACE,WAAA,CAIJ,qCACE,WAAA","sourcesContent":[".container{\r\n  display: flex;\r\n  flex-wrap: nowrap;\r\n  align-items: center;\r\n  flex-grow: 1;\r\n  margin-right: 10px;\r\n  button {\r\n    margin: 0;\r\n  }\r\n}\r\n\r\n.buttonLabel{\r\n  margin-right: 10px;\r\n}\r\n\r\n.dropdownButton{\r\n  flex-grow: 1;\r\n  :first-child:not(:last-child){\r\n    width: 120px;\r\n  }\r\n}\r\n\r\n.dropdownMenu{\r\n  width: 146px;\r\n}\r\n\r\n// .downOutlined {\r\n//   margin-top: 7px;\r\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DropdownButton__container___aqse",
	"buttonLabel": "DropdownButton__buttonLabel__vCRvw",
	"dropdownButton": "DropdownButton__dropdownButton___3b9I",
	"dropdownMenu": "DropdownButton__dropdownMenu__EPL_6"
};
export default ___CSS_LOADER_EXPORT___;
