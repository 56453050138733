import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { NodeId } from '../../../../../serverapi/api';
import { Dialog } from '../../../../UIKit/components/Dialog/Dialog.component';
import messages from '../../../messages/NewSimulationNodeDialog.messages';

type TNewSimulationNodeDialogProps = {
    parentNodeId: NodeId;
    onClose: () => void;
    onSubmit: (simulationModelTitle: string, parentNodeId: NodeId) => void;
};

type TFormValues = {
    simulationModelTitle: string;
};

export const NewSimulationNodeDialog = (props: TNewSimulationNodeDialogProps) => {
    const { onClose, parentNodeId, onSubmit } = props;
    const intl = useIntl();
    const [form] = Form.useForm();
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const handleSubmit = () => {
        form.validateFields()
            .then((values: TFormValues) => {
                setSubmitDisabled(true);
                onSubmit(values.simulationModelTitle.trim(), parentNodeId);
            })
            .catch(() => undefined);
    };

    return (
        <Dialog
            open
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.simulationAddTitle)}
            okText={<span>{intl.formatMessage(messages.create)}</span>}
            cancelText={<span>{intl.formatMessage(messages.cancel)}</span>}
            cancelButtonProps={{ ['data-test']: 'create-element_im_cancel-button' }}
            okButtonProps={{ disabled: isSubmitDisabled, ['data-test']: 'create-element_im_save-button' }}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    label={intl.formatMessage(messages.name)}
                    name="simulationModelTitle"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: intl.formatMessage(messages.requiredField),
                        },
                    ]}
                >
                    <Input
                        data-test="imitation-model-form_model-name-input"
                        placeholder={intl.formatMessage(messages.namePlaceholder)}
                        autoComplete="off"
                        autoFocus
                    />
                </Form.Item>
            </Form>
        </Dialog>
    );
};
