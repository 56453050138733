import { NodeId, Symbol } from '../../serverapi/api';
import { ObjectDefinitionImpl } from '../../models/bpm/bpm-model-impl';
import {
    SAVE_OBJECT_DEFINITION,
    SAVE_OBJECT_DEFINITION_SUCCESS,
    SAVE_OBJECT_DEFINITION_FAIL,
    UPDATE_OBJECT_DEFINITION_NAME,
    OBJECT_DEFINITIONS_ADD,
    OBJECT_DEFINITION_MOVE,
    OBJECT_DEFINITION_DELETE,
    MODEL_MOVE,
    CHECK_OBJECT_DEFINITION,
    OBJECT_DEFINITION_UML_UPDATE,
    OBJECT_DEFINITIONS_ADD_ONLY_NEW,
} from '../../actionsTypes/entities/objectDefinition.actionTypes';
import {
    TCheckObjectDefinition,
    TDragSymbolAction,
    TObjectDefinitionAddAction,
    TObjectDefinitionDeleteAction,
    TObjectDefinitionMoveAction,
    TObjectDefinitionMoveActionPayload,
    TSaveObjectDefinition,
    TSaveObjectDefinitionFail,
    TSaveObjectDefinitionSuccess,
    TUpdateObjectDefinitionName,
    TModelMoveAction,
    TModelMoveActionPayload,
    TObjectDefinitionUmlUpdatePayload,
    TObjectDefinitionUmlUpdateAction,
    TObjectDefinitionsAddOnlyNewAction,
} from './objectDefinition.actions.types';
import { MxCell } from '../../mxgraph/mxgraph';
import { EDITOR_DRAG_SYMBOL } from '../../actionsTypes/editor.actionTypes';

export const saveObjectDefinition = (
    serverId: string,
    objectDefinition: ObjectDefinitionImpl,
): TSaveObjectDefinition => ({
    type: SAVE_OBJECT_DEFINITION,
    payload: { serverId, objectDefinition },
});

export const checkObjectDefinition = (objectDefinition: ObjectDefinitionImpl): TCheckObjectDefinition => ({
    type: CHECK_OBJECT_DEFINITION,
    payload: { objectDefinition },
});

export const saveObjectDefinitionSuccess = (
    serverId: string,
    objectDefinition: ObjectDefinitionImpl,
): TSaveObjectDefinitionSuccess => ({
    type: SAVE_OBJECT_DEFINITION_SUCCESS,
    payload: { serverId, objectDefinition },
});

export const saveObjectDefinitionFail = (
    serverId: string,
    objectDefinition: ObjectDefinitionImpl,
): TSaveObjectDefinitionFail => ({
    type: SAVE_OBJECT_DEFINITION_FAIL,
    payload: { serverId, objectDefinition },
});

export const updateObjectDefinitionName = (
    serverId: string,
    objectDefinition: ObjectDefinitionImpl,
    name: string,
): TUpdateObjectDefinitionName => ({
    type: UPDATE_OBJECT_DEFINITION_NAME,
    payload: { serverId, objectDefinition, name },
});

export const objectDefinitionsAdd = (objects: ObjectDefinitionImpl[]): TObjectDefinitionAddAction => ({
    type: OBJECT_DEFINITIONS_ADD,
    payload: { objectDefinitions: objects },
});

export const objectDefinitionDelete = (nodeId: NodeId, parentNodeId?: NodeId): TObjectDefinitionDeleteAction => ({
    type: OBJECT_DEFINITION_DELETE,
    payload: { nodeId, parentNodeId },
});

export const objectDefinitionMove = (payload: TObjectDefinitionMoveActionPayload): TObjectDefinitionMoveAction => ({
    type: OBJECT_DEFINITION_MOVE,
    payload,
});

export const dragSymbol = (
    symbol: Symbol,
    objectDefinitions: ObjectDefinitionImpl[],
    sourceCell?: MxCell,
): TDragSymbolAction => ({
    type: EDITOR_DRAG_SYMBOL,
    payload: {
        symbol,
        objectDefinitions,
        sourceCell,
    },
});

export const modelMove = (payload: TModelMoveActionPayload): TModelMoveAction => ({
    type: MODEL_MOVE,
    payload,
});

export const objectDefinitionUmlUpdate = (
    payload: TObjectDefinitionUmlUpdatePayload,
): TObjectDefinitionUmlUpdateAction => ({
    type: OBJECT_DEFINITION_UML_UPDATE,
    payload,
});

export const objectDefinitionsAddOnlyNew = (
    objectDefinitions: ObjectDefinitionImpl[],
): TObjectDefinitionsAddOnlyNewAction => ({
    type: OBJECT_DEFINITIONS_ADD_ONLY_NEW,
    payload: { objectDefinitions },
});
