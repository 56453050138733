import React, { useState } from 'react';
import { Button } from 'antd';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import icAttribute from '../../../resources/icons/ic-attribute.svg';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { AttributeType } from '../../../serverapi/api';
import theme from './AttributesDialog.scss';
import messages from '../messages/AttributesDialog.messages';
import { attributeTypeFormatMessage } from '../../AdminTools/Methodology/components/Presets/AttributeTypesTab/util/attributeTypesTab.utils';
import { useIntl } from 'react-intl';

type TDatabaseDialogProps = {
    open: boolean;
    attributes: AttributeType[];
    onSubmit: (attributes: AttributeType[]) => void;
    onCancel: () => void;
};

export const AttributesDialog = (props: TDatabaseDialogProps) => {
    const { onSubmit, onCancel, attributes, open } = props;
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const intl = useIntl();

    const handleClickItem = (id: string) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(() => selectedIds.filter((sId) => sId !== id));
        } else {
            setSelectedIds(() => [...selectedIds, id]);
        }
    };

    const handleSubmit = () => {
        if (!selectedIds?.length) return;

        const selectedAttributes = attributes.filter((attrType) => selectedIds.includes(attrType.id));
        onSubmit(selectedAttributes);
        setSelectedIds([]);
    };

    const handleCancel = () => {
        setSelectedIds([]);
        onCancel();
    };

    const footer = [
        <Button data-test="attributes-dialog_cancel-button" key="cancel" size="large" onClick={handleCancel}>
            {intl.formatMessage(messages.AttributesDialogCancelButton)}
        </Button>,
        <Button
            data-test="attributes-dialog_ok-button"
            key="ok"
            size="large"
            disabled={!selectedIds.length}
            type="primary"
            onClick={handleSubmit}
        >
            {intl.formatMessage(messages.AttributesDialogOkButton)}
        </Button>,
    ];

    const sortedAttributes: AttributeType[] | undefined = attributes?.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }

        return 0;
    });

    return (
        <Dialog
            open={open}
            onCancel={handleCancel}
            title={intl.formatMessage(messages.AttributesDialogTitle)}
            onOk={handleSubmit}
            footer={footer}
        >
            <div>
                {sortedAttributes?.map((attrType) => (
                    <div
                        data-test={`attribute_type_name_${attrType.name}`}
                        className={selectedIds.includes(attrType.id) ? theme.selectedItem : theme.item}
                        key={attrType.id}
                        onClick={() => handleClickItem(attrType.id)}
                    >   
                        <div>
                            <Icon className= {theme.icon} spriteSymbol={icAttribute} />
                        </div>
                        <div>
                            {attrType.name} {attrType?.valueType && `[${attributeTypeFormatMessage(attrType?.valueType)}]`}
                        </div>
                       
                    </div>
                ))}
            </div>
        </Dialog>
    );
};
