import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Input, Row } from 'antd';
import messages from './NotificationTemplatesDialog.messages';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './NotificationTemplatesDialog.scss';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { LARGE_DIALOG_WIDTH } from '../../../config/config';
import { closeDialog } from '../../../actions/dialogs.actions';
import { InternationalString } from '@/serverapi/api';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { AvailableEventsList } from './AvailableEventsList/AvailableEventsList.component';
import { TNotificationTemplatesDialogProps } from './NotificationTemplatesDialog.types';
import { noop } from 'lodash-es';
import { createNotificationTemplate, saveNotificationTemplate } from '@/actions/notificationTemplates.actions';

export const NotificationTemplatesDialog = (props: TNotificationTemplatesDialogProps) => {
    const { isEditing } = props;

    const intl = useIntl();
    const dispatch = useDispatch();
    const [templateId, setTemplateId] = useState<string>('');
    const [templateName, setTemplateName] = useState<InternationalString>({ ru: '', en: '' });
    const [letterSubject, setLetterSubject] = useState<InternationalString>({ ru: '', en: '' });
    const [letterText, setLetterText] = useState<InternationalString>({ ru: '', en: '' });

    useEffect(() => {
        if (isEditing) {
            const { id, name, subject, template } = props.templateData;
            setTemplateId(id);
            setTemplateName(name);
            setLetterSubject(subject);
            setLetterText(template);
        } else {
            const id = uuid();
            setTemplateId(id);
        }
    }, []);

    const [generalForm] = Form.useForm();

    const onCloseDialog = () => dispatch(closeDialog(DialogType.NOTIFICATION_TEMPLATES_DIALOG));

    const handleDispatch = () => {
        const notificationTemplate = {
            id: templateId,
            name: templateName,
            subject: letterSubject,
            template: letterText,
        };
        if (isEditing) {
            dispatch(saveNotificationTemplate(notificationTemplate));
        } else {
            dispatch(createNotificationTemplate(notificationTemplate));
        }
    };

    const handleSubmit = () => {
        generalForm
            .validateFields()
            .then(() => {
                handleDispatch();
                onCloseDialog();
            })
            .catch(noop);
    };

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onCloseDialog}
            title={isEditing ? intl.formatMessage(messages.editTitle) : intl.formatMessage(messages.title)}
            open
            width={LARGE_DIALOG_WIDTH}
            okText={isEditing ? intl.formatMessage(messages.save) : intl.formatMessage(messages.create)}
            cancelText={intl.formatMessage(messages.cancel)}
            className={theme.dialogContainer}
        >
            <Form form={generalForm} layout="vertical">
                <Col className={theme.col}>
                    <Row className={theme.inputTitle}>{intl.formatMessage(messages.ID)}</Row>
                    <Row className={theme.inputContainer}>
                        <Input disabled value={templateId} />
                    </Row>
                </Col>
                <Col data-test="templateNameContainer" className={theme.col}>
                    <MultiLangInputDialog
                        generalForm={generalForm}
                        label={intl.formatMessage(messages.templateName)}
                        mainInputName="templateName"
                        onChange={(multiLangValue) => setTemplateName(multiLangValue)}
                        multiLangValue={templateName}
                        formItemClassName={theme.inputContainer}
                        required
                        placeholder={intl.formatMessage(messages.setTemplateName)}
                        data-test="templateName"
                    />
                </Col>
                <Col className={theme.col}>
                    <MultiLangInputDialog
                        generalForm={generalForm}
                        label={intl.formatMessage(messages.letterSubject)}
                        mainInputName="letterSubject"
                        onChange={(multiLangValue) => setLetterSubject(multiLangValue)}
                        multiLangValue={letterSubject}
                        formItemClassName={theme.inputContainer}
                        required
                        placeholder={intl.formatMessage(messages.setSubject)}
                        data-test="letterSubject"
                    />
                </Col>
                <Col>
                    <MultiLangInputDialog
                        generalForm={generalForm}
                        label={intl.formatMessage(messages.letterText)}
                        mainInputName="template"
                        onChange={(multiLangValue) => setLetterText(multiLangValue)}
                        multiLangValue={letterText}
                        formItemClassName={theme.inputContainer}
                        textarea
                        autoSize={{
                            minRows: 4,
                            maxRows: 4,
                        }}
                        required
                        placeholder={intl.formatMessage(messages.setText)}
                        data-test="template"
                    />
                </Col>
                <Col>
                    <AvailableEventsList />
                </Col>
            </Form>
        </Dialog>
    );
};
