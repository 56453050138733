import type { TTreeItemSelectDialogProps } from './TreeItemSelectDialog.types';
import React, { FC } from 'react';
import messages from './TreeItemSelectDialog.messages';
import { useIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './TreeItemSelectDialog.component.scss';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { Tree } from '../../Tree/components/Tree/Tree.component';
import { Button } from 'antd';

export const TreeItemSelectDialog: FC<TTreeItemSelectDialogProps> = (props) => {
    const intl = useIntl();

    const {
        treeLocation,
        disableContextMenu,
        open,
        isTreeWithClearButton,
        typesAllowedToSelect,
        treeName,
        selectedNode,
        onClear,
        onClose,
    } = props;

    const handleSubmit = () => {
        if (selectedNode) props.onSubmit(selectedNode.nodeId, selectedNode);
    };

    const footer = [
        <div className={theme.buttons}>
            <div>
                <Button data-test="clear_attribute-NODE_btn" key="clear" danger onClick={onClear}>
                    {intl.formatMessage(messages.formClearAttributeButton)}
                </Button>
            </div>
            <div>
                <Button data-test="node-translation_button_cancel" key="cancel" onClick={onClose}>
                    {intl.formatMessage(messages.formDeclineButton)}
                </Button>

                <Button
                    data-test="node-translation_button_submit"
                    key="ok"
                    type="primary"
                    onClick={handleSubmit}
                    disabled={
                        !selectedNode ||
                        (!!typesAllowedToSelect?.length && !typesAllowedToSelect.includes(selectedNode.type))
                    }
                >
                    {intl.formatMessage(messages.formConfirmButton)}
                </Button>
            </div>
        </div>,
    ];

    return (
        <Dialog
            footer={isTreeWithClearButton && footer}
            className={theme.modal}
            onOk={handleSubmit}
            okButtonProps={{ disabled: !selectedNode }}
            onCancel={onClose}
            title={intl.formatMessage(messages.formName)}
            open={open}
            width={DEFAULT_DIALOG_WIDTH}
            okText={intl.formatMessage(messages.formConfirmButton)}
            cancelText={intl.formatMessage(messages.formDeclineButton)}
        >
            <Tree treeName={treeName} data={treeLocation || []} disableContextMenu={disableContextMenu} />
        </Dialog>
    );
};
