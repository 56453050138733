// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportVisioDBConflictDialog__dialogContainer__r1uqk{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.ImportVisioDBConflictDialog__footer__pMzs8{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/modules/Import/Visio/components/ImportVisioDBConflictDialog/ImportVisioDBConflictDialog.scss"],"names":[],"mappings":"AAAA,qDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAGJ,4CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA","sourcesContent":[".dialogContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.footer {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": "ImportVisioDBConflictDialog__dialogContainer__r1uqk",
	"footer": "ImportVisioDBConflictDialog__footer__pMzs8"
};
export default ___CSS_LOADER_EXPORT___;
