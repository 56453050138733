import { addServerBatch } from '../actions/entities/servers.actions';
import { put, select, takeEvery } from 'redux-saga/effects';
import { APP_LOADED } from '../actionsTypes/app.actionTypes';
import { TServerEntity } from '../models/entities.types';
import { apiBundle } from '../services/api/api-bundle';
import { v4 as uuid } from 'uuid';
import { ServerInfo } from '../serverapi/api';
import { login, loginFailed } from '../actions/authorization.actions';
import authMessages from '../services/response.messages';
import { appLoadViewMainColor, appSetIsEnLocaleAllowed } from '../actions/app.actions';
import { serverStoreService } from '../services/ServerStoreService';
import { TAppColor } from '../reducers/app.reducer.types';
import { tabsBusSubscribeAction } from '../actions/tabsBus.actions';
import { getOrigin, replaceLastSlash } from '../utils/url.utils';
import { VersionDaoService } from '../services/dao/VersionDaoService';
import { ExperimentalFeatures } from '../models/ExperimentalFeatures';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { LocalesService } from '../services/LocalesService';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
import { checkBrowserAction } from '../actions/checkBrowser.actions';
import { DEFAULT_LOCALE } from '@/config/config';
import { localeSelect } from '@/actions/locale.actions';
import { keycloakSettingsSet } from '@/actions/keycloakSettings.actions';

function* addUserColorsShemeToApp(serverInfo: ServerInfo) {
    const { mainColor, secondColor, selectedRowColor } = serverInfo;

    if (mainColor && secondColor && selectedRowColor) {
        LocalStorageDaoService.setAppColor({ mainColor, secondColor, selectedRowColor });
        yield put(appLoadViewMainColor({ mainColor, secondColor, selectedRowColor }));
    }
}

function* addUserGreetingLogo(greetingLogo: string | undefined) {
    LocalStorageDaoService.setGreetingLogo(greetingLogo);
}

function getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    return parts.length === 2 ? parts.pop()!.split(';').shift() : undefined;
}

function* tryAutologin(server: TServerEntity) {
    // если прокси сервер неверно обрабатывает редиректы то пользователь перейдя по /sso не передаст токен,
    // но получит приложение, если у него есть куки с токеном он авторизуется под пользователем, под которым
    // он входил в систему ранее, а не под пользователем Windows
    // чтобы этого избезать устанавливаем временную куку sso
    if (server && getCookie('sso') === getCookie('jwt')) {
        yield put(login(server.id, server.url, server.name, '', '', false, true));
    } else {
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));
        yield put(loginFailed(intl.formatMessage(authMessages.autoLoginFail)));
    }
}

function* appLoaded() {
    const servers: TServerEntity[] = serverStoreService().loadServersFromLocalStorage();
    const savedAppColor: TAppColor | null = LocalStorageDaoService.getAppColor();
    yield put(checkBrowserAction());
    yield put(tabsBusSubscribeAction());

    if (savedAppColor) {
        yield put(appLoadViewMainColor(savedAppColor));
    }

    const hostName = replaceLastSlash(getOrigin());
    const serverInfo: ServerInfo | undefined = yield VersionDaoService.checkBackend();

    if (!serverInfo) {
        yield put(addServerBatch(servers));

        return;
    }

    if (serverInfo.experimentalFeatures) {
        ExperimentalFeatures.enable();
    }

    if (serverInfo.isEnLocaleAllowed) {
        yield put(appSetIsEnLocaleAllowed(serverInfo.isEnLocaleAllowed));
    } else {
        yield put(localeSelect(DEFAULT_LOCALE));
        LocalStorageDaoService.setLocale(DEFAULT_LOCALE);
    }

    if (serverInfo.greetingText) {
        LocalStorageDaoService.setGreetingText(serverInfo.greetingText);
    }

    yield put(keycloakSettingsSet(serverInfo.keycloakSettings));

    yield addUserColorsShemeToApp(serverInfo);
    yield addUserGreetingLogo(serverInfo.greetingLogo);

    let localServ = servers.find((s) => replaceLastSlash(s.url) === hostName);
    if (!localServ) {
        const id = uuid();
        localServ = {
            id,
            name: hostName,
            url: hostName,
            api: apiBundle(id, `${hostName}`),
            lastLoginTimestamp: 0,
        } as TServerEntity;
        servers.push(localServ);
    }
    yield put(addServerBatch(servers));

    // если пользователь перешел по ссылке sso значит он хотел авторизацию windows
    if (window.location.pathname === '/sso') {
        yield tryAutologin(localServ);
    }
}

export function* appStartSaga() {
    yield takeEvery(APP_LOADED, appLoaded);
}
