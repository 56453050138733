import { RENAME_OBJECT_DIALOG_SUBMIT } from '../actionsTypes/renameObjectDialog.actionTypes';
import type {
    TRenameObjectDialogSubmitActionPayload,
    TRenameObjectDialogSubmitAction,
} from './renameObjectDialog,actions.types';

export const renameObjectDialogSubmit = (
    payload: TRenameObjectDialogSubmitActionPayload,
): TRenameObjectDialogSubmitAction => ({
    type: RENAME_OBJECT_DIALOG_SUBMIT,
    payload,
});
