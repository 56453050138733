import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import messages from './NewMatrixRenameDialog.messages';
import { TRenameDialogProps } from './NewMatrixRenameDialog.types';
import theme from './NewMatrixRenameDialog.scss';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { InternationalString } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';

export function MatrixHeaderRenameDialog(props: TRenameDialogProps) {
    const { open, initName, onClose, onSubmit } = props;

    const [newName, setNewName] = useState<InternationalString>(initName || { ru: '', en: '' });

    const currentLocale = useSelector(getCurrentLocale);
    const currentLocaleName = LocalesService.internationalStringToString(newName, currentLocale);

    const intl = useIntl();
    const [generalForm] = Form.useForm();

    const footer = (
        <div>
            <Button key="cancel" size="large" onClick={onClose}>
                {intl.formatMessage(messages.cancel)}
            </Button>

            <Button
                disabled={!currentLocaleName}
                key="ok"
                size="large"
                type="primary"
                onClick={() => onSubmit(newName)}
            >
                {intl.formatMessage(messages.submit)}
            </Button>
        </div>
    );

    return (
        <>
            <Dialog footer={footer} onCancel={onClose} open={open} width="480px">
                <Form autoComplete="off" className={theme.formContainer} form={generalForm}>
                    <MultiLangInputDialog
                        autoFocus
                        generalForm={generalForm}
                        label={intl.formatMessage(messages.label)}
                        mainInputName="mainInput"
                        placeholder={intl.formatMessage(messages.placeholder)}
                        multiLangValue={initName}
                        onChange={setNewName}
                        required
                    />
                </Form>
            </Dialog>
        </>
    );
}
