import { Modal } from 'antd';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import theme from './SymbolTextEditorDialog.scss';
import messages from './SymbolTextEditorDialog.messages';
import { shouldCallModalSubmitHandler } from '../../../../../../../services/utils/ModalHelper';

type TDialogProps = WrappedComponentProps & {
    graphical: string;
    onSubmit: (graphical: string) => void;
    onClose: () => void;
};

const SymbolTextEditorDialogComponent = (props: TDialogProps) => {
    const { intl, onClose, onSubmit } = props;
    const [graphical, setGraphical] = useState<string>(props.graphical);

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    onSubmit(graphical);
                }
            }}
        >
            <Modal
                width={800}
                open
                bodyStyle={{ height: '480px' }}
                okText={intl.formatMessage(messages.save)}
                cancelText={intl.formatMessage(messages.cancel)}
                onOk={() => onSubmit(graphical)}
                onCancel={onClose}
                className={theme.symbolGraphicalText}
                title={intl.formatMessage(messages.symbolTextEditorDialogTitle)}
            >
                <textarea className={theme.textArea} onChange={(event) => setGraphical(event.target.value)}>
                    {graphical}
                </textarea>
            </Modal>
        </div>
    );
};

const IntlComponent = injectIntl(SymbolTextEditorDialogComponent);

export { IntlComponent as SymbolTextEditorDialog };
