import React from 'react';
import { NavigatorPanelButton } from '../NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { CollapseIcon } from '../NavigatorPanel/icons/CollapseIcon.component';
import { useDispatch } from 'react-redux';
import { treeCollapse } from '@/actions/tree.actions';

export const TreeCollapse = () => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(treeCollapse());
    };

    return <NavigatorPanelButton dataTest="navigator-panel_collapse_icon" IconComponent={CollapseIcon} onClick={handleClick} />;
};

