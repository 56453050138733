import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import theme from './AttributeTypesTab.scss';
import {
    AttributeType,
    AttributeTypeGroup,
    AttributeTypeSelectPropertyValue,
    AttributeValue,
    AttributeValueValueTypeEnum,
    InternationalString,
    ObjectTypeGroup,
    PrincipalAttributeTypeSettings,
    PrincipalAttributeTypeSettingsFormatEnum,
    PrincipalAttributeTypeSettingsPrincipalTypeEnum,
    QueryAttributeTypeParams,
} from '../../../../../../serverapi/api';
import { Dialog } from '../../../../../UIKit/components/Dialog/Dialog.component';
import messages from '../../../messages/Presets.messages';
import attributeTypeMessages from '../../../messages/AttributeType.messages';
import { Form, FormInstance } from 'antd';
import principalFormatMessages from '../../../messages/PrincipalFormat.messages';
import principalTypeMessages from '../../../messages/PrincipalType.messages';
import { v4 as uuid } from 'uuid';
import { MultiLangInputDialog } from '../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { TPreset } from '../../../../../../models/preset.types';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { SelectLocalizedValues } from '../SelectLocalizedValues/SelectLocalizedValues.component';
import { checkUniqueAttributeIds } from './util/attributeTypeEditorDialog.utils';
import { LocalesService } from '../../../../../../services/LocalesService';
import { InputId } from '../../../../../InputId/InputId.component';
import { InputSynonymsIds } from '../../../../../InputSynonymsIds/InputSynonymsIds.component';
import { convertStringToArray } from '../../../../../../utils/convertStringToArray';
import { StorageDefaultValueToComponent } from './StorageDefaultValueToComponent.component';
import { Select } from '../../../../../UIKit/components/Select/Select.component';
import { Checkbox } from '../../../../../UIKit/components/Checkbox/Checkbox.component';
import { QueryValues } from '../QueryAttribute/QueryValues.component';
import { Button } from '../../../../../UIKit/components/Button/Button.component';

const attributeTypeValueTypeList: AttributeValueValueTypeEnum[] = [
    'NUMERIC',
    // 'INTEGER',
    'STRING',
    'PRINCIPAL',
    'MULTI_STRING',
    'BOOLEAN',
    'DATE',
    'TIME',
    'DATE_TIME',
    'PERIOD',
    'SELECT',
    'MULTI_SELECT',
    'NODE',
    // 'DURATION',
    'JSON',
    'URL',
    'QUERY_SELECT',
    'QUERY_MULTI_SELECT',
    'TIME_WITHOUT_TIMEZONE',
    'DATE_TIME_WITHOUT_TIMEZONE',
    // 'FILE'
];

const principalFormatList: PrincipalAttributeTypeSettingsFormatEnum[] = [
    'LOGIN',
    'LOGIN_LASTNAME_NAME_MIDDLENAME',
    'LASTNAME_NAME_MIDDLENAME',
];

const principalTypeList: PrincipalAttributeTypeSettingsPrincipalTypeEnum[] = ['USER', 'GROUP', 'USERS_AND_GROUPS'];

const defaultPrincipalAttribute = {
    principalType: principalTypeList[0],
    format: principalFormatList[0],
};

type TAttributeTypeEditorDialogProps = {
    dialogVisible: boolean;
    isNewAttribute: boolean;
    preset: TPreset;
    attributeTypeGroups: AttributeTypeGroup[];
    attributeType?: AttributeType;
    onOk: (attributes: AttributeType) => void;
    onCancel: () => void;
};

export const AttributeTypeEditorDialog: FC<TAttributeTypeEditorDialogProps> = (props) => {
    const { isNewAttribute, dialogVisible, attributeTypeGroups, preset, onOk, onCancel } = props;
    const [generalForm] = Form.useForm();
    const form = useRef<FormInstance>(null);
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);
    const getAttributeType = () => ({
        valueType: attributeTypeValueTypeList[0] as AttributeValueValueTypeEnum,
        id: uuid(),
        presetId: preset.id,
        name: intl.formatMessage(messages.newAttributeName),
        attributeTypeGroup: attributeTypeGroups && attributeTypeGroups[0],
    });

    const [attributeType, setAttributeType] = useState<AttributeType>(props.attributeType || getAttributeType());
    // временно отключено - появится во второй очереди поставки
    // const [withoutLimitation, setWithoutLimitation] = useState(false);

    const onChangeAttributeTypeGroup = (groupId: string) => {
        const attributeTypeGroup = attributeTypeGroups.reduce(
            (acc, group) => (acc.id === groupId ? acc : group),
            {} as ObjectTypeGroup,
        );
        setAttributeType({ ...attributeType, attributeTypeGroup });
    };

    const changeProperty = (propName: keyof AttributeType) => (e: ChangeEvent<HTMLInputElement>) => {
        setAttributeType({ ...attributeType, [propName]: e.target.value });
    };

    const onChangeName = (value: InternationalString) => {
        setAttributeType({ ...attributeType, multilingualName: value });
    };

    const onChangeDescription = (value: InternationalString) => {
        setAttributeType({ ...attributeType, multilingualDescription: value });
    };

    const onChangeSynonymsIds = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
        const synonymsIds: string[] = convertStringToArray(e.target.value);

        setAttributeType((prevState) => ({ ...prevState, synonymsIds }));
    }, []);

    const activeValueProperty: boolean =
        attributeType.valueType === 'SELECT' || attributeType.valueType === 'MULTI_SELECT';

    const isPrincipalAttribute: boolean = attributeType.valueType === 'PRINCIPAL';
    const isNodeAttribute: boolean = attributeType.valueType === 'NODE';
    const isQueryAttribute: boolean =
        attributeType.valueType === 'QUERY_SELECT' || attributeType.valueType === 'QUERY_MULTI_SELECT';

    const onChangeSelectPropertyValues = (value: AttributeTypeSelectPropertyValue[]) => {
        setAttributeType({ ...attributeType, selectPropertyValues: value });
    };

    const onChangePrincipalAttributeType = (prop: Partial<PrincipalAttributeTypeSettings>) => {
        setAttributeType({
            ...attributeType,
            principalAttributeTypeSettings: {
                ...(attributeType.principalAttributeTypeSettings || defaultPrincipalAttribute),
                ...prop,
            },
        });
    };

    const onChangeQueryValue = (param: QueryAttributeTypeParams) => {
        setAttributeType({
            ...attributeType,
            queryParams: {
                ...attributeType.queryParams,
                ...param,
            },
        });
    };

    const onChangeDefaultValue = (value: AttributeValue) => {
        setAttributeType({ ...attributeType, attributeDefaultValue: value });
    };

    const onSave = () => {
        generalForm
            .validateFields()
            .then(() => {
                const isFieldsFilled = attributeType.selectPropertyValues?.every(
                    (attr) => attr.value.ru && attr.value.en && attr.id,
                );
                const isEveryIdUnique = checkUniqueAttributeIds(attributeType.selectPropertyValues);

                if (!activeValueProperty) {
                    delete attributeType.selectPropertyValues;
                }
                if (!isPrincipalAttribute) {
                    delete attributeType.principalAttributeTypeSettings;
                }
                if ((isEveryIdUnique && isFieldsFilled) || !activeValueProperty || !isPrincipalAttribute) {
                    onOk(attributeType);
                }
            })
            .catch(() => undefined);
    };

    const footer = [
        <Button key="cancel" size='large' onClick={() => onCancel()} dataTest="attribute-type-window_cancel-button">
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button key="ok" size='large' visualStyle='primary' onClick={() => onSave()} dataTest="attribute-type-window_save-button">
            {intl.formatMessage(isNewAttribute ? messages.create : messages.ok)}
        </Button>,
    ];

    // const onChangeFormat = (valueType: AttributeValueValueTypeEnum) => {
    //     const newAttributeType: AttributeType = {
    //         ...attributeType,
    //         valueType: valueType as AttributeValueValueTypeEnum,
    //         attributeDefaultValue: {} as AttributeValue,
    //     };
    //     if ((valueType === 'QUERY_SELECT' || valueType === 'QUERY_MULTI_SELECT') && !newAttributeType.queryParams)
    //         newAttributeType.queryParams = { queryAttributeType: paramValueList[0] };
    //     setAttributeType(newAttributeType);
    // };

    return (
        <div>
            <Dialog
                className={theme.dialog}
                onCancel={onCancel}
                open={dialogVisible}
                title={intl.formatMessage(isNewAttribute ? messages.newAttributeName : messages.attributeTypeTitle, {
                    name: attributeType.name,
                })}
                footer={footer}
            >
                <Form className={theme.form} ref={form} form={generalForm} layout="vertical">
                    <MultiLangInputDialog
                        wrapperClassName={theme.nameItemWrapper}
                        placeholder={intl.formatMessage(messages.name)}
                        multiLangValue={attributeType.multilingualName}
                        onChange={onChangeName}
                        label={intl.formatMessage(messages.name)}
                        mainInputName="multilingualName"
                        generalForm={generalForm}
                        required
                        data-test="attribute-type-name-input"
                        mainInputClassName={theme.input}
                    />

                    <InputId
                        wrapperClassName={theme.inputIdItemWrapper}
                        mainInputClassName={theme.input}
                        disabled={!isNewAttribute}
                        value={attributeType.id}
                        required
                        mainInputName="GUID"
                        onChange={changeProperty('id')}
                    />
                    <InputSynonymsIds
                        wrapperClassName={theme.synonymsIdsItemWrapper}
                        mainInputClassName={theme.input}
                        value={attributeType.synonymsIds}
                        mainInputName="synonymsIds"
                        onChange={onChangeSynonymsIds}
                    />
                    <MultiLangInputDialog
                        wrapperClassName={theme.descriptionItemWrapper}
                        mainInputClassName={theme.textArea}
                        autoSize={{ minRows: 6, maxRows: 6 }}
                        placeholder={intl.formatMessage(messages.description)}
                        multiLangValue={attributeType.multilingualDescription}
                        onChange={onChangeDescription}
                        textarea
                        label={intl.formatMessage(messages.description)}
                        mainInputName="multilingualDescription"
                        generalForm={generalForm}
                        data-test="attribute-type-description-input"
                    />

                    <Select
                        wrapperClassName={theme.attributeTypesFormatItemWrapper}
                        originalTheme
                        data-test="attribute-type-select"
                        label={intl.formatMessage(messages.attributeTypesFormat)}
                        value={
                            attributeType.valueType
                                ? intl.formatMessage(attributeTypeMessages[attributeType.valueType])
                                : intl.formatMessage(attributeTypeMessages[attributeTypeValueTypeList[0]])
                        }
                        onChange={(valueType: string) => {
                            setAttributeType({
                                ...attributeType,
                                valueType: valueType as AttributeValueValueTypeEnum,
                                attributeDefaultValue: {} as AttributeValue,
                            });
                        }}
                    >
                        {attributeTypeValueTypeList.map((option) => (
                            <Select.Option
                                key={option}
                                value={option}
                                data-test="attribute-type-select_option"
                                label={intl.formatMessage(attributeTypeMessages[option])}
                            />
                        ))}
                    </Select>
                    {activeValueProperty && (
                        <div className={theme.propertyValuesItemWrapper}>
                            <div className={theme.label}>{intl.formatMessage(messages.selectPropertyValues)}</div>
                            <SelectLocalizedValues
                                selectPropertyValues={attributeType.selectPropertyValues}
                                changeSelectPropertyValues={onChangeSelectPropertyValues}
                                form={form}
                            />
                        </div>
                    )}
                    {isQueryAttribute && (
                        <QueryValues
                            param={attributeType.queryParams}
                            label={intl.formatMessage(messages.queryType)}
                            onChange={onChangeQueryValue}
                        />
                    )}
                    {isPrincipalAttribute && (
                        <>
                            <Select
                                wrapperClassName={theme.formItemWrapper}
                                originalTheme
                                data-test="attribute-type-principal-format-select"
                                label={intl.formatMessage(messages.selectOutputFormat)}
                                value={
                                    attributeType.principalAttributeTypeSettings?.format
                                        ? intl.formatMessage(
                                              principalFormatMessages[
                                                  attributeType.principalAttributeTypeSettings?.format
                                              ],
                                          )
                                        : intl.formatMessage(principalFormatMessages[principalFormatList[0]])
                                }
                                onChange={(format: string) => {
                                    onChangePrincipalAttributeType({
                                        format: format as PrincipalAttributeTypeSettingsFormatEnum,
                                    });
                                }}
                            >
                                {principalFormatList.map((option) => (
                                    <Select.Option
                                        key={option}
                                        value={option}
                                        data-test="attribute-type-principal-format-select_option"
                                        label={intl.formatMessage(principalFormatMessages[option])}
                                    />
                                ))}
                            </Select>
                            <Select
                                wrapperClassName={theme.formItemWrapper}
                                originalTheme
                                data-test="attribute-type-principal-type-select"
                                label={intl.formatMessage(messages.selectContents)}
                                value={
                                    attributeType.principalAttributeTypeSettings?.principalType
                                        ? intl.formatMessage(
                                              principalTypeMessages[
                                                  attributeType.principalAttributeTypeSettings?.principalType
                                              ],
                                          )
                                        : intl.formatMessage(principalTypeMessages[principalTypeList[0]])
                                }
                                onChange={(principalType: string) => {
                                    onChangePrincipalAttributeType({
                                        principalType: principalType as PrincipalAttributeTypeSettingsPrincipalTypeEnum,
                                    });
                                }}
                            >
                                {principalTypeList.map((option) => (
                                    <Select.Option
                                        key={option}
                                        value={option}
                                        data-test="attribute-type-principal-type-select_option"
                                        label={intl.formatMessage(principalTypeMessages[option])}
                                    />
                                ))}
                            </Select>
                            {/* временно отключено - появится во второй очереди поставки */}
                            {/* <br />
                        <div>
                            <InputId
                                disabled={!isNewAttribute}
                                value={attributeType.id}
                                required
                                mainInputName="GUID"
                                onChange={changeProperty('id')}
                            />
                        </div>
                        <div>
                            <InputSynonymsIds
                                value={attributeType.synonymsIds}
                                mainInputName="synonymsIds"
                                onChange={onChangeSynonymsIds}
                            />
                        </div>
                        <div className={theme.attributeTypeEditorFormDiv}>
                            <MultiLangInputDialog
                                placeholder={intl.formatMessage(messages.description)}
                                multiLangValue={attributeType.multilingualDescription}
                                onChange={onChangeDescription}
                                textarea
                                label={intl.formatMessage(messages.description)}
                                mainInputName="multilingualDescription"
                                generalForm={generalForm}
                                data-test="attribute-type-description-input"
                            />
                        </div>
                        <div className={cx(theme.attributeTypeEditorFormDiv, theme.flexContainer)}>
                            <div>
                                <span>{intl.formatMessage(messages.attributeTypesFormat)}</span>
                                <br />
                                <Select
                                    data-test="attribute-type-select"
                                    value={attributeType.valueType || attributeTypeValueTypeList[0]}
                                    onChange={(valueType: AttributeValueValueTypeEnum) => onChangeFormat(valueType)}
                                >
                                    {attributeTypeValueTypeList.map((option) => (
                                        <Select.Option
                                            data-test="attribute-type-select_option"
                                            key={option}
                                            value={option}
                                        >
                                            {intl.formatMessage(attributeTypeMessages[option])}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <br />
                        {activeValueProperty && (
                            <div>
                                <span>{intl.formatMessage(messages.selectPropertyValues)}</span>
                                <SelectLocalizedValues
                                    selectPropertyValues={attributeType.selectPropertyValues}
                                    changeSelectPropertyValues={onChangeSelectPropertyValues}
                                    form={form}
                                />
                            </div>
                        )}
                        {isQueryAttribute && (
                            <div className={theme.flexContainer}>
                                <div>
                                    <span>{intl.formatMessage(messages.queryType)}</span>
                                    <QueryValues param={attributeType.queryParams} onChange={onChangeQueryValue} />
                                </div>
                            </div>
                        )}
                        {isPrincipalAttribute && (
                            <>
                                <div className={cx(theme.attributeTypeEditorFormDiv, theme.flexContainer)}>
                                    <div>
                                        <span>{intl.formatMessage(messages.selectOutputFormat)}</span>
                                        <br />
                                        <Select
                                            data-test="attribute-type-principal-format-select"
                                            value={
                                                attributeType.principalAttributeTypeSettings?.format ||
                                                principalFormatList[0]
                                            }
                                            onChange={(format: string) => {
                                                onChangePrincipalAttributeType({
                                                    format: format as PrincipalAttributeTypeSettingsFormatEnum,
                                                });
                                            }}
                                        >
                                            {principalFormatList.map((option) => (
                                                <Select.Option
                                                    data-test="attribute-type-principal-format-select_option"
                                                    key={option}
                                                    value={option}
                                                >
                                                    {intl.formatMessage(principalFormatMessages[option])}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div>
                                        <span>{intl.formatMessage(messages.selectContents)}</span>
                                        <br />
                                        <Select
                                            data-test="attribute-type-principal-type-select"
                                            value={
                                                attributeType.principalAttributeTypeSettings?.principalType ||
                                                principalTypeList[0]
                                            }
                                            onChange={(principalType: string) => {
                                                onChangePrincipalAttributeType({
                                                    principalType:
                                                        principalType as PrincipalAttributeTypeSettingsPrincipalTypeEnum,
                                                });
                                            }}
                                        >
                                            {principalTypeList.map((option) => (
                                                <Select.Option
                                                    data-test="attribute-type-principal-type-select_option"
                                                    key={option}
                                                    value={option}
                                                >
                                                    {intl.formatMessage(principalTypeMessages[option])}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                {/* временно отключено - появится во второй очереди поставки */}
                            {/* <br />
                                <div className={cx(theme.attributeTypeEditorFormDiv, theme.flexContainer)}>
                                    <div>
                                        <Form.Item
                                            label={intl.formatMessage(messages.valuesQuantity)}
                                            initialValue={attributeType.principalAttributeTypeSettings?.count}
                                            name="count"
                                        >
                                            <InputNumber
                                                disabled={withoutLimitation}
                                                onChange={(value) => {
                                                    onChangePrincipalAttributeType({ count: Number(value) });
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <Checkbox
                                            data-test="attribute-type_without-limitation_check-box"
                                            className={theme.checkbox}
                                            checked={withoutLimitation}
                                            onChange={(e) => {
                                                setWithoutLimitation(e.target.checked);
                                                onChangePrincipalAttributeType({
                                                    count: e.target.checked ? undefined : 0,
                                                });
                                            }}
                                        >
                                            <div className={theme.checkbox_label}>
                                                {intl.formatMessage(messages.withoutLimitation)}
                                            </div>
                                        </Checkbox>
                                    </div>
                                </div> */}
                        </>
                    )}
                    <Select
                        wrapperClassName={theme.formItemWrapper}
                        data-test="attribute-type-group-select"
                        onChange={onChangeAttributeTypeGroup}
                        value={
                            attributeType.attributeTypeGroup?.multilingualName
                                ? LocalesService.internationalStringToString(
                                      attributeType.attributeTypeGroup?.multilingualName,
                                      currentLocale,
                                  )
                                : ''
                        }
                        label={intl.formatMessage(messages.attributeTypeGroups)}
                        originalTheme
                    >
                        {attributeTypeGroups?.map((group: ObjectTypeGroup) => (
                            <Select.Option
                                key={group.id}
                                value={group.id}
                                label={LocalesService.internationalStringToString(
                                    group.multilingualName,
                                    currentLocale,
                                )}
                            />
                        ))}
                    </Select>
                    <div className={theme.formItemWrapper}>
                        <Checkbox
                            checked={attributeType.readOnly}
                            onChange={() => {
                                setAttributeType((prevState) => ({
                                    ...prevState,
                                    readOnly: !prevState.readOnly,
                                }));
                            }}
                        >
                            {intl.formatMessage(messages.readOnly)}
                        </Checkbox>
                    </div>
                    <div className={theme.formItemWrapper} data-test="attribute-type_create-automatically_check-box">
                        <Checkbox
                            checked={attributeType.createWithNode}
                            onChange={() => {
                                setAttributeType((prevState) => ({
                                    ...prevState,
                                    createWithNode: !prevState.createWithNode,
                                }));
                            }}
                        >
                            {intl.formatMessage(messages.createDefault)}
                        </Checkbox>
                    </div>
                    <div className={theme.formItemWrapper} data-test="attribute-type_default-value">
                        {isNodeAttribute || isQueryAttribute ? (
                            <div className={theme.label}>
                                {intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE_IS_NOT_ACCESSIBLE)}
                            </div>
                        ) : (
                            ''
                        )}
                        <div className={theme.formItemBottom}>
                            <StorageDefaultValueToComponent
                                attributeType={attributeType}
                                onChangeDefaultValue={onChangeDefaultValue}
                            />
                        </div>
                    </div>
                </Form>
            </Dialog>
        </div>
    );
};
