// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userProperty__container__sWtSg{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;height:24px;margin-bottom:14px}.userProperty__valueContainer__dxg39,.userProperty__labelContainer__UBoJr{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:100%;color:var(--TextNeutral800)}.userProperty__labelContainer__UBoJr{text-align:right;font-style:normal;font-weight:400;font-size:14px;min-width:56px;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;line-height:22px}.userProperty__valueContainer__dxg39{padding-left:24px;font-size:16px;font-weight:600;line-height:24px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/UserProperty/userProperty.scss"],"names":[],"mappings":"AAAA,gCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,WAAA,CACA,kBAAA,CAGJ,0EAEI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA,CACA,2BAAA,CAGJ,qCACI,gBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,cAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CACA,gBAAA,CAGJ,qCACI,iBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".container {\r\n    display: flex;\r\n    flex-direction: row;\r\n    height: 24px;\r\n    margin-bottom: 14px;\r\n}\r\n\r\n.valueContainer,\r\n.labelContainer {\r\n    display: flex;\r\n    align-items: center;\r\n    height: 100%;\r\n    color: var(--TextNeutral800);\r\n}\r\n\r\n.labelContainer {\r\n    text-align: right;\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n    min-width: 56px;\r\n    justify-content: flex-end;\r\n    line-height: 22px;\r\n}\r\n\r\n.valueContainer {\r\n    padding-left: 24px;\r\n    font-size: 16px;\r\n    font-weight: 600;\r\n    line-height: 24px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "userProperty__container__sWtSg",
	"valueContainer": "userProperty__valueContainer__dxg39",
	"labelContainer": "userProperty__labelContainer__UBoJr"
};
export default ___CSS_LOADER_EXPORT___;
