export const IMPORT_PRESET_REQUEST = 'IMPORT_PRESET_REQUEST';

export const SUBMIT_PRESET = 'SUBMIT_PRESET';

export const MAKE_DEFAULT = 'MAKE_DEFAULT';

export const SUBMIT_MODEL_TYPE_GROUP = 'SUBMIT_MODEL_TYPE_GROUP';

export const ADD_MODEL_TYPE_GROUPS = 'ADD_MODEL_TYPE_GROUPS';

export const SUBMIT_PRESET_SUCCESS = 'SUBMIT_PRESET_SUCCESS';

export const LOAD_PRESETS_SUCCESS = 'LOAD_PRESET_SUCCESS';

export const OPEN_METHODOLOGY_TAB = 'OPEN_METHODOLOGY_TAB';

export const OPEN_ADD_PRESET = 'OPEN_ADD_PRESET';

export const DELETE_PRESET = 'DELETE_PRESET';

export const DELETE_PRESET_SUCCESS = 'DELETE_PRESET_SUCCESS';

export const EDIT_PRESET_REQUEST = 'EDIT_PRESET_REQUEST';

export const EDIT_PRESET_REQUEST_SUCCESS = 'EDIT_PRESET_REQUEST_SUCCESS';

export const COPY_PRESET = 'COPY_PRESET';

export const SUBMIT_PRESET_COPY = 'SUBMIT_PRESET_COPY';

export const CREATE_MODEL_TYPE_GROUP = 'CREATE_MODEL_TYPE_GROUP';

export const EDIT_FOLDER_TYPE = 'EDIT_FOLDER_TYPE';

export const CREATE_FOLDER_TYPE = 'CREATE_FOLDER_TYPE';

export const OPEN_SYMBOL_EDITOR_TAB = 'OPEN_SYMBOL_EDITOR_TAB';

export const EXPORT_PRESET = 'EXPORT_PRESET';

export const EXPORT_EXCEL_PRESET = 'EXPORT_EXCEL_PRESET';

export const OVERLAY_UPDATE_STYLE = 'OVERLAY_UPDATE_STYLE';

export const CANCEL_PRESET_EDIT = 'CANCEL_PRESET_EDIT';

export const CLEAR_PRESET_REQUEST = 'CLEAR_PRESET_REQUEST';

export const SAVE_PRESET_PREPARE_REQUEST = 'SAVE_PRESET_PREPARE_REQUEST';

export const PRESET_COPY_LOADING_FINISH = 'PRESET_COPY_LOADING_FINISH';

export const OPEN_KANBAN_CARD_EDITOR_TAB = 'OPEN_KANBAN_CARD_EDITOR_TAB';

export const GET_PRESET_LIST = 'GET_PRESET_LIST';
