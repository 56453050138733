import {
    PRINCIPAL_CHOOSE_DIALOG_OPEN,
    PRINCIPAL_CHOOSE_DIALOG_SUBMIT_RESULT,
    PRINCIPAL_REQUEST_SUCCESS,
    PRINCIPAL_REQUEST_FAILURE,
    PRINCIPAL_REQUEST,
} from '../actionsTypes/principal.actionTypes';
import { PrincipalDescriptor } from '../serverapi/api';
import { TChoosePrincipalDialogSubmitResult, TPrincipalChooseDialogOpenAction } from './principal.actions.types';

export const choosePrincipalDialogSubmitResult = (data: Array<PrincipalDescriptor>): TChoosePrincipalDialogSubmitResult => ({
    type: PRINCIPAL_CHOOSE_DIALOG_SUBMIT_RESULT,
    payload: { data },
});

export const principalAddDialogOpenAction =
    (serverId: string): TPrincipalChooseDialogOpenAction =>
        ({
            type: PRINCIPAL_CHOOSE_DIALOG_OPEN,
            payload: {
                serverId
            }
        });

export const principalRequest =
    (serverId: string, forceLoad?: boolean) =>
        ({
            type: PRINCIPAL_REQUEST,
            payload: {
                serverId,
                forceLoad
            }
        });

export const principalRequestSuccess =
    (data: Array<PrincipalDescriptor>) =>
        ({
            type: PRINCIPAL_REQUEST_SUCCESS,
            payload: {
                data
            }
        });

export const principalRequestFailure = (error = 'unknown error') => ({
    type: PRINCIPAL_REQUEST_FAILURE,
    payload: { error },
});
