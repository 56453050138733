import React from 'react';
import { InitialsCircle } from '../UIKit/components/InitialsCircle/InitialsCircle.component';
import theme from './userAccount.scss';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { UserAccountSelectors } from '../../selectors/userAccount.selectors';
import { UserProperty } from '../UIKit/components/UserProperty/UserProperty.component';
import messages from '../UserAccountTab/userAccount.messages';
import { useIntl } from 'react-intl';
import { TUserAccountData } from './userAccountData.types';

export const UserAccountData = () => {
    const intl = useIntl();
    const userFullName: string = useSelector(UserAccountSelectors.getFullName);
    const data: TUserAccountData = useSelector(UserAccountSelectors.getAccountData);

    return (
        <div className={theme.accountContainer}>
            <div className={theme.dataHeaderContainer}>
                <InitialsCircle circleSize={56} fontSize={20} />
                <div className={theme.nameContainer}>{userFullName}</div>
            </div>
            <Divider></Divider>
            {Object.keys(data).map((key) => {
                return <UserProperty label={intl.formatMessage(messages[key])} value={data[key]} />;
            })}
        </div>
    );
};
