import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import style from './ImportStep.scss';
import { ArisImportDescriptor, NodeId } from '../../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { setArisResponseError, startArisImport } from '../../../../../actions/importAris.actions';
import { ImportArisSelectors } from '../../../../../selectors/importAris.selectors';
import { useIntl } from 'react-intl';
import messages from '../../messages/ImportAris.messages';
import { PreloaderAris } from '../Preloader/PreloaderAris';
import { InfoCircleOutlined } from '@ant-design/icons';

type TImportStepProps = {
    nodeId: NodeId;
};

const ImportStep: FC<TImportStepProps> = ({ nodeId }) => {
    const { serverId, repositoryId, id } = nodeId;

    const dispatch = useDispatch();
    const intl = useIntl();

    const descriptor: ArisImportDescriptor = useSelector(
        ImportArisSelectors.getArisDescriptor(serverId, repositoryId, id),
    );
    const { error } = descriptor;

    const [loading, setLoading] = useState<boolean>(false);

    const onStartImport = () => {
        setLoading(true);
        dispatch(setArisResponseError({ nodeId, error: undefined }));
        dispatch(startArisImport({ nodeId, descriptor }));
    };

    useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    return (
        <div>
            {loading ? (
                <PreloaderAris />
            ) : (
                <div className={style.description}>
                    <div>{intl.formatMessage(messages.importIsReady)}</div>
                    <InfoCircleOutlined className={style.icon} />
                </div>
            )}
            {error && <div className={style.error}>{error}</div>}
            <Button disabled={loading} type="primary" onClick={onStartImport} data-test="import-ARIS_start-import-button">
                {intl.formatMessage(messages.importRun)}
            </Button>
        </div>
    );
};

export default ImportStep;
