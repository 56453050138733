import React from 'react';
import { Select, Form, Input, FormInstance, Tooltip } from 'antd';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { SelectValue } from 'antd/es/select';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import messages from './messages/ExtendableSelect.messages';
import theme from './ExtendableSelect.scss';
import { DEFAULT_DIALOG_WIDTH } from '../../../../config/config';
import icArrowDown from '../../../../resources/icons/ic-ribbon-arrow-down.svg';
import { Icon } from '@/modules/UIKit';
import { TOOLTIP_ENTER_DELAY, TOOLTIP_LEAVE_DELAY } from '@/utils/configuration';

interface IExtendableSelectItemValue {
    value: string;
    label: string;
    style?: { [key: string]: string };
    disabled?: boolean;
}

interface IExtendableSelectProps {
    onChange?: (value: SelectValue) => void;
    baseOptions: IExtendableSelectItemValue[];
    className?: string;
    disabled?: boolean;
    value: IExtendableSelectItemValue;
    validationRules?: {}[];
    newValueLabel: string;
    renderNewOption?: boolean;
    tooltipTitle?: string;
}

type TExtendableSelectState = {
    extendedOptions: IExtendableSelectItemValue[];
    isFormVisible: boolean;
    isDropdownVisible: boolean;
    isMouseEnter: boolean;
};

type TExtendableSelectAllProps = WrappedComponentProps & IExtendableSelectProps;

type TFormValues = {
    customValue: string;
};

class ExtendableSelect extends React.Component<TExtendableSelectAllProps, TExtendableSelectState> {
    state = {
        extendedOptions: [],
        isFormVisible: false,
        isDropdownVisible: false,
        isMouseEnter: false,
    };

    formRef = React.createRef<FormInstance>();

    onDropdownVisibleChange = (open: boolean) => {
        this.setState({ isDropdownVisible: open });
    };

    onMouseEnterChange = (mouseEnter: boolean) => {
        this.setState({ isMouseEnter: mouseEnter });
    };

    handleSubmit = () => {
        const form = this.formRef.current;
        if (form) {
            form.validateFields().then((formValues: TFormValues) => {
                const newValue = { value: formValues.customValue, label: formValues.customValue, disabled: false };
                this.setState((state) => ({
                    extendedOptions: [...state.extendedOptions, newValue],
                    isFormVisible: false,
                }));
                form.resetFields();
                if (this.props.onChange) {
                    this.props.onChange(newValue.value);
                }
            });
        }
    };

    closeForm = () => {
        const form = this.formRef.current;
        if (form) {
            form.resetFields();
        }
        this.setState({ isFormVisible: false });
    };

    handleSelect = (value: string) => {
        this.setState({ isMouseEnter: false });
        if (value === 'custom') {
            this.setState({ isFormVisible: true });
        } else if (value === 'reset') {
            this.setState({ extendedOptions: [] });
        } else if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    renderNewOption = () => {
        if (this.props.renderNewOption || typeof this.props.renderNewOption === 'undefined') {
            return (
                <Select.Option title={' '} value="custom">
                    {this.props.intl.formatMessage(messages.selectAddNewValue)}
                </Select.Option>
            );
        }

        return null;
    };

    render() {
        const { tooltipTitle, baseOptions, className, validationRules, disabled, value, newValueLabel } = this.props;
        const baseOptionsRender = baseOptions.map((d: IExtendableSelectItemValue) => {
            const label = d.style ? <span style={d.style}>{d.label}</span> : d.label;

            return (
                <Select.Option key={d.value} title="" value={d.value}>
                    {label}
                </Select.Option>
            );
        });
        const { extendedOptions } = this.state;
        const extendedOptionsRender = extendedOptions.map((d: IExtendableSelectItemValue) => (
            <Select.Option title={' '} key={d.value} value={d.value}>
                {d.label}
            </Select.Option>
        ));

        const calculatedValidationRules = validationRules || [
            {
                required: true,
                message: this.props.intl.formatMessage(messages.formDefaultValidationMessage),
            },
        ];

        let resetRender;
        if (extendedOptions.length > 0) {
            resetRender = (
                <Select.Option title={' '} value="reset">
                    {this.props.intl.formatMessage(messages.selectResetValues)}
                </Select.Option>
            );
        }
        const selectValue: string = value.value.toString() || baseOptions[0].value;
        const inputForm = (
            <Form ref={this.formRef}>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label={newValueLabel}
                    name="customValue"
                    rules={calculatedValidationRules}
                >
                    <Input />
                </Form.Item>
            </Form>
        );

        const showTooltip =
            !!tooltipTitle && this.state.isMouseEnter && !this.state.isDropdownVisible && !this.state.isFormVisible;

        return (
            <div
                onMouseEnter={(e) => this.onMouseEnterChange(true)}
                onMouseLeave={(e) => this.onMouseEnterChange(false)}
            >
                <Tooltip
                    placement="top"
                    title={tooltipTitle}
                    mouseEnterDelay={TOOLTIP_ENTER_DELAY}
                    mouseLeaveDelay={TOOLTIP_LEAVE_DELAY}
                    open={showTooltip}
                >
                    <div className={theme.relativeTooltipContainer}>
                        <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            className={className}
                            onSelect={this.handleSelect}
                            disabled={disabled}
                            value={selectValue}
                            onDropdownVisibleChange={this.onDropdownVisibleChange}
                            suffixIcon={<Icon spriteSymbol={icArrowDown} className={theme.arrow} />}
                        >
                            {baseOptionsRender}
                            {extendedOptionsRender}
                            {resetRender}
                            {this.renderNewOption()}
                        </Select>
                    </div>
                </Tooltip>
                <Dialog
                    onOk={this.handleSubmit}
                    onCancel={this.closeForm}
                    open={this.state.isFormVisible}
                    width={DEFAULT_DIALOG_WIDTH}
                    okText={this.props.intl.formatMessage(messages.formConfirmButton)}
                    cancelText={this.props.intl.formatMessage(messages.formCancelButton)}
                >
                    {inputForm}
                </Dialog>
            </div>
        );
    }
}
const ExtendableSelectWithIntl = injectIntl(ExtendableSelect);

export { ExtendableSelectWithIntl as ExtendableSelect };
