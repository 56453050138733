import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { MatrixNode, MatrixType } from '../../serverapi/api';
import { MatrixBllService } from '../bll/MatrixBllService'
import { ApiBundle } from '../api/api-bundle';

export class MatrixDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getMatrix(repositoryId: string, matrixId: string) {
        const api: ApiBundle = this.getApi();
        const matrix: MatrixNode = await api.matrix.get({ repositoryId, matrixId });
        const styledMatrix: MatrixNode = MatrixBllService.addStyleIdsToCells(matrix);

        return styledMatrix;
    }

    public static async createMatrixType(requestBody: { body: MatrixType }) {
        const api = this.getApi();
        await api.matrixType.create({ body: requestBody.body });
    }

    public static async saveMatrixType(requestBody: { body: MatrixType }) {
        const api = this.getApi();
        await api.matrixType.save({ body: requestBody.body });
    }

    public static async deleteMatrixType(presetId: string, id: string) {
        const api = this.getApi();
        await api.matrixType._delete({ presetId, id });
    }
}
