import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import * as React from 'react';
import messages from '../../messages/InstancePermissionsDialog.messages';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Button, Input, Form, Tabs } from 'antd';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import theme from './InstancePermissionsDialog.scss';
import folderIcon from '../../../../resources/icons/ic-tree-folder.svg';
import modelIcon from '../../../../resources/icons/ic-tree-model-active.svg';
import objectIcon from '../../../../resources/icons/ic-tree-object-org-chart.svg';
import icDeleteBinActive from '../../../../resources/icons/ic-deleteBinActive.svg';
import icDeleteBin from '../../../../resources/icons/ic-deleteBin.svg';
import iconAdd from '../../../../resources/icons/ic-add.svg';
import { InstancePermissionsTable } from '../PermissionTables/InstancePermissionsTable.component';
import { ChangeEvent } from 'react';
import { TreeItemType } from '../../../Tree/models/tree';
import { SearchOutlined } from '@ant-design/icons';
import { TInstancePermissionsDialogProps } from './InstancePermissionsDialog.types';
import { AllPermissionsResultTable } from '../PermissionTables/AllPermissionsTable.component';
import { AdditionalPermissionsTable } from '../PermissionTables/AdditionalPermissionsTable.component';
import { InstancePermissionsDialogFooterComponent } from './InstancePermissionsDialogFooterComponent';

const { TabPane } = Tabs;

type TInstancePermissionsDialogAllProps = WrappedComponentProps & TInstancePermissionsDialogProps;

type TInstancePermissionsDialogState = {
    selectedIds: number[];
    filter: string;
};

class InstancePermissionsDialog extends React.Component<
    TInstancePermissionsDialogAllProps,
    TInstancePermissionsDialogState
> {
    constructor(props: TInstancePermissionsDialogAllProps) {
        super(props);
        this.state = { selectedIds: [], filter: '' };
    }

    onFilterChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        this.setState({ filter: value.trim() });
    }

    render() {
        const {
            nodeId,
            loading,
            changed,
            title,
            objectType,
            principalPermissions,
            onSubmit,
            onClose,
            onChange,
            onAddPrincipal,
            onDelete,
            open,
            intl,
            principals,
            error,
        } = this.props;
        const { filter } = this.state;

        const entityIcon = () => {
            switch (objectType) {
                case TreeItemType.Model:
                    return <Icon className={theme.subTitleIcon} spriteSymbol={modelIcon} />;
                case TreeItemType.ObjectDefinition:
                    return <Icon className={theme.subTitleIcon} spriteSymbol={objectIcon} />;
                default:
                    return <Icon className={theme.subTitleIcon} spriteSymbol={folderIcon} />;
            }
        };

        const preTitleMessage = () => {
            switch (objectType) {
                case TreeItemType.Repository:
                    return intl.formatMessage(messages.repositoryTitle);
                case TreeItemType.Model:
                    return intl.formatMessage(messages.modelTitle);
                case TreeItemType.ObjectDefinition:
                    return intl.formatMessage(messages.objectTitle);
                case TreeItemType.Script:
                    return intl.formatMessage(messages.scriptTitle);
                case TreeItemType.ScriptFolder:
                    return intl.formatMessage(messages.scriptFolderTitle);
                case TreeItemType.Wiki:
                    return intl.formatMessage(messages.wikiTitle);
                case TreeItemType.Matrix:
                    return intl.formatMessage(messages.matrixTitle);
                case TreeItemType.SimulationModeling:
                    return intl.formatMessage(messages.simulationModelTitle);
                case TreeItemType.File:
                    return intl.formatMessage(messages.fileTitle);
                case TreeItemType.FileFolder:
                    return intl.formatMessage(messages.fileFolderTitle);
                case TreeItemType.Spreadsheet:
                    return intl.formatMessage(messages.spreadsheetTitle);
                case TreeItemType.Dashboard:
                    return intl.formatMessage(messages.dashboardTitle);
                default:
                    return intl.formatMessage(messages.folderTitle);
            }
        };

        const dialogTitle = () => (
            <>
                {preTitleMessage()} {entityIcon()} {title}
            </>
        );

        const { selectedIds } = this.state;
        const instancePermError = error === '' ? intl.formatMessage(messages.fetchError) : error;

        return (
            <Dialog
                className={theme.dialog}
                title={dialogTitle()}
                open={open}
                width="960px"
                footer={
                [
                    <InstancePermissionsDialogFooterComponent
                        onClose={onClose}
                        changed={changed}
                        onSubmit={onSubmit}
                    />
                ]}
                onCancel={this.props.onClose}
                onOk={() => {
                    if (changed) onSubmit();
                }}
            >
                <FormGroup className={theme.formGroup}>
                    <Tabs>
                        <TabPane tab={intl.formatMessage(messages.currentPermTab)} key="currentPermTab">
                        <Form.Item>
                            <div className={theme.searchAndActionsWrapper}>
                                <Input
                                    suffix={<SearchOutlined />}
                                    onChange={(e) => this.onFilterChange(e)}
                                    className={theme.searchInput}
                                />
                                <div className={theme.permissionActions}>
                                        <Button
                                            className={theme.button}
                                            size="large"
                                            disabled={loading}
                                            type="primary"
                                            onClick={onAddPrincipal}
                                            data-test="user-add-permission-button"
                                        >
                                            <div className={theme.buttonInner}>
                                                <Icon spriteSymbol={iconAdd} className={theme.buttonIcon} />
                                                <FormattedMessage {...messages.addButton} />
                                            </div>
                                        </Button>
                                        <div className={theme.permissionActionsInner}>
                                            <Button
                                                disabled={loading || selectedIds.length === 0}
                                                size="large"
                                                className={theme.button}
                                                danger
                                                onClick={() => {
                                                    onDelete([...this.state.selectedIds] as number[]);
                                                    this.setState({ selectedIds: [] });
                                                }}
                                                data-test="delete-user-rights-button"
                                            >
                                                <div className={theme.buttonInner}>
                                                    <Icon spriteSymbol={loading || selectedIds.length ? icDeleteBinActive : icDeleteBin} className={theme.buttonIcon} />
                                                    <FormattedMessage {...messages.deleteButton} />
                                                </div>
                                            </Button>
                                    </div>
                                </div>
                            </div>
                            </Form.Item>
                            <Form.Item data-test="permission-dialog_permission-table">
                                <InstancePermissionsTable
                                    selectedIds={selectedIds}
                                    loading={loading}
                                    data={principalPermissions}
                                    onChange={onChange}
                                    principals={principals}
                                    onSelectionChanged={(_selectedIds: number[]) =>
                                        this.setState({ selectedIds: _selectedIds })
                                    }
                                    filter={filter}
                                    error={instancePermError}
                                />
                            </Form.Item>
                        </TabPane>
                        <TabPane tab={intl.formatMessage(messages.resultPermTab)} key="resultPermTab">
                            <AllPermissionsResultTable nodeId={nodeId} principals={principals} />
                        </TabPane>
                        {objectType === TreeItemType.Repository && (
                            <TabPane tab={intl.formatMessage(messages.additionalPermTab)} key="additionalPermTab">
                                <AdditionalPermissionsTable
                                    data={principalPermissions}
                                    onChange={onChange}
                                    principals={principals}
                                />
                            </TabPane>
                        )}
                    </Tabs>
                </FormGroup>
            </Dialog>
        );
    }
}

const withIntl = injectIntl(InstancePermissionsDialog);

export { withIntl as InstancePermissionsDialog };

// todo Компоненты и их структура нуждаются в рефакторинге
