import { NodeId } from '../serverapi/api';
import { TEnumPropertyValue } from './properties/TEnumPropertyDescriptor.types';
import { TPropertyValue } from '../models/properties/property-descriptor.types';

export enum EntityEnum {
    EDGE,
    OBJECT,
    MODEL,
    WIKI,
    MATRIX,
    FOLDER,
    DB,
    SCRIPT,
    SCRIPT_FOLDER,
    SPREADSHEET,
    KANBAN,
    DASHBOARD,
    SIMULATION,
    FILE,
    FILE_FOLDER,
}

export enum PropertiesTabKey {
    ATTRIBUTES = 'ATTRIBUTES',
    DEFINITION = 'DEFINITION',
    PROPERTIES = 'PROPERTIES',
    OBJECT_INSTANCES = 'OBJECT_INSTANCES',
    EDGE_INSTANCES = 'EDGE_INSTANCES',
    EDGE_INSTANCE_ATTRIBUTES = 'EDGE_INSTANCE_ATTRIBUTES',
    DECOMPOSITION_PARENTS = 'DECOMPOSITION_PARENTS',
    EDGES = 'EDGES',
}

export type TNavigatorPropertiesData = { [id: string]: TPropertyValue | TEnumPropertyValue };

export interface IDateInfo {
    createdAt?: string;
    updatedAt?: string;
}

export enum SymbolViewEnum {
    ENTRY_COUNT = 'ENTRY_COUNT',
    SYMBOLS = 'SYMBOLS',
}

export type TNavigatorPropertiesSelectorState = {
    currentGraphId?: NodeId;
    cellId?: string;
    entityType: EntityEnum | undefined;
    nodeId: NodeId;
    symbolViewMode?: SymbolViewEnum;
};

export type TSelectedElement = {
    cellId?: string;
};
