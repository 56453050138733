import { BPMMxDragSource } from './BPMMxDragSource';
import { BPMMxGraph } from '../bpmgraph';
import { MxCell, MxPoint } from '../mxgraph';
import { DiagramElement, Symbol } from '../../serverapi/api';

export class CompositeDragSource extends BPMMxDragSource {
    private elements?: DiagramElement[];

    constructor(
        symbol: Symbol,
        dropHandler: (graph: BPMMxGraph, evt: PointerEvent, target: MxCell | null, point: MxPoint) => void,
        graph: BPMMxGraph,
        serverUrl?: string,
        elements?: DiagramElement[],
    ) {
        super(symbol, dropHandler, graph, serverUrl);
        this.elements = elements;
    }

    updateElementSize(elt: HTMLElement | null, width, height) {
        if (elt?.style) {
            elt.style.border = 'dashed black 1px';
            elt.style.width = `${Math.round(width * this.graph.view.scale)}px`;
            elt.style.height = `${Math.round(height * this.graph.view.scale)}px`;
        }
    }

    createPreviewElement(): HTMLElement | null {
        if (this.elements && this.elements.length > 1) {
            let loX = this.elements[0].x || 0;
            let hiX = this.elements[0].x || 0;
            let loY = this.elements[0].y || 0;
            let hiY = this.elements[0].y || 0;
            let maxElementWidth = this.elements[0].width || 0;
            let maxElementHeight = this.elements[0].height || 0;

            this.elements.forEach((element: DiagramElement) => {
                const x = element.x || 0;
                const y = element.y || 0;
                const width = element.width || 0;
                const height = element.height || 0;

                if (x < loX) {
                    loX = x;
                }
                if (x > hiX) {
                    hiX = x;
                }
                if (y < loY) {
                    loY = y;
                }
                if (y > hiY) {
                    hiY = y;
                }
                if (width > maxElementWidth) {
                    maxElementWidth = width;
                }
                if (height > maxElementHeight) {
                    maxElementHeight = height;
                }
            });

            const width = hiX - loX + maxElementWidth;
            const height = hiY - loY + maxElementHeight;
            const elt = document.createElement('div');
            this.updateElementSize(elt, width, height);
            elt.style.border = 'dashed black 1px';

            return elt;
        }
        const elt = super.createPreviewElement();

        if (this.elements?.length === 1) {
            const { width, height } = this.elements[0];

            this.updateElementSize(elt, width, height);
        }

        return elt;
    }
}
