import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './SelectSymbolToReplaceOnPasteDialog.messages';
import theme from './SelectSymbolToReplaceOnPasteDialog.scss';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import { DiagramElement, ModelType, Symbol } from '@/serverapi/api';
import { SymbolToImageConverterGraph } from '../AdminTools/Methodology/components/Presets/SymbolToImageConverterGraph.component';
import { Form, FormInstance, Select } from 'antd';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { DEFAULT_DIALOG_WIDTH } from '@/config/config';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { MethodologiesGraph } from '@/mxgraph/MethodologiesGraph';
import { editorSelectSymbolToReplaceOnPasteSubmit } from '@/actions/editor.actions';

type TDialogProps = {
    open: boolean;
    symbols: Symbol[];
    copiedElements: DiagramElement[];
    copiedElementsIdsToReplace: string[];
    isPasteAsDefinition: boolean | undefined;
};

export const SelectSymbolToReplaceOnPasteDialog = (props: TDialogProps) => {
    const { open, symbols, copiedElements, copiedElementsIdsToReplace, isPasteAsDefinition } = props;
    const formRef = React.createRef<FormInstance>();

    const intl = useIntl();
    const dispatch = useDispatch();
    const [selectedSymbolId, setSelectedSymbolId] = useState<string>(symbols[0].id);
    const [graph, setGraph] = useState<MethodologiesGraph | undefined>(undefined);

    const converterInitialized = (methodologiesGraph: MethodologiesGraph) => {
        if (!graph) {
            setGraph(methodologiesGraph);
        }
    };
    const onClose = () => {
        formRef.current?.resetFields();
        dispatch(closeDialog(DialogType.SELECT_SYMBOL_TO_REPLACE_ON_PASTE_DIALOG));
    };
    const onSubmit = () => {
        const selectedSymbol: Symbol = symbols.find((symbol) => symbol.id === selectedSymbolId)!;
        dispatch(
            editorSelectSymbolToReplaceOnPasteSubmit({
                isPasteAsDefinition,
                selectedSymbol,
                copiedElements,
                copiedElementsIdsToReplace,
            }),
        );
    };

    return (
        <Dialog
            onOk={onSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.selectSymbolToReplaceOnPasteFormName)}
            open={open}
            width={DEFAULT_DIALOG_WIDTH}
            okText={intl.formatMessage(messages.selectSymbolToReplaceOnPasteFormConfirmButton)}
            cancelText={intl.formatMessage(messages.selectSymbolToReplaceOnPasteFormDeclineButton)}
        >
            <SymbolToImageConverterGraph modelType={{ id: uuid() } as ModelType} initialized={converterInitialized} />

            <Form ref={formRef}>
                <div>
                    <Select
                        defaultValue={symbols[0].id}
                        style={{ width: '100%' }}
                        onChange={setSelectedSymbolId}
                        className={theme.symbolSelect}
                        popupClassName={theme.dropdownMenu}
                    >
                        {symbols.map((symbol) => (
                            <Select.Option value={symbol.id} key={symbol.id} className={theme.symbolBox}>
                                {SymbolToImageConverterGraph.convertSymbol(symbol, intl, graph)}
                                {symbol.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </Form>
        </Dialog>
    );
};
