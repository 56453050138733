import { EditorMode } from '@/models/editorMode';
import { BPMMxVertexHandler } from '../../../handler/BPMMxVertexHandler.class';
import GridModelGraph from '../../GridModelGraph';

export class PSDModelVertexHandler extends BPMMxVertexHandler {
    graph: GridModelGraph;
    createCustomHandles() {
        const handles = super.createCustomHandles();

        return this.graph.mode === EditorMode.Read ? [] : handles?.filter(this.headerHandlesFilter) || [];
    }
    private headerHandlesFilter(handeler) {
        const cell = handeler.state.cell;
        return cell.getParent().children.indexOf(cell) === 0;
    }
}
