import { Modal } from 'antd';

type TShowConfirmationDialog = {
    action: string;
    title: string;
    users: string[];
    okText: string;
    cancelText: string;
    onOk: () => void;
};

export const showConfirmationDialog = (props: TShowConfirmationDialog) => {  
    const { users, action, okText, cancelText, title, onOk } = props;
    const content = `${action} ${users.join(', ')} ${users.length >= 5 ? `(${users.length})` : ''} ?`;
    Modal.confirm({
        className: 'confirmationDialog',
        title,
        content,
        okText,
        cancelText,
        okButtonProps: { danger: true, ghost: false, size: 'large' },
        cancelButtonProps: { size: 'large' },
        closable: true,
        icon: '',
        onOk() {
            onOk();
            Modal.destroyAll();
        },
        okCancel: true,
    });
};
