import React from 'react';
import theme from './MethodologySetting.scss';
import { CaretOutlined } from './CaretOutlined/CaretUpOutlined.component';
import { SortDirectionType } from 'react-virtualized';

type TSortIndicatorProps = {
    sortDirection: SortDirectionType;
};

export const SortIndicator = (props: TSortIndicatorProps) => {
    const { sortDirection } = props;
    const isASC: boolean = sortDirection === 'ASC';

    return (
        <span className={theme.sortIndicator}>
            <CaretOutlined isUpOutlined className={isASC ? theme.sortIndicatorBlueIcon : theme.sortIndicatorIcon} />
            <CaretOutlined className={isASC ? theme.sortIndicatorIcon : theme.sortIndicatorBlueIcon} />
        </span>
    );
};
