// @ts-nocheck
import { Button, Collapse, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImportSelectors } from '../../../../../selectors/import.selectors';
import { ImportVisioCollapseTableHeader } from '../ImportVisioCollapseTableHeader/ImportVisioCollapseTableHeader.component';
import { ImportVisioCollapseTableRow } from '../ImportVisioCollapseTableRow/ImportVisioCollapseTableRow';
import { VisioConflictResolutionStrategyEnum } from '../../../../../serverapi/api';
import { importVisioFile, setImportVisioData } from '../../../../../actions/import.actions';
import messages from '../../messages/ImportVisioCollapseTable.messages';
import { FormattedMessage } from 'react-intl';
import style from './ImportVisioCollapseTable.scss';

const { Panel } = Collapse;

export enum VisioListElementTypeEnum {
    OBJECT,
    EDGE,
}

type TStartegyItem = {
    name: React.ReactNode;
    value: VisioConflictResolutionStrategyEnum;
};

const strategy: TStartegyItem[] = [
    { name: <FormattedMessage {...messages.askUser} />, value: 'ASK_USER' },
    { name: <FormattedMessage {...messages.findMostCommon} />, value: 'FIND_MOST_COMMON' },
    { name: <FormattedMessage {...messages.findOldest} />, value: 'FIND_OLDEST' },
    { name: <FormattedMessage {...messages.createNew} />, value: 'CREATE_NEW' },
];

export const ImportVisioCollapseTable = () => {
    const dispatch = useDispatch();
    const files = useSelector(ImportSelectors.files);

    const handleSelect = (conflictResolutionStrategy: VisioConflictResolutionStrategyEnum) => {
        dispatch(setImportVisioData({ conflictResolutionStrategy }));
    };

    const handleImportClick = () => {
        dispatch(importVisioFile());
    };

    return (
        <>
            {files && !!files.length && (
                <div className={style.tableContainer} data-test="import-visio_table_container">
                    <Collapse defaultActiveKey={['1']}>
                        {files.reduce((acc, file) => {
                            return [
                                ...acc,
                                ...file.map((page) => {
                                    const {
                                        fileName,
                                        visioPageName,
                                        silaModelTypeId,
                                        silaModelName,
                                        edgeMappers,
                                        visioPageId,
                                        objectMappers,
                                        silaModelTypeName,
                                    } = page;

                                    return (
                                        <Panel
                                            key={`${fileName}_${visioPageName}`}
                                            showArrow={false}
                                            header={
                                                <ImportVisioCollapseTableHeader
                                                    fileName={fileName}
                                                    visioPageName={visioPageName}
                                                    silaModelTypeId={silaModelTypeId}
                                                    silaModelTypeName={silaModelTypeName}
                                                    silaModelName={silaModelName}
                                                    visioPageId={visioPageId}
                                                />
                                            }
                                        >
                                            <ImportVisioCollapseTableRow
                                                fileName={fileName!}
                                                visioPageId={visioPageId!}
                                                list={objectMappers}
                                                silaModelTypeId={silaModelTypeId!}
                                                type={VisioListElementTypeEnum.OBJECT}
                                            />
                                            <ImportVisioCollapseTableRow
                                                data-test={`import-visio_table_edge-row_${fileName}`}
                                                fileName={fileName!}
                                                visioPageId={visioPageId!}
                                                list={edgeMappers}
                                                silaModelTypeId={silaModelTypeId!}
                                                type={VisioListElementTypeEnum.EDGE}
                                            />
                                        </Panel>
                                    );
                                }),
                            ];
                        }, [])}
                    </Collapse>

                    <div className={style.footer}>
                        <span>
                            <FormattedMessage {...messages.strategyLabel} />
                        </span>
                        <Select defaultValue={strategy[0].value} className={style.typeSelect} onChange={handleSelect} data-test={`import-visio_table_edge-row_${strategy[0].value}`}>
                            {strategy.map((option) => (
                                <Select.Option value={option.value} key={option.value} data-test={`import-visio_table_edge-row_${option.value}`}>
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <Button type="primary" onClick={handleImportClick} data-test="import-visio_start-import">
                            <FormattedMessage {...messages.buttonImportText} />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
