import { Button, Checkbox, DatePicker, Form, Input, Row, Select, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FileUploadStatus, ParameterType } from '../../../../reducers/scriptExecuteDialog.reducer.types';
import { ScriptParameter } from '../../../../serverapi/api';
import messages from './ScriptExecuteDialog.messages';
import theme from './ScriptExecuteDialog.scss';
import { Option } from 'rc-select';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { TScriptExecuteFormAllProps } from './ScriptExecuteDialog.types';
import { QuerySelect } from '../../../UIKit/components/QuerySelect/QuerySelect.component';
import { useDispatch, useSelector } from 'react-redux';
import { loadScriptQueryParamValues } from '../../../../actions/scriptQueryParams.actions';
import { ScriptQueryParamsSelector } from '../../../../selectors/scriptQueryParams.selectors';
import { TScriptQueryParamsState } from '../../../../reducers/scriptQueryParams.reducer.types';
import { TOOLTIP_ENTER_DELAY, TOOLTIP_LEAVE_DELAY } from '@/utils/configuration';

export const ScriptExecuteForm = (props: TScriptExecuteFormAllProps) => {
    const {
        parsedParams,
        script,
        onUploadFile,
        onAddNode,
        fileParams,
        nodeParams,
        onDeleteNode,
        form,
        existingParams,
    } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const queryValues: TScriptQueryParamsState = useSelector(
        ScriptQueryParamsSelector.getScriptQueryParams(parsedParams),
    );

    useEffect(() => {
        const modelIdString: string | undefined = existingParams.find((param) => param.name === 'modelId')?.value;

        dispatch(loadScriptQueryParamValues(parsedParams, modelIdString));
    }, []);

    const handleUploadFile = (paramName: string) => () => {
        onUploadFile(script.nodeId.serverId, paramName);
    };

    const handleAddNode = (paramName: string) => {
        onAddNode(paramName);
    };

    const handleDeleteNode = (paramName: string) => {
        onDeleteNode(paramName);
        form.validateFields([paramName]);
    };

    const getStringField = (parameter: ScriptParameter) => {
        const defaultValue = parameter.value || parameter.defaultValue || '';

        return (
            <Form.Item
                initialValue={defaultValue}
                rules={[
                    {
                        required: parameter.required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
                name={parameter.name || ''}
                key={parameter.name}
            >
                <Input type="text" />
            </Form.Item>
        );
    };

    const getNumberField = (parameter: ScriptParameter) => {
        return (
            <Form.Item
                name={parameter.name || ''}
                rules={[
                    {
                        required: parameter.required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
                initialValue={parameter.value || parameter.defaultValue}
                className={theme.field}
                key={parameter.name}
                getValueFromEvent={(e) => e.target.value.replace(/[^0-9]/, '')}
            >
                <Input className={theme.number} />
            </Form.Item>
        );
    };

    const getBooleanField = (parameter: ScriptParameter) => {
        const defaultValue: boolean =
            parameter.value !== undefined && parameter.value !== ''
                ? parameter.value.toLowerCase() === 'true'
                : parameter.defaultValue?.toLowerCase() === 'true';

        return (
            <Form.Item
                required={parameter.required}
                initialValue={defaultValue}
                valuePropName="checked"
                name={parameter.name || ''}
                key={parameter.name}
            >
                <Checkbox className={theme.checkbox} />
            </Form.Item>
        );
    };

    const getFileField = (parameter: ScriptParameter) => {
        const paramName = parameter.name!;
        const { required } = parameter;
        const fileParam = fileParams?.[paramName];
        const loading: boolean = fileParam?.uploadStatus === FileUploadStatus.LOADING;
        const loaded: boolean = fileParam?.uploadStatus === FileUploadStatus.DONE;
        const needsLoading: boolean = !fileParams[paramName];
        if (!needsLoading) form.validateFields([paramName]);

        return (
            <Form.Item
                key={paramName}
                name={paramName}
                className={theme.fileField}
                rules={[
                    {
                        validator: (_) => {
                            return !needsLoading || !required ? Promise.resolve() : Promise.reject();
                        },
                        message: intl.formatMessage(messages.missingParamFileMessage),
                    },
                ]}
            >
                <Button
                    key={paramName}
                    className={theme.button}
                    onClick={handleUploadFile(paramName)}
                    loading={loading}
                >
                    {intl.formatMessage(messages.loadFileButton)}
                </Button>
                {loaded && (
                    <span className={theme.loadedFileText}>
                        {intl.formatMessage(messages.loadedFileName)}: {fileParam.originalFileName}
                    </span>
                )}
            </Form.Item>
        );
    };

    const getDateField = (parameter: ScriptParameter) => {
        const { name = '', defaultValue, value, required } = parameter;
        let initialValue: dayjs.Dayjs = dayjs();

        if (value !== undefined) {
            try {
                initialValue = dayjs.unix(+value);
            } catch (e) {
                // обработчик на всякий случай
            }
        } else if (defaultValue !== undefined) {
            try {
                initialValue = dayjs.unix(+defaultValue);
            } catch (e) {
                // обработчик на всякий случай
            }
        }

        if (!initialValue.isValid()) {
            initialValue = dayjs();
        }

        return (
            <Form.Item
                required={required}
                name={name}
                initialValue={initialValue}
                key={name}
                rules={[
                    {
                        required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <DatePicker
                    showTime
                    format="DD-MM-YYYY HH:mm:ss"
                    placeholder={intl.formatMessage(messages.selectDateMessage)}
                />
            </Form.Item>
        );
    };

    const getSelectStringField = (parameter: ScriptParameter) => {
        return (
            <Form.Item
                initialValue={parameter.value || parameter.defaultValue}
                name={parameter.name || ''}
                key={parameter.name}
                rules={[
                    {
                        required: parameter.required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <Select>
                    {parameter.selectStringValues?.map((value) => (
                        <Option key={value} value={value}>
                            {value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        );
    };

    const getNodeField = (parameter: ScriptParameter) => {
        const { name = '', required } = parameter;
        const isSelected: boolean = !!nodeParams[name]?.path;
        if (isSelected) form.validateFields([name]);

        return (
            <Form.Item
                name={name}
                key={name}
                rules={[
                    {
                        validator: (_) => {
                            return isSelected || !required ? Promise.resolve() : Promise.reject();
                        },
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <Row justify="space-between" className={theme.row}>
                    <Input className={theme.node} disabled type="text" value={nodeParams[name]?.path} />

                    <Button className={theme.nodeButton} icon={<EditOutlined />} onClick={() => handleAddNode(name)} />
                    {nodeParams[name]?.path && (
                        <Button
                            className={theme.nodeButton}
                            icon={<DeleteOutlined />}
                            onClick={() => handleDeleteNode(name)}
                        />
                    )}
                </Row>
            </Form.Item>
        );
    };

    const getQueryField = (parameter: ScriptParameter) => {
        const { name = '', required } = parameter;

        return (
            <Form.Item
                initialValue={parameter.value || parameter.defaultValue}
                name={parameter.name || ''}
                key={parameter.name}
                rules={[
                    {
                        required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <QuerySelect data={queryValues[name] || []} onChange={() => undefined} />
            </Form.Item>
        );
    };

    const getQueryMultiField = (parameter: ScriptParameter) => {
        const { name = '', required } = parameter;

        return (
            <Form.Item
                initialValue={parameter.value || parameter.defaultValue}
                name={parameter.name || ''}
                key={parameter.name}
                rules={[
                    {
                        required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <QuerySelect data={queryValues[name] || []} onChange={() => undefined} isMultiSelect />
            </Form.Item>
        );
    };

    const getDescriptionTooltip = (param: ScriptParameter) => {
        return (
            <Tooltip
                title={param.description}
                mouseEnterDelay={TOOLTIP_ENTER_DELAY}
                mouseLeaveDelay={TOOLTIP_LEAVE_DELAY}
                placement="topLeft"
            >
                <div className={param.required ? theme.requidedParamName : undefined}>{param.name}</div>
            </Tooltip>
        );
    };

    const columns = [
        {
            title: intl.formatMessage(messages.scriptParam),
            dataIndex: 'name',
            key: 'name',
            width: 180,
        },
        {
            title: intl.formatMessage(messages.scriptParamValue),
            dataIndex: 'paramValue',
            key: 'paramValue',
        },
    ];

    const getDataSourse = (params: ScriptParameter[]) => {
        return params.map((parameter) => {
            if (!parameter.paramType) {
                return {};
            }
            switch (parameter.paramType.toString().toUpperCase()) {
                case ParameterType.STRING:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getStringField(parameter),
                    };
                case ParameterType.NUMBER:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getNumberField(parameter),
                    };
                case ParameterType.BOOLEAN:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getBooleanField(parameter),
                    };
                case ParameterType.FILE:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getFileField(parameter),
                    };
                case ParameterType.NODE:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getNodeField(parameter),
                    };
                case ParameterType.DATE:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getDateField(parameter),
                    };
                case ParameterType.SELECT_STRING:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getSelectStringField(parameter),
                    };
                case ParameterType.QUERY_SELECT:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getQueryField(parameter),
                    };
                case ParameterType.QUERY_MULTI_SELECT:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getQueryMultiField(parameter),
                    };
                default:
                    return {};
            }
        });
    };

    const dataSource = getDataSourse(parsedParams);

    return (
        <Form form={form}>
            {!!parsedParams?.length && (
                <div className={theme.description}>{intl.formatMessage(messages.enterParametersValuesMessage)}</div>
            )}
            {dataSource.length !== 0 && (
                <Form.Item>
                    <Table
                        className={theme.table}
                        columns={columns}
                        dataSource={dataSource}
                        bordered
                        pagination={false}
                        scroll={{ y: '' }}
                    />
                </Form.Item>
            )}
        </Form>
    );
};
