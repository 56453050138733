import type { NodeId, ObjectDefinitionNode, ObjectInstance } from '../serverapi/api';
import { call, put } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { objectDefinitionsAdd, objectDefinitionUmlUpdate } from '../actions/entities/objectDefinition.actions';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { getStore } from '../store';
import { CustomMap } from '../utils/map';
import { setServerIdToNodeInterface } from '../utils/nodeId.utils';
import { apiBundle } from './api/api-bundle';
import { TreeItemType } from '../modules/Tree/models/tree';
import { TreeDaoService } from './dao/TreeDaoService';
import { treeItemUmlObjectsUpdate } from '../actions/tree.actions';
import { UML_OBJECT_TYPE } from '../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import { MxCell } from 'MxGraph';

// todo: желательно перенести отсюда getStore. Импортируя сюда getStore, мы создаем циклическую зависимость
// store должен импортировать root.saga а root.saga все остальные саги.
class ObjectDefinitionService {
    *loadObject({ serverId, repositoryId, id }: NodeId) {
        return (yield this.loadObjects(serverId, repositoryId, [id]))[0];
    }

    *loadObjects(serverId: string, repositoryId: string, objectDefinitionIDs: string[]) {
        const nodeIds: NodeId[] = objectDefinitionIDs.map((id) => ({ serverId, id, repositoryId }));
        const loaded: ObjectDefinitionImpl[] = yield this.loadObjectsFromServer(serverId, nodeIds);

        if (loaded[0]?.objectTypeId === UML_OBJECT_TYPE.CLASS) {
            const loadedWithChildren: ObjectDefinitionImpl[] = (yield TreeDaoService.getNodeAndChildrenList(
                serverId,
                loaded[0].nodeId,
            )).filter(({ type }) => type === TreeItemType.ObjectDefinition);
            yield put(objectDefinitionUmlUpdate({ objectDefinitions: loadedWithChildren }));
            yield put(treeItemUmlObjectsUpdate({ objectDefinitions: loadedWithChildren }));
        } else if (loaded[0]?.objectTypeId === UML_OBJECT_TYPE.PACKAGE) {
            const loadedChildren: ObjectDefinitionImpl[] = (yield TreeDaoService.getNodeChildren(
                serverId,
                loaded[0].nodeId,
            )).filter(({ type }) => type === TreeItemType.ObjectDefinition);
            const parentWithChildren = [...loadedChildren, ...loaded];
            yield put(objectDefinitionsAdd(parentWithChildren));
            yield put(treeItemUmlObjectsUpdate({ objectDefinitions: parentWithChildren }));
        } else {
            yield put(objectDefinitionsAdd(loaded));
        }

        return loaded;
    }

    // eslint-disable-next-line class-methods-use-this
    *loadObjectsFromServer(serverId: string, objectDefinitionIDs: NodeId[]) {
        const loadedObjects: ObjectDefinitionNode[] | undefined = yield call(() =>
            apiBundle(serverId).objectsDefinition.loadBulk({ body: objectDefinitionIDs }),
        );

        return loadedObjects?.map((o) => setServerIdToNodeInterface(this.convertToObject(o), serverId));
    }

    async findByNameAndType(
        nodeId: NodeId,
        objectDefinitionName?: string | undefined,
        force?: boolean,
    ): Promise<ObjectDefinitionImpl[]> {
        const { serverId, repositoryId, id } = nodeId;
        const sourceObjectDefinition = ObjectDefinitionSelectors.byId(nodeId)(getStore().getState());

        if (sourceObjectDefinition) {
            if (sourceObjectDefinition.userForceSelected && !force) {
                return [];
            }
            if (!objectDefinitionName) {
                return [];
            }
            const nodes: ObjectDefinitionNode[] = await apiBundle(
                serverId,
            ).objectsDefinition.objectDefinitionsByRepositoryByNameAndType({
                repositoryId,
                objectDefinitionName,
                objectTypeId: sourceObjectDefinition.objectTypeId!,
            });

            const objects = nodes
                .map((o) => setServerIdToNodeInterface(this.convertToObject(o), serverId))
                .filter(
                    (o: ObjectDefinitionNode) =>
                        o.nodeId.id !== id &&
                        o.name.toLowerCase() === objectDefinitionName.toLowerCase() &&
                        o.objectTypeId === sourceObjectDefinition.objectTypeId,
                );

            return objects;
        }

        return [];
    }

    /**
     * TODO: Этот метод вызывается в основном перед сохранением на сервер(перед saveObjectDefinition)
     * когда нужно быстро положить в стор не дожидаясь ответа от сервера
     * проблема в том что 2 раза сохраняется в стор (1 раз этим методом, 2 - вызывает saveObjectDefinition)
     * и в том что использует getStore
     * @param serverId
     * @param object
     */
    // eslint-disable-next-line class-methods-use-this
    createObjectDefinition(serverId: string, object: ObjectDefinitionImpl): ObjectDefinitionImpl {
        const nodeId: NodeId = {
            ...object.nodeId,
            id: object.nodeId ? object.nodeId.id : uuid(),
        };
        const objectDef = new ObjectDefinitionImpl({
            ...object,
            nodeId,
            attributes: object.attributes || [],
            modelAssignments: object.modelAssignments || [],
            createdAt: new Date().getTime(),
            userForceSelected: false,
        });
        getStore().dispatch(objectDefinitionsAdd([objectDef]));

        return objectDef;
    }

    findCellIdsByObject(objectNodeId: NodeId): CustomMap<NodeId, string[]> {
        const map = new CustomMap<NodeId, string[]>();
        if (objectNodeId) {
            instancesBPMMxGraphMap.values().forEach((graph) => {
                const cells = graph.getModel()?.cells;
                const objectCells = Object.values<MxCell>(cells)
                    .filter(
                        (c) =>
                            c.getValue()?.objectDefinitionId === objectNodeId.id &&
                            objectNodeId.repositoryId === graph.id.repositoryId,
                    )
                    .map((c) => c.getId());
                if (objectCells.length) {
                    map.set(graph.id, objectCells);
                }
            });
        }

        return map;
    }

    findAllObjectsInGraph(graphId: NodeId): ObjectDefinitionImpl[] {
        let objectDefinitions: ObjectDefinitionImpl[] = [];
        if (graphId) {
            const graph = instancesBPMMxGraphMap.get(graphId);
            if (graph) {
                const objectDefinitionIds: NodeId[] = Object.values<MxCell>(graph.getModel().cells)
                    .filter((e) => e.value?.type === TreeItemType.ObjectDefinition.toLowerCase())
                    .map((e) => ({
                        ...graphId,
                        id: e.value.objectDefinitionId,
                    }));
                objectDefinitions = ObjectDefinitionSelectors.byIds(objectDefinitionIds)(getStore().getState());
            }
        }

        return objectDefinitions;
    }

    getObjectDefinition(id: NodeId) {
        return ObjectDefinitionSelectors.byId(id)(getStore().getState());
    }

    getObjectDefinitionByInstance(instance: ObjectInstance, modelNodeId: NodeId): ObjectDefinitionImpl | undefined {
        return this.getObjectDefinition({
            id: instance.objectDefinitionId!,
            repositoryId: modelNodeId.repositoryId,
            serverId: modelNodeId.serverId,
        });
    }

    convertToObject(o: Partial<ObjectDefinitionImpl>): ObjectDefinitionImpl {
        return {
            modelAssignments: [],
            isDirty: true, // Это вообще где то используется?
            ...o,
        } as ObjectDefinitionImpl;
    }

    async saveObjectDefinition(objectDefinition: ObjectDefinitionImpl): Promise<ObjectDefinitionImpl> {
        const {
            nodeId: { serverId },
        } = objectDefinition;

        const updatedObject = (await apiBundle(serverId).objectsDefinition.save({
            body: objectDefinition,
        })) as ObjectDefinitionImpl;

        setServerIdToNodeInterface(updatedObject, serverId);

        return updatedObject;
    }
}

let objectDefinitionServiceInstance: ObjectDefinitionService;

export function objectDefinitionService(): ObjectDefinitionService {
    if (!objectDefinitionServiceInstance) {
        objectDefinitionServiceInstance = new ObjectDefinitionService();
    }

    return objectDefinitionServiceInstance;
}
