import React, { FC } from 'react';

type TIconInputServerProps = {
    color: string;
    className?: string;
};

const SymbolEditorTabArrowImage: FC<TIconInputServerProps> = ({ color, className }) => {
    return (
        <svg className={className} width="640" height="480" xmlns="http://www.w3.org/2000/svg">
            <g className="layer">
                <line fill="none" id="svg_1" stroke={color} strokeWidth="3" x1="35" x2="621" y1="30" y2="30" />
                <line fill="none" id="svg_4" stroke={color} strokeWidth="3" x1="35" x2="35" y1="29" y2="320" />
                <line fill="none" id="svg_6" stroke={color} strokeWidth="3" x1="613" x2="620" y1="23" y2="30" />
                <line fill="none" id="svg_7" stroke={color} strokeWidth="3" x1="613" x2="620" y1="37" y2="30" />
                <line fill="none" id="svg_8" stroke={color} strokeWidth="3" x1="28" x2="35" y1="310" y2="318" />
                <line fill="none" id="svg_9" stroke={color} strokeWidth="3" x1="35" x2="42" y1="318" y2="310" />
                <text
                    fillOpacity="0.6"
                    fill="000000"
                    fontFamily="serif"
                    fontSize="20"
                    id="svg_11"
                    stroke="000000"
                    strokeWidth="0"
                    textAnchor="middle"
                    x="27"
                    y="27"
                >
                    0
                </text>
                <text
                    fillOpacity="0.6"
                    fill="000000"
                    fontFamily="serif"
                    fontSize="20"
                    id="svg_12"
                    stroke="000000"
                    strokeWidth="0"
                    textAnchor="middle"
                    x="585"
                    y="25"
                >
                    100
                </text>
                <text
                    fillOpacity="0.6"
                    fill="000000"
                    fontFamily="serif"
                    fontSize="20"
                    id="svg_13"
                    stroke="000000"
                    strokeWidth="0"
                    textAnchor="middle"
                    x="17"
                    y="300"
                >
                    100
                </text>
                <text
                    fillOpacity="0.6"
                    fill="000000"
                    fontFamily="serif"
                    fontSize="20"
                    id="svg_14"
                    stroke="000000"
                    strokeWidth="0"
                    textAnchor="middle"
                    x="16.863636"
                    y="105.409091"
                >
                    y
                </text>
                <text
                    fillOpacity="0.6"
                    fill="000000"
                    fontFamily="serif"
                    fontSize="20"
                    id="svg_15"
                    stroke="000000"
                    strokeWidth="0"
                    textAnchor="middle"
                    x="100.863636"
                    y="20"
                >
                    x
                </text>
            </g>
        </svg>
    );
};

export default SymbolEditorTabArrowImage;
