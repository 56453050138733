import { Button, Checkbox } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { scriptContextFetch } from '../../../../actions/scriptContext.actions';
import { AllowedScriptContext, AllowedScriptModelSymbols, NodeId } from '../../../../serverapi/api';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import messages from '../../messages/ScriptContext.messages';
import { ItemsTree } from './ItemsTree.component';
import { NodesTree } from './NodesTree.component';
import { scriptContextTypes } from './scriptContext.types';
import theme from './ScriptContextComponent.scss';
import { IScriptNode } from '../../../../models/bpm/bpm-model-impl.types';
import { checkIsRestrictByType } from './addContext.utils';

type TAddScriptContext = {
    scriptNodeWithNewContext: IScriptNode;
    contextToEditType: scriptContextTypes;
};

export const AddScriptContext = (props: TAddScriptContext) => {
    const dispatch = useDispatch();
    const { scriptNodeWithNewContext, contextToEditType } = props;
    const { allowedScriptContext = {} } = scriptNodeWithNewContext;
    const intl = useIntl();
    const [isRestrictByType, setRestrictByType] = useState(
        checkIsRestrictByType(allowedScriptContext, contextToEditType),
    );
    const [isPermitObjectInstances, setPermitObjectInstances] = useState<boolean | undefined>(allowedScriptContext.allowObjectInstancesOfAllowedObjectType);
    const [showModels, setshowModels] = useState<boolean>(true);
    const [showObjects, setshowObjects] = useState<boolean>(true);
    const [isAllDBPermited, setAllDBPermited] = useState<boolean | undefined>(allowedScriptContext.allowAllDBs);
    const [isAllFilesPermited, setAllFilesPermited] = useState<boolean | undefined>(allowedScriptContext.allowAllFiles);
    const [isAllScriptsBPermited, setAllScriptsPermited] = useState<boolean | undefined>(allowedScriptContext.allowAllScripts);

    const [itemsToPermit, setItemsToPermit] = useState<string[] | AllowedScriptModelSymbols[]>();
    const [nodesToPermit, setNodesToPermit] = useState<NodeId[]>();

    const handleCancel = () => {
        dispatch(closeDialog(DialogType.ADD_SCRIPT_CONTEXT));
    };
    const handleRestrict = () => {
        setRestrictByType(!isRestrictByType);
    };
    const handlePermit = () => {
        setPermitObjectInstances(!isPermitObjectInstances);
    };
    const handleModelsShow = () => {
        setshowModels(!showModels);
    };
    const handleObjectsShow = () => {
        setshowObjects(!showObjects);
    };

    const handlePermitAllDB = () => {
        setAllDBPermited(!isAllDBPermited);
    };
    const handlePermitAllFiles = () => {
        setAllFilesPermited(!isAllFilesPermited);
    };
    const handlePermitAllScripts = () => {
        setAllScriptsPermited(!isAllScriptsBPermited);
    };

    const createNewScriptContext = (contextType: string): AllowedScriptContext => {
        switch (contextType) {
            case scriptContextTypes.runningOnModels: {
                if (isRestrictByType) {
                    return {
                        ...allowedScriptContext,
                        allowedModelTypeIds: itemsToPermit as string[],
                        allowAllModels: false,
                    };
                }

                return {
                    ...allowedScriptContext,
                    allowAllModels: true,
                };
            }
            case scriptContextTypes.runningOnObjects: {
                if (isRestrictByType) {
                    return {
                        ...allowedScriptContext,
                        allowedObjectTypeIds: itemsToPermit as string[],
                        allowAllObjects: false,
                        allowObjectInstancesOfAllowedObjectType: isPermitObjectInstances,
                    };
                }

                return {
                    ...allowedScriptContext,
                    allowAllObjects: true,
                    allowObjectInstancesOfAllowedObjectType: isPermitObjectInstances,
                };
            }
            case scriptContextTypes.runningOnEdges: {
                if (isRestrictByType) {
                    return {
                        ...allowedScriptContext,
                        allowedEdgeTypeIds: itemsToPermit as string[],
                        allowAllEdges: false,
                    };
                }

                return {
                    ...allowedScriptContext,
                    allowAllEdges: true,
                };
            }

            case scriptContextTypes.runningOnFolders: {
                if (isRestrictByType) {
                    return {
                        ...allowedScriptContext,
                        allowedFolderTypeIds: itemsToPermit as string[],
                        allowAllFolders: false,
                    };
                }

                return {
                    ...allowedScriptContext,
                    allowAllFolders: true,
                };
            }

            case scriptContextTypes.runningOnObjectInstances: {
                if (isRestrictByType) {
                    return {
                        ...allowedScriptContext,
                        allowedSymbolTypeIds: itemsToPermit as string[],
                        allowAllSymbols: false,
                    };
                }

                return {
                    ...allowedScriptContext,
                    allowAllSymbols: true,
                };
            }

            case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
                if (isRestrictByType) {
                    return {
                        ...allowedScriptContext,
                        allowedModelSymbols: itemsToPermit as AllowedScriptModelSymbols[],
                        allowAllSymbols: false,
                    };
                }

                return {
                    ...allowedScriptContext,
                };
            }
            case scriptContextTypes.runningOnSpecificElement: {
                return {
                    ...allowedScriptContext,
                    // когда в дерево не внесено ни одного изменения, то nodesToPermit=underfined,
                    // в этом кейсе сохраняем  ключи из allowedScriptContext.allowedNodeIds
                    allowedNodeIds: nodesToPermit || allowedScriptContext?.allowedNodeIds,
                };
            }

            case scriptContextTypes.everyContext:
                return {
                    ...allowedScriptContext,
                    allowAll: true,
                };

            case scriptContextTypes.runningOnFiles:
                return {
                    ...allowedScriptContext,
                    allowAllFiles: isAllFilesPermited,
                };
            case scriptContextTypes.runningOnDB:
                return {
                    ...allowedScriptContext,
                    allowAllDBs: isAllDBPermited,
                };
            case scriptContextTypes.runningOnScripts:
                return {
                    ...allowedScriptContext,
                    allowAllScripts: isAllScriptsBPermited,
                };

            default:
                return {
                    ...allowedScriptContext,
                    allowAll: false,
                };
        }
    };

    const handleOk = () => {
        dispatch(scriptContextFetch(createNewScriptContext(contextToEditType), scriptNodeWithNewContext.nodeId));
    };

    const footer = [
        <Button data-test="window_execution-context_close_btn" key="cancel" size="large" onClick={handleCancel}>
            {intl.formatMessage(messages.cancelContext)}
        </Button>,
        <Button data-test="window_execution-context_save_btn" key="ok" size="large" type="primary" onClick={handleOk}>
            {intl.formatMessage(messages.saveContext)}
        </Button>,
    ];

    const getTitleByContextType = (contextType: scriptContextTypes): string => {
        switch (contextType) {
            case scriptContextTypes.runningOnModels: {
                return intl.formatMessage(messages.runningOnModels);
            }
            case scriptContextTypes.runningOnObjects: {
                return intl.formatMessage(messages.runningOnObjects);
            }
            case scriptContextTypes.runningOnEdges: {
                return intl.formatMessage(messages.runningOnEdges);
            }
            case scriptContextTypes.runningOnFolders: {
                return intl.formatMessage(messages.runningOnFolders);
            }
            case scriptContextTypes.runningOnObjectInstances: {
                return intl.formatMessage(messages.runningOnObjectInstances);
            }
            case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
                return intl.formatMessage(messages.runningOnObjectInstancesWithBindingModelTypes);
            }
            case scriptContextTypes.runningOnSpecificElement: {
                return intl.formatMessage(messages.runningOnSpecificElement);
            }
            case scriptContextTypes.runningOnFiles: {
                return intl.formatMessage(messages.runningOnFiles);
            }

            case scriptContextTypes.runningOnDB: {
                return intl.formatMessage(messages.runningOnDB);
            }

            case scriptContextTypes.runningOnScripts: {
                return intl.formatMessage(messages.runningOnScripts);
            }
            default:
                return '';
        }
    };

    return (
        <Dialog
            onCancel={handleCancel}
            title={getTitleByContextType(contextToEditType)}
            open
            width={600}
            footer={footer}
            className={theme.dialog}
            style={{ height: 750, maxHeight: '95vh' }}
        >
            <div>
                <div className={theme.checkBoxContainer}>
                    {(contextToEditType === scriptContextTypes.runningOnObjects ||
                        contextToEditType === scriptContextTypes.runningOnEdges ||
                        contextToEditType === scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes ||
                        contextToEditType === scriptContextTypes.runningOnFolders ||
                        contextToEditType === scriptContextTypes.runningOnObjectInstances ||
                        contextToEditType === scriptContextTypes.runningOnModels) && (
                        <div>
                            <Checkbox
                                data-test="restrict-by-type_check-box"
                                className={theme.checkBox}
                                onChange={handleRestrict}
                                checked={isRestrictByType}
                            >
                                {intl.formatMessage(messages.restrictByModelType)}
                            </Checkbox>
                        </div>
                    )}
                    {contextToEditType === scriptContextTypes.runningOnObjects && (
                        <div>
                            <Checkbox
                                data-test="permit-object-instances_check-box"
                                className={theme.checkBox}
                                onChange={handlePermit}
                                checked={isPermitObjectInstances}
                            >
                                {intl.formatMessage(messages.permitObjectInstances)}
                            </Checkbox>
                        </div>
                    )}
                    {contextToEditType === scriptContextTypes.runningOnSpecificElement && (
                        <div className={theme.checkBoxContainer}>
                            <div>
                                <Checkbox
                                    data-test="running-on-specific-element_models_check-box"
                                    className={theme.checkBox}
                                    onChange={handleModelsShow}
                                    checked={showModels}
                                >
                                    {intl.formatMessage(messages.models)}
                                </Checkbox>
                            </div>
                            <div>
                                <Checkbox
                                    data-test="running-on-specific-element_objects_check-box"
                                    className={theme.checkBox}
                                    onChange={handleObjectsShow}
                                    checked={showObjects}
                                >
                                    {intl.formatMessage(messages.objects)}
                                </Checkbox>
                            </div>
                        </div>
                    )}
                    {contextToEditType === scriptContextTypes.runningOnFiles && (
                        <div>
                            <Checkbox
                                data-test="running-on-files_check-box"
                                className={theme.checkBox}
                                onChange={handlePermitAllFiles}
                                checked={isAllFilesPermited}
                            >
                                {intl.formatMessage(messages.runningOnAllFiles)}
                            </Checkbox>
                        </div>
                    )}
                    {contextToEditType === scriptContextTypes.runningOnScripts && (
                        <div>
                            <Checkbox
                                data-test="running-on-scripts_check-box"
                                className={theme.checkBox}
                                onChange={handlePermitAllScripts}
                                checked={isAllScriptsBPermited}
                            >
                                {intl.formatMessage(messages.runningOnAllScripts)}
                            </Checkbox>
                        </div>
                    )}
                    {contextToEditType === scriptContextTypes.runningOnDB && (
                        <div>
                            <Checkbox
                                data-test="running-on-DB_check-box"
                                className={theme.checkBox}
                                onChange={handlePermitAllDB}
                                checked={isAllDBPermited}
                            >
                                {intl.formatMessage(messages.runningOnAllDB)}
                            </Checkbox>
                        </div>
                    )}
                </div>
            </div>

            {[
                scriptContextTypes.runningOnObjects,
                scriptContextTypes.runningOnModels,
                scriptContextTypes.runningOnEdges,
                scriptContextTypes.runningOnFolders,
                scriptContextTypes.runningOnObjectInstances,
                scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes,
            ].includes(contextToEditType) &&
                isRestrictByType && (
                    <ItemsTree
                        setItemsToPermit={setItemsToPermit}
                        scriptId={scriptNodeWithNewContext.nodeId}
                        scriptContext={allowedScriptContext}
                        contextType={contextToEditType}
                    />
                )}

            {contextToEditType === scriptContextTypes.runningOnSpecificElement && (
                <NodesTree
                    setNodesToPermit={setNodesToPermit}
                    scriptContext={allowedScriptContext}
                    showModels={showModels}
                    showObjects={showObjects}
                />
            )}
        </Dialog>
    );
};
