export const FETCH_AUDIT = 'FETCH_AUDIT';

export const FETCH_DETAILED_AUDIT = 'FETCH_DETAILED_AUDIT';

export const FETCH_DETAILED_AUDIT_SUCCESS = 'FETCH_DETAILED_AUDIT_SUCCESS';

export const FETCH_AUDIT_SUCCESS = 'FETCH_AUDIT_SUCCESS';

export const DOWNLOAD_CSV = 'DOWNLOAD_CSV';

export const DELETE_AUDIT_RANGE = 'DELETE_AUDIT_RANGE';

export const DELETE_SESSION_RANGE = 'DELETE_SESSION_RANGE';

export const FINISH_DOWNLOAD_CSV = 'FINISH_DOWNLOAD_CSV';

export const FINISH_FETCH_AUDIT = 'FINISH_FETCH_AUDIT';

export const SET_AUDIT_FILTERED_TYPES = 'SET_AUDIT_FILTERED_TYPES';

export const SET_AUDIT_FILTERED_ACCESSES = 'SET_AUDIT_FILTERED_ACCESSES';
