import React, { FC } from 'react';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { LARGE_DIALOG_WIDTH } from '@/config/config';
import messages from './MessageContentDialog.messages';
import theme from './MessageContentDialog.scss';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { Button } from 'antd';

type TMessageContentDialogProps = {
    subject: string;
    template: string;
};

export const MessageContentDialog: FC<TMessageContentDialogProps> = ({ subject, template }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const onCloseDialog = () => dispatch(closeDialog(DialogType.MESSAGE_CONTENT_DIALOG));

    return (
        <Dialog
            onCancel={onCloseDialog}
            title={intl.formatMessage(messages.notificationsTemplate)}
            open
            width={LARGE_DIALOG_WIDTH}
            cancelText={intl.formatMessage(messages.cancel)}
            className={theme.dialogContainer}
            footer={[
                <Button key="close" onClick={onCloseDialog}>
                    {intl.formatMessage(messages.cancel)}
                </Button>,
            ]}
        >
            <div className={theme.subject}>{subject}</div>
            <div>{template}</div>
        </Dialog>
    );
};

