import React, { ChangeEvent } from 'react';
import theme from './SettingsAxis.scss';
import messages from './MatrixSettingsDialog.messages';
import { Col, Input, Button } from 'antd';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { TreeNode } from '../../models/tree.types';
import { TooltipTitle } from '../TooltipTitle/TooltipTitle.component';
import { useIntl } from 'react-intl';
import { MatrixHeaderTypeEnum } from '../../serverapi/api';
import { Tree } from '../Tree/components/Tree/Tree.component';
import { TreeItemType } from '../Tree/models/tree';

type TSettingsAxisProps = {
    treeNodes: TreeNode[];
    newHeaderNodeId: string;
    newHeaderText: string;
    newHeader: string;
    headersChilds: React.ReactNode[];
    addNewHeader: (headerType: MatrixHeaderTypeEnum) => void;
    setNewHeader: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
};

export const SettingsAxis = (props: TSettingsAxisProps) => {
    const {
        treeNodes,
        newHeaderNodeId,
        newHeaderText,
        newHeader,
        headersChilds,
        addNewHeader,
        setNewHeader,
    } = props;
    const intl = useIntl();

    return (
        <div className={theme.tabContentContainer}>
            <div className={theme.title}>{intl.formatMessage(messages.inputAxisFromTree)}</div>
            <div className={theme.selectContainer}>
                <div className={theme.treeLocationContainer}>
                    <div className={theme.treeLocation}>
                        <Tree
                            includeFilterForSelect={[TreeItemType.ObjectDefinition]}
                            data={treeNodes}
                            treeName={DialogType.MATRIX_SETTINGS_DIALOG}
                            disableContextMenu
                        />
                    </div>
                    <Col span={2} className={theme.colBorder} />
                    <ul className={theme.addedChilds}>{headersChilds}</ul>
                </div>
            </div>
            <div className={theme.buttonContainer}>
                <div className={theme.addElContainer}>
                    <Button
                        key="treeOk"
                        size="large"
                        type="primary"
                        disabled={!newHeaderNodeId}
                        className={theme.addButton}
                        onClick={() => addNewHeader('NODE')}
                    >
                        <TooltipTitle withoutTooltip>
                            <>
                                {intl.formatMessage(messages.addButton)}
                                &nbsp;{newHeaderText}
                            </>
                        </TooltipTitle>
                    </Button>
                </div>
                <div className={theme.inputTitleContainer}>
                    <div className={theme.inputTitle}>{intl.formatMessage(messages.inputAxisFromText)}</div>
                    <Input
                        className={theme.input}
                        placeholder={intl.formatMessage(messages.headerPlaceholder)}
                        defaultValue=""
                        value={newHeader}
                        onChange={setNewHeader}
                    />
                </div>
                <div>
                    <Button key="ok" size="large" type="primary" onClick={() => addNewHeader('SIMPLE')}>
                        {intl.formatMessage(messages.addButton)}
                    </Button>
                </div>
            </div>
        </div>
    );
};
