import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { createCompactableSelect } from '../../../CompactableSelect/CompactableSelect.component';
import { PickOut } from '../../../../../../models/pick-out';
import messages from './PickOutSelect.messages';
import icHightLight from '../../../../../../resources/icons/highlight.svg';
import icHightLightAll from '../../../../../../resources/icons/highlight_all.svg';
import icHightLightInverse from '../../../../../../resources/icons/highlight_inverse.svg';
import icHightLightType from '../../../../../../resources/icons/highlight_all_of_type.svg';
import icHightLightForm from '../../../../../../resources/icons/highlight_all_of_form.svg';
import { TCompactableSelectItemType } from '../../../CompactableSelect/CompactableSelect.types';
import { useDispatch } from 'react-redux';
import { pickOutAction } from '../../../../../../actions/editor.actions';
import menuItemTheme from '../../../items/MenuItem.scss';

interface IPickOutSelectProps {
    compact?: boolean;
    disabled?: boolean;
}

const PickOutCompactableSelect = createCompactableSelect<PickOut>();

export const PickOutSelect: FC<IPickOutSelectProps> = (props) => {
    const { compact, disabled } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const onPickOutAction = (pickOut: PickOut) => {
        dispatch(pickOutAction(pickOut));
    };

    return (
        <div data-test="graph-general-toolbar_select-button" className={menuItemTheme.tooltipContainer}>
            <PickOutCompactableSelect
                title={intl.formatMessage(messages.title)}
                compact={compact}
                items={[
                    {
                        type: TCompactableSelectItemType.Value,
                        value: PickOut.PickOutAll,
                        label: intl.formatMessage(messages.pickOutAll),
                        spriteSymbol: icHightLightAll,
                    },
                    {
                        type: TCompactableSelectItemType.Value,
                        value: PickOut.ReversePickOut,
                        label: intl.formatMessage(messages.reversePickOut),
                        spriteSymbol: icHightLightInverse,
                    },
                    {
                        type: TCompactableSelectItemType.Value,
                        value: PickOut.PickOutByObjectType,
                        label: intl.formatMessage(messages.pickOutByObjectType),
                        spriteSymbol: icHightLightType,
                    },
                    {
                        type: TCompactableSelectItemType.Value,
                        value: PickOut.PickOutBySymbol,
                        label: intl.formatMessage(messages.pickOutBySymbol),
                        spriteSymbol: icHightLightForm,
                    },
                ]}
                disabled={disabled}
                value={PickOut.PickDefault}
                onSelect={onPickOutAction}
                defaultSprite={icHightLight}
            />
        </div>
    );
};
