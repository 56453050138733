import { MatrixHeaderEnum } from '../modules/MatrixSettingsDialog/matrixHeaderEnum';
import { IMatrixNode } from '../models/bpm/bpm-model-impl.types';
import {
    CustomMatrixState,
    IconMatrixState,
    IconMatrixStateIconEnum,
    MatrixCell,
    MatrixData,
    MatrixHeader,
    MatrixHeaderTypeEnum,
    MatrixState,
    NodeId,
    NodeMatrixHeader,
    SimpleMatrixHeader,
    UserIconMatrixState,
} from '../serverapi/api';
import { v4 as uuid } from 'uuid';
import { isUndefined } from 'is-what';
import { PushDirectionEnum } from '../modules/MatrixSettingsDialog/headerPushDirectionEnum';
import { setServerIdToNodeInterface } from '../utils/nodeId.utils';
import { apiBundle } from './api/api-bundle';

interface IOrderedElement {
    orderNumber: number;
}

export class MatrixService {
    NO_SUCH_TYPE_HEADER_ERROR: string = 'No such type of MatrixHeaderEnum';
    NO_SUCH_TYPE_HEADER_DIRECTION_ERROR: string = 'No such type push header direction';

    addMatrixCustomState(data: MatrixData, text: string, color: string, description: string): MatrixData {
        const state: CustomMatrixState = {
            id: uuid(),
            orderNumber: this.getLastStateOrderNumber(data) + 1,
            description,
            type: 'CUSTOM',
            color,
            text,
        };
        data.states.push(state);

        return data;
    }

    addMatrixIconState(
        data: MatrixData,
        icon: IconMatrixStateIconEnum,
        color: string,
        description: string,
    ): MatrixData {
        const state: IconMatrixState = {
            id: uuid(),
            orderNumber: this.getLastStateOrderNumber(data) + 1,
            description,
            icon,
            type: 'ICON',
            color,
        };
        data.states.push(state);

        return data;
    }

    addMatrixUserIconState(data: MatrixData, iconId: string, description: string): MatrixData {
        const state: UserIconMatrixState = {
            id: uuid(),
            orderNumber: this.getLastStateOrderNumber(data) + 1,
            description,
            type: 'USER_ICON',
            iconPath: iconId,
        };
        data.states.push(state);

        return data;
    }

    addMatrixHeader(
        data: MatrixData,
        rowColType: MatrixHeaderEnum,
        headerType: MatrixHeaderTypeEnum,
        value: string,
    ): MatrixData {
        const lastNumber: number = this.getLastOrderNumber(data, rowColType);
        let resultHeader: MatrixHeader;
        if (headerType === 'SIMPLE') {
            resultHeader = <SimpleMatrixHeader>{
                type: headerType,
                orderNumber: lastNumber + 1,
                title: value,
            };
        } else if (headerType === 'NODE') {
            resultHeader = <NodeMatrixHeader>{
                type: headerType,
                orderNumber: lastNumber + 1,
                nodeId: value,
            };
        } else {
            throw new Error(this.NO_SUCH_TYPE_HEADER_ERROR);
        }

        if (rowColType === MatrixHeaderEnum.ColumnHeader) {
            data.columns.push(resultHeader);
        } else if (rowColType === MatrixHeaderEnum.RowHeader) {
            data.rows.push(resultHeader);
        } else {
            throw new Error(this.NO_SUCH_TYPE_HEADER_ERROR);
        }

        return data;
    }

    deleteMatrixHeader(matrix: IMatrixNode, type: MatrixHeaderEnum, orderNumber: number): IMatrixNode {
        const data: MatrixData | undefined = matrix.data;
        if (!data) {
            return matrix;
        }
        if (type === MatrixHeaderEnum.ColumnHeader) {
            data.columns = data.columns.filter((t) => t.orderNumber !== orderNumber);
            data.cells = data.cells.filter((t) => t.colNumber !== orderNumber);
        } else if (type === MatrixHeaderEnum.RowHeader) {
            data.rows = data.rows.filter((t) => t.orderNumber !== orderNumber);
            data.cells = data.cells.filter((t) => t.rowNumber !== orderNumber);
        } else {
            throw new Error(this.NO_SUCH_TYPE_HEADER_ERROR);
        }

        return matrix;
    }

    pushHeader(
        matrix: IMatrixNode,
        type: MatrixHeaderEnum,
        orderNumber: number,
        direction: PushDirectionEnum,
    ): IMatrixNode {
        const data: MatrixData | undefined = matrix.data;
        if (!data) {
            return matrix;
        }
        let headers: MatrixHeader[];
        if (type === MatrixHeaderEnum.ColumnHeader) {
            headers = data.columns;
        } else if (type === MatrixHeaderEnum.RowHeader) {
            headers = data.rows;
        } else {
            throw new Error(this.NO_SUCH_TYPE_HEADER_ERROR);
        }

        const fromIndex: number = headers.findIndex((t) => t.orderNumber === orderNumber);
        const toIndex: number = direction === PushDirectionEnum.UP ? fromIndex - 1 : fromIndex + 1;
        if (fromIndex < 0 || fromIndex >= headers.length || toIndex < 0 || toIndex >= headers.length) {
            return matrix;
        }
        const fromOrder: number = headers[fromIndex].orderNumber;
        const toOrder: number = headers[toIndex].orderNumber;

        this.swapElements(headers, fromIndex, toIndex);
        this.swapCells(data.cells, type, fromOrder, toOrder);

        return matrix;
    }

    pushState(matrix: IMatrixNode, stateId: string, direction: PushDirectionEnum): IMatrixNode {
        if (matrix.data) {
            const states: MatrixState[] = matrix.data.states;
            this.reorderElements(states);

            const fromIndex: number = states.findIndex((t) => t.id === stateId);
            const toIndex: number = direction === PushDirectionEnum.UP ? fromIndex - 1 : fromIndex + 1;
            if (fromIndex < 0 || fromIndex >= states.length || toIndex < 0 || toIndex >= states.length) {
                return matrix;
            }
            this.swapElements(states, fromIndex, toIndex);
            // интерфейс вместо эни, ИД к хидерам
        }

        return matrix;
    }

    deleteState(matrix: IMatrixNode, stateId: string): IMatrixNode {
        const data: MatrixData | undefined = matrix.data;
        if (data) {
            data.states = data.states.filter((t) => t.id !== stateId);
            data.cells = data.cells.filter((t) => t.stateId !== stateId);
        }

        return matrix;
    }

    switchCell(data: MatrixData | undefined, rowNumber: number, colNumber: number): MatrixData | undefined {
        if (!data) {
            return data;
        }
        const states: MatrixState[] = data.states.sort((a: MatrixState, b: MatrixState) => {
            const aIndex: number = a.orderNumber;
            const bIndex: number = b.orderNumber;
            if (aIndex === bIndex) {
                return 0;
            }
            if (aIndex < bIndex) {
                return -1;
            }

            return 1;
        });
        const matrixCell: MatrixCell | undefined = data.cells.find(
            (t) => t.colNumber === colNumber && t.rowNumber === rowNumber,
        );

        if (isUndefined(matrixCell)) {
            if (!isUndefined(states[0])) {
                const newCell: MatrixCell = {
                    stateId: states[0].id,
                    rowNumber,
                    colNumber,
                };
                data.cells.push(newCell);
            }
        } else {
            const state: MatrixState = states.find((x) => x.id === matrixCell.stateId)!;
            const stateIndex: number = states.indexOf(state);
            if (stateIndex === states.length - 1) {
                this.deleteCell(data, matrixCell);
            } else {
                matrixCell.stateId = states[stateIndex + 1].id;
            }
        }

        return data;
    }

    deleteCell(data: MatrixData, cell: MatrixCell) {
        const index: number = data.cells.indexOf(cell);
        data.cells.splice(index, 1);
    }

    getStateById(data: MatrixData, stateId: string): MatrixState {
        return data.states.find((t) => t.id === stateId)!;
    }

    loadMatrix(nodeId: NodeId): Promise<IMatrixNode> {
        return apiBundle(nodeId.serverId)
            .matrix.get({ repositoryId: nodeId.repositoryId, matrixId: nodeId.id })
            .then((m) => {
                setServerIdToNodeInterface(m, nodeId.serverId);

                return { ...m, isDirty: false, isNew: false, serverId: nodeId.serverId };
            });
    }

    saveMatrix(matrix: IMatrixNode): Promise<IMatrixNode> {
        return apiBundle(matrix.nodeId.serverId)
            .matrix.save({ body: matrix })
            .then((m) => {
                setServerIdToNodeInterface(m, matrix.nodeId.serverId);

                return { ...m, isDirty: false, isNew: false, serverId: matrix.nodeId.serverId };
            });
    }

    async lockMatrix(nodeId: NodeId) {
        const lock = await apiBundle(nodeId.serverId).matrix.lockMatrix({
            matrixId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });

        return lock;
    }

    async unlockMatrix(nodeId: NodeId) {
        const unlock = await apiBundle(nodeId.serverId).matrix.unlockMatrix({
            matrixId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });

        return unlock;
    }

    private swapElements(arr: IOrderedElement[], fromIndex: number, toIndex: number) {
        const tempOrder: number = arr[fromIndex].orderNumber;
        arr[fromIndex].orderNumber = arr[toIndex].orderNumber;
        arr[toIndex].orderNumber = tempOrder;
        this.reorderElements(arr);
    }

    private swapCells(allCells: MatrixCell[], type: MatrixHeaderEnum, fromNum: number, toNum: number) {
        if (type === MatrixHeaderEnum.RowHeader) {
            allCells.map((t) => {
                if (t.rowNumber === fromNum) {
                    t.rowNumber = toNum;
                } else if (t.rowNumber === toNum) {
                    t.rowNumber = fromNum;
                }
            });
        } else if (type === MatrixHeaderEnum.ColumnHeader) {
            allCells.map((t) => {
                if (t.colNumber === fromNum) {
                    t.colNumber = toNum;
                } else if (t.colNumber === toNum) {
                    t.colNumber = fromNum;
                }
            });
        } else {
            throw new Error(this.NO_SUCH_TYPE_HEADER_ERROR);
        }
    }

    private getLastOrderNumber(data: MatrixData, type: MatrixHeaderEnum): number {
        let arr: MatrixHeader[];
        if (type === MatrixHeaderEnum.ColumnHeader) {
            arr = data.columns;
        } else if (type === MatrixHeaderEnum.RowHeader) {
            arr = data.rows;
        } else {
            throw new Error(this.NO_SUCH_TYPE_HEADER_ERROR);
        }
        const numbers: number[] = arr.map((t) => t.orderNumber);
        numbers.push(0);
        const result: number = Math.max.apply(this, numbers);

        return result;
    }

    private getLastStateOrderNumber(data: MatrixData): number {
        const numbers: number[] = data.states.map((t) => t.orderNumber);
        numbers.push(0);
        const result: number = Math.max.apply(this, numbers);

        return result;
    }

    private reorderElements(elements: IOrderedElement[]) {
        elements.sort((a: MatrixHeader, b: MatrixHeader) => {
            const aIndex: number = a.orderNumber;
            const bIndex: number = b.orderNumber;
            if (aIndex === bIndex) {
                return 0;
            }
            if (aIndex < bIndex) {
                return -1;
            }

            return 1;
        });
    }
}

let matrixServiceInstance: MatrixService;

export function matrixService(): MatrixService {
    if (!matrixServiceInstance) {
        matrixServiceInstance = new MatrixService();
    }

    return matrixServiceInstance;
}
