import React from 'react';
import theme from './InstancePermissionsDialog.scss';
import messages from '../../messages/InstancePermissionsDialog.messages';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

type TInstancePermissionsDialogFooterProps = {
    onClose: () => void;
    onSubmit: () => void;
    changed: boolean;
  };
  
export const InstancePermissionsDialogFooterComponent = ({onClose, changed, onSubmit}: TInstancePermissionsDialogFooterProps) => {
    return (
        <> 
            <Button
                className={theme.cancelBtn}
                data-test="permission-dialog_cancel-button"
                key="cancel" size="large"
                onClick={onClose}
            >
                <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button
                className={changed ? theme.saveBtn : ''}
                data-test="permission-dialog_confirm-button"
                disabled={!changed}
                key="ok"
                size="large"
                type="primary"
                onClick={onSubmit}
            >
                <FormattedMessage {...messages.saveButton} />
            </Button>
        </>
    )
};
