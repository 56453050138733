import {call, put, select, takeEvery} from 'redux-saga/effects';
import {FILE_UPLOAD} from '../actionsTypes/uploader.actionTypes';
import {
    fileUploadFail,
    fileUploadSuccess
} from '../actions/uploader.actions';
import {TServerEntity} from '../models/entities.types';
import {ServerSelectors} from '../selectors/entities/server.selectors';
import {
    FileNodeDTO,
    RootNodeId,
    UserDTO
} from '../serverapi/api';
import {showNotification} from '../actions/notification.actions';
import {NotificationType} from '../models/notificationType';
import {TreeItemType} from "../modules/Tree/models/tree";
import {TabsSelectors} from "../selectors/tabs.selectors";
import {getUser} from "../selectors/authorization.selectors";
import {fileReplaceAttributesUpdate, treeItemChildAdd} from "../actions/tree.actions";
import {TreeNode} from "../models/tree.types";
import { TFileUploadAction } from '../actions/uploader.actions.types';
import { getExtension, isImageFile } from '../utils/files.utils';

// todo: обьединить с file.saga

/**
 * Загрузка файла.
 * @param action
 */

function* showNotificationImageUploadFail(id:  string) { 
    yield put(showNotification({
        id,
        type: NotificationType.INCORRECT_IMAGE_ERROR
    }));
    yield put(fileUploadFail(id));
}

function* saveFileNode(action: TFileUploadAction) {
    const { nodeId, file, parentNodeId, uploadedFromTree } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const tab = yield select(TabsSelectors.getActiveTab); // todo: проверить где есть загрузка картини без вкладки
    const parentId = parentNodeId || tab?.content?.parentNodeId;
    const user: UserDTO | undefined = yield select(getUser);
    const extension = getExtension(file?.name);

    if (extension) {
        const fileNode: FileNodeDTO = {
            nodeId,
            parentNodeId: parentId || {id: user?.login, repositoryId: RootNodeId.FILE_FOLDER_ROOT_REPOSITORY_ID, serverId: server.id},
            type: TreeItemType.File,
            name: file.name,
            extension,
            file,
        };
        if (!uploadedFromTree && !isImageFile(fileNode)) {
            yield showNotificationImageUploadFail(nodeId.id);

            return;
        }

        const uploadedFile: FileNodeDTO = yield call(() => server.api.file.uploadFile({body: fileNode}));
       
        if (!uploadedFile) {
            yield showNotificationImageUploadFail(nodeId.id);

            return;
        }
        yield put(fileUploadSuccess(uploadedFile, server, nodeId.id));
        yield put(treeItemChildAdd({parentNodeId: parentId, child: [{...fileNode, hasChildren: false} as TreeNode]}));
        yield put(fileReplaceAttributesUpdate(nodeId));
    } else {
        yield put(showNotification({
            id: nodeId.id,
            type: NotificationType.INCORRECT_IMAGE_ERROR
        }));
    }
}

export function* uploaderSaga() {
    yield takeEvery(FILE_UPLOAD, saveFileNode);
}
