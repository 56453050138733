// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateGraphSymbolDialog__formItem___JK43,.CreateGraphSymbolDialog__formItemDisabled___hUsW{margin:0}.CreateGraphSymbolDialog__formItemDisabled___hUsW .ant-form-item-explain{width:0;height:0;display:none}.CreateGraphSymbolDialog__editableTextContainer__n6whN{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%}.CreateGraphSymbolDialog__editableTextContainer__n6whN button{-ms-flex-negative:0;flex-shrink:0}.CreateGraphSymbolDialog__editableTextContainer__n6whN .ant-row{width:100%;padding-right:10px}.CreateGraphSymbolDialog__required__AaUgf{color:red}", "",{"version":3,"sources":["webpack://./src/modules/CreateGraphSymbolDialog/CreateGraphSymbolDialog.scss"],"names":[],"mappings":"AAAA,4FACI,QAAA,CAIA,yEACI,OAAA,CACA,QAAA,CACA,YAAA,CAIR,uDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,UAAA,CACA,8DACI,mBAAA,CAAA,aAAA,CAGJ,gEACI,UAAA,CACA,kBAAA,CAIR,0CACI,SAAA","sourcesContent":[".formItem, .formItemDisabled {\r\n    margin: 0;\r\n}\r\n\r\n.formItemDisabled {\r\n    :global(.ant-form-item-explain) {\r\n        width: 0;\r\n        height: 0;\r\n        display: none;\r\n    }\r\n}\r\n\r\n.editableTextContainer {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n    button {\r\n        flex-shrink: 0;\r\n    }\r\n\r\n    :global(.ant-row) {\r\n        width: 100%;\r\n        padding-right: 10px;\r\n    }\r\n}\r\n\r\n.required {\r\n    color: red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": "CreateGraphSymbolDialog__formItem___JK43",
	"formItemDisabled": "CreateGraphSymbolDialog__formItemDisabled___hUsW",
	"editableTextContainer": "CreateGraphSymbolDialog__editableTextContainer__n6whN",
	"required": "CreateGraphSymbolDialog__required__AaUgf"
};
export default ___CSS_LOADER_EXPORT___;
