import React from 'react';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { Modal } from 'antd';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useDispatch } from 'react-redux';
import messages from '../../messages/ImportVisioErrorDialog.messages';
import { FormattedMessage } from 'react-intl';
import { shouldCallModalSubmitHandler } from '../../../../../services/utils/ModalHelper';

export const ImportVisioErrorDialog = ({ errorText }) => {
    const dispatch = useDispatch();

    const handlOkClick = () => {
        dispatch(closeDialog(DialogType.IMPORT_VISIO_ERROR));
    };

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    handlOkClick();
                }
            }}
        >
            <Modal
                width={400}
                open
                onOk={handlOkClick}
                title={<FormattedMessage {...messages.title} />}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                {errorText}
            </Modal>
        </div>
    );
};
