import { Alert, Button, Form, Radio } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { ConflictResolutionStrategy } from '../../../../services/api/custom/TransferApi';
import { TreeItemType } from '../../../Tree/models/tree';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../../UIKit/components/Dialog/Dialog.types';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import messages from '../../messages/ImportDialog.messages';
import theme from './ImportDialog.scss';
import { TImportDialogProps } from '../../types/ImportDialog.types';

type TImportDialogState = {
    resolveMethod: string;
};

class ImportDialog extends React.Component<TImportDialogProps, TImportDialogState> {
    state = {
        resolveMethod: ConflictResolutionStrategy.REPLACE,
    };

    handleForAllNodes = () => {
        this.props.submitAllNodes(this.state.resolveMethod);
    };

    handleForCurrentNode = () => {
        this.props.submitNode(this.props.itemId, this.state.resolveMethod);
    };

    handleMethodChange = (event) => {
        this.setState({
            resolveMethod: event.target.value,
        });
    };

    render() {
        const { intl, open } = this.props;
        const dialogProps: Partial<TDialogProps> = {
            open,
            closable: false,
            title: `${intl.formatMessage(messages.dialogName)} - ${this.props.conflictsNumber}`,
        };
        const isRepo = this.props.itemType === TreeItemType.Repository;
        const forAllNodesDisabled: boolean =
            isRepo ||
            (this.state.resolveMethod === ConflictResolutionStrategy.REPLACE && this.props.nodesWithoutAccess);

        const footer = [
            <Button
                data-test="import_conflict-dialog_for-node_btn"
                key="forCurrentNode"
                size="large"
                onClick={this.handleForCurrentNode}
            >
                {intl.formatMessage(messages.conflictDialogForNodeButton)}
            </Button>,
            <Button
                data-test="import_conflict-dialog_for-all-nodes_btn"
                key="forAllNodes"
                size="large"
                onClick={this.handleForAllNodes}
                disabled={forAllNodesDisabled}
            >
                {intl.formatMessage(messages.conflictDialogForAllNodesButton)}
            </Button>,
            <Button
                data-test="import_conflict-dialog_cancel_btn"
                key="cancel"
                size="large"
                onClick={this.props.onCancel}
                disabled={false}
            >
                {intl.formatMessage(messages.cancelButton)}
            </Button>,
        ];

        return (
            <Dialog {...dialogProps} footer={footer}>
                {this.props.error && <Alert type="error" message={intl.formatMessage(this.props.error)} showIcon />}
                <Form layout="vertical" hideRequiredMark>
                    <FormGroup>
                        <Form.Item
                            className={theme.itemType}
                            label={`${
                                this.props.noAccess
                                    ? `${intl.formatMessage(messages.conflictDialogNotAccessToNode)}\n`
                                    : this.props.nodesWithoutAccess
                                    ? `${intl.formatMessage(messages.conflictDialogNoAccessInSet)}\n`
                                    : ''
                            }${intl.formatMessage(messages.dialogLabel)}`}
                        >
                            <div style={{ lineHeight: 1.5 }}>
                                <p style={{ marginBottom: '2em', whiteSpace: 'pre-wrap' }}>
                                    {intl.formatMessage(messages.conflictDialogNodeInfo, {
                                        id: this.props.itemId.id,
                                        itemTypeLocal: this.props.itemTypeLocal,
                                        path: this.props.path,
                                    })}
                                </p>
                            </div>
                            <Radio.Group
                                value={this.state.resolveMethod}
                                onChange={this.handleMethodChange}
                                className={theme.radioStyle}
                            >
                                <Radio
                                    data-test="import_conflict-dialog_replace_radio"
                                    value={ConflictResolutionStrategy.REPLACE}
                                    disabled={this.props.noAccess}
                                >
                                    {intl.formatMessage(messages.conflictDialogReplaceRadio)}
                                </Radio>
                                {!isRepo && ( // БД не мержим, никто не помнит почему так решили
                                    <Radio
                                        data-test="import_conflict-dialog_leave_radio"
                                        value={ConflictResolutionStrategy.LEAVE}
                                    >
                                        {intl.formatMessage(messages.conflictDialogLeaveRadio)}
                                    </Radio>
                                )}
                                <Radio
                                    data-test="import_conflict-dialog_new-guid_radio"
                                    value={ConflictResolutionStrategy.CLONE}
                                >
                                    {intl.formatMessage(messages.conflictDialogCloneRadio)}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </FormGroup>
                    <p />
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ImportDialog);

export { IntlComponent as ImportDialog };
