import React from 'react';
import classnames from 'classnames';
import theme from './Button.scss';
import { Tooltip } from 'antd';
import { TButtonProps } from './Button.types';

/**
 * ссылка на фигму (https://www.figma.com/design/QwNP21XezszK7TasNIoDQf/SDS-Design-KIT?node-id=9-167&t=aUiHP2EDE6s7uZY2-0)
 *
 * @param {'large' | 'normal' | 'small'} size большая, средняя или маленькая кнопка
 * @param {'primary' | 'secondary' | 'text'} visualStyle сплошная заливка, только текст кнопки без заливки и рамок, простая кнопка без заливки
 * @param {boolean | undefined} danger кнопка красного цвета
 * @param {React.ReactNode} children текст в кнопке
 * @param {boolean | undefined} disabled дизейбл кнопки
 * @param {string | undefined} key React key кнопки
 * @param {MouseEventHandler<HTMLButtonElement> | undefined} onClick функция, которая будет вызвана при нажатии на кнопку
 * @param {string | undefined} dataTest идентификатор для тестов
 * @param {string | undefined} tooltip текст подсказки
 */

export const Button = (props: TButtonProps) => {
    const {
        children,
        danger,
        size = 'normal',
        visualStyle = 'secondary',
        disabled,
        key,
        dataTest,
        tooltip,
        onClick,
    } = props;
    const buttonClass: string = classnames({
        [theme.button]: !disabled && visualStyle === 'secondary',
        [theme.buttonFilled]: !disabled && visualStyle === 'primary',
        [theme.textOnly]: !disabled && visualStyle === 'text',
        [theme.buttonDisabled]: disabled && visualStyle === 'secondary',
        [theme.buttonFilledDisabled]: disabled && visualStyle === 'primary',
        [theme.textOnlyDisabled]: disabled && visualStyle === 'text',
        [theme.big]: size === 'large',
        [theme.middle]: size === 'normal',
        [theme.small]: size === 'small',
        [theme.red]: danger,
    });

    return (
        <Tooltip mouseLeaveDelay={0} title={tooltip}>
            <button className={buttonClass} key={key} onClick={!disabled ? onClick : undefined} data-test={dataTest}>
                {children}
            </button>
        </Tooltip>
    );
};
