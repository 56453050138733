import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import theme from './ContentLoadingPageTab.scss';
import { Spin } from 'antd';

class ContentLoadingPageTab extends React.Component<WrappedComponentProps> {
    canceledMoseAction = (event: React.MouseEvent<HTMLDivElement>) => event.preventDefault();

    render() {
        const cancel = this.canceledMoseAction;

        return (
            <div className={theme.container} onMouseUp={cancel} onMouseDown={cancel} onClick={cancel}>
                <Spin size='large' className={theme.loader}/>
            </div>
        );
    }
}

const withIntl = injectIntl(ContentLoadingPageTab);

export { withIntl as ContentLoadingPageTab };
