import { IScriptNode } from '../../models/bpm/bpm-model-impl.types';
import {
    ScriptExecution,
    ScriptParameter,
    NodeId,
    ScriptExecutionResults,
    AllowedScriptContext,
    SchedulerTimeSettings,
    SchedulerTask,
} from '../../serverapi/api';
import { TServerEntity } from '../../models/entities.types';
import {
    SCRIPT_REQUEST_SUCCESS,
    SCRIPT_SAVE,
    SCRIPT_SAVE_REQUEST,
    SCRIPT_SAVE_REQUEST_SUCCESS,
    SCRIPT_SAVE_REQUEST_FAILURE,
    SCRIPT_OPEN,
    SCRIPT_OPEN_BY_NODE_ID,
    SCRIPT_CLOSE,
    SCRIPTS_DASHBOARD_OPEN,
    SCRIPTS_EXECUTION_REQUEST,
    SCRIPTS_EXECUTION_SAVE_TO_STORE,
    SCRIPTS_EXECUTION_REQUEST_FAILURE,
    DASHBOARD_OPEN,
    STATUS_CHANGE_NOTIFICATION_SHOW,
    REPORT_DOWNLOAD_DATE_SET,
    FILE_DOWNLOADED_NOTIFICATION_SHOW,
    SCRIPT_CHANGE_LOCALLY,
    SCRIPT_CREATE,
    SCRIPT_EXECUTION_SUBSCRIBE,
    SCRIPT_REMOVE_SUCCESS,
    SCRIPT_SUBMITTED_VIA_NODE,
    SCRIPT_EXECUTE,
    SCRIPT_EXECUTE_REQUEST_SUCCESS,
    SCRIPT_EXECUTE_REQUEST_FAILURE,
    CHANGE_SCRIPT_DATA,
    SCRIPT_MODE_CHANGE,
    SCRIPT_SCHEDULE,
    SCRIPT_CONTEXT_DELETE,
    SCRIPT_CONTEXT_SAVE_SUCCESS,
    SCRIPT_EDIT_SCHEDULE_SUBMIT,
    SCRIPT_NODE_TO_EXECUTE,
    SCRIPT_NODE_TO_SCHEDULE,
} from '../../actionsTypes/entities/script.actionTypes';
import { EditorMode } from '../../models/editorMode';
import {
    TScriptDefaultAction,
    TScriptDefaultRequestAction,
    TScriptSubmittedViaNode,
    TScriptsSaveToStoreAction,
    TScriptsFailureRequestAction,
    TScriptDefaultRequestFailureAction,
    TScriptOpenByNodeIdAction,
    TScriptChangeModeAction,
    TScriptExecutionSubscribeAction,
    TScriptsDashboardOpenAction,
    TScriptsExecutionRequestAction,
    TDashboardOpenAction,
    TScriptChangingStatusNotificationShowAction,
    TScriptSetReportDownloadDateAction,
    TFileDownloadedFileNotificationShowAction,
    TChangeScriptData,
    TScriptExecuteAction,
    TScriptScheduleAction,
    TScriptDeleteAction,
    TScriptContextDeleteAction,
    TScriptContexSaveAction,
    TScriptSubmittedViaNodePayload,
    TScriptEditScheduleSubmitAction,
    TScriptNodeToExecuteAction,
    TScriptNodeToScheduleAction,
    TScriptCreateAction,
    TScriptCreateDataTransport,
} from './script.actions.types';
import { scriptContextTypes } from '../../modules/ObjectPropertiesDialog/components/ScriptContext/scriptContext.types';

export const changeScriptData = (nodeId: NodeId, script: IScriptNode): TChangeScriptData => ({
    type: CHANGE_SCRIPT_DATA,
    payload: {
        nodeId,
        script,
    },
});

export const scriptRequestSuccess = (serverId: string, script: IScriptNode): TScriptDefaultRequestAction => ({
    type: SCRIPT_REQUEST_SUCCESS,
    payload: {
        serverId,
        script,
    },
});

export const handleScriptSubmittedViaNode = (payload: TScriptSubmittedViaNodePayload): TScriptSubmittedViaNode => ({
    type: SCRIPT_SUBMITTED_VIA_NODE,
    payload,
});

export const scriptsExecutionSaveToStore = (
    serverId: string,
    executions: ScriptExecutionResults,
): TScriptsSaveToStoreAction => ({
    type: SCRIPTS_EXECUTION_SAVE_TO_STORE,
    payload: {
        serverId,
        executions,
    },
});

export const scriptsExecutionRequestFailure = (serverId: string, error: string): TScriptsFailureRequestAction => ({
    type: SCRIPTS_EXECUTION_REQUEST_FAILURE,
    payload: { serverId, error },
});

export const scriptSave = (script: IScriptNode): TScriptDefaultAction => ({
    type: SCRIPT_SAVE,
    payload: {
        script,
    },
});

export const scriptSaveRequest = (
    serverId: string,
    script: IScriptNode,
    forceSave?: boolean,
): TScriptDefaultRequestAction => ({
    type: SCRIPT_SAVE_REQUEST,
    payload: {
        script,
        serverId,
        forceSave,
    },
});

export const scriptSaveRequestSuccess = (serverId: string, script: IScriptNode): TScriptDefaultRequestAction => ({
    type: SCRIPT_SAVE_REQUEST_SUCCESS,
    payload: {
        serverId,
        script,
    },
});

export const scriptsSaveRequestFailure = (serverId: string, error: string): TScriptDefaultRequestFailureAction => ({
    type: SCRIPT_SAVE_REQUEST_FAILURE,
    payload: { serverId, error },
});

export const scriptOpenByNodeId = (nodeId: NodeId): TScriptOpenByNodeIdAction => ({
    type: SCRIPT_OPEN_BY_NODE_ID,
    payload: {
        nodeId,
    },
});

export const scriptChangeMode = (nodeId: NodeId, mode: EditorMode): TScriptChangeModeAction => ({
    type: SCRIPT_MODE_CHANGE,
    payload: {
        nodeId,
        mode,
    },
});

export const scriptOpen = (script: IScriptNode): TScriptDefaultAction => ({
    type: SCRIPT_OPEN,
    payload: {
        script,
    },
});

export const scriptClose = (script: IScriptNode): TScriptDefaultAction => ({
    type: SCRIPT_CLOSE,
    payload: {
        script,
    },
});

export const scriptChange = (script: IScriptNode): TScriptDefaultAction => ({
    type: SCRIPT_CHANGE_LOCALLY,
    payload: {
        script,
    },
});

export const scriptCreate = (params: TScriptCreateDataTransport): TScriptCreateAction => ({
    type: SCRIPT_CREATE,
    payload: {
        params,
    },
});

export const scriptRemoveSuccess = (nodeId: NodeId): TScriptDeleteAction => ({
    type: SCRIPT_REMOVE_SUCCESS,
    payload: {
        nodeId,
    },
});

export const scriptExecute = (scriptId: NodeId, params: ScriptParameter[]): TScriptExecuteAction => ({
    type: SCRIPT_EXECUTE,
    payload: {
        scriptId,
        params,
    },
});

export const scriptSchedule = (
    scriptId: NodeId,
    params: ScriptParameter[],
    timeSettings: SchedulerTimeSettings,
    disable?: boolean,
): TScriptScheduleAction => ({
    type: SCRIPT_SCHEDULE,
    payload: {
        scriptId,
        params,
        timeSettings,
        disable,
    },
});

export const scriptExecuteRequestSuccess = (serverId: string, script: IScriptNode): TScriptDefaultRequestAction => ({
    type: SCRIPT_EXECUTE_REQUEST_SUCCESS,
    payload: {
        serverId,
        script,
    },
});

export const scriptExecuteRequestFailure = (serverId: string, error: string): TScriptDefaultRequestFailureAction => ({
    type: SCRIPT_EXECUTE_REQUEST_FAILURE,
    payload: { serverId, error },
});

export const subscribeToScriptExecutions = (serverId: string): TScriptExecutionSubscribeAction => ({
    type: SCRIPT_EXECUTION_SUBSCRIBE,
    payload: {
        serverId,
    },
});

export const scriptsDashboardOpen = (nodeId: NodeId): TScriptsDashboardOpenAction => ({
    type: SCRIPTS_DASHBOARD_OPEN,
    payload: {
        nodeId,
    },
});

export const requestScriptsExecution = (serverId: string): TScriptsExecutionRequestAction => ({
    type: SCRIPTS_EXECUTION_REQUEST,
    payload: {
        serverId,
    },
});

export const dashboardOpen = (executionResults: ScriptExecutionResults, serverId: string): TDashboardOpenAction => ({
    type: DASHBOARD_OPEN,
    payload: {
        executionResults,
        serverId,
    },
});

// tslint:disable-next-line:max-line-length
export const changingStatusNotificationShow = (
    server: TServerEntity,
    scriptExecution: ScriptExecution,
): TScriptChangingStatusNotificationShowAction => ({
    type: STATUS_CHANGE_NOTIFICATION_SHOW,
    payload: {
        server,
        scriptExecution,
    },
});

// tslint:disable-next-line:max-line-length
export const setReportDownloadDate = (
    serverId: string,
    scriptExecutionId: string,
): TScriptSetReportDownloadDateAction => ({
    type: REPORT_DOWNLOAD_DATE_SET,
    payload: {
        serverId,
        scriptExecutionId,
    },
});

export const fileDownloadedNotificationShow = (
    fileName: string,
    filePath: string,
): TFileDownloadedFileNotificationShowAction => ({
    type: FILE_DOWNLOADED_NOTIFICATION_SHOW,
    payload: {
        fileName,
        filePath,
    },
});

export const deleteScriptContext = (type: scriptContextTypes, scriptId: NodeId): TScriptContextDeleteAction => ({
    type: SCRIPT_CONTEXT_DELETE,
    payload: {
        type,
        scriptId,
    },
});

export const saveScriptContextSuccess = (
    scriptContext: AllowedScriptContext,
    scriptId: NodeId,
): TScriptContexSaveAction => ({
    type: SCRIPT_CONTEXT_SAVE_SUCCESS,
    payload: {
        scriptContext,
        scriptId,
    },
});

export const scriptEditScheduleSubmit = (payload: {
    task: SchedulerTask;
    serverId: string;
}): TScriptEditScheduleSubmitAction => ({
    type: SCRIPT_EDIT_SCHEDULE_SUBMIT,
    payload,
});

export const scriptNodeToExecute = (payload: {
    script: IScriptNode;
    existingParams: ScriptParameter[];
}): TScriptNodeToExecuteAction => ({
    type: SCRIPT_NODE_TO_EXECUTE,
    payload,
});

export const scriptNodeToSchedule = (payload: {
    script: IScriptNode;
    existingParams: ScriptParameter[];
}): TScriptNodeToScheduleAction => ({
    type: SCRIPT_NODE_TO_SCHEDULE,
    payload,
});
