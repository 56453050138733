export const CREATE_APPROVAL = 'CREATE_APPROVAL';

export const ADD_APPROVALS = 'ADD_APPROVALS';

export const EDIT_APPROVAL = 'EDIT_APPROVAL';

export const DELETE_APPROVALS = 'DELETE_APPROVALS';

export const CLEAR_APPROVALS = 'CLEAR_APPROVALS';

export const SUBMIT_APPROVAL = 'SUBMIT_APPROVAL';

export const LOAD_NODE_APPROVALS = 'LOAD_NODE_APPROVALS';

export const COMMENT_APPROVAL = 'COMMENT_APPROVAL';

export const EDIT_EXISTING_APPROVAL = 'EDIT_EXISTING_APPROVAL';

export const OPEN_APPROVALS_TAB = 'OPEN_APPROVALS_TAB';

export const CHECK_APPROVALS = 'CHECK_APPROVALS';

export const DELETE_APPROVALS_FROM_STORE = 'DELETE_APPROVALS_FROM_STORE';

export const CHANGE_CHECK_ALL_APPROVALS = 'CHANGE_CHECK_ALL_APPROVALS';

export const VOTE_APPROVAL = 'VOTE_APPROVAL';

export const UPDATE_APPROVAL = 'UPDATE_APPROVAL';

export const COPY_APPROVAL = 'COPY_APPROVAL';

export const OPEN_APPROVAL_TEMPLATE_DIALOG = 'OPEN_APPROVAL_TEMPLATE_DIALOG';

export const CHANGE_APPROVAL_ASSISTANTS_LIST = 'CHANGE_APPROVAL_ASSISTANTS_LIST';

export const ADD_MY_APPROVALS = 'ADD_MY_APPROVALS';

export const REFRESH_MY_APPROVALS = 'REFRESH_MY_APPROVALS';

export const GO_TO_APPROVAL = 'GO_TO_APPROVAL';

export const LOAD_APPROVALS_ON_INIT = 'LOAD_APPROVALS_ON_INIT';
