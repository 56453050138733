import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox } from 'antd';
import messages from './messages/GroupDataTab.messages';
import theme from './GroupedDataTab.scss';
import { TabHeader } from '../Header/TabHeader.component';
import { GroupedTypesTable } from '../util/GroupedTypesTable.component';
import { TGroup, TTableRowRecord, TTableDataType } from '../util/GroupedTypesTable.types';
import { TSpriteSymbol } from '../../../../../../models/spriteSymbol.types';

type TGroupedDataTabProps = {
    dataTypes: TTableDataType[];
    dataTypeGroups: TGroup[];
    validTypes: string[];
    setValidTypes: (validTypes: string[]) => void;
    icon: TSpriteSymbol;
    isAllowAny: boolean;
    setIsAllowAny: (isAllowAny: boolean) => void;
};

export const GroupedDataTab: FC<TGroupedDataTabProps> = ({
    dataTypes,
    dataTypeGroups,
    validTypes,
    setValidTypes,
    icon,
    isAllowAny,
    setIsAllowAny,
}) => {
    const intl = useIntl();
    const [filter, setFilter] = useState<string>('');
    const isRowDisabled = isAllowAny;

    function handleCheck(dataId: string, isChecked: boolean): void {
        if (isChecked) {
            setValidTypes(validTypes.filter((el) => el !== dataId));
        } else {
            setValidTypes([...validTypes, dataId]);
        }
    }

    function handleCheckAll(): void {
        if (isAllowAny) {
            setValidTypes([]);
            setIsAllowAny(false);
        } else {
            setValidTypes([]);
            setIsAllowAny(true);
        }
    }

    function renderCheckboxColumn(dataId: string, row: TTableRowRecord<TTableDataType>): null | JSX.Element {
        if (row?.children) {
            // у папок нет чекбоксов
            return null;
        }

        const isChecked = validTypes.includes(dataId) || isAllowAny;

        return (
            <div className={theme.checkbox}>
                <Checkbox
                    disabled={isRowDisabled}
                    checked={isChecked}
                    onChange={() => handleCheck(dataId, isChecked)}
                />
            </div>
        );
    }

    const columns = [
        {
            title: intl.formatMessage(messages.allowed),
            dataIndex: 'id',
            key: 'checkbox',
            render: renderCheckboxColumn,
            width: 50,
        },
    ];

    return (
        <section className={theme.container}>
            <div className={theme.header}>
                <TabHeader onSearchChange={setFilter} />
                <div className={theme.checkbox}>
                    <Checkbox checked={isAllowAny} onChange={handleCheckAll}>
                        {intl.formatMessage(messages.allowAll)}
                    </Checkbox>
                </div>
            </div>
            <GroupedTypesTable
                isTableDisabled={isAllowAny}
                typeGroups={dataTypeGroups}
                actionsDisabled={false}
                searchFilter={filter}
                types={dataTypes}
                columns={columns}
                icon={icon}
            />
        </section>
    );
};
