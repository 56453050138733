import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'DecompositionUnsavedModelDialog.title',
        defaultMessage: 'Удаление строки/столбца',
    },

    text: {
        id: 'DecompositionUnsavedModelDialog.text',
        defaultMessage: 'У вас есть несохраненные модели декомпозиции, сохранить их и сохранить текущую модель?',
    },

    cancelText: {
        id: 'DecompositionUnsavedModelDialog.cancelText',
        defaultMessage: 'Нет',
    },

    submitText: {
        id: 'DecompositionUnsavedModelDialog.submitText',
        defaultMessage: 'Да',
    },
});
