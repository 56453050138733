// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrintDialog-component__dialog__JhTN6 .PrintDialog-component__footer__faxff{padding:0 30px 10px;width:-webkit-max-content;width:-moz-max-content;width:max-content}.PrintDialog-component__dialog__JhTN6 .ant-modal-body{overflow-x:hidden}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/PrintDialog/PrintDialog.component.scss"],"names":[],"mappings":"AACI,4EACI,mBAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CAGJ,sDACI,iBAAA","sourcesContent":[".dialog {\r\n    .footer {\r\n        padding: 0 30px 10px;\r\n        width: max-content;\r\n    }\r\n\r\n    :global(.ant-modal-body) {\r\n        overflow-x: hidden;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "PrintDialog-component__dialog__JhTN6",
	"footer": "PrintDialog-component__footer__faxff"
};
export default ___CSS_LOADER_EXPORT___;
