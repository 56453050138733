import React, { useState } from 'react';
import { TInputTypeEnum } from '../EditableText/EditableText.types';
import { EditableText } from '../EditableText/EditableText.component';
import theme from './MultiLangEditableText.component.scss';
import { MultiLangStringDialogComponent } from '../../../ObjectPropertiesDialog/components/MultiLangStringDialogComponent';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../services/LocalesService';
import { AttributeValueString, InternationalString } from '../../../../serverapi/api';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { MultiLangUrlDialogComponent } from '../../../ObjectPropertiesDialog/components/MultiLangUrlDialogComponent';
import { TAttributeUrlValues } from '../../../ObjectPropertiesDialog/components/AttributeTab.types';

type TMultiLangEditableTextProps = {
    disabled: boolean;
    allowEmptyValue?: boolean;
    record: AttributeValueString;
    onChange: (formValues: InternationalString | string | TAttributeUrlValues) => void;
    clearSelecting: () => void;
    onClickLink?: () => void;
    inputType?: TInputTypeEnum;
    dataTestContainer?: string;
    dataTestBtn?: string;
    isUrlType?: boolean;
    withoutMultiLang?: boolean;
    editable?: boolean;
    allowMultiLangEmptyValue?: boolean;
    text: string | undefined;
    className?: string;
};

export const MultiLangEditableText = (props: TMultiLangEditableTextProps) => {
    const {
        disabled,
        record,
        clearSelecting,
        inputType,
        allowEmptyValue,
        dataTestContainer,
        dataTestBtn,
        onChange,
        onClickLink,
        isUrlType,
        withoutMultiLang,
        editable = true,
        allowMultiLangEmptyValue,
        text,
        className,
    } = props;

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const currentLocale = useSelector(getCurrentLocale);
    const curLocale: string = LocalesService.convertToString(currentLocale).toLowerCase();

    const onActivateEditable = () => {
        clearSelecting();
        setIsEditing(true);
    };
    const onDeactivateEditable = () => {
        setIsEditing(false);
    };

    const handleEditAttributeBtn = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setIsEditing(true);
        e.stopPropagation();
    };

    return (
        <>
            <div className={theme.editableElementLarge} data-test={dataTestContainer}>
                <EditableText
                    text={text || ''}
                    isEditing={isEditing}
                    disabled={disabled}
                    onActivateEditable={onActivateEditable}
                    onDeactivateEditable={onDeactivateEditable}
                    onChange={onChange}
                    allowEmptyValue={allowEmptyValue}
                    inputType={inputType}
                    isUrlType={isUrlType}
                    onClickLink={onClickLink}
                    className={className}
                />
            </div>
            {editable && !isEditing && (
                <div className={theme.editableElementButtons}>
                    <Button
                        data-test={dataTestBtn}
                        icon={<EditOutlined />}
                        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleEditAttributeBtn(e)}
                    />
                    {!withoutMultiLang &&
                        (isUrlType ? (
                            <MultiLangUrlDialogComponent handleOk={onChange} record={record} />
                        ) : (
                            <MultiLangStringDialogComponent
                                handleOk={onChange}
                                record={record}
                                curLocale={curLocale}
                                allowEmptyValue={allowMultiLangEmptyValue}
                            />
                        ))}
                </div>
            )}
        </>
    );
};
