import * as React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { Table, Button, Popover } from 'antd';
import theme from './ScriptExecutionsTable.scss';
import messages from '../../messages/ScriptDashboardTable.messages';
import statusMessages from '../../../App/messages/AppScriptExecutionStatuses.messages';
import { isUndefined } from 'is-what';
import icStatusFail from '../../../../resources/icons/ic-script-exec-fail.svg';
import icStatusSuccess from '../../../../resources/icons/ic-script-exec-success.svg';
import icStatusStopped from '../../../../resources/icons/ic-script-exec-stopped.svg';
import icStatusRunning from '../../../../resources/icons/ic-script-exec-running.svg';
import icStatusWait from '../../../../resources/icons/ic-script-exec-wait.svg';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { NodeId, ScriptExecutionResults } from '../../../../serverapi/api';
import { ColumnProps } from 'antd/es/table';
import { isEqual } from 'lodash-es';

type TScriptExecutionsTableProps = {
    data: ScriptExecutionResults;
    setDownloadDate: (scriptExecutionId: string) => void;
    downloadFile: (fileId: NodeId) => void;
    downloadLogFile: (scriptExecutionId: string) => void;
} & JSX.IntrinsicAttributes;

type TScriptExecutionsTableAllProps = WrappedComponentProps & TScriptExecutionsTableProps;

type TScriptExecutionsTableState = {
    data: ScriptExecutionResults;
};

type TParameter = {
    name: string;
    value: string;
};

type TScriptExecutionColumnData = {
    scripExecutionId?: string;
    name?: string;
    status?: string;
    statusIcon?: TSpriteSymbol;
    author?: string;
    executionResult?: NodeId;
    createdDate?: Date;
    finishedDate?: Date;
    downloadedDate?: Date;
    createdDateStr?: string;
    finishedDateStr?: string;
    downloadedDateStr?: string;
    parameters?: TParameter[];
    executionLog?: string;
};

class ScriptExecutionsTable extends React.Component<TScriptExecutionsTableAllProps, TScriptExecutionsTableState> {
    firstLoad: boolean = true;

    columns: Array<ColumnProps<TScriptExecutionColumnData>>;
    parametersColumns: Array<ColumnProps<TParameter>>;

    constructor(props: TScriptExecutionsTableAllProps) {
        super(props);

        this.columns = [
            {
                title: props.intl.formatMessage(messages.scriptColumnName),
                dataIndex: 'name',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
            {
                width: 110,
                title: props.intl.formatMessage(messages.statusColumnName),
                dataIndex: 'status',
                render: (value: string, record: TScriptExecutionColumnData) => {
                    return this.renderStatusColumn(value, record.statusIcon!);
                },
            },
            {
                width: 175,
                title: props.intl.formatMessage(messages.startDateColumnName),
                dataIndex: 'createdDateStr',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
            {
                width: 175,
                title: props.intl.formatMessage(messages.finishDateColumnName),
                dataIndex: 'finishedDateStr',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
            {
                width: 100,
                title: props.intl.formatMessage(messages.resultColumnName),
                dataIndex: 'scripExecutionId',
                render: (value: string, record: TScriptExecutionColumnData) => {
                    return this.renderDownloadColumn(record);
                },
            },
            {
                width: 175,
                title: props.intl.formatMessage(messages.downloadedDateColumnName),
                dataIndex: 'downloadedDateStr',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
            {
                width: 140,
                title: props.intl.formatMessage(messages.parameters),
                dataIndex: 'parameters',
                render: (value: TParameter[]) => {
                    return this.renderParamsColumn(value);
                },
            },
            {
                width: 100,
                title: props.intl.formatMessage(messages.logColumnName),
                render: (value: string, record: TScriptExecutionColumnData) => {
                    return this.renderLogDownloadColumn(record);
                },
            },
        ];

        this.parametersColumns = [
            {
                width: 150,
                title: props.intl.formatMessage(messages.paramNameColumnName),
                dataIndex: 'name',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
            {
                width: 300,
                title: props.intl.formatMessage(messages.paramValueColumnName),
                dataIndex: 'value',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
        ];

        this.state = { data: props.data };
    }

    renderParamsColumn(value: TParameter[]) {
        if (value.length) {
            return (
                // tslint:disable-line
                <Popover
                    trigger="hover"
                    placement="left"
                    content={
                        // tslint:disable-line
                        <Table
                            rowKey={(record: TParameter) => `${record.name}`}
                            columns={this.parametersColumns}
                            dataSource={value}
                            size="small"
                            className={theme.table}
                            bordered
                            pagination={false}
                        />
                    }
                >
                    <span className={theme.textaslink}>
                        <FormattedMessage {...messages.parameters} />
                    </span>
                </Popover>
            );
        }

        return (
            <div>
                <FormattedMessage {...messages.noParameters} />
            </div>
        );
    }

    renderStringColumn(value: string) {
        return (
            <div>
                <span>{value}</span>
            </div>
        );
    }

    renderStatusColumn(value: string, icon: TSpriteSymbol) {
        return (
            <div>
                <span>
                    <Icon className={theme.icon} spriteSymbol={icon} />
                    &nbsp;
                    {value}
                </span>
            </div>
        );
    }

    renderDownloadColumn(record: TScriptExecutionColumnData) {
        const { executionResult, scripExecutionId } = record;
        const { serverId } = this.props.data.nodeId;
        if (!executionResult) {
            return (
                <div>
                    <Button size="small" className={theme.button} disabled>
                        <div className={theme.buttonInner}>
                            <FormattedMessage {...messages.noData} />
                        </div>
                    </Button>
                </div>
            );
        }

        return (
            <div>
                <Button
                    size="small"
                    className={theme.button}
                    onClick={() => {
                        this.props.downloadFile({ ...executionResult, serverId });
                        this.props.setDownloadDate(scripExecutionId!);
                    }}
                >
                    <div className={theme.buttonInner}>
                        <FormattedMessage {...messages.download} />
                    </div>
                </Button>
            </div>
        );

    }

    noDataButton() {
        return (
            <div>
                <Button size="small" className={theme.button} disabled>
                    <div className={theme.buttonInner}>
                        <FormattedMessage {...messages.noData} />
                    </div>
                </Button>
            </div>
        );
    }

    tableButton(onClick: () => void) {
        return (
            <div>
                <Button
                    size="small"
                    className={theme.button}
                    onClick={onClick}
                >
                    <div className={theme.buttonInner}>
                        <FormattedMessage {...messages.download} />
                    </div>
                </Button>
            </div>
        );
    }

    renderLogDownloadColumn(record: TScriptExecutionColumnData) {
        const { scripExecutionId } = record;

        if (!scripExecutionId) {
            return this.noDataButton();
        }

        return this.tableButton(() => this.props.downloadLogFile(scripExecutionId));
    }

    componentDidMount() {
        if (this.firstLoad) {
            this.firstLoad = false;
            const { data } = this.state;
            this.setState({ data });
        }
    }

    componentDidUpdate({ data }: Readonly<TScriptExecutionsTableAllProps>): void {
        if (!isEqual(this.props.data, data)) {
            this.setState({ data });
        }
    }

    render() {
        const { intl } = this.props;
        const { data } = this.state;

        const statuses = {
            WAIT: intl.formatMessage(statusMessages.appScriptExecutionStatusWait),
            RUNNING: intl.formatMessage(statusMessages.appScriptExecutionStatusRunning),
            SUCCESS: intl.formatMessage(statusMessages.appScriptExecutionStatusSuccess),
            FAIL: intl.formatMessage(statusMessages.appScriptExecutionStatusFail),
            STOPPED: intl.formatMessage(statusMessages.appScriptExecutionStatusStopped),
        };

        const statusesIcons = {
            WAIT: icStatusWait,
            RUNNING: icStatusRunning,
            SUCCESS: icStatusSuccess,
            FAIL: icStatusFail,
            STOPPED: icStatusStopped,
        };

        let columnsData =
            data &&
            data.results &&
            data.results.map((e) => {
                const item: TScriptExecutionColumnData = {};
                item.scripExecutionId = e.id!;
                item.name = e.scriptName;
                item.status = statuses[e.status!];
                item.statusIcon = statusesIcons[e.status!];
                item.createdDate = new Date(e.createdDate!);
                item.finishedDate = new Date(e.finishedDate!);
                item.downloadedDate = new Date(e.downloadedDate!);
                item.createdDateStr = new Date(e.createdDate!).toLocaleString();
                item.finishedDateStr =
                    isUndefined(e.finishedDate) || e.finishedDate == null
                        ? intl.formatMessage(messages.noData)
                        : new Date(e.finishedDate!).toLocaleString();
                item.downloadedDateStr =
                    isUndefined(e.downloadedDate) || e.downloadedDate == null
                        ? intl.formatMessage(messages.notDownloaded)
                        : new Date(e.downloadedDate!).toLocaleString();
                item.executionResult = e.executionResult;
                item.parameters = [];

                if (e.parameters) {
                    item.parameters = e.parameters.map((p) => ({ name: p.name, value: p.value } as TParameter));
                }

                return item;
            });

        columnsData =
            columnsData &&
            columnsData.sort((n1, n2) => {
                return n2.createdDate!.getTime() - n1.createdDate!.getTime();
            });

        return (
            <div className={theme.container}>
                <Table
                    rowKey={(record: TScriptExecutionColumnData) => `${record.scripExecutionId}`}
                    columns={this.columns}
                    dataSource={columnsData}
                    size="small"
                    className={theme.table}
                    bordered
                    scroll={{
                        y: 'max-content',
                        x: 'max-content',
                    }}
                    pagination={false}
                />
            </div>

        );
    }
}

const ScriptExecutionsTableWithIntl = injectIntl(ScriptExecutionsTable);

export { ScriptExecutionsTableWithIntl as ScriptExecutionsTable };
