// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TreeItemSelectDialog-component__modal__IJoU5 .ant-modal-body{height:500px}.TreeItemSelectDialog-component__buttons___XB8U{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/TreeItemSelectDialog/TreeItemSelectDialog.component.scss"],"names":[],"mappings":"AAEQ,8DACI,YAAA,CAKZ,gDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA","sourcesContent":[".modal{\r\n    :global{\r\n        .ant-modal-body{\r\n            height: 500px;\r\n        }\r\n    }\r\n}\r\n\r\n.buttons {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "TreeItemSelectDialog-component__modal__IJoU5",
	"buttons": "TreeItemSelectDialog-component__buttons___XB8U"
};
export default ___CSS_LOADER_EXPORT___;
