import type { TImageUpload } from './image.types';
import type { NodePos } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Image, { ImageOptions } from '@tiptap/extension-image';
import { ImageRenderer } from '../renderers/image/ImageRenderer.component';
import { ImagePastePlugin } from '../plugins/image/ImagePaste.plugin';
import { IMAGE_NODE_NAME } from './constants';

interface IImageOptions extends ImageOptions {
    imageUpload?: TImageUpload;
}

const allowedImageTypes = ['image/gif', 'image/jpg', 'image/png'];

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        [IMAGE_NODE_NAME]: {
            /**
             * Refresh image node
             */
            refreshImage: (imageId: string) => ReturnType;
            /**
             * Remove image node
             */
            removeImage: (imageId: string) => ReturnType;
        };
    }
}

const ExtendedImage = Image.extend<IImageOptions>({
    name: IMAGE_NODE_NAME,

    draggable: false,

    addAttributes() {
        return {
            ...this.parent?.(),
            id: {
                default: null,
                parseHTML: (element) => element.getAttribute('id'),
                renderHTML: (attributes) => {
                    if (!attributes.id) {
                        return {};
                    }

                    return {
                        id: attributes.id,
                    };
                },
            },
            width: {
                default: '100%',
                parseHTML: (element) => {
                    const widthAttr = element.getAttribute('width');

                    if (!widthAttr) {
                        return 0;
                    }

                    // TODO нужно адаптировать под разные величины
                    // const widthNumber = parseInt(widthAttr, 10);
                    // return !isNaN(widthNumber) ? widthNumber : widthAttr;
                    return widthAttr;
                },
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width,
                    };
                },
            },
            height: {
                default: 'auto',
                parseHTML: (element) => {
                    const heightAttr = element.getAttribute('height');

                    if (!heightAttr) {
                        return 0;
                    }

                    // TODO нужно адаптировать под разные величины
                    // const heightNumber = parseInt(heightAttr, 10);
                    // return !isNaN(heightNumber) ? heightNumber : heightAttr;
                    return heightAttr;
                },
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height,
                    };
                },
            },
        };
    },

    // TODO разобраться с типизацией
    // @ts-ignore
    addCommands() {
        return {
            ...this.parent?.(),
            refreshImage:
                (imageId: string) =>
                ({ editor }) => {
                    const images = editor.$nodes(this.name);
                    const uploadingImage = images?.find((image: NodePos) => image.node.attrs['id'] === imageId);

                    if (!uploadingImage) {
                        return false;
                    }

                    const pos = uploadingImage.pos;
                    const node = uploadingImage.node;
                    const { height, id, src, width } = node.attrs;
                    const content = [{ type: node.type.name, attrs: { height, id, src, width } }];

                    setTimeout(() => {
                        editor.commands.deleteRange({ from: pos, to: pos + node.nodeSize });
                        editor.commands.insertContentAt(pos, content);
                    }, 0);

                    return true;
                },
            removeImage:
                (imageId: string) =>
                ({ editor }) => {
                    const images = editor.$nodes(this.name);
                    const uploadingImage = images?.find((image: NodePos) => image.node.attrs['id'] === imageId);

                    if (!uploadingImage) {
                        return false;
                    }

                    const pos = uploadingImage.pos;
                    const node = uploadingImage.node;

                    setTimeout(() => {
                        editor.commands.deleteRange({ from: pos, to: pos + node.nodeSize });
                    }, 0);

                    return true;
                },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(ImageRenderer, { className: this.options.HTMLAttributes.class });
    },

    addProseMirrorPlugins() {
        const editor = this.editor;
        const imageUpload = this.options.imageUpload;

        return imageUpload ? [ImagePastePlugin({ editor, allowedImageTypes, imageUpload })] : [];
    },
});

export { ExtendedImage as Image };
