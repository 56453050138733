import React, { memo, FC } from 'react';
import { Button } from 'antd';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import theme from './CompactableDropdownButton.scss';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import icArrowDown from '../../../../resources/icons/ic-ribbon-arrow-down.svg';

interface ICompactableDropdownButtonProps {
    onClick?: React.FormEventHandler<{}>;
    onMouseUp?: React.FormEventHandler<{}>;
    onMouseDown?: React.FormEventHandler<{}>;
    title: string;
    disabled?: boolean;
    compact?: boolean;
    spriteSymbol?: TSpriteSymbol;
    className?: string;
}

const CompactableDropdownButton: FC<ICompactableDropdownButtonProps> = (props) => {
    const { compact, className, title, disabled, spriteSymbol, onClick, onMouseDown, onMouseUp } = props;

    return (
        <Button
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            disabled={disabled}
            className={className ? ` ${className}` : ''}
        >
            <div className={theme.container}>
                {spriteSymbol && <Icon spriteSymbol={spriteSymbol} disabled={disabled} />}
                {compact ? '' : <span className="title">{title}</span>}
                <Icon spriteSymbol={icArrowDown} className={compact ? theme.arrowCompact : theme.arrow} disabled={disabled} />
            </div>
        </Button>
    );
};

const withMemo = memo(CompactableDropdownButton);

export { withMemo as CompactableDropdownButton };
