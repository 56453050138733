import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ModelAssignment } from '../../../../serverapi/api';
import messages from './messages/DecompositionsListDialog.messages';
import theme from './DecompositionsListDialog.scss';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Table, Form } from 'antd';
import { TreeItemType } from '../../../Tree/models/tree';
import modelTypeMessages from '../../../../models/modelType.messages';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import objectIcon from '../../../../resources/icons/ic-tree-object-org-chart.svg';
import { TDecompositionsListDialogProps } from './DecompositionsListDialog.types';

type TTableRow = {
    key: number;
    id: string | undefined;
    modelName: string | undefined;
    modelPath: string | undefined;
    modelType: string | undefined;
};

const DecompositionsListDialog: FC<TDecompositionsListDialogProps> = (props) => {
    const { open, symbol, modelAssignment, name, serverId, repositoryId, nodeType } = props;
    const intl = useIntl();


    if (!modelAssignment.length) {
        return null;
    }

    const tableData: TTableRow[] = modelAssignment.map((assignment: ModelAssignment, index: number) => {
        return {
            key: index,
            id: assignment.modelId,
            modelName: assignment.modelName,
            modelPath: assignment.modelPath,
            modelType:
                assignment.nodeType === TreeItemType.Matrix ||
                assignment.nodeType === TreeItemType.Wiki ||
                assignment.nodeType === TreeItemType.Spreadsheet
                    ? intl.formatMessage(modelTypeMessages[assignment.nodeType])
                    : assignment.modelTypeName,
        };
    }).sort(((d1, d2) => (d1.modelName || '') > (d2.modelName || '') ? 1 : -1));

    const assignmentColumns = [
        {
            title: intl.formatMessage(messages.modelName),
            dataIndex: 'modelName',
            key: 'modelName',
        },
        {
            title: intl.formatMessage(messages.modelType),
            dataIndex: 'modelType',
            key: 'modelType',
        },
        {
            title: intl.formatMessage(messages.modelPath),
            dataIndex: 'modelPath',
            key: 'modelPath',
        },
    ];

    const renderTitle = () => {
        let title = '';
        if (nodeType === 'OBJECT') {
            title = intl.formatMessage(messages.dialogTitleObjects);
        }
        if (nodeType === 'EDGE') {
            title = intl.formatMessage(messages.dialogTitleEdges);
        }
        

        return (
            <div className={theme.title}>
                {title}
                <div className={theme.subTitle}>
                    {symbol && <img src={symbol.icon} className={theme.subTitleIcon} />}
                    {!symbol && <Icon className={theme.subTitleIcon} spriteSymbol={objectIcon} />}
                    <span className={theme.name}>{name}</span>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Dialog open={open} title={renderTitle()} width={800} footer={null} onCancel={props.onCancel} className={theme.dialog}>
                <FormGroup className={theme.formGroup}>
                    <Form.Item>
                        <div className={theme.titleText}>{intl.formatMessage(messages.dialogLabelSelectModel)}</div>
                        <Table
                            dataSource={tableData}
                            className={theme.table}
                            columns={assignmentColumns}
                            size="middle"
                            bordered
                            pagination={false}
                            onRow={(row: TTableRow) => ({
                                onClick: () => {
                                    if (row.id) {
                                        const rowAssignment = modelAssignment.find((as) => as.modelId === row.id);
                                        props.onOpenDetailing(
                                            { serverId, repositoryId, id: row.id },
                                            rowAssignment?.nodeType as TreeItemType,
                                        );
                                    }
                                },
                            })}
                            scroll={{
                                y: '',
                            }}
                        />
                    </Form.Item>
                </FormGroup>
            </Dialog>
        </div>
    );
};

export { DecompositionsListDialog };
