import React from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import messages from './DeleteEditingCommentDialog.messages';
import { Button } from 'antd';
import { useIntl } from 'react-intl';

type TDeleteEditingCommentDialogProps = {
    onCancel?: () => void;
};

export const DeleteEditingCommentDialog = (props: TDeleteEditingCommentDialogProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const onCancel = () => {
        dispatch(closeDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG));
    };

    const onOk = () => {
        if (props.onCancel) props.onCancel();
        dispatch(closeDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG));
    };

    const footer = [
        <Button key="cancel" size="large" onClick={onCancel}>
            <div>{intl.formatMessage(messages.cancel)}</div>
        </Button>,
        <Button key="ok" size="large" type="primary" danger onClick={onOk}>
            {intl.formatMessage(messages.deleteComment)}
        </Button>,
    ];

    return (
        <Dialog
            open
            onOk={onOk}
            onCancel={onCancel}
            title={intl.formatMessage(messages.title)}
            footer={footer}
        >
            <div>{intl.formatMessage(messages.text)}</div>
        </Dialog>
    );
};
