import React, { useMemo, useState } from 'react';
import { injectIntl, useIntl, WrappedComponentProps } from 'react-intl';
import icModel from '../../../../../../resources/icons/ic-tree-model-active.svg';
import { ModelType, ModelTypeGroup } from '../../../../../../serverapi/api';
import { AddModel, DeleteSelected, TabHeader } from '../Header/TabHeader.component';
import { GroupedTypesTable } from '../util/GroupedTypesTable.component';
import { AddModelTypeDialog } from './AddModelTypeDialog.component';
import messages from './ObjectType.messages';
import { differenceBy, uniqBy } from 'lodash-es';
import theme from './ObjectType.scss';
import { Alert } from 'antd';
import { getMetodologyElementsDeleteMessages } from '../util/metodologyElementsDeleteMessages.utils';

type TDecompositionsTabIntlProps = {
    modelTypes: ModelType[];
    availableModelTypes?: ModelType[];
    allowAnyDecompositionType: boolean;
    modelTypeGroups: ModelTypeGroup[];
    disabled?: boolean;
};

type TDecompositionsTabIntlActionProps = {
    addModelTypes: (modelType: ModelType[]) => void;
    onChangeAllowAnyDecompositionType: (checked: boolean) => void;
    deleteModelTypes: (modelTypes: ModelType[]) => void;
    deleteModelTypeGroups: (modelTypeGroups: ModelTypeGroup[]) => void;
};

type TDecompositionsTabIntlFullProps = TDecompositionsTabIntlProps &
    TDecompositionsTabIntlActionProps &
    WrappedComponentProps;

const DecompositionsTabComponent = (props: TDecompositionsTabIntlFullProps) => {
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [selectedModelTypes, setSelectedAttributeTypes] = useState<ModelType[]>([]);
    const [selectedModelTypeGroups, setSelectedAttributeGroupTypes] = useState<ModelTypeGroup[]>([]);
    const selected = selectedModelTypes.length || selectedModelTypeGroups.length;

    const availableModelTypes: ModelType[] = props.availableModelTypes || [];
    const availableModelTypesGroups: ModelTypeGroup[] = uniqBy(
        availableModelTypes.map((mt) => mt.modelTypeGroup),
        'id',
    );

    const filteredAvailableModelTypes: ModelType[] = useMemo(
        () => differenceBy(props.modelTypes, availableModelTypes, 'id'),
        [props.modelTypes, availableModelTypes],
    );
    const intl = useIntl();
    const { deleteMessage: deleteModelsMessage, deleteGroupsMessage } = getMetodologyElementsDeleteMessages({selectedModelTypes, selectedModelTypeGroups});

    return (
        <div className={theme.container}>
            {dialogVisible && (
                <AddModelTypeDialog
                    modelTypes={filteredAvailableModelTypes}
                    onCancel={() => {
                        setDialogVisible(false);
                    }}
                    dialogVisible={dialogVisible}
                    onOk={(modelTypes) => {
                        setDialogVisible(false);
                        props.addModelTypes(modelTypes);
                    }}
                />
            )}
            <TabHeader
                buttons={[
                    DeleteSelected.build(
                        () => {
                            if (selectedModelTypes.length) {
                                setSelectedAttributeTypes([]);
                                props.deleteModelTypes(selectedModelTypes);
                            }
                            if (selectedModelTypeGroups.length) {
                                setSelectedAttributeGroupTypes([]);
                                props.deleteModelTypeGroups(selectedModelTypeGroups);
                            }
                        },
                        !selected,
                        undefined,
                        intl.formatMessage(messages.deleteModelsDialogTitle),
                        <Alert message={
                            <>
                                {deleteGroupsMessage}
                                {deleteModelsMessage}
                            </>
                        } type="warning" />,
                    ),
                    {
                        children: intl.formatMessage(messages.allowAnyDecomposition),
                        isCheckbox: true,
                        checked: props.allowAnyDecompositionType,
                        onClick: (value) => {
                            const allowAllDecompositions = value?.target?.checked;
                            props.onChangeAllowAnyDecompositionType(allowAllDecompositions);
                        },
                    },
                    AddModel.build(()=>{setDialogVisible(true)}, props.allowAnyDecompositionType)
                ]}
                onSearchChange={setSearchFilter}
            />
            <GroupedTypesTable
                types={availableModelTypes}
                typeGroups={availableModelTypesGroups}
                searchFilter={searchFilter}
                actionsDisabled={!!props.disabled}
                icon={icModel}
                onDeleteType={(type: ModelType) => props.deleteModelTypes([type])}
                onDeleteGroup={(group: ModelTypeGroup) => props.deleteModelTypeGroups([group])}
                onSelectType={setSelectedAttributeTypes as any}
                onSelectGroup={setSelectedAttributeGroupTypes as any}
                columns={[
                    {
                        title: props.intl.formatMessage(messages.description),
                        dataIndex: 'description',
                    },
                ]}
                titleDeleteMessage={messages.deleteModelsDialogTitle}
                deleteGroupMessage={messages.deleteModelGroups}
                deleteElMessage={messages.deleteModels}
            />
        </div>
    );
};

const DecompositionsTabIntl = injectIntl(DecompositionsTabComponent);

export { DecompositionsTabIntl as DecompositionsTab };
