import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import formMessages from '../../../../models/formDefault.messages';
import messages from '../../UserManagement/messages/userManagment.messages';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { Button, Table } from 'antd';
import { TreeNode } from '../../../../models/tree.types';
import { DEFAULT_DIALOG_WIDTH } from '../../../../config/config';
import { TUserDBAccessDialogActionProps, TUserDBAccessDialogProps } from '../UserDBAccessDialog.types';

type TUserDBAccessDialogFullProps = TUserDBAccessDialogActionProps & TUserDBAccessDialogProps & WrappedComponentProps;

class UserDBAccessDialog extends Component<TUserDBAccessDialogFullProps> {
    state = {
        selectedRowKeys: this.props.allowedRepos
            ? this.props.allowedRepos.map((g, i) => g.nodeId.repositoryId || `${i}`)
            : [],
    };

    handleSubmit = () => {
        if (this.state.selectedRowKeys) this.props.onSubmit(this.state.selectedRowKeys);
    };

    addRowDisableClassName = () => {
        return ''; // this.state.difference.includes(record.key) ? admTheme.disabled : '';
    };

    onSelectChange = (selectedRowKeys: any) => {
        this.setState({ selectedRowKeys });
    };

    getColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage(messages.titleCol),
                dataIndex: 'title',
            },
        ];
    };

    getData = (srcData: TreeNode[]) => {
        return Object.keys(srcData).map((k) => ({
            key: srcData[k].nodeId.repositoryId,
            title: srcData[k].name,
        }));
    };

    render() {
        const { intl, onClose, open, repoList } = this.props;
        const { selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const footer = [
            <Button key="cancel" size="large" onClick={onClose}>
                {intl.formatMessage(formMessages.cancelButton)}
            </Button>,
            <Button key="ok" size="large" type="primary" onClick={this.handleSubmit}>
                {intl.formatMessage(formMessages.saveButtonLabel)}
            </Button>,
        ];

        return (
            <Dialog
                onOk={this.handleSubmit}
                onCancel={onClose}
                title={intl.formatMessage(messages.dbAccess)}
                open={open}
                width={DEFAULT_DIALOG_WIDTH}
                footer={footer}
            >
                {repoList && (
                    <Table
                        size="middle"
                        rowSelection={rowSelection}
                        rowClassName={this.addRowDisableClassName}
                        columns={this.getColumns()}
                        dataSource={this.getData(repoList)}
                        pagination={false}
                    />
                )}
            </Dialog>
        );
    }
}

export default injectIntl(UserDBAccessDialog);
