import {
    AUTHORIZATION_FAILED,
    AUTHORIZATION_KEYCLOAK_REQUEST,
    AUTHORIZATION_REQUEST,
    AUTHORIZATION_SUCCESS,
} from '../actionsTypes/authorization.actionTypes';
import { UserDTO, UserProperty } from '../serverapi/api';
import {
    TAuthorizationFailedAction,
    TAuthorizationKeycloakRequestAction,
    TAuthorizationRequestAction,
    TAuthorizationSuccessAction,
} from './authorization.actions.types';

export const login = (
    id: string,
    url: string,
    serverName: string,
    username: string,
    password: string,
    remember: boolean,
    autoLogin: boolean,
): TAuthorizationRequestAction => ({
    type: AUTHORIZATION_REQUEST,
    payload: {
        id,
        url,
        serverName,
        username,
        password,
        remember,
        autoLogin,
    },
});

export const keycloakLogin = (
    accessToken: string,
    rerenderCallback: () => void,
): TAuthorizationKeycloakRequestAction => ({
    type: AUTHORIZATION_KEYCLOAK_REQUEST,
    payload: {
        accessToken,
        rerenderCallback,
    },
});

export const loginCompleted = (user: UserDTO, property: UserProperty): TAuthorizationSuccessAction => ({
    type: AUTHORIZATION_SUCCESS,
    payload: {
        user,
        property,
    },
});

export const loginFailed = (error: string): TAuthorizationFailedAction => ({
    type: AUTHORIZATION_FAILED,
    payload: error,
});
