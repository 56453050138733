import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { select } from 'redux-saga/effects';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { TWorkspaceTab, TWorkspaceTabItemParams } from '../models/tab.types';
import { TServerEntity } from '../models/entities.types';
import {
    NodeId,
    ObjectDefinitionNode,
    ParentModelOfObjectDefinition,
    PrincipalDescriptor,
    Symbol,
} from '../serverapi/api';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { compareNodeIds } from '../utils/nodeId.utils';
import { nodeService } from '../services/NodeService';
import { defaultWorkspaceTabActions } from '../models/tab';
import { EditorMode } from '../models/editorMode';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { ModelSelectors } from '../selectors/model.selectors';
import messages from '../modules/Workspace/components/Workspace/Workspace.messages';
import { LocalesService } from '../services/LocalesService';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';

export function* modelContextByGraphId(graphId: NodeId) {
    if (graphId.id === 'HomePageTab') {
        return null;
    }

    const schema = yield select(TabsSelectors.byId(graphId));

    if (!schema) {
        return null;
    }

    const { params } = schema;
    const serverId = graphId.serverId || params.serverId || params.content?.serverId;
    const graph = instancesBPMMxGraphMap.get(graphId);
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    
    if (!server) return;

    const { api } = server;

    if (graph) {
        graph.bpmMxGraphContext = { ...graph.bpmMxGraphContext, api, serverId };
    }

    return { graph, schema, api, parentNodeId: schema.parentNodeId, nodeId: graphId, notationId: 'bpmNotation' };
}

export function* getActiveModelContext() {
    const scheme = yield select(TabsSelectors.getActiveTab);

    return scheme && scheme.nodeId.id !== 'homePageTab' ? yield modelContextByGraphId(scheme.nodeId) : null;
}

export const checkDeletableDuplicateObj = async (
    obj: ObjectDefinitionImpl | undefined,
    symbol: Symbol | undefined,
    graph: BPMMxGraph,
) => {
    if (!obj) {
        return false;
    }

    if (obj.name !== symbol?.name) {
        // переименование не из дефолтного имени
        return false;
    }

    const cellIdsMap = nodeService().findCellIdsByNodeId(obj.nodeId);
    let allCellIds: string[] = [];

    for (const cellsOfGraph of cellIdsMap.values()) {
        allCellIds = [...allCellIds, ...cellsOfGraph];
    }

    const { cells } = graph.getModel();
    const cellIds = allCellIds.filter((id) => id && ComplexSymbolManager.isObjectCell(cells[id]));

    if (cellIds.length > 1) {
        // экземпляры объекта на фронте, на всех открытых моделях
        return false;
    }

    const node = await nodeService().loadNodeFromServer(obj.nodeId);
    const objectEntries: ParentModelOfObjectDefinition[] | undefined = (node as ObjectDefinitionNode | undefined)
        ?.objectEntries;
    // в objectEntries.entries будет [] в том случае если текущая модель еще не сохранена, а на других моделях нет
    // экземпляров. Значит все экземпляры (ровно 1) на текущей модели и объект можно удалить.
    // Больше 1 быть не может т.к. выполнилось бы предыдущее условие, меньше 1 тоже быть не может т.к.
    // вообще бы эта ф-ция не запустилась
    if (!objectEntries?.length) {
        return true;
    }

    const entriesCount: number = objectEntries.reduce(
        // складываем все экземпляры со всех моделей на бэке
        (summ, entry) => summ + Number(entry.entryCount),
        0,
    );

    if (entriesCount > 1) {
        return false;
    }

    const entryModelId = {
        repositoryId: obj.nodeId.repositoryId,
        id: objectEntries[0].modelId!,
        serverId: obj.nodeId.serverId,
    };
    // в objectEntries.entries[0] хранятся данные по текущей открытой модели
    // если id модели на фронте и id модели в objectEntries.entries[0] не совпадают, значит на бэке еще не сохранена
    // текущая модель, следовательно фронт знает про 1 экземпляр на текущей модели, а бэк знает про 1 экземпляр на какой-то
    // другой не открытой модели, то есть экземпляров 2 и удалять объект нельзя
    if (!compareNodeIds(entryModelId, graph.id)) {
        // сравнение id модели на бэке и фронте
        return false;
    }

    return true;
};

export function* getContentLoadingPageTab(nodeId: NodeId) {
    const loadingTabId = {
        ...nodeId,
        id: nodeId.id.concat('loading'),
    };
    const title: string = yield select(ModelSelectors.getModelNameByNodeId(nodeId));
    const intl = LocalesService.useIntl();
    const contentLoadingPageTab: TWorkspaceTab = {
        title: title || intl.formatMessage(messages.unknownModel),
        nodeId: loadingTabId,
        type: WorkSpaceTabTypes.CONTENT_LOADING_PAGE_TAB,
        mode: EditorMode.Read,
        params: {
            closable: false,
        } as TWorkspaceTabItemParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    return contentLoadingPageTab;
}

export const getUserInitials = (author?: PrincipalDescriptor) =>
    `${author?.name?.[0]?.toUpperCase() || ''}${author?.lastname?.[0]?.toUpperCase() || ''}`;
