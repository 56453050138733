import React, { ChangeEvent, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { FormInstance } from 'antd';
import { ModelType, ModelTypeGroup, InternationalString } from '../../../../../../../serverapi/api';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import {
    changeModelTypeAllowAnyObject,
    changeModelTypeAllowApprovals,
    changeModelTypeAutoCreateEdge,
    changeModelTypeDescription,
    changeModelTypeEnableModel,
    changeModelTypeGroupSymbol,
    changeModelTypeId,
    changeModelTypeName,
    changeModelTypeSynonymsId,
} from '../../../../../../../actions/workspaceTab/editModelTypeWorkspaceTab.actions';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { InputId } from '../../../../../../InputId/InputId.component';
import { InputSynonymsIds } from '../../../../../../InputSynonymsIds/InputSynonymsIds.component';
import { convertStringToArray } from '../../../../../../../utils/convertStringToArray';
import theme from './ModelTypeGeneralTab.scss';
import messages from '../ModelType.messages';
import { Select } from '../../../../../../UIKit/components/Select/Select.component';
import { Checkbox } from '../../../../../../UIKit/components/Checkbox/Checkbox.component';

type TModelTypeGeneralTabProps = {
    modelType: ModelType;
    modelTypeGroups: ModelTypeGroup[];
    createMode: boolean;
    generalForm: FormInstance<any> | undefined;
    onChangeModelTypeGroup: (group: ModelTypeGroup) => void;
    serverId: string;
};

const ModelTypeGeneralTab: FC<TModelTypeGeneralTabProps> = (props) => {
    const dispatch = useDispatch();
    const { createMode, modelType, modelTypeGroups, generalForm, serverId } = props;
    const { presetId } = modelType;
    const modelTypeId = modelType.id;
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);

    const onChangeModelTypeGroup = (groupId: string) => {
        props.onChangeModelTypeGroup(modelTypeGroups.find((mtg) => mtg.id === groupId)!);
    };

    const onChangeModelTypeName = useCallback((multilingualName: InternationalString) => {
        dispatch(changeModelTypeName({ multilingualName, serverId, presetId, modelTypeId }));
    }, []);

    /**
     * useCallback при монтировании компонента кэширует modelTypeId,
     * и при изменении id записывает изменения в нужную ветку редакса.
     */
    const onChangeModelTypeId = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeModelTypeId({ serverId, presetId, modelTypeId, id: e.target.value.trim() }));
    }, []);

    const onChangeModelTypeSynonymsIds = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
        const synonymsIds: string[] = convertStringToArray(e.target.value);

        dispatch(changeModelTypeSynonymsId({ serverId, presetId, modelTypeId, synonymsIds }));
    }, []);

    const onChangeModelTypeDescription = useCallback((multilingualDescription: InternationalString) => {
        dispatch(changeModelTypeDescription({ serverId, presetId, modelTypeId, multilingualDescription }));
    }, []);

    const onChangeModelTypeGroupSymbol = useCallback((groupSymbol: boolean) => {
        dispatch(changeModelTypeGroupSymbol({ serverId, presetId, modelTypeId, groupSymbol }));
    }, []);

    const onChangeModelTypeEnableModel = useCallback((enableModel: boolean) => {
        dispatch(changeModelTypeEnableModel({ serverId, presetId, modelTypeId, enableModel }));
    }, []);

    const onChangeModelTypeAllowAnyObject = useCallback((allowAnyObject: boolean) => {
        dispatch(changeModelTypeAllowAnyObject({ serverId, presetId, modelTypeId, allowAnyObject }));
    }, []);

    const onChangeModelTypeAllowApprovals = useCallback((allowApprovals: boolean) => {
        dispatch(changeModelTypeAllowApprovals({ serverId, presetId, modelTypeId, allowApprovals }));
    }, []);

    const onChangeModelTypeAutoCreateEdge = useCallback((autoCreateEdge: boolean) => {
        dispatch(changeModelTypeAutoCreateEdge({ serverId, presetId, modelTypeId, autoCreateEdge }));
    }, []);

    return (
        <div data-test="model-type-general-tab" className={theme.form}>
            <MultiLangInputDialog
                data-test="model-type-name-input"
                placeholder={intl.formatMessage(messages.name)}
                multiLangValue={modelType.multilingualName}
                onChange={onChangeModelTypeName}
                label={intl.formatMessage(messages.name)}
                mainInputName="multilingualName"
                mainInputClassName={theme.input}
                generalForm={generalForm}
                required
                autoFocus
            />
            <InputId
                disabled={!createMode}
                value={modelType.id}
                required
                mainInputName="GUID"
                mainInputClassName={theme.input}
                onChange={onChangeModelTypeId}
            />
            <div className={theme.selectWrapper}>
                <div className={theme.customRequired}>
                    <Select
                        label={
                            <span className={theme.selectLabel}>{intl.formatMessage(messages.modelTypeGroups)}</span>
                        }
                        data-test="model-type-group-select"
                        onChange={onChangeModelTypeGroup}
                        value={LocalesService.internationalStringToString(
                            modelType.modelTypeGroup?.multilingualName,
                            currentLocale,
                        )}
                        originalTheme
                    >
                        {modelTypeGroups.map((group: ModelTypeGroup) => {
                            return (
                                <Select.Option
                                    key={group.id}
                                    value={group.id}
                                    label={LocalesService.internationalStringToString(
                                        group.multilingualName,
                                        currentLocale,
                                    )}
                                />
                            );
                        })}
                    </Select>
                </div>
            </div>

            <InputSynonymsIds
                value={modelType.synonymsIds}
                mainInputName="synonymsIds"
                mainInputClassName={theme.input}
                onChange={onChangeModelTypeSynonymsIds}
            />
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.description)}
                multiLangValue={modelType.multilingualDescription}
                onChange={onChangeModelTypeDescription}
                textarea
                label={intl.formatMessage(messages.description)}
                mainInputName="multilingualDescription"
                mainInputClassName={theme.textArea}
                generalForm={generalForm}
                data-test="model-type-description-input"
            />
            <Checkbox
                className={theme.firstCheckbox}
                checked={modelType.enableModel}
                onChange={() => onChangeModelTypeEnableModel(!modelType.enableModel)}
            >
                {intl.formatMessage(messages.enableModel)}
            </Checkbox>
            <Checkbox
                className={theme.checkbox}
                checked={modelType.groupSymbol}
                onChange={() => onChangeModelTypeGroupSymbol(!modelType.groupSymbol)}
            >
                {intl.formatMessage(messages.groupSymbol)}
            </Checkbox>
            <Checkbox
                className={theme.checkbox}
                data-test="allow-any-object_checkbox"
                checked={modelType.allowAnyObject}
                onChange={() => onChangeModelTypeAllowAnyObject(!modelType.allowAnyObject)}
            >
                {intl.formatMessage(messages.allowAnyObject)}
            </Checkbox>
            <Checkbox
                className={theme.checkbox}
                checked={modelType.allowApprovals}
                onChange={() => onChangeModelTypeAllowApprovals(!modelType.allowApprovals)}
            >
                {intl.formatMessage(messages.allowApprovals)}
            </Checkbox>
            <Checkbox
                className={theme.checkbox}
                data-test="auto-edge-creation_checkbox"
                checked={modelType.autoCreateEdge}
                onChange={() => onChangeModelTypeAutoCreateEdge(!modelType.autoCreateEdge)}
            >
                {intl.formatMessage(messages.autoCreateEdge)}
            </Checkbox>
        </div>
    );
};

const withMemo = React.memo(ModelTypeGeneralTab);

export { withMemo as ModelTypeGeneralTab };
