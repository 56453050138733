import { Input, Table } from 'antd';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import adminToolTheme from '../../style/AdminTools.scss';
import { EditButton, RowButtons, DeleteButton } from '../../Button/RowButtons';
import upMessages from '../../UserProfiles/messages/userProfiles.messages';
import { SearchOutlined } from '@ant-design/icons';
import { GroupDTO, InternationalString, ServerProfile, UserDTO } from '../../../../serverapi/api';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { DEFAULT_LOCALE } from '../../../../config/config';
import { TUserProfilesActionProps, TUserProfilesProps } from '../UserProfiles.types';
import { LocalesService } from '../../../../services/LocalesService';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TUserProfilesFullProps = TUserProfilesProps & TUserProfilesActionProps & WrappedComponentProps;

type TUserProfilesOwnersTableRecord = {
    key: string;
    multilingualName: InternationalString;
    usersGroups: string;
};

class UserProfiles extends Component<TUserProfilesFullProps> {
    state = {
        searchInput: '',
    };

    componentDidMount() {
        this.props.getServerProfilesData(this.props.serverId);
    }

    getColumns = () => {
        const { intl, serverId } = this.props;

        return [
            {
                title: this.props.intl.formatMessage(upMessages.profileName),
                dataIndex: 'multilingualName',
                sorter: (a: TUserProfilesOwnersTableRecord, b: TUserProfilesOwnersTableRecord) => {
                    const locale = this.props.currentLocale || DEFAULT_LOCALE;

                    return sortByAlpha(a.multilingualName[locale], b.multilingualName[locale]);
                },
                render: (value: InternationalString) =>
                    LocalesService.internationalStringToString(value, this.props.currentLocale),
            },
            {
                title: this.props.intl.formatMessage(upMessages.profileUsersGroupsColTitle),
                dataIndex: 'usersGroups',
            },
            {
                title: '',
                dataIndex: 'manageBtn',
                key: 'manageBtn',
                render: (_, record: TUserProfilesOwnersTableRecord) => (
                    <RowButtons
                        className={adminToolTheme.userProfilesRowButtons}
                        buttons={[
                            EditButton.build(() => {
                                this.props.onEditClicked(record.key, serverId);
                            }, undefined, undefined, undefined, true),
                            DeleteButton.build(
                                () => {
                                    this.props.handleDeleteServerProfile({ profileId: record.key, serverId });
                                },
                                undefined,
                                undefined,
                                undefined,
                                intl.formatMessage(upMessages.deleteUserProfileQuestion),
                                `${intl.formatMessage(upMessages.deleteUserProfileContent)} ${record.usersGroups} ?`,
                            ),
                        ]}
                    />
                ),
            },
        ];
    };

    prepareGroups = (profileId: string) => {
        const { owners } = this.props;

        return owners?.[profileId]?.groups.map((g: GroupDTO) => g.groupName).sort() || [];
    };

    prepareUsers = (profileId: string) => {
        const { owners } = this.props;

        return owners?.[profileId]?.users.map((u: UserDTO) => u.login).sort() || [];
    };

    getData = () => {
        let filteredServerProfiles: ServerProfile[] = [];
        filteredServerProfiles =
            this.state.searchInput === ''
                ? this.props?.serverProfiles
                : this.props?.serverProfiles.filter((p: ServerProfile) => {
                    const groups = this.prepareGroups(p.id).join(', ');
                    const users = this.prepareUsers(p.id).join(', ');
                    const name = p.multilingualName
                        ? LocalesService.internationalStringToString(p.multilingualName, this.props.currentLocale)
                        : '';

                    return (
                        [name, p.id, groups, users]
                            .filter((f) => f)
                            .map((f) => `${f}`.toLowerCase())
                            .filter((f) => f.includes(this.state.searchInput)).length > 0
                    );
                });

        return filteredServerProfiles?.map((p: ServerProfile) => {
            const groups = this.prepareGroups(p.id).join(', ');
            const users = this.prepareUsers(p.id).join(', ');

            return {
                key: p.id,
                multilingualName: p.multilingualName,
                usersGroups: `${groups}\r\n${users}`,
            };
        });
    };

    handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== this.state.searchInput) {
            this.setState({
                searchInput: e.target.value.trim().toLowerCase(),
            });
        }
    };

    render() {
        const { intl } = this.props;

        return (
            <>
                <div className={adminToolTheme.contentHeader}>
                    <div style={{ flexGrow: 1 }}>
                        <Input suffix={<SearchOutlined />} onChange={this.handleSearch} style={{ width: 200 }} />
                    </div>
                    <div style={{ flexGrow: 1, textAlign: 'right' }}>
                        <Button
                            dataTest="user-profiles_add-new-profile"
                            key="ok"
                            size="large"
                            visualStyle="primary"
                            onClick={() => this.props.addNewProfile()}
                        >
                            {intl.formatMessage(upMessages.profileAddBtnLabel)}
                        </Button>
                    </div>
                </div>
                <Table
                    className={adminToolTheme.dataTable}
                    columns={this.getColumns()}
                    dataSource={this.getData()}
                    pagination={false}
                    scroll={{
                        y: 'max-content',
                    }}
                />
            </>
        );
    }
}

export default injectIntl(UserProfiles);
