import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../messages/CommonToolbar.messages';
import classnames from 'classnames';
import { Button, Tooltip } from 'antd';
import menuItemTheme from '../items/MenuItem.scss';
import { NodeId } from '../../../../serverapi/api';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { EditorMode } from '../../../../models/editorMode';
import icReadMode from '../../../../resources/icons/ic-read-mode.svg';
import icEditMode from '../../../../resources/icons/ic-edit-mode.svg';
import icGridBtn from '../../../../resources/icons/toolbar/dashboard-grid-button.svg';
import theme from './DashboardEditorToolbar.scss';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { dashboardEditorModeChanged } from '@/actions/dashboard.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';

type TDashboardEditorToolbarProps = {
    compact?: boolean | undefined;
    id: NodeId;
};

export const DashboardEditorToolbar: FC<TDashboardEditorToolbarProps> = (props) => {
    const activeWorkspaceTab = useSelector(TabsSelectors.getActiveTab);
    const isActiveScheme: boolean | undefined = !!useSelector(TabsSelectors.getActiveTabId);
    const dispatch = useDispatch();
    const intl = useIntl();
    const editorMode = activeWorkspaceTab ? activeWorkspaceTab.mode : undefined;
    const className = classnames(menuItemTheme.container, {
        [menuItemTheme.container_compact]: props.compact,
        [menuItemTheme.toolbar_disabled]:
            activeWorkspaceTab === undefined ||
            (activeWorkspaceTab && activeWorkspaceTab.type === 'HomePageTab') ||
            activeWorkspaceTab.type === 'ContentLoadingPageTab',
    });
    const groupClassName = classnames(menuItemTheme.group, 'group');
    const isReadMode = activeWorkspaceTab && activeWorkspaceTab.mode === EditorMode.Read;
    const editModeTooltipeTitle = isReadMode
        ? intl.formatMessage(messages.toEditMode)
        : intl.formatMessage(messages.toReadMode);

    const gridTooltipe = isReadMode
        ? intl.formatMessage(messages.onlyInEditModeAvailable)
        : intl.formatMessage(messages.dashboardGridTooltip);

    const handleModeChange = () => {
        dispatch(dashboardEditorModeChanged(editorMode === EditorMode.Edit ? EditorMode.Read : EditorMode.Edit));
    };

    const handleGridButton = () => {
        dispatch(openDialog(DialogType.DASHBOARD_CELLS_COUNT_DIALOG, { nodeId: activeWorkspaceTab?.nodeId }));
    };

    return (
        <div className={className}>
            <div className={groupClassName}>
                <div className={!props.compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        title={isActiveScheme ? editModeTooltipeTitle : ''}
                    >
                        <Button
                            className={theme.modeButton}
                            onClick={handleModeChange}
                            data-test="dashboard-toolbar_edit-model-button"
                        >

                            <Icon spriteSymbol={editorMode === EditorMode.Edit ? icEditMode : icReadMode} />
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className={menuItemTheme.divider} />

            <div className={menuItemTheme.group}>
                <span className={!props.compact ? theme.modeGroup_full : undefined}>
                    <Tooltip mouseLeaveDelay={0} placement="topLeft" title={gridTooltipe}>
                        <Button
                            disabled={editorMode === EditorMode.Read}
                            className={theme.gridButton}
                            onClick={handleGridButton}
                            data-test="dashboard-toolbar_edit-grid-button"
                        >
                            <Icon spriteSymbol={icGridBtn} />
                            {intl.formatMessage(messages.dashboardGrid)}
                        </Button>
                    </Tooltip>
                </span>
            </div>
        </div>
    );
};
