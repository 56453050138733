import React, { FC, useEffect, useState } from 'react';
import menuItemTheme from '../items/MenuItem.scss';
import messages from '../../messages/CommonToolbar.messages';
import { AlignSelect } from './ToolbarItems/AlignSelect/AlignSelect.component';
import { MoveLayerSelect } from './ToolbarItems/MoveLayerSelect/MoveLayerSelect.component';
import { InsertSpaceSelect } from './ToolbarItems/InsertSpaceSelect/InsertSpaceSelect.component';
import cx from 'classnames';
import { WrappedComponentProps, useIntl } from 'react-intl';
import { MoveToSelect } from './ToolbarItems/MoveToSelect/MoveToSelect.component';
import { EditorMode } from '../../../../models/editorMode';
import { BpmNodeFilterBase } from '../../../../models/bpmNodeFilterBase';
import { IWorkspaceTabItemModelParams, TWorkspaceTab } from '../../../../models/tab.types';
import { PickOutSelect } from './ToolbarItems/PickOutSelect/PickOutSelect.component';
import { InsertionButton } from './ToolbarItems/InsertionButton/InsertionButton.component';
import { ModelTypes } from '../../../../models/ModelTypes';
import { METHODOLOGY_SETTINGS_TYPES_ARR } from '../../../Workspace/workspaceTab.constants';
import { WorkSpaceTabTypes } from '../../../Workspace/WorkSpaceTabTypesEnum';
import { AdminToolTreeType } from '../../../AdminTools/data/admintool.types';
import { MxClipboard } from '@/mxgraph/mxgraph';
import { ComplexSymbolManager } from '../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { useSelector } from 'react-redux';
import {
    DiagramElement,
    EdgeDefinitionNode,
    EdgeInstance,
    ModelNode,
    NodeId,
    ObjectInstance,
    ShapeInstance,
} from '../../../../serverapi/api';
import { instancesBPMMxGraphMap } from '../../../../mxgraph/bpm-mxgraph-instance-map';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { ObjectDefinitionImpl } from '../../../../models/bpm/bpm-model-impl';
import { getCopiedElements, getSelectedCellIds } from '../../../../selectors/editor.selectors';
import { ObjectDefinitionSelectors } from '../../../../selectors/objectDefinition.selectors';
import { UserProfileSelectors } from '../../../../selectors/userProfile.selectors';
import { noop } from 'lodash-es';
import { ProfileBllService } from '../../../../services/bll/ProfileBllService';
import { EditModeButton } from './ToolbarItems/EditModeButton/EditModeButton';
import { CopyButton } from './ToolbarItems/CopyButton/CopyButton.component';
import { CutButton } from './ToolbarItems/CutButton/CutButton.component';
import { PrintButton } from './ToolbarItems/PrintButton/PrintButton.component';
import { DeleteButton } from './ToolbarItems/DeleteButton/DeleteButton.component';
import { PropertyButton } from './ToolbarItems/PropertyButton/PropertyButton.component';
import { DecompositionButton } from './ToolbarItems/DecompositionButton/DecompositionButton.component';
import { UpdateButton } from './ToolbarItems/UpdateButton/UpdateButton.component';
import { SearchButton } from './ToolbarItems/SearchButton/SearchButton.component';
import { FilterCheckbox } from './ToolbarItems/FilterCheckbox/FilterCheckbox.component';
import { SwitchGridLayout } from './ToolbarItems/SwitchGridLayout/SwitchGridLayout.component';
import { SwitchFilterModeSelect } from './ToolbarItems/SwitchFilterModeSelect/SwitchFilterModeSelect.component';
import { ToolbarButtonsBllService } from '../../../../services/bll/ToolbarButtonsBllService';
import { EdgeDefinitionSelectors } from '../../../../selectors/edgeDefinition.selector';
import { ModelSelectors } from '../../../../selectors/model.selectors';
import { createCommonTooltipMessage } from '../../utils';
import { getCopyableCells } from '@/services/bll/BpmMxEditorBLLService';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';
import { TSelectedCellElements } from '@/services/bll/ToolbarButtonsBllService.types';

type TGraphGeneralToolbarProps = WrappedComponentProps & {
    compact?: boolean;
    nodeId: NodeId;
};

export const GraphGeneralToolbar: FC<TGraphGeneralToolbarProps> = (props) => {
    const intl = useIntl();
    const [isEmptyClipboard, setIsEmptyClipboard] = useState<boolean>(MxClipboard.isEmpty());

    const { compact, nodeId } = props;
    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(nodeId);

    useEffect(() => {
        if (nodeId && activeGraph) {
            const clipboardUpdate = () => {
                setIsEmptyClipboard(MxClipboard.isEmpty());
            };
            activeGraph.selectionModel.addListener('CLIPBOARD_UPDATE', clipboardUpdate);

            return () => {
                activeGraph.selectionModel.removeListener(clipboardUpdate);
            };
        }

        return noop;
    }, [nodeId, activeGraph]);

    const selectedCellsIds: string[] = useSelector(getSelectedCellIds);
    const { rawSelectedCells, selectedCellsWithoutLabels }: TSelectedCellElements =
        ToolbarButtonsBllService.getRawSelectedCellsAndSelectedCellsWithoutLabels(activeGraph, selectedCellsIds);
    const params = useSelector(TabsSelectors.getActiveTabParams) as IWorkspaceTabItemModelParams | undefined;
    const {
        nodeFilterBase = BpmNodeFilterBase.Off,
        nodeFilterInput,
        nodeFilterOutput,
        modelType,
    } = params || ({} as IWorkspaceTabItemModelParams);
    const tabNodeId = useSelector(TabsSelectors.getActiveTab)?.nodeId;

    const { serverId, repositoryId } = nodeId;
    const activeWorkspaceTab = useSelector(TabsSelectors.getActiveTab);
    const copiedElements = useSelector(getCopiedElements);
    const isWhiteBoard: boolean = activeGraph?.modelType?.id === ModelTypes.MIND_MAP;
    const hasCopiedElements = copiedElements.length > 0 || (isWhiteBoard && !isEmptyClipboard);
    const userProfile = useSelector(UserProfileSelectors.selectUserProfileByNodeId(nodeId));
    const model: ModelNode | undefined = useSelector(ModelSelectors.byId(nodeId));

    let firstSelectedCell;
    let selectedCellsCount = 0;
    let selectedObjectsCount = 0;
    let selectedEdgesCount = 0;

    let hasNonEditableSelectedObject: boolean = false;
    let hasNonEditableSelectedEdge: boolean = false;
    let hasNonDeletableSelectedEdge: boolean = false;
    let hasSelectedShapesWithoutImage: boolean = false;

    rawSelectedCells.forEach((cell) => {
        const cellValue = cell.getValue();

        if (ComplexSymbolManager.isCellStyleEditable(cell)) {
            firstSelectedCell = !firstSelectedCell ? cell : firstSelectedCell;
            selectedCellsCount = selectedCellsCount + 1;
            selectedObjectsCount = cellValue.type === 'object' ? selectedObjectsCount + 1 : selectedObjectsCount;
            selectedEdgesCount = cellValue.type === 'edge' ? selectedEdgesCount + 1 : selectedEdgesCount;
        }

        if (cellValue?.type === 'object') {
            hasNonEditableSelectedObject =
                hasNonEditableSelectedObject ||
                (!!userProfile && !ProfileBllService.isSymbolEditable(userProfile, cell.getValue()?.idSymbol));
        } else if (cellValue?.type === 'edge') {
            hasNonEditableSelectedEdge =
                hasNonEditableSelectedEdge ||
                (!!userProfile && !ProfileBllService.isEdgeEditable(userProfile, cell.getValue()?.edgeTypeId));

            hasNonDeletableSelectedEdge =
                hasNonDeletableSelectedEdge ||
                (!!userProfile && !ProfileBllService.isEdgeDeletable(userProfile, cell.getValue()?.edgeTypeId));
        } else if (cellValue?.type === 'shape') {
            hasSelectedShapesWithoutImage = hasSelectedShapesWithoutImage || !cell.getValue()?.imageId;
        }
    });

    const hasOneOrMoreCellsSelected = selectedCellsCount > 0;
    const hasOneCellSelected = selectedCellsCount === 1;
    const hasMoreThanOneCellsSelected = selectedCellsCount > 1;
    const minTwoObjectsSelected = selectedObjectsCount >= 2;
    const minThreeObjectsSelected = selectedObjectsCount >= 3;
    const onlyEdgesSelected = selectedEdgesCount === rawSelectedCells.length;

    const selectedCellValue: DiagramElement = firstSelectedCell?.getValue();
    const hasOneObjSelected = hasOneCellSelected && selectedCellValue?.type === 'object';
    const hasOneShapeWithImageId: boolean =
        hasOneCellSelected && selectedCellValue?.type === 'shape' && !!(selectedCellValue as ShapeInstance).imageId;
    const hasOneEdgeWithDefinition: boolean =
        hasOneCellSelected &&
        selectedCellValue?.type === 'edge' &&
        !!(selectedCellValue as EdgeInstance).edgeDefinitionId;
    const oneEdgeOrObjectSelected =
        hasOneCellSelected && (selectedCellValue?.type === 'object' || selectedCellValue?.type === 'edge');

    const objectDefinition: ObjectDefinitionImpl | undefined = useSelector(
        ObjectDefinitionSelectors.byId({
            id: (selectedCellValue as ObjectInstance | undefined)?.objectDefinitionId || '',
            serverId,
            repositoryId,
        }),
    );
    const edgeDefinition: EdgeDefinitionNode | undefined = useSelector(
        EdgeDefinitionSelectors.byId({
            id: (selectedCellValue as EdgeInstance | undefined)?.edgeDefinitionId || '',
            serverId,
            repositoryId,
        }),
    );
    const modelAssignments = objectDefinition?.modelAssignments || edgeDefinition?.modelAssignments || [];

    const isSequenceGraph: boolean = activeGraph?.modelType?.id === ModelTypes.SEQUENCE_DIAGRAM;
    const isActiveScheme: boolean = !!activeWorkspaceTab?.nodeId?.id;
    const isReadMode: boolean = activeWorkspaceTab?.mode === EditorMode.Read;
    const isActiveToolbar = activeWorkspaceTab && isActiveScheme;
    const isPrintable: boolean = !!model?.printable;

    const showMoveToTreeBtn =
        hasOneObjSelected || selectedCellsCount === 0 || hasOneShapeWithImageId || hasOneEdgeWithDefinition;
    const hasModelAssignments = modelAssignments.length > 0;
    const hasTransition: boolean = showMoveToTreeBtn || hasModelAssignments;
    const hasCopyableCells = !!activeGraph && getCopyableCells(activeGraph, rawSelectedCells).length > 0;
    const isToolbarDisabled =
        activeWorkspaceTab === undefined ||
        (activeWorkspaceTab &&
            ((activeWorkspaceTab as TWorkspaceTab).type === WorkSpaceTabTypes.HOME_PAGE_TAB ||
                (activeWorkspaceTab as TWorkspaceTab).type === WorkSpaceTabTypes.CONTENT_LOADING_PAGE_TAB ||
                (activeWorkspaceTab as TWorkspaceTab).type === WorkSpaceTabTypes.SCRIPT_DASHBOARD ||
                (activeWorkspaceTab as TWorkspaceTab).type === WorkSpaceTabTypes.SCRIPT_SCHEDULER ||
                (activeWorkspaceTab as TWorkspaceTab).type === WorkSpaceTabTypes.SIMULATION_MODELING ||
                (activeWorkspaceTab as TWorkspaceTab).type === WorkSpaceTabTypes.DB_SEARCH ||
                (activeWorkspaceTab as TWorkspaceTab).type === WorkSpaceTabTypes.COMPARE_MODELS ||
                (activeWorkspaceTab as TWorkspaceTab).type === WorkSpaceTabTypes.GRAPHICAL_COMPARISON ||
                nodeId?.repositoryId === AdminToolTreeType.ADMIN_TOOL_ROOT ||
                METHODOLOGY_SETTINGS_TYPES_ARR.some((type) => type === (activeWorkspaceTab as TWorkspaceTab).type)));

    const {
        editModeBtnDisabled,
        pasteBtnDisabled,
        copyBtnDisabled,
        printBtnDisabled,
        cutBtnDisabled,
        deleteBtnDisabled,
        insertSpaceBtnDisabled,
        alignBtnDisabled,
        moveBtnDisabled,
        propertyBtnDisabled,
        decompositionBtnDisabled,
        updateBtnDisabled,
        pickOutBtnDisabled,
        searchBtnDisabled,
        moveToBtnDisabled,
        switchFilterModeBtnDisabled,
        filterCheckboxDisabled,
    } = ToolbarButtonsBllService.buttonsVisibility({
        activeWorkspaceTab,
        isActiveToolbar,
        isReadMode,
        hasCopiedElements,
        hasCopyableCells,
        isPrintable,
        hasNonEditableSelectedObject,
        hasNonEditableSelectedEdge,
        hasNonDeletableSelectedEdge,
        hasOneCellSelected,
        hasOneOrMoreCellsSelected,
        isWhiteBoard,
        oneEdgeOrObjectSelected,
        hasTransition,
        hasSelectedShapesWithoutImage,
        hasMoreThanOneCellsSelected,
        minTwoObjectsSelected,
        onlyEdgesSelected,
        isSequenceGraph,
        edgeDefinition,
    });

    const className = cx(menuItemTheme.container, {
        [menuItemTheme.container_compact]: compact,
        [menuItemTheme.toolbar_disabled]: isToolbarDisabled,
    });

    const createSearchTooltipMessage = () => {
        const title = compact ? intl.formatMessage(messages.search) : '';

        return isActiveToolbar && isReadMode ? title : intl.formatMessage(messages.onlyInReadModeAvailable);
    };

    const createAlignTooltipMessage = () => {
        if (isSequenceGraph) return intl.formatMessage(messages.sequenceAlignDisabled);

        return !isReadMode ? intl.formatMessage(messages.selectMinTwoElementsOnCanvas) : '';
    };

    const createTooltipMessage = (message: string, disabled: boolean | undefined, disabledMessage: string) => {
        if (isSequenceGraph && disabled) return `${message} ${intl.formatMessage(messages.sequenceActionDisabled)}`;
        if (isReadMode) return intl.formatMessage(messages.selectElementInEditorMode);

        return createCommonTooltipMessage(message, disabled, disabledMessage);
    };

    const createPrintTooltipMessage = () => {
        if (!isActiveToolbar) {
            return '';
        }

        return intl.formatMessage(isPrintable ? messages.print : messages.noAccessToPrint);
    };

    return (
        <div className={className}>
            <div className={menuItemTheme.group}>
                <EditModeButton
                    nodeId={nodeId}
                    compact={compact}
                    isReadMode={isReadMode}
                    isActiveScheme={isActiveScheme}
                    modelTypeId={modelType?.id}
                    unlockDisabled={editModeBtnDisabled}
                />
            </div>

            <div className={menuItemTheme.divider} />

            <div className={menuItemTheme.group}>
                <InsertionButton
                    compact={compact}
                    disabled={pasteBtnDisabled}
                    isActiveScheme={isActiveScheme}
                    isWhiteBoard={isWhiteBoard}
                    tooltipTitle={createTooltipMessage(
                        compact ? intl.formatMessage(messages.paste) : '',
                        pasteBtnDisabled,
                        intl.formatMessage(messages.clipboardIsEmpty),
                    )}
                />
            </div>
            <div className={menuItemTheme.editGroup}>
                <span className={menuItemTheme.spanGroupRow}>
                    <CopyButton
                        disabled={copyBtnDisabled}
                        tooltipTitle={createTooltipMessage(
                            intl.formatMessage(messages.copy),
                            copyBtnDisabled,
                            intl.formatMessage(messages.selectElementOnCanvas),
                        )}
                    />
                    <PrintButton
                        disabled={printBtnDisabled}
                        tooltipTitle={createPrintTooltipMessage()}
                        nodeId={nodeId}
                    />
                </span>
                {!compact && <br />}
                <span className={menuItemTheme.spanGroupRow}>
                    <CutButton
                        disabled={cutBtnDisabled}
                        tooltipTitle={createTooltipMessage(
                            intl.formatMessage(messages.cut),
                            cutBtnDisabled,
                            intl.formatMessage(messages.selectElementOnCanvas),
                        )}
                    />
                    <DeleteButton
                        disabled={deleteBtnDisabled}
                        tooltipTitle={createTooltipMessage(
                            intl.formatMessage(messages.toBasket),
                            deleteBtnDisabled,
                            intl.formatMessage(messages.selectElementOnCanvas),
                        )}
                    />
                </span>
            </div>

            <div className={menuItemTheme.divider} />

            <div className={menuItemTheme.group}>
                <SwitchGridLayout compact={compact} />
                <InsertSpaceSelect compact={compact} disabled={insertSpaceBtnDisabled} />
            </div>

            <div className={menuItemTheme.divider} />

            <div className={menuItemTheme.group}>
                <AlignSelect
                    compact={compact}
                    disabledTitle={createAlignTooltipMessage()}
                    disabled={alignBtnDisabled}
                    minThreeObjectsSelected={minThreeObjectsSelected}
                />

                <MoveLayerSelect compact={compact} disabled={moveBtnDisabled} isReadMode={isReadMode} />
            </div>

            <div className={menuItemTheme.divider} />

            <div className={menuItemTheme.group}>
                <PropertyButton
                    disabled={propertyBtnDisabled}
                    tooltipTitle={isActiveToolbar && compact ? intl.formatMessage(messages.textProperty) : ''}
                    selectedCells={selectedCellsWithoutLabels}
                    tabNodeId={tabNodeId}
                />

                <DecompositionButton
                    createTooltipMessage={createTooltipMessage}
                    oneEdgeOrObjectSelected={oneEdgeOrObjectSelected}
                    disabled={decompositionBtnDisabled}
                    selectedCells={selectedCellsWithoutLabels}
                    tabNodeId={tabNodeId}
                    compact={compact}
                />
            </div>

            <div className={menuItemTheme.divider} />

            <div className={menuItemTheme.group}>
                <UpdateButton disabled={updateBtnDisabled} isReadMode={isReadMode} compact={compact} />
                <PickOutSelect compact={compact} disabled={pickOutBtnDisabled} />
            </div>

            <div className={menuItemTheme.group}>
                <SearchButton
                    disabled={searchBtnDisabled}
                    nodeId={nodeId}
                    tooltipTitle={createSearchTooltipMessage()}
                />

                <MoveToSelect
                    compact={compact}
                    disabled={moveToBtnDisabled}
                    hasModelAssignments={hasModelAssignments}
                    showMoveToTreeBtn={showMoveToTreeBtn}
                />
            </div>

            <div className={menuItemTheme.divider} />

            <div className={menuItemTheme.group}>
                <SwitchFilterModeSelect
                    compact={compact}
                    disabled={switchFilterModeBtnDisabled}
                    nodeFilterBase={nodeFilterBase}
                    tabNodeId={tabNodeId}
                />

                <FilterCheckbox
                    compact={compact}
                    disabled={filterCheckboxDisabled}
                    isActiveToolbar={isActiveToolbar}
                    nodeFilterInput={nodeFilterInput}
                    nodeFilterOutput={nodeFilterOutput}
                    tabNodeId={tabNodeId}
                />
            </div>
        </div>
    );
};
