import React from 'react';
import { Button } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../UIKit/components/Dialog/Dialog.types';
import messages from '../messages/DecompositionUnsavedModelDialog.messages';
import icWarning from '../../../resources/icons/ic-warning.svg';
import theme from './DecompositionUnsavedModelDialog.scss';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { TDecompositionUnsavedModelDialogProps } from './DecompositionUnsavedModelDialog.types';

class RawDecompositionUnsavedModelDialog extends React.Component<TDecompositionUnsavedModelDialogProps> {
    handleSubmit = () => {
        const { onSubmit, onClose } = this.props;
        onSubmit();
        onClose();
    };

    handleCancel = () => {
        const { onCancel, onClose } = this.props;
        onCancel();
        onClose();
    };

    render() {
        const { open, onClose } = this.props;

        const dialogProps: Partial<TDialogProps> = {
            open,
            onCancel: onClose,
            title: <FormattedMessage id={messages.title.id} />,
            onOk: this.handleSubmit,
        };

        const footer = [
            <Button key="cancel" size="large" onClick={this.handleCancel}>
                <div className={theme.cancel}>
                    <Icon spriteSymbol={icWarning} className={theme.cancelIcon} />
                    <FormattedMessage id={messages.cancelText.id} />
                </div>
            </Button>,
            <Button key="ok" size="large" type="primary" onClick={this.handleSubmit}>
                <FormattedMessage id={messages.submitText.id} />
            </Button>,
        ];

        return (
            <Dialog {...dialogProps} footer={footer} className={theme.dialog}>
                <div className={theme.content}>
                    <FormattedMessage id={messages.text.id} />
                </div>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(RawDecompositionUnsavedModelDialog);

export { IntlComponent as DecompositionUnsavedModelDialog };
