import React, { FC, useState, useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useIntl } from 'react-intl';
import messages from './DropdownButton.messages';
import theme from './DropdownButton.scss';
import { TButtonAction } from './DropdownButton.types';

type TDropdownButtonProps = {
    actions: TButtonAction[];
    disabled?: boolean;
};

export const DropdownButton: FC<TDropdownButtonProps> = (props) => {
    const { actions, disabled } = props;
    const intl = useIntl();
    const [currentActionIndex, setCurrentActionIndex] = useState(0);

    const items = useMemo(
        () =>
            actions.map((action, index) => ({
                key: action.title,
                label: action.title,
                onClick: () => setCurrentActionIndex(index),
                disabled,
            })),
        [actions, disabled],
    );

    return (
        <div className={theme.container}>
            <label className={theme.buttonLabel}>{intl.formatMessage(messages.action)}</label>
            <Dropdown.Button
                className={theme.dropdownButton}
                type="primary"
                disabled={disabled}
                icon={<DownOutlined className={theme.downOutlined} />}
                menu={{ items, className: theme.dropdownMenu }}
                trigger={['click']}
                onClick={() => actions[currentActionIndex].onClick()}
                destroyPopupOnHide
            >
                {actions[currentActionIndex].title}
            </Dropdown.Button>
        </div>
    );
};
