import type { DiagramElement, InternationalString, NodeId, Symbol } from '../serverapi/api';
import type { MxCell, MxPoint } from '../mxgraph/mxgraph';
import { Alignment } from '../models/alignment';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { EditorMode } from '../models/editorMode';
import { Grouping } from '../models/grouping';
import { SpaceAction } from '../models/insertionSpace';
import { MoveTo } from '../models/move-to';
import { MoveLayer } from '../models/movelayer';
import { PickOut } from '../models/pick-out';
import { KeyCodes } from '../utils/keys';
import { TreeNode } from '../models/tree.types';
import {
    CHANGE_SYMBOL_FOR_CELL,
    EDITOR_ADD_BPMN_TABLE_ROW,
    EDITOR_ADD_TABLE_COLUMN,
    EDITOR_ADD_TABLE_ROW,
    EDITOR_ALIGN,
    EDITOR_DISTRIBUTE,
    EDITOR_CELL_ADDED,
    EDITOR_CELL_COLOR_PICKED,
    EDITOR_CHANGE_EDGE_COLOR,
    EDITOR_CHANGE_EDGE_STYLE,
    EDITOR_CHANGE_EDGE_TYPE,
    EDITOR_CHANGE_FONT_COLOR,
    EDITOR_SET_CELL_DEFAULT_STYLE,
    EDITOR_COPY,
    EDITOR_CREATED,
    EDITOR_DELETE,
    EDITOR_DESTROY,
    EDITOR_DRAG_SHAPE,
    EDITOR_DROP_PASTE_PREVIEW,
    EDITOR_ESCAPE,
    EDITOR_FORMAT_BY_EXAMPLE,
    EDITOR_GROUPING,
    EDITOR_INIT,
    EDITOR_LABEL_CHANGED,
    EDITOR_MODE_CHANGED,
    EDITOR_MODE_CHANGED_PREPARE,
    EDITOR_MOVE_LAYER,
    EDITOR_MOVE_OBJECT,
    EDITOR_MOVE_TO,
    EDITOR_MOVE_TO_DIRECT,
    EDITOR_SINGLE_CELL_SELECTED,
    EDITOR_PASTE,
    EDITOR_PICK_OUT,
    EDITOR_REMOVE_BPMN_TABLE_ROW,
    EDITOR_REMOVE_TABLE_COLUMN,
    EDITOR_REMOVE_TABLE_ROW,
    EDITOR_SELECT_ALL,
    EDITOR_SET_FOCUS_AND_START_EDIT_LABEL,
    EDITOR_SET_OBJECT_TO_CELL,
    EDITOR_SET_REPOSITORY_ID_WHERE_COPIED_FROM,
    EDITOR_SET_COPIED_ELEMENTS,
    EDITOR_UPDATE,
    EDITOR_ZOOM_IN,
    EDITOR_ZOOM_OUT,
    EDITOR_DROP_FILE_NODE,
    DELETE_SELECTED_CELLS_FROM_ACTIVE_GRAPH,
    EDITOR_CHANGE_EDGE_MULTILINGUAL_NAME,
    EDITOR_DRAG_NODE,
    EDITOR_SELECTION_MODEL_CHANGE,
    EDITOR_PROCESS_SPACE_ACTION,
    EDITOR_CUT,
    EDITOR_COPY_WHITEBOARD,
    EDITOR_PASTE_WHITEBOARD,
    CREATE_NEW__OBJECT_NAME,
    OPEN_SEARCH_ACTION,
    OPEN_ELEMENT_PROPERTIES,
    OPEN_BD_SEARCH_ACTION,
    EDITOR_SELECT_SYMBOL_TO_REPLACE_ON_PASTE_SUBMIT,
    COPY_MODEL_LINK,
    MODEL_PUBLISH,
    MODEL_UNPUBLISH,
} from '../actionsTypes/editor.actionTypes';
import {
    TChangeSymbolForCellAction,
    TEditorAddBpmnTableRowAction,
    TEditorAddTableColumnAction,
    TEditorAddTableRowAction,
    TEditorAlignAction,
    TEditorCellAdded,
    TEditorCellColorPicked,
    TEditorChangeEdgeStyleAction,
    TEditorSetCellDefaultStyle,
    TEditorSetCellDefaultStylePayload,
    TEditorCopyAction,
    TEditorCreatedAction,
    TEditorCreatedPayload,
    TEditorDeleteAction,
    TEditorDestroyAction,
    TEditorDestroyPayload,
    TEditorDistributeAction,
    TEditorDragShapeAction,
    TEditorDropPastePreviewAction,
    TEditorEscapeAction,
    TEditorFormatByExample,
    TEditorGropingAction,
    TEditorInitAction,
    TEditorInitPayload,
    TEditorLabelChanged,
    TEditorModeChangedAction,
    TEditorModeChangedPrepareAction,
    TEditorMoveAction,
    TEditorMoveObjectAction,
    TEditorMoveToAction,
    TEditorMoveToDirectAction,
    TEditorSingleCellSelected,
    TEditorPasteAction,
    TEditorPickOutAction,
    TEditorRemoveBpmnTableRowAction,
    TEditorRemoveTableColumnAction,
    TEditorRemoveTableRowAction,
    TEditorSelectAllAction,
    TEditorSetFocusAndStartEditLabel,
    TEditorSetObjectToCell,
    TEditorUpdateAction,
    TDropFileNodeAction,
    TSetRepositoryIdWhereCopiedFromAction,
    TEditorSetCopiedElementsAction,
    TZoomAction,
    TDeleteSelectedCellsFromActiveGraphAction,
    TEditorDragNodePayload,
    TEditorDragNodeAction,
    TEditorSelectionModelChangePayload,
    TEditorSelectionModelChangeAction,
    TEditorProcessSpaceAction,
    TEditorCutAction,
    TEditorCopyWhiteboardAction,
    TEditorPasteWhiteboardAction,
    TEditorChangeEdgeType,
    TCreateNewObjectName,
    TOpenSearchAction,
    TOpenElementPropertiesAction,
    TEditorOpenBdSearchAction,
    TEditorSelectSymbolToReplaceOnPasteSubmitPayload,
    TEditorSelectSymbolToReplaceOnPasteSubmitAction,
    TCopyModelLinkAction,
    TModelPublishAction,
    TModelUnpublishAction,
} from './editor.actions.types';
import { WhiteboardGraph } from '@/mxgraph/WhiteboardGraph';

// export type TEditorSearchElementAction = {
//     type: typeof EDITOR_SEARCH_ELEMENT
//     payload: {
//         name: string
//     }
// };

// export type TEditorEditingStoppedAction = {
//     type: typeof EDITOR_STOP_EDITING_CELL,
//     payload: {
//         cellId: string
//     }
// };

// export const editorStopEditingCell = (cellId: string): TEditorEditingStoppedAction => ({
//     type: EDITOR_STOP_EDITING_CELL,
//     payload: {
//         cellId
//     }
// });

export const editorInit = (payload: TEditorInitPayload): TEditorInitAction => ({
    type: EDITOR_INIT,
    payload,
});

export const editorCreated = (payload: TEditorCreatedPayload): TEditorCreatedAction => ({
    type: EDITOR_CREATED,
    payload,
});

export const editorDestroy = (payload: TEditorDestroyPayload): TEditorDestroyAction => ({
    type: EDITOR_DESTROY,
    payload,
});

export const alignAction = (alignment: Alignment): TEditorAlignAction => ({
    type: EDITOR_ALIGN,
    payload: {
        alignment,
    },
});

export const distributeAction = (isHorizontal: boolean): TEditorDistributeAction => ({
    type: EDITOR_DISTRIBUTE,
    payload: {
        isHorizontal,
    },
});

export const pickOutAction = (pickOut: PickOut): TEditorPickOutAction => ({
    type: EDITOR_PICK_OUT,
    payload: {
        pickOut,
    },
});

export const moveToAction = (moveTo: MoveTo): TEditorMoveToAction => ({
    type: EDITOR_MOVE_TO,
    payload: {
        moveTo,
    },
});

export const moveToDirectAction = (treeNode: TreeNode, treeName?: string): TEditorMoveToDirectAction => ({
    type: EDITOR_MOVE_TO_DIRECT,
    payload: {
        treeNode,
        treeName,
    },
});
export const modelPublishAction = (nodeId: NodeId): TModelPublishAction => ({
    type: MODEL_PUBLISH,
    payload: {
        nodeId,
    },
});

export const modelUnpublishAction = (nodeId: NodeId): TModelUnpublishAction => ({
    type: MODEL_UNPUBLISH,
    payload: {
        nodeId,
    },
});
export const copyModelLinkAction = (nodeId: NodeId): TCopyModelLinkAction => ({
    type: COPY_MODEL_LINK,
    payload: {
        nodeId,
    },
});

export const moveLayerAction = (movelayer: MoveLayer): TEditorMoveAction => ({
    type: EDITOR_MOVE_LAYER,
    payload: {
        movelayer,
    },
});

export const copyAction = (): TEditorCopyAction => ({
    type: EDITOR_COPY,
});

export const copyWhiteboardAction = (graph: WhiteboardGraph): TEditorCopyWhiteboardAction => ({
    type: EDITOR_COPY_WHITEBOARD,
    payload: {
        graph,
    },
});

export const cutAction = (): TEditorCutAction => ({
    type: EDITOR_CUT,
});

export const pasteAction = (pasteAsNewObjectDefinition?: boolean): TEditorPasteAction => ({
    type: EDITOR_PASTE,
    payload: {
        pasteAsNewObjectDefinition,
    },
});

export const pasteWhiteboardAction = (graph: WhiteboardGraph): TEditorPasteWhiteboardAction => ({
    type: EDITOR_PASTE_WHITEBOARD,
    payload: {
        graph,
    },
});

export const updateAction = (): TEditorUpdateAction => ({
    type: EDITOR_UPDATE,
});

export const dropPastePreviewAction = (
    elements: DiagramElement[],
    dropPoint: MxPoint,
    pasteAsNewObjectDefinition?: boolean,
): TEditorDropPastePreviewAction => ({
    type: EDITOR_DROP_PASTE_PREVIEW,
    payload: {
        elements,
        dropPoint,
        pasteAsNewObjectDefinition,
    },
});

export const dragShapeAction = (symbol: Symbol): TEditorDragShapeAction => ({
    type: EDITOR_DRAG_SHAPE,
    payload: {
        symbol,
    },
});

export const formatByExampleAction = (): TEditorFormatByExample => ({
    type: EDITOR_FORMAT_BY_EXAMPLE,
});

export const setCellDefaultStyle = (payload: TEditorSetCellDefaultStylePayload): TEditorSetCellDefaultStyle => ({
    type: EDITOR_SET_CELL_DEFAULT_STYLE,
    payload,
});

export const selectAllAction = (): TEditorSelectAllAction => ({
    type: EDITOR_SELECT_ALL,
});

export const deleteAction = (
    cellsForDelete: MxCell[],
    graphId: NodeId,
    checkProfile: boolean = false,
    checkCellIsEdited: boolean = false,
): TEditorDeleteAction => ({
    type: EDITOR_DELETE,
    payload: {
        cellsForDelete,
        graphId,
        checkProfile,
        checkCellIsEdited,
    },
});

export const handleDeleteSelectedCellsFromActiveGraphAction = (): TDeleteSelectedCellsFromActiveGraphAction => ({
    type: DELETE_SELECTED_CELLS_FROM_ACTIVE_GRAPH,
});

export const moveObjectAction = (key: KeyCodes): TEditorMoveObjectAction => ({
    type: EDITOR_MOVE_OBJECT,
    payload: {
        key,
    },
});

export const openSearchAction = (): TOpenSearchAction => ({
    type: OPEN_SEARCH_ACTION,
});

export const editorLabelChangedAction = (graphId: NodeId, cellId: string, newLabel?: string): TEditorLabelChanged => ({
    type: EDITOR_LABEL_CHANGED,
    payload: {
        graphId,
        cellId,
        newLabel,
    },
});

export const editorCellAddedAction = (graphId: NodeId, cellId: string): TEditorCellAdded => ({
    type: EDITOR_CELL_ADDED,
    payload: {
        graphId,
        cellId,
    },
});

export const editorCellColorPickedAction = (color: string): TEditorCellColorPicked => ({
    type: EDITOR_CELL_COLOR_PICKED,
    payload: {
        color,
    },
});

export const editorModeChangedPrepareAction = (mode: EditorMode): TEditorModeChangedPrepareAction => ({
    type: EDITOR_MODE_CHANGED_PREPARE,
    payload: {
        mode,
    },
});

export const openBdSearchAction = (): TEditorOpenBdSearchAction => ({
    type: OPEN_BD_SEARCH_ACTION,
});

export const editorModeChangedAction = (mode: EditorMode): TEditorModeChangedAction => ({
    type: EDITOR_MODE_CHANGED,
    payload: {
        mode,
    },
});

export const setFocusAndStartEditAction = (cellId: string): TEditorSetFocusAndStartEditLabel => ({
    type: EDITOR_SET_FOCUS_AND_START_EDIT_LABEL,
    payload: {
        cellId,
    },
});

export const setRepositoryIdWhereCopiedFrom = (
    repositoryId: string,
    serverId: string,
): TSetRepositoryIdWhereCopiedFromAction => ({
    type: EDITOR_SET_REPOSITORY_ID_WHERE_COPIED_FROM,
    payload: {
        repositoryId,
        serverId,
    },
});

export const setCopiedElementsAction = (elements: DiagramElement[]): TEditorSetCopiedElementsAction => ({
    type: EDITOR_SET_COPIED_ELEMENTS,
    payload: {
        elements,
    },
});

export const editorGroupingAction = (grouping: Grouping): TEditorGropingAction => ({
    type: EDITOR_GROUPING,
    payload: {
        grouping,
    },
});

// export const editorSearchElementAction = (name: string): TEditorSearchElementAction => ({
//     type: EDITOR_SEARCH_ELEMENT,
//     payload: {
//         name
//     }
// });

export const escapeAction = (): TEditorEscapeAction => ({
    type: EDITOR_ESCAPE,
});

export const processSpaceAction = (action: SpaceAction): TEditorProcessSpaceAction => ({
    type: EDITOR_PROCESS_SPACE_ACTION,
    payload: {
        action,
    },
});

export const changeEdgeMultilingualName = (graphId: NodeId, edgeId: string, name: InternationalString | undefined) => ({
    type: EDITOR_CHANGE_EDGE_MULTILINGUAL_NAME,
    payload: {
        graphId,
        edgeId,
        name,
    },
});

export const changeEdgeType = (type: string): TEditorChangeEdgeType => ({
    type: EDITOR_CHANGE_EDGE_TYPE,
    payload: {
        type,
    },
});

export const changeEdgeColor = (color: string) => ({
    type: EDITOR_CHANGE_EDGE_COLOR,
    payload: {
        color,
    },
});

export const changeFontColor = (color: string) => ({
    type: EDITOR_CHANGE_FONT_COLOR,
    payload: {
        color,
    },
});

export const changeEdgeStyle = (
    keys: Array<string>,
    values: Array<string | null>,
    cellId: string,
): TEditorChangeEdgeStyleAction => ({
    type: EDITOR_CHANGE_EDGE_STYLE,
    payload: { keys, values, cellId },
});

export const editorSetObjectToCell = (
    cellId: string,
    object: ObjectDefinitionImpl,
    isDeletable: boolean,
): TEditorSetObjectToCell => ({
    type: EDITOR_SET_OBJECT_TO_CELL,
    payload: {
        cellId,
        object,
        isDeletable,
    },
});

export const createNewObjectName = (
    cellId: string,
    object: ObjectDefinitionImpl,
    newObjectName: string,
): TCreateNewObjectName => ({
    type: CREATE_NEW__OBJECT_NAME,
    payload: {
        cellId,
        object,
        newObjectName,
    },
});

export const editorSingleCellSelected = (): TEditorSingleCellSelected => ({
    type: EDITOR_SINGLE_CELL_SELECTED,
});

// TODO удалить закомментированные экшны и их компоненты !!
// export type TEditorLookupExistingObjectDefinitions = {
//     type: typeof EDITOR_LOOKUP_EXISTING_OBJECT_DEFINITIONS,
//     payload: {
//         cellId: string
//     }
// };

// export const editorLookupExistingObjectDefinitions = (cellId: string): TEditorLookupExistingObjectDefinitions => ({
//     type: EDITOR_LOOKUP_EXISTING_OBJECT_DEFINITIONS,
//     payload: {
//         cellId
//     }
// });

export const addTableRow = (cellId: string): TEditorAddTableRowAction => ({
    type: EDITOR_ADD_TABLE_ROW,
    payload: {
        cellId,
    },
});

export const removeTableRow = (cellId: string): TEditorRemoveTableRowAction => ({
    type: EDITOR_REMOVE_TABLE_ROW,
    payload: {
        cellId,
    },
});

export const addTableColumn = (cellId: string): TEditorAddTableColumnAction => ({
    type: EDITOR_ADD_TABLE_COLUMN,
    payload: {
        cellId,
    },
});

export const removeTableColumn = (cellId: string): TEditorRemoveTableColumnAction => ({
    type: EDITOR_REMOVE_TABLE_COLUMN,
    payload: {
        cellId,
    },
});

export const addBpmnTableRow = (cellId: string): TEditorAddBpmnTableRowAction => ({
    type: EDITOR_ADD_BPMN_TABLE_ROW,
    payload: {
        cellId,
    },
});

export const removeBpmnTableRow = (cellId: string): TEditorRemoveBpmnTableRowAction => ({
    type: EDITOR_REMOVE_BPMN_TABLE_ROW,
    payload: {
        cellId,
    },
});

export const changeSymbolForCell = (cellId: string, graphId: NodeId, symbol: Symbol): TChangeSymbolForCellAction => ({
    type: CHANGE_SYMBOL_FOR_CELL,
    payload: {
        cellId,
        graphId,
        symbol,
    },
});

export const zoomInAction = (): TZoomAction => ({
    type: EDITOR_ZOOM_IN,
    payload: null,
});

export const zoomOutAction = (): TZoomAction => ({
    type: EDITOR_ZOOM_OUT,
    payload: null,
});

// export const zoomToAction = (value: number): TZoomToAction => ({
//     type: EDITOR_ZOOM_TO,
//     payload: value
// });

export const createEditorDNDHandler = (nodeId: NodeId): TDropFileNodeAction => ({
    type: EDITOR_DROP_FILE_NODE,
    payload: {
        nodeId,
    },
});

export const editorDragNode = (payload: TEditorDragNodePayload): TEditorDragNodeAction => ({
    type: EDITOR_DRAG_NODE,
    payload,
});

export const editorSelectionModelChange = (
    payload: TEditorSelectionModelChangePayload,
): TEditorSelectionModelChangeAction => ({
    type: EDITOR_SELECTION_MODEL_CHANGE,
    payload,
});

export const openElementPropertiesAction = (): TOpenElementPropertiesAction => ({
    type: OPEN_ELEMENT_PROPERTIES,
});

export const editorSelectSymbolToReplaceOnPasteSubmit = (
    payload: TEditorSelectSymbolToReplaceOnPasteSubmitPayload,
): TEditorSelectSymbolToReplaceOnPasteSubmitAction => ({
    type: EDITOR_SELECT_SYMBOL_TO_REPLACE_ON_PASTE_SUBMIT,
    payload,
});
