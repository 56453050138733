import React, { useCallback, useState } from 'react';
import { get } from 'lodash-es';
import {
    InternationalString,
    ModelType,
    Symbol,
    KanbanBoardType,
    KanbanCardType,
} from '../../../../../../../serverapi/api';
import { TreeNode } from '../../../../../../../models/tree.types';
import { TPreset } from '../../../../../../../models/preset.types';
import { DeleteButton, EditButton, RowButtons } from '../../../../../Button/RowButtons';
import { DeleteSelected, TabHeader } from '../../Header/TabHeader.component';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import messages from '../KanbanModelType.messages';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { useIntl } from 'react-intl';
import { toggleArrayItem } from '../../../../../../../utils/toggleArrayItem';
import { useDispatch, useSelector } from 'react-redux';
import { openKanbanCardEditorTab, openSymbolEditorTab } from '../../../../../../../actions/methodologySetting.actions';
import { SymbolsTabDialog } from '../../ModelType/Dialogs/SymbolsTabDialog.component';
import {
    addCardsKanbanModelType,
    addSymbolsKanbanModelType,
    deleteCardsKanbanModelType,
    deleteSymbolsKanbanModelType,
} from '../../../../../../../actions/workspaceTab/editKanbanModelTypeWorkspaceTab.actions';
import { Table } from 'antd';
import theme from './ObjectsCardsTab.scss';
import icKanbanCard from '../../../../../../../resources/icons/ic-kanban-card.svg';
import { ColumnProps } from 'antd/es/table';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { CardsTabDialog } from './CardsTabDialog.component';
import { updateSymbolsAddedToKanbanModelType } from '../../../../../../../actions/presetSettings/presetSettingsKanbanModelType.actions';
import { editObjectType } from '../../../../../../../actions/objectType.actions';
import { isKanbanCardType } from '../../util/KanbanCardEditor.utils';

type TObjectsCardsTabProps = {
    modelType: KanbanBoardType;
    modelTypeSymbols: Symbol[];
    symbols: Symbol[];
    serverId: string;
    serverNode: TreeNode;
    preset: TPreset;
    cards: KanbanCardType[];
    modelTypeCards: KanbanCardType[];
};

const ObjectsCardsTab = (props: TObjectsCardsTabProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);

    const [graph, setGraph] = useState<MethodologiesGraph | undefined>();
    const [symbolDialogVisible, setSymbolDialogVisible] = useState<boolean>(false);
    const [cardDialogVisible, setCardDialogVisible] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');

    const { modelTypeSymbols, modelType, symbols, serverId, serverNode, preset, cards, modelTypeCards } = props;
    const modelTypeId = modelType.id;
    const presetId = preset.id;
    const allSymbols = [...modelTypeSymbols, ...(modelTypeCards as unknown as Symbol[])];

    const converterInitialized = (methodologiesGraph: MethodologiesGraph) => {
        if (!graph) {
            setGraph(methodologiesGraph);
        }
    };

    const onAddSymbols = useCallback((symbolsForAdd: Symbol[]) => {
        dispatch(
            addSymbolsKanbanModelType({
                presetId,
                modelTypeId,
                symbolsIds: symbolsForAdd.map(({ id }) => id),
            }),
        );
    }, []);

    const onDeleteSymbols = useCallback((symbolsForDelete: Symbol[]) => {
        dispatch(
            deleteSymbolsKanbanModelType({
                symbolIds: symbolsForDelete.map(({ id }) => id),
                presetId,
                modelTypeId,
            }),
        );
    }, []);

    const onDeleteCards = useCallback((cardsForDelete: KanbanCardType[]) => {
        dispatch(
            deleteCardsKanbanModelType({
                cardIds: cardsForDelete.map(({ id }) => id),
                presetId,
                modelTypeId,
            }),
        );
    }, []);

    const onAddCards = useCallback((cardsForAdd: KanbanCardType[]) => {
        dispatch(addCardsKanbanModelType({ presetId, modelTypeId, cardTypesIds: cardsForAdd.map(({ id }) => id) }));
    }, []);

    const onEditSymbol = useCallback((symbol: Symbol, objectTypeId: string): void => {
        if (!isKanbanCardType(symbol)) {
            dispatch(openSymbolEditorTab({ symbol, objectTypeId, preset, serverNode }));
        } else {
            dispatch(
                openKanbanCardEditorTab({
                    serverNode,
                    preset,
                    objectTypeId,
                    kanbanCardType: symbol as unknown as KanbanCardType,
                }),
            );
        }
    }, []);

    const onEditObjectType = useCallback((objectTypeId: string) => {
        dispatch(editObjectType({ objectTypeId, preset, serverNode }));
    }, []);

    const renderRowActionButtons = (symbol: Symbol) => {
        const objectTypeId: string = symbol.objectType || get(symbol, 'objectTypeId')!;
        return (
            <RowButtons
                buttons={[
                    EditButton.build(() => {}, undefined, undefined, {
                        items: [
                            {
                                key: 'editSymbol',
                                message: messages.editSymbol,
                                onClick: () => onEditSymbol(symbol, objectTypeId),
                            },
                            {
                                key: 'editObject',
                                message: messages.editObject,
                                onClick: () => onEditObjectType(objectTypeId),
                            },
                        ],
                    }),
                    DeleteButton.build(() => {
                        if (isKanbanCardType(symbol)) return onDeleteCards([symbol as unknown as KanbanCardType]);
                        onDeleteSymbols([symbol]);
                    }),
                ]}
            />
        );
    };

    const onRow = (row: Symbol) => {
        const newState: string[] = toggleArrayItem<string>(row.id, selectedRowKeys);

        return {
            onClick: () => setSelectedRowKeys(newState),
        };
    };

    const handleAddSymbols = (symbols: Symbol[]) => {
        setSymbolDialogVisible(false);
        onAddSymbols(symbols);
        if (graph) {
            const symbolsToUpdate: Symbol[] = [];
            symbols.forEach((symbol) => {
                if (symbol.symbolSvg) return;
                graph.setSymbolName(symbol.name);
                const svg = graph.convertSymbolToSvgImage(symbol);
                const svgString = svg.svgString;
                symbolsToUpdate.push({ ...symbol, symbolSvg: svgString });
            });
            dispatch(updateSymbolsAddedToKanbanModelType({ serverId, symbols: symbolsToUpdate }));
        }
    };

    const searchElements = (symbol: Symbol): boolean => {
        const name = LocalesService.internationalStringToString(symbol.multilingualName, currentLocale);
        const description = LocalesService.internationalStringToString(symbol.multilingualDescription, currentLocale);
        const searchText = searchFilter.toLowerCase().trim() || '';
        const searchFields = [name, description, symbol.id];
        const found = searchFields.some((field) => field?.toLowerCase().trim().includes(searchText));

        return found;
    };

    const columns: Array<ColumnProps<Symbol>> = [
        {
            title: intl.formatMessage(messages.name),
            dataIndex: 'multilingualName',
            className: theme.column,
            render: (value: InternationalString) => LocalesService.internationalStringToString(value, currentLocale),
        },
        {
            title: intl.formatMessage(messages.guid),
            dataIndex: 'id',
            className: theme.column,
        },
        {
            title: intl.formatMessage(messages.objectType),
            dataIndex: 'objectType',
            className: theme.column,
            render: (value: string, record: Symbol) => {
                if (isKanbanCardType(record)) return get(record, 'objectTypeId');
                return record.objectType;
            },
        },
        {
            title: intl.formatMessage(messages.symbolCard),
            dataIndex: 'graphical',
            className: theme.column,
            render: (value: string, record: Symbol) => {
                if (isKanbanCardType(record)) {
                    return (
                        <div>
                            <img style={{ width: 120, height: 50 }} src={icKanbanCard.toString()} />
                        </div>
                    );
                }
                return SymbolToImageConverterGraph.convertSymbol(record, intl, graph);
            },
        },
        {
            dataIndex: 'groupId',
            render: (value: string, record: Symbol) => {
                return renderRowActionButtons(record);
            },
            className: theme.buttonsColumn,
        },
    ];

    return (
        <div className={theme.container}>
            {symbolDialogVisible && (
                <SymbolsTabDialog
                    availableSymbols={symbols}
                    modelTypeSymbols={modelTypeSymbols}
                    graph={graph}
                    onAddSymbols={handleAddSymbols}
                    onCancel={() => setSymbolDialogVisible(false)}
                />
            )}
            {cardDialogVisible && (
                <CardsTabDialog
                    onCancel={() => setCardDialogVisible(false)}
                    onAddCards={(cardsForAdd) => {
                        setCardDialogVisible(false);
                        onAddCards(cardsForAdd);
                    }}
                    availableCards={cards}
                    modelTypeCards={modelTypeCards}
                />
            )}
            <SymbolToImageConverterGraph modelType={{} as ModelType} initialized={converterInitialized} />
            <TabHeader
                buttons={[
                    {
                        children: intl.formatMessage(messages.newCard),
                        onClick: () => {
                            setCardDialogVisible(true);
                        },
                    },
                    {
                        children: intl.formatMessage(messages.newSymbol),
                        onClick: () => {
                            setSymbolDialogVisible(true);
                        },
                    },
                    DeleteSelected.build(() => {
                        const symbols = modelTypeSymbols.filter((symbol) =>
                            selectedRowKeys.some((key) => key === symbol.id),
                        );
                        const cards = modelTypeCards.filter((symbol) =>
                            selectedRowKeys.some((key) => key === symbol.id),
                        );

                        symbols.length && onDeleteSymbols(symbols);
                        cards.length && onDeleteCards(cards);
                    }, !selectedRowKeys.length),
                ]}
                onSearchChange={setSearchFilter}
            />

            <Table
                rowSelection={{
                    selectedRowKeys,
                    onChange: setSelectedRowKeys as any,
                }}
                rowKey={(record: Symbol) => `${record.id}`}
                columns={columns}
                dataSource={allSymbols.filter(searchElements)}
                size="small"
                className={theme.table}
                onRow={onRow}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
                pagination={false}
            />
        </div>
    );
};

const withMemo = React.memo(ObjectsCardsTab);

export { withMemo as ObjectsCardsTab };
