// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectTreeItemAddFavoriteDialog__modal__as0sX .ant-modal-body{height:500px}.SelectTreeItemAddFavoriteDialog__modal__as0sX .ant-tooltip-disabled-compatible-wrapper{margin-left:8px}.SelectTreeItemAddFavoriteDialog__buttons___ianO{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.SelectTreeItemAddFavoriteDialog__title__WDdUe{font-size:16px}.SelectTreeItemAddFavoriteDialog__search__zySWm{margin-top:15px;border-radius:4px}.SelectTreeItemAddFavoriteDialog__label__JhG8c{margin-top:20px;margin-left:12px}", "",{"version":3,"sources":["webpack://./src/modules/Workspace/components/SelectTreeItemAddFavoriteDialog/SelectTreeItemAddFavoriteDialog.scss"],"names":[],"mappings":"AAEM,+DACI,YAAA,CAEJ,wFACI,eAAA,CAKV,iDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAGF,+CACE,cAAA,CAGF,gDACE,eAAA,CACA,iBAAA,CAGF,+CACE,eAAA,CACA,gBAAA","sourcesContent":[".modal{\r\n  :global{\r\n      .ant-modal-body{\r\n          height: 500px;\r\n      }\r\n      .ant-tooltip-disabled-compatible-wrapper {\r\n          margin-left: 8px;\r\n      }\r\n  }\r\n}\r\n\r\n.buttons {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.title {\r\n  font-size: 16px;\r\n}\r\n\r\n.search {\r\n  margin-top: 15px;\r\n  border-radius: 4px;\r\n}\r\n\r\n.label {\r\n  margin-top: 20px;\r\n  margin-left: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "SelectTreeItemAddFavoriteDialog__modal__as0sX",
	"buttons": "SelectTreeItemAddFavoriteDialog__buttons___ianO",
	"title": "SelectTreeItemAddFavoriteDialog__title__WDdUe",
	"search": "SelectTreeItemAddFavoriteDialog__search__zySWm",
	"label": "SelectTreeItemAddFavoriteDialog__label__JhG8c"
};
export default ___CSS_LOADER_EXPORT___;
