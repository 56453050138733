import * as React from 'react';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import { closeDialog } from '../../actions/dialogs.actions';
import { useDispatch } from 'react-redux';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { Button } from 'antd';
import theme from './AcceptAgreementDialog.scss';
import messages from './AcceptAgreement.messages';
import { appExit } from '../../actions/app.actions';
import { AppExitMethod } from '../../models/app';
import { acceptAgreement } from '../../actions/agreement.actions';
import { useIntl } from 'react-intl';
import Linkify from 'linkify-react';

type TAcceptAgreementDialogProps = {
    open: boolean;
    serverId: string;
    text: string;
} & JSX.IntrinsicAttributes;

const AcceptAgreement: React.FC<TAcceptAgreementDialogProps> = ({ open, text, serverId }) => {
    const dispatch = useDispatch();
    const [verticalScroll, setVerticalScroll] = React.useState(false);
    const [end, setEnd] = React.useState(false);
    const intl = useIntl();

    const handleClose = () => {
        dispatch(appExit(AppExitMethod.Logout));
    };

    const elRef = React.useCallback((node) => {
        if (node !== null) {
            const hasVerScroll = node.parentNode.parentNode.scrollHeight > node.parentNode.parentNode.clientHeight;
            if (hasVerScroll) {
                setVerticalScroll(true);
            }
        }
    }, []);

    const handleScroll = (event) => {
        if (Math.ceil(event.target.scrollHeight - event.target.scrollTop) - event.target.clientHeight <= 1) {
            setEnd(true);
        }
    };

    const footer = [
        <Button
            key="cancel"
            size="large"
            onClick={() => {
                dispatch(appExit(AppExitMethod.Logout));
            }}
        >
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button
            disabled={verticalScroll && !end}
            key="ok"
            size="large"
            type="primary"
            onClick={() => {
                dispatch(acceptAgreement(serverId));
                dispatch(closeDialog(DialogType.ACCEPT_AGREEMENT));
            }}
        >
            {intl.formatMessage(messages.accept)}
        </Button>,
    ];

    return (
        <div className={theme.container} onScroll={handleScroll}>
            <Dialog
                maskClosable={false}
                onCancel={handleClose}
                open={open}
                title={intl.formatMessage(messages.agreement)}
                footer={footer}
            >
                <div>
                    <Linkify options={{ target: "_blank" }}>
                        <div ref={elRef} style={{ whiteSpace: 'pre-wrap', color: 'gray' }}>
                            {text}
                        </div>
                    </Linkify>
                </div>
            </Dialog>
        </div>
    );
};

export default AcceptAgreement;
