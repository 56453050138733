import React, { useContext } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import icPaste from 'icons/insert.svg';
import icCopy from 'icons/copy.svg';
import icCut from 'icons/cut.svg';
import icDelete from 'icons/delete.svg';
import { ToolbarButton, ToolbarButtonGroup } from 'UIKit';
import { actionCut, actionPaste, actionDelete, getSelectedText } from '../../common/actions';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';

const getChangedBlock = (editorState, value) => {
    const { action, text } = value;
    if (action === 'cut') {
        return actionCut(editorState);
    }
    if (action === 'paste') {
        return actionPaste(editorState, text);
    }
    if (action === 'delete') {
        return actionDelete(editorState);
    }

    return editorState;
};

const GeneralActionsComponent = ({ copyToClipboard, readFromClipboard }) => {
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, editorState } = useSharedState({ stateObserver, getChangedBlock });
    const intl = useIntl();
    const onActionChange = (action) => () => {
        if (action === 'paste') {
            readFromClipboard((text) => setCurrentState({ action, text }));
        } else if (action === 'copy') {
            const selectedText = getSelectedText(editorState);
            copyToClipboard(selectedText);
        } else if (action === 'cut') {
            const selectedText = getSelectedText(editorState);
            copyToClipboard(selectedText);
            setCurrentState({ action });
        } else if (action === 'delete') {
            setCurrentState({ action });
        }
    };

    return (
        <ToolbarButtonGroup>
            <ToolbarButton
                tooltip={intl.formatMessage(messages.cut)}
                spriteSymbol={icCut}
                onClick={onActionChange('cut')}
                dataTest="wiki_cut_button"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.copy)}
                spriteSymbol={icCopy}
                onClick={onActionChange('copy')}
                dataTest="wiki_copy_button"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.paste)}
                spriteSymbol={icPaste}
                onClick={onActionChange('paste')}
                dataTest="wiki_paste_button"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.toBasket)}
                spriteSymbol={icDelete}
                onClick={onActionChange('delete')}
                dataTest="wiki_delete_button"
            />
        </ToolbarButtonGroup>
    );
};

export default GeneralActionsComponent;
