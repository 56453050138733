// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddEventDialog__eventModalDialogWrapper__jd093 .ant-modal-body{max-height:100%}.AddEventDialog__eventModalDialogWrapper__jd093 .ant-form-item-label>label.ant-form-item-required{top:0px}.AddEventDialog__field__NZEec{text-align:left}.AddEventDialog__field__NZEec label{white-space:normal;display:inline-block}.AddEventDialog__field__NZEec label::after{top:0}.AddEventDialog__field__NZEec .AddEventDialog__row__NDM_6{-ms-flex-wrap:nowrap;flex-wrap:nowrap}.AddEventDialog__field__NZEec .AddEventDialog__node__v3B0n{-ms-flex-negative:1;flex-shrink:1}.AddEventDialog__field__NZEec .AddEventDialog__nodeButton__h_LAa{margin-left:2px;-ms-flex-negative:0;flex-shrink:0}.AddEventDialog__eventFormWrapper___J8XQ{overflow:auto;height:70vh;padding-right:10px}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/AddEventDialog/AddEventDialog.scss"],"names":[],"mappings":"AAGQ,gEACI,eAAA,CAEJ,kGACI,OAAA,CAKZ,8BACI,eAAA,CAEA,oCACI,kBAAA,CACA,oBAAA,CACA,2CACI,KAAA,CAIR,0DACI,oBAAA,CAAA,gBAAA,CAGJ,2DACI,mBAAA,CAAA,aAAA,CAGJ,iEACI,eAAA,CACA,mBAAA,CAAA,aAAA,CAIR,yCACI,aAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":["\r\n.eventModalDialogWrapper {\r\n    :global {\r\n        .ant-modal-body {\r\n            max-height: 100%;\r\n        }\r\n        .ant-form-item-label > label.ant-form-item-required {\r\n            top: 0px;\r\n        }\r\n    }\r\n}\r\n\r\n.field {\r\n    text-align: left;\r\n\r\n    label {\r\n        white-space: normal;\r\n        display: inline-block;\r\n        &::after {\r\n            top: 0;\r\n        }\r\n    }\r\n    \r\n    .row {\r\n        flex-wrap: nowrap;\r\n    }\r\n\r\n    .node {\r\n        flex-shrink: 1;\r\n    }\r\n\r\n    .nodeButton {\r\n        margin-left: 2px;\r\n        flex-shrink: 0;\r\n    }\r\n}\r\n\r\n.eventFormWrapper {\r\n    overflow: auto;\r\n    height: 70vh;\r\n    padding-right: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventModalDialogWrapper": "AddEventDialog__eventModalDialogWrapper__jd093",
	"field": "AddEventDialog__field__NZEec",
	"row": "AddEventDialog__row__NDM_6",
	"node": "AddEventDialog__node__v3B0n",
	"nodeButton": "AddEventDialog__nodeButton__h_LAa",
	"eventFormWrapper": "AddEventDialog__eventFormWrapper___J8XQ"
};
export default ___CSS_LOADER_EXPORT___;
