import { Comment, EntityStringRequest, NodeId } from '@/serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';
import { setServerIdToComment } from '../../modules/Comments/utils/commentsUtils';

export class CommentsDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async pinComment(repositoryId: string, commentId: string, serverId: string): Promise<Comment> {
        const api = this.getApi();
        const value = true;
        const comment: Comment = await api.comment.pin({
            repositoryId,
            commentId,
            body: { value },
        });

        return setServerIdToComment(comment, serverId);
    }

    public static async unpinComment(repositoryId: string, commentId: string, serverId: string): Promise<Comment> {
        const api = this.getApi();
        const value = false;
        const comment: Comment = await api.comment.pin({
            repositoryId,
            commentId,
            body: { value },
        });

        return setServerIdToComment(comment, serverId);
    }

    public static async setCritical(repositoryId: string, commentId: string, serverId: string): Promise<Comment> {
        const api = this.getApi();

        const comment: Comment = await api.comment.additionalStatus({
            repositoryId,
            commentId,
            body: 'CRITICAL',
        });

        return setServerIdToComment(comment, serverId);
    }

    public static async setUncritical(repositoryId: string, commentId: string, serverId: string): Promise<Comment> {
        const api = this.getApi();

        const comment: Comment = await api.comment.additionalStatus({
            repositoryId,
            commentId,
            body: 'EMPTY',
        });

        return setServerIdToComment(comment, serverId);
    }

    public static async setOpen(repositoryId: string, commentId: string, serverId: string): Promise<Comment> {
        const api = this.getApi();

        const comment: Comment = await api.comment.status({
            repositoryId,
            commentId,
            body: 'OPEN',
        });

        return setServerIdToComment(comment, serverId);
    }

    public static async setClose(repositoryId: string, commentId: string, serverId: string): Promise<Comment> {
        const api = this.getApi();

        const comment: Comment = await api.comment.status({
            repositoryId,
            commentId,
            body: 'CLOSE',
        });

        return setServerIdToComment(comment, serverId);
    }

    public static async uploadCommentFile(
        repositoryId: string,
        commentId: string,
        body: File,
    ): Promise<EntityStringRequest> {
        const api = this.getApi();

        const res: EntityStringRequest = await api.comment.uploadCommentFile({
            repositoryId,
            commentId,
            body,
        });

        return res;
    }

    public static async deleteCommentFile(repositoryId: string, commentId: string, fileId: string): Promise<void> {
        const api = this.getApi();

        await api.comment.deleteCommentFile({
            repositoryId,
            commentId,
            fileId,
        });
    }

    public static async deleteComment(commentId: NodeId): Promise<boolean> {
        const api = this.getApi();

        const result = await api.comment._delete({
            repositoryId: commentId.repositoryId,
            commentId: commentId.id,
        });

        return result;
    }

    public static async save(comment: Comment): Promise<Comment> {
        const api = this.getApi();

        const newComment: Comment = await api.comment.save({ body: comment });

        return setServerIdToComment(newComment, comment.commentId.serverId);
    }

    public static async getCommentsByIds(
        repositoryId: string,
        commentIds: string[],
        serverId: string,
    ): Promise<Comment[]> {
        const api = this.getApi();
        const comments: Comment[] = await Promise.all(
            commentIds.map((commentId) => api.comment.getCommentById({ repositoryId, commentId })),
        );

        return comments.map((comment) => setServerIdToComment(comment, serverId));
    }

    public static async getCommentsByModelId(modelId: NodeId): Promise<Comment[]> {
        const api = this.getApi();

        const comments: Comment[] = await api.comment.getCommentsByModelId({
            repositoryId: modelId.repositoryId,
            modelId: modelId.id,
        });

        return comments.map((comment) => ({
            ...comment,
            commentId: { ...comment.commentId, serverId: modelId.serverId },
        }));
    }
}
