import { NotificationType } from '../models/notificationType';

const FAILED_TO_FETCH = 'Failed to fetch';
const UNEXPECTED_END_OF_JSON_INPUT = 'Unexpected end of JSON input';
export const LOW_SERVER = 'Server has no time to save data';

export const notificationTypeMap: Map<string, NotificationType> = new Map([
    // сетевая ошибка
    [FAILED_TO_FETCH, NotificationType.NETWORK_ERROR],
    [UNEXPECTED_END_OF_JSON_INPUT, NotificationType.UNEXPECTED_JSON],
    [LOW_SERVER, NotificationType.LOW_SERVER],
]);

export type TErrorBodyType = {
    message?: string;
    code?: string;
};

export enum BadRequestErrors {
    RESTORE_NODE = 'RESTORE_NODE',
    NODE_DELETED = 'NODE_DELETED',
    UNSUPPORTED_OPERATION = 'UNSUPPORTED_OPERATION',
    LIMITED_FILE_SIZE_CUSTOM_MAX = 'LIMITED_FILE_SIZE_CUSTOM_MAX',
    LOGIN_IS_EMPTY = 'LOGIN_IS_EMPTY',
    PASSWORD_LENGTH_IS_INCORRECT = 'PASSWORD_LENGTH_IS_INCORRECT',
    PASSWORD_NOT_CONTAIN_LATIN_LETTER = 'PASSWORD_NOT_CONTAIN_LATIN_LETTER',
    PASSWORD_CONTAINS_NOT_ONLY_LATIN_LETTERS = 'PASSWORD_CONTAINS_NOT_ONLY_LATIN_LETTERS',
    PASSWORD_NOT_CONTAIN_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS = 'PASSWORD_NOT_CONTAIN_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS',
    PASSWORD_NOT_CONTAIN_DIGITS = 'PASSWORD_NOT_CONTAIN_DIGITS',
    PASSWORD_NOT_CONTAIN_SPECIAL_CHARACTERS = 'PASSWORD_NOT_CONTAIN_SPECIAL_CHARACTERS',
    NEW_PASSWORD_EQUAL_OLD_PASSWORD = 'NEW_PASSWORD_EQUAL_OLD_PASSWORD',
    PASSWORD_CHANGED_RECENTLY = 'PASSWORD_CHANGED_RECENTLY',
    LOGIN_CONTAINS_NOT_ONLY_LATIN_LETTERS = 'LOGIN_CONTAINS_NOT_ONLY_LATIN_LETTERS',
    NODE_MISSING_PARENT = 'NODE_MISSING_PARENT',
    INVALID_REPOSITORY_ID = 'INVALID_REPOSITORY_ID',
    PARENT_AND_CHILD_ID_UNEQUAL = 'PARENT_AND_CHILD_ID_UNEQUAL',
    REPOSITORY_PARENT_ERROR = 'REPOSITORY_PARENT_ERROR',
    INCORRECT_RESOURCE_FOR_NODE = 'INCORRECT_RESOURCE_FOR_NODE',
    APPROVAL_NOT_ALLOWED = 'APPROVAL_NOT_ALLOWED',
    THE_CURRENT_PASSWORD_IS_INCORRECT = 'THE_CURRENT_PASSWORD_IS_INCORRECT',
    PASSWORD_CHANGE_PROHIBITED = 'PASSWORD_CHANGE_PROHIBITED',
    PUBLICATION_NODE_MARK_DELETED = 'PUBLICATION_NODE_MARK_DELETED',
    PUBLICATION_NODE_READ_ACCESS_DENIED = 'PUBLICATION_NODE_READ_ACCESS_DENIED',
}
