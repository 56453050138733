import React, { FC } from 'react';
import messages from './SpreadsheetDialog.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { Node, NodeId } from '../../serverapi/api';
import { CommonCreateDialog } from '../CommonCreateDialog/CommonCreateDialog.component';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { spreadsheetDialogSubmit } from '../../actions/spreadsheetDialog.actions';
import { TreeItemType } from '../Tree/models/tree';

type TSpreadsheetDialogProps = {
    parentNodeId: NodeId;
    type: TreeItemType;
    extraProperties?: Node;
};

const SpreadsheetDialog: FC<TSpreadsheetDialogProps> = (props) => {
    const { parentNodeId, type, extraProperties } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    return (
        <CommonCreateDialog
            type={type}
            title={intl.formatMessage(messages.spreadsheetFormName)}
            placeholder={intl.formatMessage(messages.spreadsheetNamePlaceholder)}
            cancelText={<span data-test="create-element_table_cancel-button">{intl.formatMessage(messages.spreadsheetFormDeclineButton)}</span> }
            okText={<span data-test="create-element_table_save-button">{intl.formatMessage(messages.spreadsheetFormConfirmButton)}</span> }
            requiredErrorMsg={intl.formatMessage(messages.spreadsheetNameRequiredError)}
            labelName={
                <FormattedMessage
                    id={messages.spreadsheetNameLabel.id}
                    defaultMessage={messages.spreadsheetNameLabel.defaultMessage}
                />
            }
            inputDataTestId="spreadsheet-form_spreadsheet-name-input"
            onSubmit={(name: string) => {
                dispatch(
                    spreadsheetDialogSubmit({
                        spreadsheetName: name,
                        parentNodeId,
                        type,
                        extraProperties,
                    }),
                );
            }}
            onClose={() => dispatch(closeDialog(DialogType.SPREADSHEET_DIALOG))}
        />
    );
};

export default SpreadsheetDialog;
