import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'MyApprovalsTab.name',
        defaultMessage: 'Согласование',
    },
    stage: {
        id: 'MyApprovalsTab.stage',
        defaultMessage: 'Этап',
    },
    createdBy: {
        id: 'MyApprovalsTab.createdBy',
        defaultMessage: 'Автор',
    },
    createdAt: {
        id: 'MyApprovalsTab.createdAt',
        defaultMessage: 'Дата создания',
    },
    status: {
        id: 'MyApprovalsTab.status',
        defaultMessage: 'Статус',
    },
    userStatuses: {
        id: 'MyApprovalsTab.userStatuses',
        defaultMessage: 'Роль',
    },
    elements: {
        id: 'MyApprovalsTab.elements',
        defaultMessage: 'Элементы',
    },
    approvalPeriod: {
        id: 'MyApprovalsTab.approvalPeriod',
        defaultMessage: 'Срок согласования',
    },
    chooseCreater: {
        id: 'MyApprovalsTab.chooseCreater',
        defaultMessage: 'Выберите создателя',
    },
    ALL: {
        id: 'MyApprovalsTab.ALL',
        defaultMessage: 'Все',
    },
    APPROVED: {
        id: 'MyApprovalsTab.APPROVED',
        defaultMessage: 'Согласовано',
    },
    NOT_APPROVED: {
        id: 'MyApprovalsTab.NOT_APPROVED',
        defaultMessage: 'Не согласовано',
    },
    CANCELLED: {
        id: 'MyApprovalsTab.CANCELLED',
        defaultMessage: 'Отменено',
    },
    DRAFT: {
        id: 'MyApprovalsTab.DRAFT',
        defaultMessage: 'Черновик',
    },
    IN_PROCESS: {
        id: 'MyApprovalsTab.IN_PROCESS',
        defaultMessage: 'В процессе',
    },
    MY_APPROVALS: {
        id: 'MyApprovalsTab.MY_APPROVALS',
        defaultMessage: 'Мои согласования',
    },
    WAITING_TO_APPROVE: {
        id: 'MyApprovalsTab.WAITING_TO_APPROVE',
        defaultMessage: 'Ожидает согласования',
    },
    period: {
        id: 'MyApprovalsTab.period',
        defaultMessage: 'Период отображения',
    },
    at: {
        id: 'MyApprovalsTab.at',
        defaultMessage: 'в',
    },
    AUTHOR: {
        id: 'MyApprovalsTab.AUTHOR',
        defaultMessage: 'Автор',
    },
    ASSISTANT: {
        id: 'MyApprovalsTab.ASSISTANT',
        defaultMessage: 'Заместитель',
    },
    USER: {
        id: 'MyApprovalsTab.USER',
        defaultMessage: 'Согласующий',
    },
    waitingToVote: {
        id: 'MyApprovalsTab.waitingToVote',
        defaultMessage: 'Ожидает голосования:',
    },
    goToApproval: {
        id: 'MyApprovalsTab.goToApproval',
        defaultMessage: 'Перейти к согласованию',
    },
    refresh: {
        id: 'MyApprovalsTab.refresh',
        defaultMessage: 'Обновить',
    },
    allApprovals: {
        id: 'MyApprovalsTab.allApprovals',
        defaultMessage: 'Все согласования',
    },
    copyApproval: {
        id: 'MyApprovalsTab.copyApproval',
        defaultMessage: 'Скопировать согласование',
    },
    editApproval: {
        id: 'MyApprovalsTab.editApproval',
        defaultMessage: 'Редактировать согласование',
    },
});
