import { defineMessages } from 'react-intl';

export default defineMessages({
    modelNamePlaceholder: {
        id: 'ModelDialog.modelNamePlaceholder',
        defaultMessage: 'Имя новой модели',
    },
    modelNameLabel: {
        id: 'ModelDialog.modelNameLabel',
        defaultMessage: 'Имя модели',
    },
    modelTypeLabel: {
        id: 'ModelDialog.modelTypeLabel',
        defaultMessage: 'Тип модели',
    },
    pathPlaceholder: {
        id: 'ModelDialog.pathPlaceholder',
        defaultMessage: 'Расположение',
    },
    pathRequiredError: {
        id: 'ModelDialog.pathRequiredError',
        defaultMessage: 'Путь должен быть заполнен',
    },
    pathLabel: {
        id: 'ModelDialog.pathLabel',
        defaultMessage: 'Расположение',
    },
    modelNameRequiredError: {
        id: 'ModelDialog.modelNameRequiredError',
        defaultMessage: 'Имя модели должно быть заполнено',
    },
    formStatusError: {
        id: 'ModelDialog.formStatusError',
        defaultMessage: 'Ошибка',
    },
    requiredModelType: {
        id: 'ModelDialog.requiredModelType',
        defaultMessage: 'Необходимо выбрать тип модели',
    },
    requiredModelLocation: {
        id: 'ModelDialog.requiredModelLocation',
        defaultMessage: 'Необходимо выбрать расположение модели',
    },
    noAvailableModelTypeError: {
        id: 'ModelDialog.noAvailableModelTypeError',
        defaultMessage: 'В методологии нет доступных типов моделей. Выберите другую методологию или внесите необходимые изменения в текущую методологию',
    },
});
