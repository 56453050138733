import type { IFlatObjectInstanceSerializer } from '../serializers/ComplexSymbolSerializer.class.types';
import type { ISymbolPreviewGeometry, TSimpleSymbolCustomProps } from '../ComplexSymbol.class.types';
import type { ObjectDefinitionImpl, ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';
import type { MxCell, MxGeometry, MxStencil } from 'MxGraph';
import type { ObjectInstance, Symbol } from '@/serverapi/api';
import { MxRectangle } from 'MxGraph';
import { v4 as uuid } from 'uuid';
import { SymbolTypeId } from '../ComplexSymbol.constants';
import { ComplexSymbol } from '../ComplexSymbol.class';
import FlatObjectInstanceSerializer from '../serializers/FlatObjectInstanceSerializer.class';
import { isNullOrUndefined } from 'is-what';
import { LabelSymbol } from '@/models/bpm/bpm-model-impl';
import { getLabelValue } from './sideEffects';
import { isSymbolEditable } from '../sideEffects';
import { MxStencilRegistry } from '@/mxgraph/mxgraph';
import { generateLabelCellId } from '../../ComplexSymbol.utils';
import { SymbolType } from '@/models/Symbols.constants';
import { TObjectStyle, getSystemStyles } from './sideEffects';
import { getDefaultSymbolStyle } from './SimpleSymbol.utils';
import { canExtendParentStyleName } from '@/mxgraph/bpmgraph.constants';

export class SimpleSymbol extends ComplexSymbol {
    complexSymbolTypeId = SymbolTypeId.SIMPLE;
    customProps: TSimpleSymbolCustomProps;
    labelCell: MxCell;
    serializer: IFlatObjectInstanceSerializer = new FlatObjectInstanceSerializer();

    protected afterCreate(rootCell: MxCell): MxCell {
        super.afterCreate(rootCell);

        const { objectDefinitionId } = this.rootCellValue;

        const isEditable =
            !!objectDefinitionId &&
            isSymbolEditable(this.rootCellValue.symbolId, {
                ...this.graph.id,
                id: objectDefinitionId,
            });

        this.labelCell.complexSymbolRef = this;
        this.serializer.setLabelCell(this.labelCell);
        this.serializer.setEditable(isEditable);
        this.serializer.setInitialSymbol(this.customProps?.symbol);
        this.addManagedCells([this.labelCell]);
        this.graph.refresh(this.labelCell);

        return rootCell;
    }

    public addToGraph() {
        const cells = this.createSymbolCells();

        const rootCell = cells[0] || null;
        const labelCell = cells[1] || null;
        this.labelCell = labelCell;

        return this.afterCreate(rootCell);
    }

    public get isUseRenameDialog(): boolean {
        return false;
    }

    public get isConnectable(): boolean {
        return true;
    }

    public getLabelCell(): MxCell {
        return this.getManagedCells()[1];
    }

    public getSearchValue(): string {
        const cell: MxCell = this.getLabelCell();

        return this.graph.getView().getState(cell)?.text?.value || '';
    }

    public convertValueToString(cell: MxCell): string {
        const cellValue = cell.getValue();

        if (cellValue?.type === SymbolType.LABEL) {
            return getLabelValue({
                ...this.graph.id,
                id: cellValue.objectDefinitionId,
            });
        }

        return '';
    }

    public getResizedCells(bound: MxRectangle): [MxCell[], MxRectangle[]] {
        const { width, height, x, y } = this.getRootCell().getGeometry();
        const labelCell = this.getLabelCell();
        const labelGeo = labelCell.getGeometry();

        const wRatio = width / bound.width;
        const hRatio = height / bound.height;
        const xRatioPos = (labelGeo.x - x) / width;
        const yRatioPos = (labelGeo.y - y) / height;
        const xDiff: number = bound.x - x;
        const yDiff: number = bound.y - y;
        const newX = x + bound.width * xRatioPos + xDiff;
        const newY = y + bound.height * yRatioPos + yDiff;
        const newWidth = labelGeo.width / wRatio;
        const newHeight = labelGeo.height / hRatio;

        const newRectangle = new MxRectangle(newX, newY, newWidth, newHeight);

        return [[labelCell], [newRectangle]];
    }

    public getCopyableCell(): MxCell | null {
        return this.rootCell;
    }

    public isCellStyleEditable(cell: MxCell): boolean {
        return true;
    }

    public hasEditableLabel(): boolean {
        return true;
    }

    public shouldChangeTarget(cell: MxCell): boolean {
        return this.rootCell.getId() !== cell.getId();
    }

    public setDefaultStyle(): void {
        const label = this.labelCell;
        const { objectDefinitionId, symbolId } = this.rootCellValue;
        const { symbol } = this.customProps || {};

        if (!objectDefinitionId || !symbol) {
            return;
        }

        const defaultStyle = getDefaultSymbolStyle(symbol, symbolId);
        const model = this.graph.getModel();

        this.graph.setCellStyle(defaultStyle, [this.rootCell]);

        if (symbol.labelStyle) {
            this.graph.setCellStyle(symbol.labelStyle, [label]);
        }

        const labelGeo = model.getGeometry(label).clone();
        const cellGeo = model.getGeometry(this.rootCell).clone();

        const stencil: MxStencil = MxStencilRegistry.getStencil(symbolId);
        const { w0: stencilWidth, h0: stencilHeight } = stencil;
        const { labelWidth, labelHeight, labelXOffset, labelYOffset } = symbol;
        const width = symbol.width || stencilWidth;
        const height = symbol.height || stencilHeight;

        if (labelWidth && labelHeight && labelXOffset && labelYOffset) {
            if (width && height) {
                cellGeo.width = Number(width);
                cellGeo.height = Number(height);
            }

            labelGeo.width = Number(labelWidth);
            labelGeo.height = Number(labelHeight);
            labelGeo.x = cellGeo.x + Number(labelXOffset);
            labelGeo.y = cellGeo.y + Number(labelYOffset);
        }

        model.setGeometry(this.rootCell, cellGeo);
        model.setGeometry(label, labelGeo);
    }

    public getCellForRename(cell: MxCell): MxCell {
        return this.rootCell;
    }

    setSymbolForCell(symbol: Symbol): void {
        const style = getDefaultSymbolStyle(symbol, symbol.id);
        const labelStyle = symbol?.labelStyle || '';

        if (this.rootCellValue?.symbolId) {
            this.rootCellValue.symbolId = symbol.id;
            this.customProps = {
                ...(this.customProps || {}),
                symbol,
            };
        }

        this.serializer.setInitialSymbol(symbol);
        this.graph.setCellStyle(style, [this.rootCell]);
        this.graph.setCellStyle(labelStyle, [this.labelCell]);
    }

    getSymbolStyles(): TObjectStyle {
        const rootCellValue = this.rootCellValue as ObjectInstanceImpl;
        const { symbol, staticSymbol } = this.customProps || {};
        const style = rootCellValue.style || getDefaultSymbolStyle(symbol, rootCellValue.symbolId);
        const labelStyle = rootCellValue.labelStyle || symbol?.labelStyle || '';

        return staticSymbol
            ? { style, labelStyle, connectable: true }
            : getSystemStyles({ ...rootCellValue, style, labelStyle }, this.graph.id);
    }

    private createSymbolCells(): MxCell[] {
        const { graph } = this;
        const rootCellValue = this.rootCellValue as ObjectInstanceImpl;
        const { x, y, symbolId } = rootCellValue;
        const objectDefinitionId = rootCellValue.objectDefinitionId || '';
        const stencil: MxStencil = MxStencilRegistry.getStencil(symbolId);
        const width = rootCellValue.width ? rootCellValue.width : stencil.w0;
        const height = rootCellValue.height ? rootCellValue.height : stencil.h0;
        const { style, labelStyle, connectable } = this.getSymbolStyles();

        const parent: MxCell = rootCellValue?.parent
            ? graph.getModel().getCell(rootCellValue?.parent)
            : graph.getDefaultParent();
        const rootCellId = rootCellValue?.id || uuid();
        const rootCell = graph.insertVertex(parent, rootCellId, rootCellValue, x, y, width, height, style);

        const { labelWidth, labelHeight, labelXOffset, labelYOffset } = rootCellValue;
        const labelValue = new LabelSymbol({ mainCellId: rootCellId, objectDefinitionId });

        const labelCell = graph.insertVertex(
            parent,
            generateLabelCellId(rootCellId),
            labelValue,
            x + (isNullOrUndefined(labelXOffset) ? 0 : labelXOffset),
            y + (isNullOrUndefined(labelYOffset) ? 0 : labelYOffset),
            isNullOrUndefined(labelWidth) ? width : labelWidth,
            isNullOrUndefined(labelHeight) ? height : labelHeight,
            `${labelStyle};${canExtendParentStyleName}=0;`,
        );

        rootCell.setConnectable(connectable);
        labelCell.setConnectable(false);

        return [rootCell, labelCell];
    }

    public getSymbolPreviewGeometry(symbol: Symbol): ISymbolPreviewGeometry {
        const rootGeo = this.rootCell.getGeometry();
        const labelGeo: MxGeometry = this.labelCell.getGeometry();

        return {
            labelHeight: labelGeo.height,
            labelWidth: labelGeo.width,
            labelXOffset: labelGeo?.x - rootGeo.x || 0,
            labelYOffset: labelGeo?.y - rootGeo.y || 0,
        };
    }

    public setObjectToCell(cell: MxCell, object: ObjectDefinitionImpl): void {
        const cellValue: ObjectInstance = cell.getValue();

        cell.setValue({ ...cellValue, objectDefinitionId: object.nodeId.id });

        const oldLabelSymbol: LabelSymbol = this.labelCell.getValue();
        const newLabelSymbol = new LabelSymbol({
            mainCellId: oldLabelSymbol.mainCellId,
            objectDefinitionId: object.nodeId.id,
        });

        this.labelCell.setValue(newLabelSymbol);
    }
}
