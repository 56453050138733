import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
    CHANGE_COMMENT_STATUS,
    DELETE_COMMENT,
    DELETE_COMMENT_MARKER,
    DRAG_COMMENT_MARKER,
    LOAD_COMMENTS,
    PIN_COMMENT,
    SAVE_COMMENT,
    SET_DISPLAY_COMMENT_MARKERS,
    UNPIN_COMMENT,
    SET_CRITICAL_COMMENT,
    SET_UNCRITICAL_COMMENT,
    DELETE_COMMENT_MARKERS_BY_GRAPH,
    SAVE_UPLOADED_FILES,
    SAVE_EDITING_COMMENT,
    TOGGLE_DISPLAY_ALL_COMMENT_MARKERS,
    HIDE_ALL_COMMENT_MARKERS,
} from '../actionsTypes/comments.actionTypes';
import {
    addComments,
    changeIsGraphElement,
    deleteCommentMarker,
    deleteCommentSuccess,
    deleteEditingComment,
    deleteUploadedFilesSuccess,
    editComment,
    pinCommentSuccess,
    saveComment,
    saveUploadedFiles,
    saveUploadedFilesSuccess,
    setDisplayCommentMarkers,
    unpinCommentSuccess,
} from '../actions/comments.actions';
import {
    TChangeCommentStatus,
    TDeleteCommentAction,
    TDeleteCommentMarkerAction,
    TDragCommentMarkerAction,
    TLoadCommentsAction,
    TPinComment,
    TSaveCommentAction,
    TSetCriticalCommentAction,
    TSaveUploadedFilesAction,
    TSetDisplayCommentMarkersAction,
    TSetUncriticalCommentAction,
    TUnpinComment,
    TSaveEditingCommentAction,
} from '../actions/comments.actions.types';
import {
    Comment,
    NodeId,
    PrincipalDescriptor,
    EntityStringRequest,
    CommentFileDTO,
    DiagramElement,
} from '../serverapi/api';
import { CommentsSelectors } from '../selectors/comments.selectors';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { getActiveGraph } from '../selectors/editor.selectors';
import { MxCell, MxConstants, MxEvent, MxPoint } from '../mxgraph/mxgraph';
import { CommentMarker } from '../models/bpm/bpm-model-impl';
import { BPMMxDragSource } from '../mxgraph/util/BPMMxDragSource';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import isMouseEvent = MxEvent.isMouseEvent;
import isLeftMouseButton = MxEvent.isLeftMouseButton;
import { SymbolConstants } from '../models/Symbols.constants';
import { homePageTabId } from '../models/home-page';
import { CommentsDaoService } from '@/services/dao/CommentsDaoService';
import { getCircleBackgroundColor, getCommentStrokeColor } from '../modules/Comments/utils/colorsAndLetterMatching';
import { PrincipalsSelectors } from '../selectors/principals.selectors';
import { getUserLogin } from '../selectors/authorization.selectors';
import { getUserInitials } from './utils';
import { TComment } from '../reducers/comments.reducer.types';
import { CommentStatus } from '../models/commentMarkerConstants';
import { ServerErrorType } from '@/models/serverErrorType';
import { getStore } from '@/store';
import { showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { percentToPoint, pointToPercent } from '@/utils/bpm.mxgraph.utils';
import { BPMMxGraphModel } from '../mxgraph/BPMMxGraphModel.class';
import { differenceBy, uniqBy } from 'lodash-es';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { batchActions } from '../actions/rootReducer.actions';

function* displayCommentMarkers(showMarkers: boolean, commentIds: string[]) {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    if (!activeGraphId) return;

    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
    if (!activeGraph) return;

    const graphModel: BPMMxGraphModel = activeGraph.getModel();

    commentIds.forEach((commentId) => {
        const cell = graphModel.getCell(commentId);

        if (cell) {
            graphModel.setVisible(cell, showMarkers);
        }
    });
}

function* handleLoadComments(action: TLoadCommentsAction) {
    const {
        modelId,
        modelId: { serverId },
        isLoadWithShow,
    } = action.payload;

    if (!serverId || !modelId || modelId.id === homePageTabId.id) {
        return;
    }

    const loadedComments: Comment[] = yield CommentsDaoService.getCommentsByModelId(modelId);
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);
    const users: PrincipalDescriptor[] = yield select(PrincipalsSelectors.getUsers);
    const userLogin: string = yield select(getUserLogin);

    if (graph) {
        loadedComments.forEach((comment) => {
            const author = users?.find((user) => user.login === comment?.author);
            const isAutor = userLogin === comment?.author;
            const initials = getUserInitials(author);
            const marker: CommentMarker = new CommentMarker(comment, initials);
            const color = getCircleBackgroundColor(comment?.author || '', comment?.commentStatus);
            const target = comment.elementId ? graph.model.getCell(comment.elementId) : graph.getDefaultParent();

            let point = new MxPoint(0, 0);
            if (comment.elementId && comment.elementOffsetX && comment.elementOffsetY) {
                const percentPoint = new MxPoint(comment.elementOffsetX, comment.elementOffsetY);
                point = percentToPoint(percentPoint, target);
            } else if (comment.x && comment.y) {
                point.x = comment.x;
                point.y = comment.y;
            }

            if (comment.isGraphElement) {
                // TODO переместить в общий метод/сервис создания Comment
                const cell = graph.insertVertex(
                    target,
                    comment.commentId.id,
                    marker,
                    point.x,
                    point.y,
                    28,
                    28,
                    `shape=ellipse;fillColor=${color};fontFamily='Segoe UI';fontColor=#ffffff;fontSize=12;strokeWidth=4;strokeColor=${getCommentStrokeColor(
                        comment,
                    )}`,
                );
                graph.setTooltips(true);
                graph.getTooltipForCell = (cell: MxCell) => {
                    return cell;
                };
                graph.setCellStyles(MxConstants.STYLE_MOVABLE, isAutor ? '1' : '0', [cell]);
                graph.setCellStyles(MxConstants.STYLE_EDITABLE, '0', [cell]);
                graph.setCellStyles(MxConstants.STYLE_RESIZABLE, '0', [cell]);
                cell.setConnectable(false);
            }
        });
    }

    const comments: Comment[] = yield select(CommentsSelectors.getCommentsByModelId(modelId));
    const newComments: Comment[] = differenceBy(loadedComments, comments, 'commentId.id');

    yield put(
        setDisplayCommentMarkers(
            uniqBy([...comments, ...loadedComments], 'commentId.id').map((comment) => comment.commentId.id),
            !!isLoadWithShow,
        ),
    );

    if (newComments.length > 0) {
        yield put(addComments(modelId, newComments));
    }
}

function* handleSaveEditingComment(action: TSaveEditingCommentAction) {
    const { modelId } = action.payload;

    const editingComment: TComment | undefined = yield select(CommentsSelectors.getEditingComment(modelId));

    if (!editingComment) {
        return;
    }
    const { commentId, parentId, text, files, threadId }: TComment | undefined = editingComment;

    if (!modelId || !commentId) return;

    try {
        const comment: Comment | undefined =
            commentId && (yield select(CommentsSelectors.getCommentById(modelId, commentId)));

        const changedComment = comment
            ? { ...comment, text: text || '', threadId }
            : {
                  commentId: {
                      id: commentId,
                      repositoryId: modelId.repositoryId,
                      serverId: modelId.serverId,
                  },
                  text: text || '',
                  nodeId: modelId.id,
                  parentId,
                  commentStatus: parentId ? undefined : CommentStatus.open,
                  threadId,
              };

        const savedComment: Comment = yield CommentsDaoService.save(changedComment);
        const comments: Comment[] = yield select(CommentsSelectors.getCommentsByModelId(modelId));
        const ids = comments.map((e) => e.commentId.id);

        if (ids.includes(changedComment.commentId.id)) {
            yield put(editComment(savedComment));
        } else {
            yield put(addComments(modelId, [savedComment]));
        }

        if (files?.newFiles) {
            yield put(saveUploadedFiles(files.newFiles, savedComment));
        }

        const commentFilesIds: string[] = files?.commentFiles?.map((file) => file.id) || [];
        const deletedFilesIds: string[] =
            changedComment.commentFiles
                ?.filter((file: CommentFileDTO) => !commentFilesIds.includes(file.id))
                ?.map((file) => file.id) || [];

        for (const fileId of deletedFilesIds) {
            yield CommentsDaoService.deleteCommentFile(
                changedComment.commentId?.repositoryId,
                changedComment.commentId.id,
                fileId,
            );
            yield put(deleteUploadedFilesSuccess(fileId, changedComment.commentId, modelId));
        }
    } finally {
        yield put(deleteEditingComment(modelId));
    }
}

function* handleSaveComment(action: TSaveCommentAction) {
    const { comment } = action.payload;

    yield CommentsDaoService.save(comment);
}

function* handleSaveUploadedFiles(action: TSaveUploadedFilesAction) {
    const { files, comment } = action.payload;
    const { id } = comment.commentId;

    const uploadedFiles: CommentFileDTO[] = yield all(
        files.map(async (file: File) => {
            const response: EntityStringRequest = await CommentsDaoService.uploadCommentFile(
                comment?.commentId?.repositoryId,
                id,
                file,
            );
            const { lastModified, name, size, type, webkitRelativePath } = file as File;

            return { lastModified, name, size, type, webkitRelativePath, id: response.value };
        }),
    );

    yield put(saveUploadedFilesSuccess(comment, uploadedFiles));
}

function* handleDeleteComment(action: TDeleteCommentAction) {
    const { commentId, modelId } = action.payload;
    const answers = yield select(CommentsSelectors.getCommentsByParentId(modelId, commentId.id));
    const deleteSuccess = yield CommentsDaoService.deleteComment(commentId);

    if (deleteSuccess) {
        yield put(deleteCommentSuccess(action.payload.modelId, commentId));
        yield put(deleteCommentMarker(commentId));

        for (const answer of answers) {
            yield put(deleteCommentSuccess(action.payload.modelId, answer.commentId));
            yield put(deleteCommentMarker(answer.commentId));
        }
    }
}

function* handleCommentMarkerPaste(action: TDragCommentMarkerAction) {
    const activeGraphId = yield select(getActiveGraph);
    const users: PrincipalDescriptor[] = yield select(PrincipalsSelectors.getUsers);
    const userLogin: string = yield select(getUserLogin);
    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);

    if (activeGraph) {
        const { comment, isMarkerDragging } = action.payload;
        const { commentSymbol } = SymbolConstants;

        if (!activeGraph.tooltipHandler.enabled) {
            activeGraph.setTooltips(true);
            activeGraph.getTooltipForCell = (cell: MxCell) => {
                return cell;
            };
        }

        const existingCell = activeGraph.getModel().getCell(comment.commentId.id);

        const dropHandler = async (
            graph: BPMMxGraph,
            evt: PointerEvent,
            target: MxCell,
            point: MxPoint,
            elementOffsetPoint?: MxPoint,
        ) => {
            const author = users?.find((user) => user.login === comment?.author);
            const isAutor = userLogin === comment?.author;
            const initials = getUserInitials(author);
            const marker: CommentMarker = new CommentMarker(comment, initials);
            const color = getCircleBackgroundColor(comment?.author || '', comment?.commentStatus);
            const elementId = (target.getValue() as DiagramElement)?.id;

            if (elementId && elementOffsetPoint) {
                const percentPoint = pointToPercent(elementOffsetPoint, target);
                comment.elementOffsetX = percentPoint.x;
                comment.elementOffsetY = percentPoint.y;
                comment.elementId = elementId;
            } else {
                comment.elementOffsetX = undefined;
                comment.elementOffsetY = undefined;
                comment.elementId = undefined;
            }

            if (isMouseEvent(evt) && isLeftMouseButton(evt)) {
                comment.isGraphElement = true;
                comment.x = point.x;
                comment.y = point.y;

                try {
                    await CommentsDaoService.save(comment);
                } catch (err) {
                    if (err.status === ServerErrorType.FORBIDDEN) {
                        getStore().dispatch(showNotificationByType(NotificationType.FORBIDDEN_ERROR));
                    }

                    return err;
                }

                // TODO переместить в общий метод/сервис
                const cell: MxCell = graph.insertVertex(
                    target,
                    comment.commentId.id,
                    marker,
                    point.x,
                    point.y,
                    28,
                    28,
                    `shape=ellipse;fontColor=#ffffff;fillColor=${color};fontSize=12;strokeWidth=4;strokeColor=${getCommentStrokeColor(
                        comment,
                    )}`,
                );
                cell.edge = false;
                cell.connectable = false;
                graph.setCellStyles(MxConstants.STYLE_MOVABLE, isAutor ? '1' : '0', [cell]);
                graph.setCellStyles(MxConstants.STYLE_EDITABLE, '0', [cell]);
                graph.setCellStyles(MxConstants.STYLE_RESIZABLE, '0', [cell]);
                cell.setConnectable(false);

                getStore().dispatch(
                    changeIsGraphElement(comment.commentId, { ...comment.commentId, id: comment.nodeId }, true),
                );
            }
        };

        if (!existingCell && isMarkerDragging) {
            const dragSource = new BPMMxDragSource(commentSymbol, dropHandler, activeGraph);

            dragSource.emulatePointerEvent();
        } else {
            activeGraph.scrollCellToVisible(existingCell, true);
            activeGraph.selectCellsForEvent([existingCell], null);
        }
    }
}

function* handleDeleteCommentMarkerByGraph() {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    const activeGraph: BPMMxGraph | undefined = activeGraphId && instancesBPMMxGraphMap.get(activeGraphId);
    const activeCells: MxCell[] = activeGraph?.getSelectionCells() || [];

    for (const cell of activeCells) {
        const value = cell.getValue();

        if (value instanceof CommentMarker) {
            yield put(deleteCommentMarker(value.comment.commentId));
        }
    }
}

function* handleCommentMarkerDelete(action: TDeleteCommentMarkerAction) {
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph = instancesBPMMxGraphMap.get(activeGraphId);

    if (activeGraph) {
        const { commentId } = action.payload;
        // todo: refactor
        const comments: Comment[] = yield select(CommentsSelectors.getCommentsByModelId(activeGraph.id));
        const comment = comments.find((e) => e.commentId.id === commentId.id);

        if (comment) {
            const changedComment: Comment = JSON.parse(JSON.stringify(comment));
            changedComment.x = undefined;
            changedComment.y = undefined;
            changedComment.elementOffsetX = undefined;
            changedComment.elementOffsetY = undefined;
            changedComment.elementId = undefined;
            changedComment.isGraphElement = false;

            yield put(changeIsGraphElement(comment.commentId, { ...comment.commentId, id: comment.nodeId }, false));
            yield put(saveComment(changedComment));
        }

        const cell = activeGraph.getModel().getCell(commentId.id);

        if (cell) {
            activeGraph.getModel().remove(cell);
        }
    }
}

function* handleSetDisplayCommentMarkers(action: TSetDisplayCommentMarkersAction) {
    const { commentIds, newDisplayStatus } = action.payload;

    yield displayCommentMarkers(newDisplayStatus, commentIds);
}

function* handleToggleDisplayAllCommentMarkers() {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    if (!activeGraphId) return;

    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
    if (!activeGraph) return;

    const graphModel: BPMMxGraphModel = activeGraph.getModel();
    const isGraphHasCommentPanel = yield select(CommentsSelectors.isGraphHasCommentPanel(activeGraphId));
    const allComments: Comment[] = yield select(CommentsSelectors.getCommentsInGraph(activeGraphId));
    const allCommentIds: string[] = allComments.map((comment) => comment.commentId.id);
    const hasVisibleMarkers: boolean = allCommentIds.some((commentId) => {
        const cell = graphModel.getCell(commentId);
        if (cell && cell.isVisible()) return true;

        return false;
    });

    if (!isGraphHasCommentPanel) {
        yield displayCommentMarkers(!hasVisibleMarkers, allCommentIds);
    }
}

function* handleHideAllCommentMarkers() {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    if (!activeGraphId) return;

    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
    if (!activeGraph) return;

    const allComments: Comment[] = yield select(CommentsSelectors.getCommentsInGraph(activeGraphId));
    const allCommentIds: string[] = allComments.map((comment) => comment.commentId.id);

    yield displayCommentMarkers(false, allCommentIds);
}

function* handleChangeCommentStatus(action: TChangeCommentStatus) {
    const { modelId, commentId, commentStatus } = action.payload;
    const serverId: string = modelId.serverId;

    if (commentStatus === CommentStatus.open) {
        const comment: Comment = yield CommentsDaoService.setOpen(commentId.repositoryId, commentId.id, serverId);

        yield put(editComment(comment));
        yield updateCommentMarkers([comment]);
    } else {
        yield CommentsDaoService.setClose(commentId.repositoryId, commentId.id, serverId);
        const comments: Comment[] = yield CommentsDaoService.getCommentsByModelId(modelId);

        yield put(batchActions(comments.map((comment) => editComment(comment))));
        yield updateCommentMarkers(comments);
    }
}

function* updateCommentMarkers(comments: Comment[]) {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    if (!activeGraphId) return;
    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);

    if (!activeGraph) return;
    const graphModel: BPMMxGraphModel = activeGraph.getModel();

    comments.forEach((comment) => {
        const commentCell: MxCell | undefined = graphModel.getCell(comment.commentId.id);
        const color: string = getCircleBackgroundColor(comment?.author || '', comment?.commentStatus);

        if (comment && commentCell) {
            const style: string = `shape=ellipse;fillColor=${color};fontFamily='Segoe UI';fontColor=#ffffff;fontSize=12;strokeWidth=4;strokeColor=${getCommentStrokeColor(
                comment,
            )}`;
            commentCell.setStyle(style);
        }
    });

    activeGraph.refresh();
}

function* handlePinComment(action: TPinComment) {
    const { modelId, comment } = action.payload;

    yield CommentsDaoService.pinComment(
        comment.commentId.repositoryId,
        comment.commentId.id,
        comment.commentId.serverId,
    );
    yield put(pinCommentSuccess(modelId, comment));
}

function* handleUnpinComment(action: TUnpinComment) {
    const { modelId, comment } = action.payload;

    yield CommentsDaoService.unpinComment(
        comment.commentId.repositoryId,
        comment.commentId.id,
        comment.commentId.serverId,
    );
    yield put(unpinCommentSuccess(modelId, comment));
}

function* handleSetCriticalComment(action: TSetCriticalCommentAction) {
    const {
        payload: { commentId },
    } = action;

    const serverId: string = yield select(ServerSelectors.serverId);
    const comment: Comment = yield CommentsDaoService.setCritical(commentId.repositoryId, commentId.id, serverId);

    yield put(editComment(comment));
    yield updateCommentMarkers([comment]);
}

function* handleSetUncriticalComment(action: TSetUncriticalCommentAction) {
    const {
        payload: { commentId },
    } = action;

    const serverId: string = yield select(ServerSelectors.serverId);
    const comment: Comment = yield CommentsDaoService.setUncritical(commentId.repositoryId, commentId.id, serverId);

    yield put(editComment(comment));
    yield updateCommentMarkers([comment]);
}

export function* commentSaga() {
    yield takeEvery(LOAD_COMMENTS, handleLoadComments);
    yield takeEvery(SAVE_EDITING_COMMENT, handleSaveEditingComment);
    yield takeEvery(SAVE_COMMENT, handleSaveComment);
    yield takeEvery(DELETE_COMMENT, handleDeleteComment);
    yield takeEvery(DRAG_COMMENT_MARKER, handleCommentMarkerPaste);
    yield takeEvery(DELETE_COMMENT_MARKERS_BY_GRAPH, handleDeleteCommentMarkerByGraph);
    yield takeEvery(DELETE_COMMENT_MARKER, handleCommentMarkerDelete);
    yield takeEvery(SET_DISPLAY_COMMENT_MARKERS, handleSetDisplayCommentMarkers);
    yield takeEvery(TOGGLE_DISPLAY_ALL_COMMENT_MARKERS, handleToggleDisplayAllCommentMarkers);
    yield takeEvery(HIDE_ALL_COMMENT_MARKERS, handleHideAllCommentMarkers);
    yield takeEvery(CHANGE_COMMENT_STATUS, handleChangeCommentStatus);
    yield takeEvery(PIN_COMMENT, handlePinComment);
    yield takeEvery(UNPIN_COMMENT, handleUnpinComment);
    yield takeEvery(SET_CRITICAL_COMMENT, handleSetCriticalComment);
    yield takeEvery(SET_UNCRITICAL_COMMENT, handleSetUncriticalComment);
    yield takeEvery(SAVE_UPLOADED_FILES, handleSaveUploadedFiles);
}
