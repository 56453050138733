import React, { useMemo, FC } from 'react';
import { Table, Button } from 'antd';
import adminToolTheme from '../../../AdminTools/style/AdminTools.scss';
import { useIntl } from 'react-intl';
import messages from './ModelEventsList.messages';
import { EventDescriptorMacros, Node } from 'src/serverapi/api';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import icDelete from '../../../../resources/icons/delete.svg';

const TABLE_HEIGHT = 250;

type TModelEventsListTable = {
    modelEvents: EventDescriptorMacros[];
    setModelEvents: (modelEvents: EventDescriptorMacros[]) => void;
    scripts: Node[];
    loading: boolean;
};

export const ModelEventsListTable: FC<TModelEventsListTable> = ({ modelEvents, setModelEvents, scripts, loading }) => {
    const intl = useIntl();

    const deleteModelEvent = (id: string) => () => {
        setModelEvents(modelEvents.filter((event) => event.id !== id));
    };

    const columns = useMemo(
        () => [
            {
                title: intl.formatMessage(messages.script),
                dataIndex: 'scriptId',
                width: 208,
                render: (scriptId: string) => scripts.find((script) => script.nodeId.id === scriptId)?.name || scriptId,
            },
            {
                title: intl.formatMessage(messages.eventAction),
                dataIndex: 'eventActions',
                width: 164,
                render: (action: string) => intl.formatMessage(messages[action]),
            },
            {
                title: intl.formatMessage(messages.eventRunStrategy),
                dataIndex: 'eventRunStrategy',
                width: 164,
                render: (eventRunStrategy: string) => intl.formatMessage(messages[eventRunStrategy]),
            },
            {
                width: 90,
                render: (_, record: EventDescriptorMacros) => (
                    <Button type="link" onClick={deleteModelEvent(record.id)} icon={<Icon spriteSymbol={icDelete} />} />
                ),
            },
        ],
        [scripts, modelEvents],
    );

    return (
        <Table
            className={adminToolTheme.dataTable}
            dataSource={modelEvents}
            columns={columns}
            loading={loading}
            pagination={false}
            rowKey="id"
            scroll={{
                y: modelEvents.length ? TABLE_HEIGHT : undefined,
            }}
        />
    );
};
