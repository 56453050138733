import React, { useEffect, useRef, useState } from 'react';
import { Menu, Select } from 'antd';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash-es';
import {
    AttributeType,
    AttributeTypeStyleFormatEnum,
    AttributeTypeStyleRule,
    PresetImage,
    KanbanCardSectorAttribute,
} from '../../../../../../../../serverapi/api';
import theme from './KanbanCardAttributesEditor.scss';
import { KanbanCardAttributeTypeSelector } from './KanbanCardAttributeTypeSelector.component';
import { useDispatch, useSelector } from 'react-redux';
import {
    addKanbanCardAttribute,
    changeKanbanCardAttribute,
    deleteKanbanCardAttributes,
    moveKanbanCardAttribute,
} from '../../../../../../../../actions/workspaceTab/editKanbanCardWorkspaceTab.actions';
import icArrowUp from '../../../../../../../../resources/icons/ic-arrow-full-up.svg';
import icArrowDown from '../../../../../../../../resources/icons/ic-arrow-full-down.svg';
import { NUIButton } from '../../../../../../../UIKit/components/NUIButton/NUIButton.component';
import { Icon } from '../../../../../../../UIKit/components/Icon/Icon.component';
import icDelete from '../../../../../../../../resources/icons/ic-delete-blue.svg';
import { showDeleteConfirmation } from '../../../../../../Button/DeleteButton';
import { KanbanCardAttributeDisplayFormatSelector } from './KanbanCardAttributeDisplayFormatSelector.component';
import { PresetImageSelectors } from '../../../../../../../../selectors/presetSettings/presetImage.selectors';
import { KanbanCardAttributeRulesSelector } from './KanbanCardAttributeRulesSelector.component';
import { StyledAttributeType } from '../../../../../../../../models/bpm/bpm-model-impl';
import { useRulesValidation } from '../../../../../../../../hooks/useAttributeRules';
import { DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID } from '../../../util/KanbanCardEditor.utils';
import { KanbanCardSectorAttributeSettingsFormat } from '../../../../../../../../models/kanban.types';
import { useIntl } from 'react-intl';
import messages from '../KanbanCardEditor.messages';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TKanbanCardAttributesEditorProps = {
    attributeTypes: AttributeType[];
    sectorAttributes: KanbanCardSectorAttribute[];
    presetId: string;
    serverId: string;
    kanbanCardTypeId: string;
    sectorNames: string[];
    setRulesValidation: (validation: boolean) => void;
};

export const KanbanCardAttributesEditor = (props: TKanbanCardAttributesEditorProps) => {
    const { attributeTypes, sectorAttributes, presetId, serverId, kanbanCardTypeId, sectorNames, setRulesValidation } =
        props;
    const sectorNamesRef = useRef(sectorNames);
    const intl = useIntl();

    const dispatch = useDispatch();
    const presetImages: PresetImage[] = useSelector(PresetImageSelectors.listAllByPreset(serverId, presetId));
    const [currentAttribute, setCurrentAttribute] = useState<KanbanCardSectorAttribute | undefined>(
        sectorAttributes?.[0],
    );

    const rulesForValidation = currentAttribute
        ? sectorAttributes
              .filter((attr) => attr.settings.rules.length)
              .reduce((accumulator, currentValue, index) => {
                  accumulator[`${index}`] = { rules: currentValue.settings.rules };
                  return accumulator;
              }, {})
        : {};

    const rulesValidation = useRulesValidation(rulesForValidation);
    useEffect(() => {
        setRulesValidation(rulesValidation);
    }, [rulesValidation]);

    useEffect(() => {
        if (
            sectorNames.length < sectorNamesRef.current.length &&
            !sectorNames.includes(currentAttribute?.sectorName!)
        ) {
            setCurrentAttribute(sectorAttributes?.[0]);
        }
        sectorNamesRef.current = sectorNames;
    }, [sectorNames]);

    const getTabNameByAttrTypeId = (attrTypeId: string, sectorName: string): string => {
        const attributeType = attributeTypes.find((attr) => attr.id === attrTypeId);
        return `(${sectorName}) ${attributeType?.name}`;
    };

    const changeAttribute = (attribute: KanbanCardSectorAttribute): void => {
        setCurrentAttribute(attribute);
        dispatch(
            changeKanbanCardAttribute({
                presetId,
                attribute,
                kanbanCardTypeId,
            }),
        );
    };

    const onAttributeTypeChange = (attribute: KanbanCardSectorAttribute): void => changeAttribute(attribute);

    const onSectorChange = (sectorName: string): void => {
        const changedAttribute = { ...currentAttribute, sectorName } as KanbanCardSectorAttribute;
        changeAttribute(changedAttribute);
    };

    const onMoveAttribute = (isDirectionUp: boolean): void => {
        dispatch(
            moveKanbanCardAttribute({
                presetId,
                attributeId: currentAttribute?.id!,
                kanbanCardTypeId,
                isDirectionUp,
            }),
        );
    };

    const onAttributeDelete = () => {
        const indexOfCurrentAttr = sectorAttributes.findIndex((attr) => attr.id === currentAttribute?.id);
        const indexOfNewCurrentAttr = indexOfCurrentAttr === 0 ? 1 : 0;
        setCurrentAttribute(sectorAttributes[indexOfNewCurrentAttr]);
        dispatch(
            deleteKanbanCardAttributes({
                presetId,
                attributeIds: [currentAttribute?.id!],
                kanbanCardTypeId,
            }),
        );
    };

    const onAttributeFormatChange = (format: AttributeTypeStyleFormatEnum) => {
        const changedAttribute = {
            ...currentAttribute,
            settings: {
                ...currentAttribute?.settings,
                format,
            },
        } as KanbanCardSectorAttribute;
        changeAttribute(changedAttribute);
    };

    const onAttributeTextValueChange = (value: string | undefined) => {
        let changedAttribute: KanbanCardSectorAttribute = cloneDeep(currentAttribute!);
        if (typeof value === 'undefined') {
            delete changedAttribute.textValue;
        } else {
            changedAttribute = {
                ...changedAttribute,
                textValue: value,
            };
        }

        changeAttribute(changedAttribute);
    };

    const onAttributeFontStyleToggle = (propName: string) => {
        const changedAttribute = {
            ...currentAttribute,
            settings: {
                ...currentAttribute?.settings,
                style: {
                    ...currentAttribute?.settings.style,
                    [propName]: !currentAttribute?.settings.style[propName],
                },
            },
        } as KanbanCardSectorAttribute;

        changeAttribute(changedAttribute);
    };

    const onAttributeColorChange = (color: string) => {
        const changedAttribute = {
            ...currentAttribute,
            settings: {
                ...currentAttribute?.settings,
                style: {
                    ...currentAttribute?.settings.style,
                    color,
                },
            },
        } as KanbanCardSectorAttribute;

        changeAttribute(changedAttribute);
    };

    const onAttributeFontSizeChange = (fontSize: number) => {
        const changedAttribute = {
            ...currentAttribute,
            settings: {
                ...currentAttribute?.settings,
                style: {
                    ...currentAttribute?.settings.style,
                    fontSize,
                },
            },
        } as KanbanCardSectorAttribute;

        changeAttribute(changedAttribute);
    };

    const onAttributeNewLineOutputChange = (fromNewLine: boolean) => {
        const changedAttribute = {
            ...currentAttribute,
            fromNewLine,
        } as KanbanCardSectorAttribute;

        changeAttribute(changedAttribute);
    };

    const onAttributeImageIdChange = (imageId: string) => {
        const changedAttribute = {
            ...currentAttribute,
            settings: {
                ...currentAttribute?.settings,
                imageId,
            },
        } as KanbanCardSectorAttribute;

        changeAttribute(changedAttribute);
    };

    const onAttributeImageSizeChange = (imageSize: number) => {
        const changedAttribute = {
            ...currentAttribute,
            settings: {
                ...currentAttribute?.settings,
                imageSize,
            },
        } as KanbanCardSectorAttribute;

        changeAttribute(changedAttribute);
    };

    const onAttributeAdd = () => {
        const newAttribute = {
            attributeTypeId: DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID,
            id: uuid(),
            sectorName: 'A1',
            settings: {
                style: {
                    bold: false,
                    italic: false,
                    underline: false,
                    color: '#808080',
                    fontSize: 9,
                },
                format: KanbanCardSectorAttributeSettingsFormat.TEXT,
                imageId: '',
                imageSize: 15,
                rules: [],
            },
            fromNewLine: false,
            textValue: '',
        } as KanbanCardSectorAttribute;

        const index = sectorAttributes.findIndex((attr) => attr.id === currentAttribute?.id);

        setCurrentAttribute(newAttribute);
        dispatch(
            addKanbanCardAttribute({
                presetId,
                kanbanCardTypeId,
                attribute: newAttribute,
                index,
            }),
        );
    };

    const onAttributeRulesChange = (rules: AttributeTypeStyleRule[]) => {
        const changedAttribute = {
            ...currentAttribute,
            settings: {
                ...currentAttribute?.settings,
                rules,
            },
        } as KanbanCardSectorAttribute;

        changeAttribute(changedAttribute);
    };

    const currentAttributeValueType = attributeTypes.find(
        (attr) => attr.id === currentAttribute?.attributeTypeId,
    )?.valueType;
    const attributeForRulesHook = { valueType: currentAttributeValueType } as StyledAttributeType;

    return (
        <div className={theme.attributesSelector}>
            <div className={theme.leftSideBar}>
                {props.attributeTypes.length ? (
                    <div className={theme.addAttribute}>
                        <Button size="large" onClick={onAttributeAdd}>
                            {intl.formatMessage(messages.addAttribute)}
                        </Button>
                    </div>
                ) : null}

                <div className={theme.menu}>
                    <Menu
                        mode="inline"
                        onSelect={(val) => {
                            const selectedAttr = props.sectorAttributes.find((attr) => attr.id === val.key);
                            setCurrentAttribute(selectedAttr);
                        }}
                        selectedKeys={[currentAttribute?.id || '']}
                    >
                        {props.sectorAttributes?.map(({ id, attributeTypeId, sectorName }) => (
                            <Menu.Item key={id}>{getTabNameByAttrTypeId(attributeTypeId, sectorName)}</Menu.Item>
                        ))}
                    </Menu>
                </div>
            </div>

            <div className={theme.centerBar}>
                {currentAttribute ? (
                    <div className={theme.attributeTab}>
                        <div className={theme.attributeControls}>
                            <Select
                                dropdownClassName={theme.attributeSelectDropdown}
                                value={currentAttribute.sectorName}
                                onChange={onSectorChange}
                                getPopupContainer={(trigger) => trigger.parentNode}
                            >
                                {sectorNames.map((sectorName) => (
                                    <Select.Option value={sectorName} key={sectorName}>
                                        {sectorName}
                                    </Select.Option>
                                ))}
                            </Select>
                            <NUIButton
                                onClick={() => {
                                    onMoveAttribute(true);
                                }}
                            >
                                <Icon spriteSymbol={icArrowUp} />
                            </NUIButton>
                            <NUIButton
                                onClick={() => {
                                    onMoveAttribute(false);
                                }}
                            >
                                <Icon spriteSymbol={icArrowDown} />
                            </NUIButton>
                            <NUIButton
                                onClick={() => {
                                    showDeleteConfirmation({
                                        onDelete: onAttributeDelete,
                                        deleteQuestion: intl.formatMessage(messages.attributeDeletion),
                                        dialogContent: intl.formatMessage(messages.doYouWantToDelete),
                                    });
                                }}
                            >
                                <Icon spriteSymbol={icDelete} />
                            </NUIButton>
                        </div>

                        <KanbanCardAttributeTypeSelector
                            attributeTypes={props.attributeTypes}
                            onChange={onAttributeTypeChange}
                            currentAttribute={currentAttribute}
                        />

                        {currentAttribute.attributeTypeId !== DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID ? (
                            <KanbanCardAttributeRulesSelector
                                attributeForRulesHook={attributeForRulesHook}
                                changeAttributeRules={onAttributeRulesChange}
                                attributeId={currentAttribute.id}
                                currentAttributeValueType={currentAttributeValueType}
                                rules={currentAttribute.settings.rules}
                                presetId={presetId}
                            />
                        ) : null}

                        <KanbanCardAttributeDisplayFormatSelector
                            onAttributeFormatChange={onAttributeFormatChange}
                            currentAttribute={currentAttribute}
                            onAttributeTextValueChange={onAttributeTextValueChange}
                            onAttributeFontStyleToggle={onAttributeFontStyleToggle}
                            onAttributeColorChange={onAttributeColorChange}
                            onAttributeFontSizeChange={onAttributeFontSizeChange}
                            onAttributeNewLineOutputChange={onAttributeNewLineOutputChange}
                            onAttributeImageIdChange={onAttributeImageIdChange}
                            onAttributeImageSizeChange={onAttributeImageSizeChange}
                            presetImages={presetImages}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
