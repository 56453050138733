import React, { FC, ReactNode, useState } from 'react';
import theme from './CompareModels.scss';
import { Button, Table } from 'antd';
import { useIntl } from 'react-intl';
import messages from './CompareModels.messages';
import { CompareResult, NodeId } from '@/serverapi/api';
import { ColumnsType } from 'antd/es/table';
import { useDispatch } from 'react-redux';
import { loadModelById } from '@/actions/loadModel.actions';
import { TCompareModelsProps, TTableData } from './CompareModels.types';
import { openGraphicalComparisonTab } from '@/actions/compareModels.actions';
import cx from 'classnames';
import { Checkbox } from '../UIKit/components/Checkbox/Checkbox.component';

export const CompareModels: FC<TCompareModelsProps> = (props) => {
    const {
        tab: {
            params: { compareResults, comparedModelNodeId1, comparedModelNodeId2 },
        },
    } = props;

    const [showDifferent, setShowDifferent] = useState<boolean>(true);

    const intl = useIntl();
    const dispatch = useDispatch();

    const openModelHandler = (nodeId: NodeId) => {
        dispatch(loadModelById(nodeId));
    };

    const graphicalComparisonHandler = () => {
        dispatch(openGraphicalComparisonTab({ comparedModelNodeId1, comparedModelNodeId2 }));
    };

    const typesWithCalculatedRowSpans: string[] = [];
    const instance1IdsWithCalculatedRowSpans: string[] = [];
    const instance2IdsWithCalculatedRowSpans: string[] = [];
    let lastIndexInGroup = 0;

    const sortedCompareResults = compareResults.filter(
        ({ compareStatus }) => !showDifferent || (showDifferent && compareStatus === 'DIFFERENT'),
    );

    const sourceData: TTableData[] = sortedCompareResults.map((compareResult) => {
        const { type, instance1Id, instance2Id } = compareResult;

        const typeName = messages[type] ? intl.formatMessage(messages[type]) : type;

        /**
         * Для того, чтобы объединить ячейки по горизонтали, необходимо одной из них
         * задать rowSpan равный количеству объединяемых ячеек, а всем остальным
         * задать rowSpan равный 0 (ячейка в таком случае скрывается)
         */
        let rowSpan = 0;
        if (!typesWithCalculatedRowSpans.includes(type) && !(type === 'OBJECT_INSTANCE' || type === 'EDGE_INSTANCE')) {
            rowSpan = sortedCompareResults.filter((rowData) => rowData.type === type).length;
            typesWithCalculatedRowSpans.push(type);
        }

        if (type === 'OBJECT_INSTANCE' || type === 'EDGE_INSTANCE') {
            if (instance1Id && !instance1IdsWithCalculatedRowSpans.includes(instance1Id)) {
                rowSpan = sortedCompareResults.filter((rowData) => rowData.instance1Id === instance1Id).length;
                instance1IdsWithCalculatedRowSpans.push(instance1Id);
            }
            if (instance2Id && !instance2IdsWithCalculatedRowSpans.includes(instance2Id)) {
                rowSpan = sortedCompareResults.filter((rowData) => rowData.instance2Id === instance2Id).length;
                instance2IdsWithCalculatedRowSpans.push(instance2Id);
            }
        }

        lastIndexInGroup += rowSpan;

        let value1 = compareResult.attribute1IsAbsent
            ? intl.formatMessage(messages.attributeIsAbsent)
            : compareResult.value1;
        let value2 = compareResult.attribute2IsAbsent
            ? intl.formatMessage(messages.attributeIsAbsent)
            : compareResult.value2;

        if (
            (type === 'OBJECT_INSTANCE' || type === 'EDGE_INSTANCE') &&
            compareResult.attributeTypeId.toLowerCase() === 'id'
        ) {
            value1 = compareResult.instance1Id;
            value2 = compareResult.instance2Id;
        }

        return {
            ...compareResult,
            value1,
            value2,
            typeName,
            rowSpan,
            lastIndexInGroup,
        } as TTableData;
    });

    const elemTypeCellsPropsSetter = (data: TTableData) => {
        return {
            rowSpan: data.rowSpan,
            style: {
                backgroundColor: 'var(--BgNeutral10)',
                borderBottomWidth: '2px',
                borderRightWidth: '2px',
                borderColor: 'var(--BorderSecondaryNeutral200)',
                paddingLeft: '22px',
            },
        };
    };

    const dataCellsPropsSetter = (data: TTableData, index: number = 0, isValueCol?: boolean) => {
        const color = index % 2 === 0 ? 'var(--BgNeutral10)' : 'var(--BgNeutral20)';
        const bbw = index + 1 === data.lastIndexInGroup ? '2px' : '1px';
        const biew = isValueCol ? '0' : '2px';

        return {
            style: {
                backgroundColor: color,
                fontWeight: 400,
                borderBottomWidth: bbw,
                borderColor: 'var(--BorderSecondaryNeutral200)',
                borderInlineEndWidth: biew,
            },
        };
    };

    const valueCellRenderer = (value: any, record: TTableData, isFirstValue?: boolean): ReactNode => {
        const { type, attributeTypeId, compareStatus } = record;
        const isDifferentValue = compareStatus === 'DIFFERENT';
        const highLightValue = isDifferentValue && !showDifferent;
        if (attributeTypeId.toLowerCase() === 'id') {
            if (type === 'MODEL') {
                const nodeId = isFirstValue ? comparedModelNodeId1 : comparedModelNodeId2;

                return (
                    <div key="test" onClick={() => openModelHandler(nodeId)} className={theme.link}>
                        {value}
                    </div>
                );
            }
        }

        return <div className={cx({ [theme.differentValue]: highLightValue })}>{value}</div>;
    };

    const columns: ColumnsType<TTableData> = [
        {
            title: intl.formatMessage(messages.elementType),
            dataIndex: 'typeName',
            key: 'typeName',
            width: 224,
            onCell: elemTypeCellsPropsSetter,
            render: (value: any, record: TTableData) => {
                if (record.rowSpan !== 0 && (record.type === 'OBJECT_INSTANCE' || record.type === 'EDGE_INSTANCE')) {
                    const instanceId = record.instance1Id || record.instance2Id;
                    if (instanceId) {
                        const compareResult: CompareResult | undefined = sortedCompareResults.find(
                            (res) =>
                                res.attributeTypeId === 'name' &&
                                record.type === res.type &&
                                (res.instance1Id === instanceId || res.instance2Id === instanceId),
                        );
                        const name: string | undefined = compareResult?.value1 || compareResult?.value2;
                        if (name)
                            return (
                                <>
                                    <div>{value}</div>
                                    <div title={name} className={theme.title}>
                                        {`"${name}"`}
                                    </div>
                                </>
                            );
                    }
                }
                return <>{value}</>;
            },
        },
        {
            title: intl.formatMessage(messages.attributeTypeName),
            dataIndex: 'attributeTypeName',
            key: 'attributeTypeName',
            width: 204,
            onCell: dataCellsPropsSetter,
        },
        {
            title: intl.formatMessage(messages.valueModel1),
            dataIndex: 'value1',
            key: 'value1',
            width: 356,
            onCell: (data, index) => dataCellsPropsSetter(data, index, true),
            render: (value: any, record: TTableData) => valueCellRenderer(value, record, true),
        },
        {
            title: intl.formatMessage(messages.valueModel2),
            dataIndex: 'value2',
            key: 'value2',
            width: 356,
            onCell: (data, index) => dataCellsPropsSetter(data, index, true),
            render: (value: any, record: TTableData) => valueCellRenderer(value, record),
        },
    ];

    const getUniqKey = (record: TTableData) => {
        return `${record.type}_${record.attributeTypeId}_${record.instance1Id || ''}_${record.instance2Id || ''}`;
    };

    return (
        <div className={theme.container}>
            <div className={theme.controlsPanelContainer}>
                <Button type="primary" onClick={graphicalComparisonHandler}>
                    {intl.formatMessage(messages.graphicalComparison)}
                </Button>
                <Checkbox
                    onChange={() => {
                        setShowDifferent((showDifferent) => !showDifferent);
                    }}
                    checked={showDifferent}
                    children={intl.formatMessage(messages.showDifferent)}
                    className={theme.checkBox}
                />
            </div>
            <Table
                bordered
                rowKey={getUniqKey}
                className={theme.dataTable}
                columns={columns}
                dataSource={sourceData}
                pagination={false}
                scroll={{
                    y: 'max-content',
                }}
            />
        </div>
    );
};
