import { Button, Col } from 'antd';
import React, { ReactNode } from 'react';
import { DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import theme from './MatrixSettingsDialog.scss';
import { MatrixHeader } from '../../serverapi/api';
import { TreeNode } from '../../models/tree.types';

type TMatrixHeaderProps = {
    deleteHeader: () => void
    pushDownHeader: () => void
    pushUpHeader: () => void;
    renderHeader: (header: MatrixHeader, treeItemsById: { [id: string]: TreeNode }) => ReactNode;
    header: MatrixHeader;
    treeItemsById: { [id: string]: TreeNode };
};

export const MatrixHeaderButtons = (props: TMatrixHeaderProps) => {
    const { deleteHeader, pushDownHeader, pushUpHeader, renderHeader, header, treeItemsById } = props;

    return (
        <li key={header.orderNumber}>
            <div style={{ marginTop: 8, marginBottom: 15 }} className={theme.matrixHeaderContainer}>
                <Col span={16}>{renderHeader(header, treeItemsById)}</Col>
                <Col span={2}>
                    <Button className={theme.tableButton} type="text" onClick={deleteHeader}>
                        <DeleteOutlined />
                    </Button>
                </Col>
                <Col span={2}>
                    <Button className={theme.tableButton} type="text" onClick={pushDownHeader}>
                        <DownOutlined />
                    </Button>
                </Col>
                <Col span={2}>
                    <Button className={theme.tableButton} type="text" onClick={pushUpHeader}>
                        <UpOutlined />
                    </Button>
                </Col>
            </div>
        </li>
    );
};
