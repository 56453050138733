import { defineMessages } from 'react-intl';

export default defineMessages({
    newCard: {
        id: 'KanbanModelType.newCard',
        defaultMessage: 'Новая карточка',
    },
    editSymbol: {
        id: 'KanbanModelType.editSymbol',
        defaultMessage: 'Редактировать символ',
    },
    editObject: {
        id: 'KanbanModelType.editObject',
        defaultMessage: 'Редактировать тип объекта',
    },
    name: {
        id: 'KanbanModelType.name',
        defaultMessage: 'Название',
    },
    guid: {
        id: 'KanbanModelType.guid',
        defaultMessage: 'GUID',
    },
    newSymbol: {
        id: 'KanbanModelType.newSymbol',
        defaultMessage: 'Новый символ',
    },
    description: {
        id: 'KanbanModelType.description',
        defaultMessage: 'Описание',
    },
    modelType: {
        id: 'KanbanModelType.modelType',
        defaultMessage: 'Тип модели',
    },
    kanbanBoard: {
        id: 'KanbanModelType.kanbanBoard',
        defaultMessage: 'Канбан-доска',
    },
    modelTypeGroup: {
        id: 'KanbanModelType.modelTypeGroup',
        defaultMessage: 'Группа типа модели',
    },
    generalSettings: {
        id: 'KanbanModelType.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    cancel: {
        id: 'KanbanModelType.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'KanbanModelType.save',
        defaultMessage: 'Сохранить',
    },
    create: {
        id: 'KanbanModelType.create',
        defaultMessage: 'Создать',
    },
    remove: {
        id: 'KanbanModelType.remove',
        defaultMessage: 'Удалить',
    },
    objectsAndCards: {
        id: 'KanbanModelType.objectsAndCards',
        defaultMessage: 'Объекты и карточки',
    },
    columnsSettings: {
        id: 'KanbanModelType.columnsSettings',
        defaultMessage: 'Настройка колонок',
    },
    automation: {
        id: 'KanbanModelType.automation',
        defaultMessage: 'Автоматизация',
    },
    objectType: {
        id: 'KanbanModelType.objectType',
        defaultMessage: 'Тип объекта',
    },
    symbolCard: {
        id: 'KanbanModelType.symbolCard',
        defaultMessage: 'Символ / Карточка',
    },
    addCards: {
        id: 'KanbanModelType.addCards',
        defaultMessage: 'Добавление карточек',
    },
    card: {
        id: 'KanbanModelType.card',
        defaultMessage: 'Карточка',
    },
    column: {
        id: 'KanbanModelType.column',
        defaultMessage: 'Колонка',
    },
    generalColumnsSettings: {
        id: 'KanbanModelType.generalColumnsSettings',
        defaultMessage: 'Общие настройки колонок',
    },
    hideColumnHeaders: {
        id: 'KanbanModelType.hideColumnHeaders',
        defaultMessage: 'Скрыть заголовки колонок',
    },
    sameColumnsWidth: {
        id: 'KanbanModelType.sameColumnsWidth',
        defaultMessage: 'Одинаковая ширина колонок',
    },
    automaticWindowWidthSize: {
        id: 'KanbanModelType.automaticWindowWidthSize',
        defaultMessage: 'Автоматический размер на всю ширину окна',
    },
    fixedColumnWidth: {
        id: 'KanbanModelType.fixedColumnWidth',
        defaultMessage: 'Фиксированная ширина каждой колонки',
    },
    columnsList: {
        id: 'KanbanModelType.columnsList',
        defaultMessage: 'Список колонок',
    },
    addColumn: {
        id: 'KanbanModelType.addColumn',
        defaultMessage: 'Добавить колонку',
    },
    columnName: {
        id: 'KanbanModelType.columnName',
        defaultMessage: 'Название колонки',
    },
    columnID: {
        id: 'KanbanModelType.columnID',
        defaultMessage: 'ID колонки',
    },
    requiredFieldError: {
        id: 'KanbanModelType.requiredFieldError',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    sameColumnIDError: {
        id: 'KanbanModelType.sameColumnIDError',
        defaultMessage: 'Колонка с таким ID уже есть',
    },
    columnWidthAdaptive: {
        id: 'KanbanModelType.columnWidthAdaptive',
        defaultMessage: 'Ширина колонки адаптивная',
    },
    columnWidthFixed: {
        id: 'KanbanModelType.columnWidthFixed',
        defaultMessage: 'Ширина колонки фиксированная',
    },
    headerColor: {
        id: 'KanbanModelType.headerColor',
        defaultMessage: 'Цвет шапки',
    },
    iconInHeader: {
        id: 'KanbanModelType.iconInHeader',
        defaultMessage: 'Иконка в шапке',
    },
    columnColor: {
        id: 'KanbanModelType.columnColor',
        defaultMessage: 'Цвет колонки',
    },
    limitCardsObjectsCountInColumn: {
        id: 'KanbanModelType.limitCardsObjectsCountInColumn',
        defaultMessage: 'Лимитировать количество карточек/объектов в колонке',
    },
    automationBoardPlacing: {
        id: 'KanbanModelType.automationBoardPlacing',
        defaultMessage: 'Автоматическое размещение на доске',
    },
    automationObjectChanging: {
        id: 'KanbanModelType.automationObjectChanging',
        defaultMessage: 'Автоматическое изменение объектов',
    },
    today: {
        id: 'KanbanModelType.today',
        defaultMessage: 'Сегодня',
    },
    currentMonth: {
        id: 'KanbanModelType.currentMonth',
        defaultMessage: 'Текущий месяц',
    },
    unsupportedValueType: {
        id: 'KanbanModelType.unsupportedValueType',
        defaultMessage: 'Неподдерживаемый тип значения',
    },
    definePlacingRules: {
        id: 'KanbanModelType.definePlacingRules',
        defaultMessage: 'Определите правила, согласно которым объекты автоматически располагаются на доске.',
    },
    addRule: {
        id: 'KanbanModelType.addRule',
        defaultMessage: 'Добавить правило',
    },
    symbolType: {
        id: 'KanbanModelType.symbolType',
        defaultMessage: 'Тип символа',
    },
    searchArea: {
        id: 'KanbanModelType.searchArea',
        defaultMessage: 'Зона поиска:',
    },
    allDatabase: {
        id: 'KanbanModelType.allDatabase',
        defaultMessage: 'Вся база',
    },
    filterConditionsSummarized: {
        id: 'KanbanModelType.filterConditionsSummarized',
        defaultMessage: 'Условия фильтрации (суммируются)',
    },
    objectAttribute: {
        id: 'KanbanModelType.objectAttribute',
        defaultMessage: 'Атрибут объекта',
    },
    condition: {
        id: 'KanbanModelType.condition',
        defaultMessage: 'Условие',
    },
    equals: {
        id: 'KanbanModelType.equals',
        defaultMessage: 'Равен',
    },
    value: {
        id: 'KanbanModelType.value',
        defaultMessage: 'Значение',
    },
    placementInColumn: {
        id: 'KanbanModelType.placementInColumn',
        defaultMessage: 'Размещение в колонке:',
    },
    defineObjectChangingRules: {
        id: 'KanbanModelType.defineObjectChangingRules',
        defaultMessage: 'Определите правила, согласно которым объекты автоматически изменяются.',
    },
    ruleName: {
        id: 'KanbanModelType.ruleName',
        defaultMessage: 'Название правила',
    },
    actionConditionsSummarized: {
        id: 'KanbanModelType.actionConditionsSummarized',
        defaultMessage: 'Условия для выполнения действий (суммируются)',
    },
    if: {
        id: 'KanbanModelType.if',
        defaultMessage: 'Если',
    },
    objectInColumn: {
        id: 'KanbanModelType.objectInColumn',
        defaultMessage: 'Объект в колонке',
    },
    performActions: {
        id: 'KanbanModelType.performActions',
        defaultMessage: 'Выполнить действия',
    },
    action: {
        id: 'KanbanModelType.action',
        defaultMessage: 'Действие',
    },
    changeObjectAttribute: {
        id: 'KanbanModelType.changeObjectAttribute',
        defaultMessage: 'Изменить атрибут объекта',
    },
    attribute: {
        id: 'KanbanModelType.attribute',
        defaultMessage: 'Атрибут',
    },
});
