import { Checkbox, Input, InputNumber } from 'antd';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../../../../../../models/bpm/bpm-model-impl';
import { EndObjectInstance, ModelNode } from '../../../../../../serverapi/api';
import { Dialog } from '../../../../../UIKit/components/Dialog/Dialog.component';
import messages from '../../../../messages/SimulationModeling.messages';
import theme from './EventDialogs.scss';

type TSimulationEditorProps = WrappedComponentProps & {
    endObject?: EndObjectInstance;
    model?: ModelNode;
    object?: ObjectDefinitionImpl;
    objectDiagramElement?: ObjectInstanceImpl;
    onSubmitEndObject: (endObject: EndObjectInstance) => void;
    onClose: () => void;
};

const EndObjectEditorDialogComponent = (props: TSimulationEditorProps) => {
    const { intl, onSubmitEndObject, onClose, model, object, endObject, objectDiagramElement } = props;
    const [eventsMaxRuns, setEventsMaxRuns] = useState<number>(props.endObject?.maxEventsCount || 0);
    const modelName = endObject?.modelName || model?.name || '';
    const elementName = endObject?.elementName || object?.name || '';
    const submit = () => {
        const endObjectId = endObject?.id;
        onSubmitEndObject({
            id: {
                elementId: endObjectId?.elementId || objectDiagramElement?.id || '',
                modelId: endObjectId?.modelId || model?.nodeId.id || '',
            },
            modelName,
            elementName,
            maxEventsCount: eventsMaxRuns,
        });
    };

    return (
        <Dialog
            open
            okText={<div data-test={`simulation_end-event_save-button`}>{intl.formatMessage(messages.save)}</div>}
            cancelText={<div data-test={`simulation_end-event_cancel-button`}>{intl.formatMessage(messages.cancel)}</div>}
            onOk={submit}
            onCancel={onClose}
            title={intl.formatMessage(messages.simulationEndAddTitle)}
        >
            <div>
                <div className={theme.guidInput} data-test="simulation_end-event-ID">
                    <label>{intl.formatMessage(messages.labelID)}</label>
                    <br />
                    <Input
                        readOnly
                        size="large"
                        value={
                            modelName && elementName
                                ? `${modelName}/${elementName}`
                                : intl.formatMessage(messages.copyIDElement)
                        }
                    />
                </div>
                <div className={theme.eventRow}>
                    <div className={theme.eventColumn} data-test="simulation_max-end-events">
                        <Checkbox />
                        <label>{intl.formatMessage(messages.endRun)}</label>
                        <InputNumber value={eventsMaxRuns} onChange={setEventsMaxRuns as any} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const IntlComponent = injectIntl(EndObjectEditorDialogComponent);

export { IntlComponent as EndObjectEditorDialog };
