import { ConfigProvider, DatePicker, Form, Input, Select } from 'antd';
import React, { FC, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { createExternalSessionRequest } from '../../../actions/externalSession.actions';
import { principalRequest } from '../../../actions/principal.actions';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { PrincipalsSelectors } from '../../../selectors/principals.selectors';
import { PrincipalDescriptor, ExternalSessionRequestLocaleEnum } from '../../../serverapi/api';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { Locale } from '../../Header/components/Header/header.types';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './AddExternalSessionDialog.messages';
import theme from './AddExternalSessionDialog.scss';
import dayjs, {Dayjs} from 'dayjs';
import { LocalesService } from '../../../services/LocalesService';

type TAddExternalSessionDialogProps = {
    serverId: string;
};

type TFormValues = {
    name: string;
    locale: ExternalSessionRequestLocaleEnum;
    expireAt: Dayjs;
    userId: number;
};

export const AddExternalSessionDialog: FC<TAddExternalSessionDialogProps> = ({ serverId }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const users: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getUsers);

    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(principalRequest(serverId, true));
    }, []);

    const onClose = useCallback(() => {
        dispatch(closeDialog(DialogType.ADD_EXTERNAL_SESSION));
    }, []);

    const handleSubmit = () => {
        form.validateFields().then((values: TFormValues) => {
            const { name, locale, expireAt, userId } = values;

            dispatch(
                createExternalSessionRequest(
                    {
                        name,
                        locale,
                        expireAt: expireAt && expireAt.startOf('day').unix(),
                        userId,
                    },
                    serverId,
                ),
            );
            onClose();
        });
    };

    return (
        <Dialog
            className={theme.eventModalDialogWrapper}
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.title)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            okText={<div data-test="external-sessions_add-key-dialog_confirm">{intl.formatMessage(messages.create)}</div>}
            cancelText={<div data-test="external-sessions_add-key-dialog_cancel">{intl.formatMessage(messages.cancel)}</div>}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label={intl.formatMessage(messages.tokenName)}
                    rules={[{ required: true, whitespace: true, message: intl.formatMessage(messages.requiredField) }]}
                    data-test="external-sessions_add-key-dialog_name"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="locale"
                    label={intl.formatMessage(messages.locale)}
                    rules={[{ required: true, whitespace: true, message: intl.formatMessage(messages.requiredField) }]}
                    data-test="external-sessions_add-key-dialog_locale"
                >
                    <Select>
                        {Object.values(Locale).map((locale) => (
                            <Select.Option key={locale} value={locale.toUpperCase()}>
                                {locale}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <ConfigProvider locale={LocalesService.checkAndSetLocale(intl.locale)}>
                    <Form.Item name="expireAt" label={intl.formatMessage(messages.exriresAt)} data-test="external-sessions_add-key-dialog_date">
                        <DatePicker
                            className={theme.datePicker}
                            placeholder={intl.formatMessage(messages.selectDate)}
                            format="DD-MM-YYYY"
                            disabledDate={(date) => date.isBefore(dayjs())}
                            showToday={false}
                        />
                    </Form.Item>
                </ConfigProvider>
                <Form.Item
                    name="userId"
                    label={intl.formatMessage(messages.user)}
                    rules={[{ required: true, message: intl.formatMessage(messages.requiredField) }]}
                    data-test="external-sessions_add-key-dialog_user"
                >
                    <Select allowClear>
                        {users.map((user) => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.login}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Dialog>
    );
};
