import { Modal, Select } from 'antd';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { shouldCallModalSubmitHandler } from '../../../../../../../services/utils/ModalHelper';
import { TGroup } from '../../util/GroupedTypesTable.types';
import messages from './GroupSelectionDialog.messages';
import theme from './GroupSelectionDialog.scss';

type TDialogProps = WrappedComponentProps & {
    groups: TGroup[];
    onSubmit: (groupId?: TGroup) => void;
    onClose: () => void;
};

const GroupSelectionDialogComponent = (props: TDialogProps) => {
    const { intl, onClose, onSubmit, groups } = props;
    const [groupId, setGroupId] = useState<string>('');
    const currentLocale = useSelector(getCurrentLocale);

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    onSubmit(groups.find((g) => g.id === groupId));
                }
            }}
        >
            <Modal
                open
                okText={intl.formatMessage(messages.move)}
                cancelText={intl.formatMessage(messages.cancel)}
                onOk={() => onSubmit(groups.find((g) => g.id === groupId))}
                onCancel={onClose}
                title={intl.formatMessage(messages.groupSelectionDialog)}
            >
                <div className={theme.selectGroupType}>
                    <span>{intl.formatMessage(messages.selectGroupTitle)}</span>
                    <br />
                    <Select onChange={(val: string) => setGroupId(val)} value={groupId}>
                        {groups.map((group: TGroup) => {
                            return (
                                <Select.Option key={group.id} value={group.id}>
                                    {LocalesService.internationalStringToString(group.multilingualName, currentLocale)}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
            </Modal>
        </div>
    );
};

const IntlComponent = injectIntl(GroupSelectionDialogComponent);

export { IntlComponent as GroupSelectionDialog };
