import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import icDelete from '../../../../../../resources/icons/delete.svg';
import { handleDeleteSelectedCellsFromActiveGraphAction } from '../../../../../../actions/editor.actions';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton.component';

interface IDeleteButtonProps {
    disabled: boolean | undefined;
    tooltipTitle: string;
}

export const DeleteButton: FC<IDeleteButtonProps> = (props) => {
    const { disabled, tooltipTitle } = props;
    const dispatch = useDispatch();

    const onDeleteAction = () => {
        dispatch(handleDeleteSelectedCellsFromActiveGraphAction());
    };

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icDelete}
            tooltipTitle={tooltipTitle}
            dataTest="general-toolbar_delete-btn"
            onClick={onDeleteAction}
        />
    );
};
