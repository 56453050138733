import type { TableRowSelection } from 'antd/es/table/interface';
import type {
    TEdgeDefinitionData,
    TEdgeManagementDialogProps,
    TEdgeManagementDialogSubmitData,
} from './EdgeManagementDialog.types';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Table } from 'antd';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { FormGroup } from '@/modules/UIKit/components/Forms/components/FormGroup/FormGroup.component';
import messages from './EdgeManagementDialog.messages';
import theme from './EdgeManagementDialog.scss';
import { LocalesService } from '@/services/LocalesService';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';

type TableData = {
    id: string;
    sourceObjectName: string;
    targetObjectName: string;
    edgeName: string;
    edgeTypeName: string;
};

const getSelectedKeys = (data: TEdgeDefinitionData[]): string[] =>
    data.filter((el) => el.edgeInstances.length > 0).map((el) => el.edgeDefinition.nodeId.id);

const getSelectionDiff = (data: TEdgeDefinitionData[], selectedRowKeys: string[]): TEdgeManagementDialogSubmitData =>
    data.reduce<TEdgeManagementDialogSubmitData>(
        (acc, el) => {
            if (el.edgeInstances.length === 0 && selectedRowKeys.includes(el.edgeDefinition.nodeId.id)) {
                acc.checked.push(el);
            }

            if (el.edgeInstances.length > 0 && !selectedRowKeys.includes(el.edgeDefinition.nodeId.id)) {
                acc.unchecked.push(el);
            }

            return acc;
        },
        { checked: [], unchecked: [] },
    );

const EdgeManagementDialog: FC<TEdgeManagementDialogProps> = (props) => {
    const { data, open, onSubmit, onCancel } = props;
    const [selectedRowKeys, setSelectedRowKeys] = useState(getSelectedKeys(data));
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();

    const tableData = useMemo(
        () =>
            data.map((el) => ({
                id: el.edgeDefinition.nodeId.id,
                sourceObjectName: LocalesService.internationalStringToString(
                    el.sourceObjectDefinition.multilingualName,
                    currentLocale,
                ),
                targetObjectName: LocalesService.internationalStringToString(
                    el.targetObjectDefinition.multilingualName,
                    currentLocale,
                ),
                edgeName: LocalesService.internationalStringToString(el.edgeDefinition.multilingualName, currentLocale),
                edgeTypeName: LocalesService.internationalStringToString(el.edgeType?.multilingualName, currentLocale),
            })),
        [data],
    );

    const columns = useMemo(
        () => [
            {
                title: intl.formatMessage(messages.sourceObjectName),
                dataIndex: 'sourceObjectName',
                key: 'sourceObjectName',
            },
            {
                title: intl.formatMessage(messages.edgeTypeName),
                dataIndex: 'edgeTypeName',
                key: 'edgeTypeName',
            },
            {
                title: intl.formatMessage(messages.edgeName),
                dataIndex: 'edgeName',
                key: 'edgeName',
            },
            {
                title: intl.formatMessage(messages.targetObjectName),
                dataIndex: 'targetObjectName',
                key: 'targetObjectName',
            },
        ],
        [],
    );

    const handleRowSelectionChange = useCallback((keys: string[]) => setSelectedRowKeys(keys), []);

    const handleSubmit = () => onSubmit(getSelectionDiff(data, selectedRowKeys));

    const rowSelection: TableRowSelection<TableData> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (keys) => handleRowSelectionChange(keys as string[]),
        columnWidth: 44,
    };

    return (
        <Dialog
            open={open}
            title={intl.formatMessage(messages.title)}
            width={800}
            onOk={handleSubmit}
            onCancel={onCancel}
            className={theme.dialog}
            okText={intl.formatMessage(messages.save)}
            cancelText={intl.formatMessage(messages.cancel)}
        >
            <FormGroup className={theme.formGroup}>
                <Form.Item>
                    <div className={theme.tableContainer}>
                        <Table
                            rowKey={({ id }: TableData) => id}
                            className={theme.table}
                            columns={columns}
                            dataSource={tableData}
                            rowSelection={rowSelection}
                            size="middle"
                            pagination={false}
                            scroll={{ y: 'max-content' }}
                        />
                    </div>
                </Form.Item>
            </FormGroup>
        </Dialog>
    );
};

export { EdgeManagementDialog };
