import type { MenuProps, DropDownProps } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import * as React from 'react';
import { Dropdown } from 'antd';
import theme from './contextMenu.scss';
import classNames from 'classnames';

type TContextMenuProps = {
    menuId?: string;
    visible: boolean;
    overlay: MenuProps['items'] | null;
    children: React.ReactNode;
    onClick?: (event: MenuInfo) => void;
} & Omit<DropDownProps, 'overlay'>;

export const ContextMenu = (props: TContextMenuProps) => {
    const { overlay, children, visible, placement, className, overlayClassName, trigger = ['click'], onClick } = props;

    if (overlay === null) {
        return <div>{children}</div>;
    }

    return (
        <Dropdown
            placement={placement}
            trigger={trigger}
            menu={{ items: overlay, onClick }}
            open={visible}
            onOpenChange={props.onOpenChange}
            className={className}
            overlayClassName={classNames(theme.dropdownContainer, overlayClassName)}
            dropdownRender={(menu) => {
                return (
                    <div
                        className="tree-item_context-menu"
                        data-test="tree-item_context-menu"
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        {React.cloneElement(menu as React.ReactElement)}
                    </div>
                );
            }}
            destroyPopupOnHide
            align={{ overflow: { adjustY: true, shiftY: true, adjustX: true, shiftX: true } }}
        >
            {children}
        </Dropdown>
    );
};
