import React from 'react';
import { NodeId } from '../../../../serverapi/api';
import { useSelector } from 'react-redux';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import theme from './CommentTooltipHeader.scss';

type TCommentTooltipHeader = {
    modelId: NodeId;
    commentId: string;
    isSmallFormat: boolean;
};

export const CommentTooltipHeader = (props: TCommentTooltipHeader) => {
    const { modelId, commentId, isSmallFormat } = props;
    const createdDate: string = useSelector(CommentsSelectors.getCreateDate(modelId, commentId));
    const authorName: string = useSelector(CommentsSelectors.getCommentAuthorName(modelId, commentId));

    return (
        <div className={isSmallFormat ? theme.smallCommentHeader : theme.commentHeader}>
            <div className={theme.commentHeaderName}>{`${authorName}`}</div>
            <div className={theme.commentHeaderDate}>{createdDate}</div>
        </div>
    );
};
