import {
    ADD_APPROVALS,
    ADD_MY_APPROVALS,
    CHANGE_APPROVAL_ASSISTANTS_LIST,
    CHANGE_CHECK_ALL_APPROVALS,
    CHECK_APPROVALS,
    CLEAR_APPROVALS,
    COMMENT_APPROVAL,
    COPY_APPROVAL,
    CREATE_APPROVAL,
    DELETE_APPROVALS,
    DELETE_APPROVALS_FROM_STORE,
    EDIT_APPROVAL,
    EDIT_EXISTING_APPROVAL,
    GO_TO_APPROVAL,
    LOAD_APPROVALS_ON_INIT,
    LOAD_NODE_APPROVALS,
    OPEN_APPROVALS_TAB,
    OPEN_APPROVAL_TEMPLATE_DIALOG,
    REFRESH_MY_APPROVALS,
    SUBMIT_APPROVAL,
    UPDATE_APPROVAL,
    VOTE_APPROVAL,
} from '@/actionsTypes/approval.actionTypes';
import {
    TAddApprovalsAction,
    TAddApprovalsPayload,
    TAddMyApprovalsAction,
    TAddMyApprovalsPayload,
    TChangeApprovalAssistantsListAction,
    TChangeCheckAllApprovalsAction,
    TCheckApprovalsAction,
    TClearApprovalsAction,
    TCommentApprovalAction,
    TCommentApprovalPayload,
    TCopyApprovalAction,
    TCopyApprovalPayload,
    TCreateApprovalAction,
    TCreateApprovalPayload,
    TDeleteApprovalAction,
    TDeleteApprovalPayload,
    TDeleteApprovalsFromStoreAction,
    TEditApprovalAction,
    TEditApprovalPayload,
    TEditExistingApprovalAction,
    TEditExistingApprovalPayload,
    TGoToApprovalAction,
    TGoToApprovalPayload,
    TLoadApprovalsOnInitAction,
    TLoadNodeApprovalsAction,
    TLoadNodeApprovalsPayload,
    TOpenApprovalTemplateDialogAction,
    TOpenApprovalTemplateDialogPayload,
    TOpenApprovalsTabAction,
    TRefreshMyApprovalsAction,
    TRefreshMyApprovalsPayload,
    TSubmitApprovalAction,
    TSubmitApprovalPayload,
    TUpdateApprovalAction,
    TUpdateApprovalPayload,
    TVoteApprovalAction,
    TVoteApprovalPayload,
} from './approval.actions.types';
import { ApprovalAssistantsDTO, NodeId } from '../serverapi/api';

export const createApproval = (payload: TCreateApprovalPayload): TCreateApprovalAction => ({
    type: CREATE_APPROVAL,
    payload,
});

export const addApprovals = (payload: TAddApprovalsPayload): TAddApprovalsAction => ({
    type: ADD_APPROVALS,
    payload,
});

export const editApproval = (payload: TEditApprovalPayload): TEditApprovalAction => ({
    type: EDIT_APPROVAL,
    payload,
});

export const deleteApprovals = (payload: TDeleteApprovalPayload): TDeleteApprovalAction => ({
    type: DELETE_APPROVALS,
    payload,
});

export const deleteApprovalsFromStore = (payload: TDeleteApprovalPayload): TDeleteApprovalsFromStoreAction => ({
    type: DELETE_APPROVALS_FROM_STORE,
    payload,
});

export const clearApprovals = (): TClearApprovalsAction => ({
    type: CLEAR_APPROVALS,
});

export const submitApproval = (payload: TSubmitApprovalPayload): TSubmitApprovalAction => ({
    type: SUBMIT_APPROVAL,
    payload,
});

export const loadNodeApprovals = (payload: TLoadNodeApprovalsPayload): TLoadNodeApprovalsAction => ({
    type: LOAD_NODE_APPROVALS,
    payload,
});

export const commentApproval = (payload: TCommentApprovalPayload): TCommentApprovalAction => ({
    type: COMMENT_APPROVAL,
    payload,
});

export const editExistingApproval = (payload: TEditExistingApprovalPayload): TEditExistingApprovalAction => ({
    type: EDIT_EXISTING_APPROVAL,
    payload,
});

export const openApprovalsTab = (): TOpenApprovalsTabAction => ({
    type: OPEN_APPROVALS_TAB,
});

export const checkApprovals = (nodeIds: NodeId[]): TCheckApprovalsAction => ({
    type: CHECK_APPROVALS,
    payload: {
        nodeIds,
    },
});

export const changeCheckAllApprovals = (): TChangeCheckAllApprovalsAction => ({
    type: CHANGE_CHECK_ALL_APPROVALS,
});

export const voteApproval = (payload: TVoteApprovalPayload): TVoteApprovalAction => ({
    type: VOTE_APPROVAL,
    payload,
});

export const updateApproval = (payload: TUpdateApprovalPayload): TUpdateApprovalAction => ({
    type: UPDATE_APPROVAL,
    payload,
});

export const copyApproval = (payload: TCopyApprovalPayload): TCopyApprovalAction => ({
    type: COPY_APPROVAL,
    payload,
});

export const openApprovalTemplateDialog = (
    payload: TOpenApprovalTemplateDialogPayload,
): TOpenApprovalTemplateDialogAction => ({
    type: OPEN_APPROVAL_TEMPLATE_DIALOG,
    payload,
});

export const changeApprovalAssistantsList = (payload: ApprovalAssistantsDTO): TChangeApprovalAssistantsListAction => ({
    type: CHANGE_APPROVAL_ASSISTANTS_LIST,
    payload,
});

export const addMyApprovals = (payload: TAddMyApprovalsPayload): TAddMyApprovalsAction => ({
    type: ADD_MY_APPROVALS,
    payload,
});

export const refreshMyApprovals = (payload: TRefreshMyApprovalsPayload): TRefreshMyApprovalsAction => ({
    type: REFRESH_MY_APPROVALS,
    payload,
});

export const goToApproval = (payload: TGoToApprovalPayload): TGoToApprovalAction => ({
    type: GO_TO_APPROVAL,
    payload,
});

export const loadApprovalsOnInit = (): TLoadApprovalsOnInitAction => ({
    type: LOAD_APPROVALS_ON_INIT,
});
