import { Input, InputNumber } from 'antd';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../../../../../../models/bpm/bpm-model-impl';
import { ModelNode, StartObjectInstance } from '../../../../../../serverapi/api';
import { Dialog } from '../../../../../UIKit/components/Dialog/Dialog.component';
import messages from '../../../../messages/SimulationModeling.messages';
import theme from './EventDialogs.scss';

type TDialogProps = WrappedComponentProps & {
    startObject?: StartObjectInstance;
    model?: ModelNode;
    object?: ObjectDefinitionImpl;
    objectDiagramElement?: ObjectInstanceImpl;
    onSubmitStartObject: (startObject: StartObjectInstance) => void;
    onClose: () => void;
};

const StartEventEditorDialogComponent = (props: TDialogProps) => {
    const { intl, onSubmitStartObject, onClose, model, object, startObject, objectDiagramElement } = props;
    const [eventsCount, setEventsCount] = useState(props.startObject?.countCreatedEvents || 0);
    const [eventsMaxRuns, setEventsMaxRuns] = useState<number>(props.startObject?.startEventsMaxRuns || 0);
    const [interval, setInterval] = useState(props.startObject?.intervalCreatedEvents || 0);
    const modelName = startObject?.modelName || model?.name || '';
    const elementName = startObject?.elementName || object?.name || '';
    const submit = () => {
        const startObjectId = startObject?.id;
        onSubmitStartObject({
            id: {
                elementId: startObjectId?.elementId || objectDiagramElement?.id || '',
                modelId: startObjectId?.modelId || model?.nodeId.id || '',
            },
            modelName,
            elementName,
            intervalCreatedEvents: interval,
            countCreatedEvents: eventsCount,
            startEventsMaxRuns: eventsMaxRuns,
        });
    };

    return (
        <Dialog
            open
            okText={<div data-test={`simulation_start-event_save-button`}>{intl.formatMessage(messages.save)}</div>}
            cancelText={<div data-test={`simulation_start-event_cancel-button`}>{intl.formatMessage(messages.cancel)}</div>}
            onOk={submit}
            onCancel={onClose}
            title={intl.formatMessage(messages.simulationStartAddTitle)}
        >
            <div>
                <div className={theme.guidInput} data-test="simulation_start-event-ID">
                    <label>{intl.formatMessage(messages.labelID)}</label>
                    <br />
                    <Input
                        readOnly
                        size="large"
                        value={
                            modelName && elementName
                                ? `${modelName}/${elementName}`
                                : intl.formatMessage(messages.copyIDElement)
                        }
                    />
                </div>
                <div className={theme.eventRow}>
                    <div className={theme.eventColumn} data-test="simulation_start-events-number">
                        <label>{intl.formatMessage(messages.eventsCount)}</label>
                        <br />
                        <InputNumber value={eventsCount} onChange={setEventsCount as any} />
                    </div>
                    <div className={theme.eventColumn} data-test="simulation_start-events-interval">
                        <label>{intl.formatMessage(messages.interval)}</label>
                        <br />
                        <InputNumber value={interval} onChange={setInterval as any} />
                    </div>
                </div>
                <div className={theme.eventColumn} data-test="simulation_max-start-events">
                    <label>{intl.formatMessage(messages.maxRuns)}</label>
                    <br />
                    <InputNumber value={eventsMaxRuns} onChange={setEventsMaxRuns as any} />
                </div>
            </div>
        </Dialog>
    );
};

const IntlComponent = injectIntl(StartEventEditorDialogComponent);

export { IntlComponent as StartEventEditorDialog };
