import type { StyledAttributeType } from '../../../../../models/bpm/bpm-model-impl';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select } from 'antd';
import { Icon } from '../../../../UIKit/components/Icon/Icon.component';
import editorTheme from '../AttributesEditor.scss';
import theme from './AttributeTypeSelector.scss';
import { FormGroup } from '../../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import messages from './AttributeTypeSelector.messages';
import attributeTypeMessages from '../../../../AdminTools/Methodology/messages/AttributeType.messages';
import icDelete from '../../../../../resources/icons/delete.svg';
import {
    floatingAttributesChangeTypeAction,
    floatingAttributesRemoveStyleAction,
} from '../../../actions/FloatingAttributes.actions';
import { FloatingAttributesPanelSelectors } from '@/modules/FloatingAttributes/selectors/FloatingAttributes.selectors';
import { TAttributeDiscriminator } from '@/modules/FloatingAttributes/FloatingAttributes.types';

type TAttributeTypeSelectorProps = {
    attribute: StyledAttributeType;
    disabled?: boolean;
};

const divider = '______';

export const AttributeTypeSelector = (props: TAttributeTypeSelectorProps) => {
    const { attribute, disabled } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const allAttributes = useSelector(FloatingAttributesPanelSelectors.getAttributeTypesForSelect);

    const onChangeHandler = (selectValue: string) => {
        const [attributeId, attributeDiscriminator] = selectValue.split(divider);
        dispatch(floatingAttributesChangeTypeAction(attributeId, attributeDiscriminator as TAttributeDiscriminator));
    };

    const onRemoveHandler = () => dispatch(floatingAttributesRemoveStyleAction());
    const definitionAttributes = allAttributes
        .filter((attr) => attr.attributeDiscriminator === 'DEFINITION')
        .sort((a, b) => a.name.localeCompare(b.name));

    const instanceAttributes = allAttributes
        .filter((attr) => attr.attributeDiscriminator === 'INSTANCE')
        .sort((a, b) => a.name.localeCompare(b.name));

    const systemAttributes = allAttributes
        .filter((attr) => attr.attributeDiscriminator === 'SYSTEM')
        .sort((a, b) => a.name.localeCompare(b.name));

    const modelAttributes = allAttributes
        .filter((attr) => attr.attributeDiscriminator === 'MODEL_SYSTEM')
        .sort((a, b) => a.name.localeCompare(b.name));

    const userModelAttributes = allAttributes
        .filter((attr) => attr.attributeDiscriminator === 'MODEL')
        .sort((a, b) => a.name.localeCompare(b.name));
    return (
        <Form>
            <FormGroup>
                {definitionAttributes.length ||
                instanceAttributes.length ||
                systemAttributes.length ||
                modelAttributes.length ? (
                    <>
                        <h3 className={editorTheme.formRow}>{intl.formatMessage(messages.attribute)}</h3>
                        <Form.Item>
                            <div className={theme.selectAttributeWrapper}>
                                <Select
                                    className={theme.attributeSelect}
                                    data-test="open_list_with_attributes"
                                    value={`${attribute.id}${divider}${attribute.attributeDiscriminator}`}
                                    onChange={onChangeHandler}
                                    disabled={disabled}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                >
                                    {definitionAttributes.length && (
                                        <Select.OptGroup label={intl.formatMessage(messages.definitionAttributes)}>
                                            {definitionAttributes.map((el) => (
                                                <Select.Option
                                                    data-test={`select_attribute_from_list_${el.name}_def`}
                                                    value={`${el.id}${divider}${el.attributeDiscriminator}`}
                                                    key={`${el.id}_def`}
                                                >
                                                    {el.name}
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    )}
                                    {instanceAttributes.length && (
                                        <Select.OptGroup label={intl.formatMessage(messages.instanceAttributes)}>
                                            {instanceAttributes.map((el) => (
                                                <Select.Option
                                                    data-test={`select_attribute_from_list_${el.name}_inst`}
                                                    value={`${el.id}${divider}${el.attributeDiscriminator}`}
                                                    key={`${el.id}_inst`}
                                                >
                                                    {el.name}
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    )}
                                    {systemAttributes.length && (
                                        <Select.OptGroup label={intl.formatMessage(messages.systemAttributes)}>
                                            {systemAttributes.map((el) => (
                                                <Select.Option
                                                    data-test={`select_attribute_from_list_${el.name}_sys`}
                                                    value={`${el.id}${divider}${el.attributeDiscriminator}`}
                                                    key={`${el.id}_sys`}
                                                >
                                                    {el.name}
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    )}
                                    {modelAttributes.length && (
                                        <Select.OptGroup label={intl.formatMessage(messages.modelAttributes)}>
                                            {modelAttributes.map((el) => (
                                                <Select.Option
                                                    data-test={`select_attribute_from_list_${el.name}_model`}
                                                    value={`${el.id}${divider}${el.attributeDiscriminator}`}
                                                    key={`${el.id}_model`}
                                                >
                                                    {el.name}
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    )}
                                    {userModelAttributes.length && (
                                        <Select.OptGroup label={intl.formatMessage(messages.userModelAttributes)}>
                                            {userModelAttributes.map((el) => (
                                                <Select.Option
                                                    data-test={`select_attribute_from_list_${el.name}_user_model`}
                                                    value={`${el.id}${divider}${el.attributeDiscriminator}`}
                                                    key={`${el.id}_user_model`}
                                                >
                                                    {el.name}
                                                </Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    )}
                                </Select>
                                {!disabled && (
                                    <div
                                        data-test="delete-attribute-around-object_btn"
                                        className={editorTheme.deleteButton}
                                    >
                                        <Icon onClick={onRemoveHandler} spriteSymbol={icDelete} />
                                    </div>
                                )}
                            </div>
                        </Form.Item>
                        <div className={theme.formatRow}>
                            <span>{intl.formatMessage(messages.format)}:</span>
                            <Input
                                className={theme.formatValue}
                                value={
                                    attribute.valueType
                                        ? intl.formatMessage(attributeTypeMessages[attribute.valueType])
                                        : ''
                                }
                                disabled
                            />
                        </div>
                    </>
                ) : (
                    <>{intl.formatMessage(messages.noAvailableItems)}</>
                )}
            </FormGroup>
        </Form>
    );
};
