import React, { FC } from 'react';
import { WrappedComponentProps } from 'react-intl';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Button, Form, Row, Select } from 'antd';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import messages from './ServerForm.messages';
import css from './theme/ServerSelect.scss';
import { withTheme } from '../../../utils/withTheme';
import { TServerEntity } from '../../../models/entities.types';
import IconInputServerComponent from '../../UIKit/components/Icons/IconInputServer.component';
import electron from '../../../electron';
import { useCheckTextOverflow } from '../../../hooks/useCheckTextOverflow';

const { Option } = Select;
type TServerSelectProps = WrappedComponentProps & {
    availableServers: TServerEntity[];
    selectedServerKey: string;
    onChange: (id: string) => void;
    onServerEdit: (id: string) => void;
    onServerAdd: () => void;
    theme: {
        option?: string;
        group?: string;
        optionIcon?: string;
        icon?: string;
        item?: string;
        row?: string;
        select?: string;
        editButton?: string;
        addButton?: string;
        staticServerIcon?: string;
        disable_pointer_events?: string;
    };
};

const RawServerSelect: FC<TServerSelectProps> = (props) => {
    const { intl, onServerEdit, onServerAdd, onChange, availableServers, theme, selectedServerKey } = props;

    const isTextOverflow = useCheckTextOverflow('.ant-select-selection-item');

    return (
        <FormGroup className={theme.group}>
            <Form.Item className={theme.item}>
                <Row className={theme.row}>
                    <IconInputServerComponent color="#FFFFFF" className={theme.staticServerIcon} />
                    {availableServers && availableServers.length ? (
                        <Select
                            disabled={!electron}
                            className={`${theme.select} ${isTextOverflow ? '' : theme.disable_pointer_events}`}
                            placeholder={intl.formatMessage(messages.serverUrl)}
                            onChange={onChange}
                            notFoundContent={intl.formatMessage(messages.serversNotFound)}
                            value={selectedServerKey}
                        >
                            {availableServers.map((s) => (
                                <Option key={s.id} value={s.id} title={s.url}>
                                    {s.name}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <Select
                            className={`${theme.select} ${isTextOverflow ? '' : theme.disable_pointer_events}`}
                            placeholder={intl.formatMessage(messages.serverUrl)}
                            notFoundContent={intl.formatMessage(messages.serversNotFound)}
                            value={intl.formatMessage(messages.serverName)}
                        />
                    )}
                    {electron && (
                        <>
                            <Button
                                className={theme.editButton}
                                onClick={() => onServerEdit(selectedServerKey)}
                                disabled={!selectedServerKey}
                                style={selectedServerKey ? { color: 'white' } : {}}
                            >
                                <SettingOutlined className={theme.icon} />
                            </Button>
                            <Button className={theme.addButton} onClick={onServerAdd} style={{ color: 'white' }}>
                                <PlusOutlined className={theme.icon} />
                            </Button>
                        </>
                    )}
                </Row>
            </Form.Item>
        </FormGroup>
    );
};

export const ServerSelect = withTheme(Symbol('UserEdit'), css)(RawServerSelect);
