import { Checkbox, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import messages from '../../messages/ScriptContext.messages';
import { useIntl } from 'react-intl';
import theme from './ScriptContextComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ScriptSelectors } from '../../../../selectors/script.selectors';
import { AllowedScriptContext, NodeId, ScriptNode } from '../../../../serverapi/api';
import { fetchNodes } from '../../../../actions/nodes.actions';
import { useEventDataSource } from '../../../../hooks/scriptContext.hook';
import { AllPresetsMetaDataRequest } from '../../../../../src/actions/notation.actions';
import { scriptContextFetch } from '../../../../../src/actions/scriptContext.actions';
import { scriptContextLoadingStatus } from '../../../../selectors/scriptContext.selectors';
import { saveScriptContextSuccess } from '../../../../actions/entities/script.actions';

type TScriptContext = {
    script: ScriptNode;
};

export const ScriptContext = (props: TScriptContext) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const scriptNode: ScriptNode = props.script;
    const scriptContext: AllowedScriptContext = useSelector(ScriptSelectors.context(scriptNode.nodeId));
    const allowedNodeIds: NodeId[] | undefined = scriptContext?.allowedNodeIds;
    const [isEveryContext, setIsEveryContext] = useState<boolean>(!!scriptContext?.allowAll);
    const isLoading: boolean = useSelector(scriptContextLoadingStatus);
    const scriptNodeWithNewContext = { ...scriptNode, allowedScriptContext: scriptContext };
    const dataSource: {
        type: string;
        paramValue: JSX.Element;
    }[] = useEventDataSource(scriptNodeWithNewContext);

    useEffect(() => {
        dispatch(fetchNodes(scriptContext?.allowedNodeIds || []));
    }, [allowedNodeIds]);

    useEffect(() => {
        dispatch(AllPresetsMetaDataRequest());
    }, []);

    const columns = [
        {
            title: intl.formatMessage(messages.type),
            dataIndex: 'type',
            key: 'type',
            width: '420px',
        },
        {
            title: intl.formatMessage(messages.runningContext),
            dataIndex: 'paramValue',
            key: 'paramValue',
        },
    ];

    const handleChangeEveryContext = () => {
        // todo: если мы сохраняем данные в сторе, зачем нам тут стейт?
        const newState: boolean = !isEveryContext;
        setIsEveryContext(newState);
        dispatch(scriptContextFetch({ ...scriptContext, allowAll: newState }, scriptNodeWithNewContext.nodeId));
    };

    const handleChangeEventHandlerScript = () => {
        dispatch(
            saveScriptContextSuccess(
                { ...scriptContext, eventHandlerScript: !scriptContext.eventHandlerScript },
                scriptNodeWithNewContext.nodeId,
            ),
        );
    };

    return (
        <div>
            <Checkbox
                data-test="any-launch-context_checkbox"
                className={theme.checkBox}
                onChange={handleChangeEveryContext}
                checked={isEveryContext}
                disabled={isLoading}
            >
                {intl.formatMessage(messages.everyContext)}
            </Checkbox>
            <Checkbox data-test="event-script_checkbox"
                className={theme.checkBox}
                onChange={handleChangeEventHandlerScript}
                checked={!!scriptContext.eventHandlerScript}
                disabled={isLoading}
            >
                {intl.formatMessage(messages.eventHandlerScript)}
            </Checkbox>
            <div>
                <Table
                    rowKey={(report) => `${report.type}`}
                    className={theme.table}
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};
