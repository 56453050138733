import type { InternationalString, NodeId, NodeTypeEnum, FileFolderNodeDTOFileFolderTypeEnum } from '../serverapi/api';
import type { TreeNode } from './tree.types';
import { FileFolderType, TreeItemType } from '../modules/Tree/models/tree';
import { LocalesService } from '../services/LocalesService';
import { UML_OBJECT_TYPE } from '../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';

export const REPOSITORY_ID_SPIKE: string = 'repository-id';

export const MATRIX_DIAGRAM_TYPE_ID = 'MatrixEditor'; // todo: Почему нельзя было использовать TreeItemType?

export const WIKI_DIAGRAM_TYPE_ID = 'WikiEditor';

export const SCRIPT_DIAGRAM_TYPE_ID = 'ScriptEditor';

export const SCRIPT_DIAGRAM_TYPE_NAME = 'Script Editor';

export const UML_OBJECT_SORT_ORDER = {
    [UML_OBJECT_TYPE.ATTRIBUTE]: 100,
    [UML_OBJECT_TYPE.METHOD]: 200,
    [UML_OBJECT_TYPE.RECEPTION]: 300,
    [UML_OBJECT_TYPE.CLASS]: 400,
    [UML_OBJECT_TYPE.PACKAGE]: 500,
};

export const compareNodes = <
    T extends {
        objectTypeId?: string;
        nodeId: NodeId;
        type: NodeTypeEnum | TreeItemType;
        multilingualName?: InternationalString;
    },
>(
    n1: T,
    n2: T,
    childrenIdOrder: string[] = [],
): number => {
    const n1ObjectTypeId: string | undefined = n1.type === TreeItemType.ObjectDefinition ? n1.objectTypeId : undefined;
    const n2ObjectTypeId: string | undefined = n2.type === TreeItemType.ObjectDefinition ? n2.objectTypeId : undefined;
    const isNodesUmlObjects = n1ObjectTypeId?.startsWith('o_uml') && n2ObjectTypeId?.startsWith('o_uml');

    const r: number = isNodesUmlObjects
        ? compareTreeUmlObjectType(n1, n2, childrenIdOrder)
        : compareTreeObjectType(n1.type, n2.type);

    if (r === 0) {
        const name1 = LocalesService.internationalStringToString(n1.multilingualName).toLowerCase();
        const name2 = LocalesService.internationalStringToString(n2.multilingualName).toLowerCase();

        return name1 === name2 ? 0 : name1 > name2 ? 1 : -1;
    }

    return r;
};

export const compareTreeUmlObjectType = <T extends { objectTypeId?: string; nodeId: NodeId }>(
    n1: T,
    n2: T,
    childrenIdOrder: string[] = [],
): number => {
    const n1ObjectTypeId = n1.objectTypeId;
    const n2ObjectTypeId = n2.objectTypeId;
    let r: number;

    if (n1ObjectTypeId === n2ObjectTypeId) {
        const order1 = childrenIdOrder.findIndex((id) => id === n1.nodeId.id);
        const order2 = childrenIdOrder.findIndex((id) => id === n2.nodeId.id);

        r = order1 === order2 ? 0 : (order1 > order2 && order2 !== -1) || (order1 === -1 && order2 !== -1) ? 1 : -1;
    } else {
        const order1 = UML_OBJECT_SORT_ORDER[n1ObjectTypeId || ''];
        const order2 = UML_OBJECT_SORT_ORDER[n2ObjectTypeId || ''];

        r = order1 === order2 ? 0 : order1 > order2 ? 1 : -1;
    }

    return r;
};

export const orderSortCompareTreeUml =
    (childrenIdOrder?: string[]) =>
    <T extends { objectTypeId?: string; nodeId: NodeId }>(a: T, b: T) =>
        compareTreeUmlObjectType(a, b, childrenIdOrder);

export const compareTree = (n1: TreeNode, n2: TreeNode, childrenIdOrder: string[] = []): number => {
    if (n1.fileFolderType && n2.fileFolderType) {
        const r = compareFileFolderType(n1.fileFolderType, n2.fileFolderType);

        if (r === 0) {
            const fileFolderType1 = n1.fileFolderType?.toLowerCase();
            const fileFolderType2 = n2.fileFolderType?.toLowerCase();

            return fileFolderType1 === fileFolderType2 ? 0 : fileFolderType1 > fileFolderType2 ? 1 : -1;
        }

        return r;
    }

    return compareNodes(n1, n2, childrenIdOrder);
};

export const TREE_OBJECT_TYPE_SORT_ORDER = {
    [TreeItemType.Server]: 100,
    [TreeItemType.Repository]: 200,
    [TreeItemType.Folder]: 300,
    [TreeItemType.Model]: 400,
    [TreeItemType.FileFolder]: 600,
    [TreeItemType.ScriptFolder]: 700,
    [TreeItemType.Script]: 800,
    [TreeItemType.Matrix]: 900,
    [TreeItemType.Wiki]: 1100,
    [TreeItemType.Spreadsheet]: 1150,
    [TreeItemType.Kanban]: 1180,
    [TreeItemType.SimulationModeling]: 1200,
    [TreeItemType.AdminTool]: 1300,
    [TreeItemType.ObjectDefinition]: 1500,
    [TreeItemType.File]: 1600,
};

export const FILE_FOLDER_TYPE_SORT_ORDER = {
    [FileFolderType.COMMON]: 100,
    [FileFolderType.USER_PRIVATE]: 200,
    [FileFolderType.TEMP]: 300,
    [FileFolderType.NONE]: 400,
};

export const compareTreeObjectType = (
    type1: TreeItemType | NodeTypeEnum,
    type2: TreeItemType | NodeTypeEnum,
): number => {
    const order1 = TREE_OBJECT_TYPE_SORT_ORDER[type1];
    const order2 = TREE_OBJECT_TYPE_SORT_ORDER[type2];

    return order1 === order2 ? 0 : order1 > order2 ? 1 : -1;
};

export const compareFileFolderType = (
    type1: FileFolderNodeDTOFileFolderTypeEnum,
    type2: FileFolderNodeDTOFileFolderTypeEnum,
): number => {
    const order1 = FILE_FOLDER_TYPE_SORT_ORDER[type1];
    const order2 = FILE_FOLDER_TYPE_SORT_ORDER[type2];

    return order1 === order2 ? 0 : order1 > order2 ? 1 : -1;
};
