import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Form } from 'antd';
import messages from './DiagramScriptDialog.messages';
import { useIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './DiagramScriptDialog.scss';
import SearchBar from '../../Permissions/components/SearchBar/SearchBar.component';
import { TreeNode } from '../../../models/tree.types';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TreeItemType } from '../../Tree/models/tree';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { Tree } from '../../Tree/components/Tree/Tree.component';
import { getFilteredTreeNodeScripts } from '../../../selectors/fetchedScripts.selectors';
import { DiagramElement, NodeId } from '../../../serverapi/api';
import { closeDialog } from '../../../actions/dialogs.actions';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { treeItemsClearSelection } from '@/actions/tree.actions';

type TChooseScriptDialogExternalProps = {
    serverId: string;
    nodeId: NodeId;
    dialogType: DialogType;
    modelId?: NodeId;
    element: DiagramElement | undefined;
    title?: string;
    okButtonTitle?: string;
    errorMessage?: string;
    isContextSensitive?: boolean;
    isEvent?: boolean;
    onSubmit: (selectedScript: TreeNode) => void;
};

export const ChooseScriptDialog = (props: TChooseScriptDialogExternalProps) => {
    const {
        serverId,
        nodeId,
        modelId,
        element,
        title,
        okButtonTitle,
        errorMessage,
        isContextSensitive,
        dialogType,
        isEvent,
        onSubmit,
    } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const selectedScriptItem: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode(dialogType));

    const filteredScriptsTreeNodes = useSelector(
        getFilteredTreeNodeScripts({ serverId, filter, nodeId, modelId, element, isContextSensitive, isEvent }),
    );

    const onCloseDialog = () => {
        dispatch(closeDialog(dialogType));
        dispatch(treeItemsClearSelection());
    };

    const handleSubmit = () => {
        if (selectedScriptItem?.type === TreeItemType.Script) {
            onSubmit(selectedScriptItem);
            onCloseDialog();
        } else {
            setError(true);
        }
    };

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onCloseDialog}
            title={title || intl.formatMessage(messages.chooseScript)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            okText={okButtonTitle || intl.formatMessage(messages.save)}
            cancelText={intl.formatMessage(messages.scriptFormDeclineButton)}
        >
            {error && (
                <Alert type="error" message={errorMessage || intl.formatMessage(messages.scriptNotSelected)} showIcon />
            )}
            <Form layout="vertical">
                <Form.Item label={intl.formatMessage(messages.searchLabel)}>
                    <SearchBar value={filter} placeholder="" disabled={false} onSearch={setFilter} />
                </Form.Item>
                <div className={theme.tree} data-test="script-select-dialog_tree">
                    {filteredScriptsTreeNodes.length ? (
                        <Tree
                            includeFilterForSelect={[TreeItemType.Script]}
                            data={filteredScriptsTreeNodes}
                            treeName={dialogType}
                        />
                    ) : (
                        <div className={theme.noScripts}>{intl.formatMessage(messages.noScriptAvailable)}</div>
                    )}
                </div>
            </Form>
        </Dialog>
    );
};
