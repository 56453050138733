import { Button, Tooltip } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import icReadMode from '../../../../../../resources/icons/ic-read-mode.svg';
import icEditMode from '../../../../../../resources/icons/ic-edit-mode.svg';
import messages from '../../../../messages/CommonToolbar.messages';
import menuItemTheme from '../../../items/MenuItem.scss';
import { EditorMode } from '../../../../../../models/editorMode';
import { NodeId } from '../../../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { editorModeChangedPrepareAction } from '../../../../../../actions/editor.actions';
import { workspaceTabSetParams } from '../../../../../../actions/tabs.actions';
import { UserProfileSelectors } from '../../../../../../selectors/userProfile.selectors';

interface IEditModeButtonProps {
    compact: boolean | undefined;
    isActiveScheme: boolean;
    isReadMode: boolean | undefined;
    nodeId: NodeId;
    modelTypeId: string | undefined;
    unlockDisabled: boolean;
};

export const EditModeButton: FC<IEditModeButtonProps> = (props) => {
    const { compact, isActiveScheme, isReadMode, nodeId, modelTypeId } = props;
    const intl = useIntl();
    const isReadOnly: boolean = !useSelector(UserProfileSelectors.isModelUpdatable(nodeId, modelTypeId)) || props.unlockDisabled;
    const dispatch = useDispatch();

    const editModeTooltipTitle = props.unlockDisabled
        ? intl.formatMessage(messages.unlockDisabled)
        : isReadMode
            ? intl.formatMessage(messages.toEditMode)
            : intl.formatMessage(messages.toReadMode);

    const onModeChange = (mode: EditorMode) => {
        dispatch(editorModeChangedPrepareAction(mode));
    };

    const isBlockNavigationArrows = (workspaceId: NodeId, undoManager: { undo: boolean; redo: boolean }) => {
        dispatch(workspaceTabSetParams(workspaceId, { undoManager }));
    };

    const handleModeChange = () => {
        if (isReadMode) {
            onModeChange(EditorMode.Edit);
        } else {
            onModeChange(EditorMode.Read);
            isBlockNavigationArrows(nodeId, { undo: false, redo: false });
        }
    };

    return (
        <div className={!compact ? menuItemTheme.modeGroup_full : undefined}>
            <Tooltip
                mouseLeaveDelay={0}
                placement="topLeft"
                overlayClassName={menuItemTheme.customTooltip}
                title={isActiveScheme ? editModeTooltipTitle : ''}
            >
                <Button
                    data-test="graph-general-toolbar_edit-mode-button"
                    className={menuItemTheme.modeButton}
                    onClick={handleModeChange}
                    disabled={isReadOnly}
                >
                    <Icon className={menuItemTheme.editModeIcon} spriteSymbol={isReadMode ? icReadMode : icEditMode} dataTest={isReadMode ? "graph-general-toolbar_mode-button_read" : "graph-general-toolbar_mode-button_edit"}/>
                </Button>
            </Tooltip>
        </div>
    );
};
