// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DecompositionUnsavedModelDialog__dialog__buU8I .ant-modal-footer .ant-btn{margin-top:0;vertical-align:top}.DecompositionUnsavedModelDialog__content__D7m8x{max-width:402px;color:var(--text);font-size:14px}.DecompositionUnsavedModelDialog__cancel__b7lXL{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.DecompositionUnsavedModelDialog__cancelIcon__xaH9W{margin-right:5px}", "",{"version":3,"sources":["webpack://./src/modules/DecompositionUnsavedModelDialog/components/DecompositionUnsavedModelDialog.scss"],"names":[],"mappings":"AAEQ,2EACI,YAAA,CACA,kBAAA,CAIZ,iDACI,eAAA,CACA,iBAAA,CACA,cAAA,CAGJ,gDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGJ,oDACI,gBAAA","sourcesContent":[".dialog {\r\n    :global {\r\n        .ant-modal-footer .ant-btn {\r\n            margin-top: 0;\r\n            vertical-align: top;\r\n        }\r\n    }\r\n}\r\n.content {\r\n    max-width: 402px;\r\n    color: var(--text);\r\n    font-size: 14px;\r\n}\r\n\r\n.cancel {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.cancelIcon {\r\n    margin-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "DecompositionUnsavedModelDialog__dialog__buU8I",
	"content": "DecompositionUnsavedModelDialog__content__D7m8x",
	"cancel": "DecompositionUnsavedModelDialog__cancel__b7lXL",
	"cancelIcon": "DecompositionUnsavedModelDialog__cancelIcon__xaH9W"
};
export default ___CSS_LOADER_EXPORT___;
