import { defineMessages } from 'react-intl';

export default defineMessages({
    userAccountTab: {
        id: 'UserAccount.userAccountTab',
        defaultMessage: 'Личный кабинет',
    },
    mainTab: {
        id: 'UserAccount.mainTab',
        defaultMessage: 'Главная',
    },
    securityTab: {
        id: 'UserAccount.securityTab',
        defaultMessage: 'Безопасность',
    },
    login: {
        id: 'UserAccount.login',
        defaultMessage: 'Логин',
    },
    email: {
        id: 'UserAccount.email',
        defaultMessage: 'Почта',
    },
    changePassword: {
        id: 'UserAccount.changePassword',
        defaultMessage: 'Сменить пароль',
    },
    lastUpDate: {
        id: 'UserAccount.lastUpDate',
        defaultMessage: 'Последнее обновление ',
    },
    securityNote: {
        id: 'UserAccount.securityNote',
        defaultMessage: 'Видны только вам',
    },
});
