import { defineMessages } from 'react-intl';

export default defineMessages({
    treeFilterPlaceholder: {
        id: 'MatrixSettingsDialog.treeFilterPlaceholder',
        defaultMessage: 'Фильтрация',
    },
    pathPlaceholder: {
        id: 'MatrixSettingsDialog.pathPlaceholder',
        defaultMessage: 'Выбрать из списка',
    },
    plusIcon: {
        id: 'MatrixSettingsDialog.plusIcon',
        defaultMessage: 'Плюс',
    },
    minusIcon: {
        id: 'MatrixSettingsDialog.minusIcon',
        defaultMessage: 'Минус',
    },
    crossIcon: {
        id: 'MatrixSettingsDialog.crossIcon',
        defaultMessage: 'Крестик',
    },
    checkIcon: {
        id: 'MatrixSettingsDialog.checkIcon',
        defaultMessage: 'Галочка',
    },
    customState: {
        id: 'MatrixSettingsDialog.customState',
        defaultMessage: 'Произвольный',
    },
    iconState: {
        id: 'MatrixSettingsDialog.iconState',
        defaultMessage: 'Иконка',
    },
    userIconState: {
        id: 'MatrixSettingsDialog.userIconState',
        defaultMessage: 'Пользовательская иконка',
    },
    title: {
        id: 'MatrixSettingsDialog.title',
        defaultMessage: 'Свойства матричной модели:',
    },
    inputAxisFromText: {
        id: 'MatrixSettingsDialog.inputAxisFromText',
        defaultMessage: 'Добавить простой текст',
    },
    inputAxisFromTree: {
        id: 'MatrixSettingsDialog.inputAxisFromTree',
        defaultMessage: 'Добавить из дерева',
    },
    headerPlaceholder: {
        id: 'MatrixSettingsDialog.headerPlaceholder',
        defaultMessage: 'Текст заголовка',
    },
    actions: {
        id: 'MatrixSettingsDialog.actions',
        defaultMessage: 'Действия',
    },
    iconDescription: {
        id: 'MatrixSettingsDialog.iconDescription',
        defaultMessage: 'Расшифровка',
    },
    iconSymbol: {
        id: 'MatrixSettingsDialog.iconSymbol',
        defaultMessage: 'Символ',
    },
    settingsAxisX: {
        id: 'MatrixSettingsDialog.settingsAxisX',
        defaultMessage: 'Настройка оси X',
    },
    settingsAxisY: {
        id: 'MatrixSettingsDialog.settingsAxisY',
        defaultMessage: 'Настройка оси Y',
    },
    settingsLinks: {
        id: 'MatrixSettingsDialog.settingsLinks',
        defaultMessage: 'Представление связей',
    },
    okButton: {
        id: 'MatrixSettingsDialog.okButton',
        defaultMessage: 'Сохранить',
    },
    addButton: {
        id: 'MatrixSettingsDialog.addButton',
        defaultMessage: 'Добавить',
    },
    cancelButton: {
        id: 'MatrixSettingsDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    fileDropText: {
        id: 'MatrixSettingsDialog.fileDropText',
        defaultMessage: 'Нажмите или перетащите сюда картинку',
    },
});
