import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { ScriptDashboard } from '../components/ScriptDashboard/ScriptDashboard.component';
import { TScriptDashboardProps } from '../components/ScriptDashboard/ScriptDashboard.types';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { setReportDownloadDate } from '../../../actions/entities/script.actions';
import { NodeId, ScriptExecutionResults } from '../../../serverapi/api';
import { ScriptExecutionSelectors } from '../../../selectors/scriptExecution.selectors';
import { fileDownload } from '../../../actions/file.actions';
import { TWorkspaceTab } from '../../../models/tab.types';
import { downloadLogFile } from '@/actions/scriptDashboard.actions';

type TScriptDashboardContainerOwnProps = {
    tab: TWorkspaceTab;
};

const mapStateToProps = (
    state: TRootState,
    ownProps: TScriptDashboardContainerOwnProps,
): Partial<TScriptDashboardProps> => {
    const content = ownProps.tab.content as ScriptExecutionResults;

    return {
        content: ScriptExecutionSelectors.byServerId(content.nodeId.serverId)(state),
        server: ServerSelectors.server(content.nodeId.serverId)(state),
    };
};

const mapDispatchToProps: (dispatch) => Partial<TScriptDashboardProps> = (dispatch) => ({
    setReportDownloadDate: (serverId: string, scriptExecutionId: string) =>
        dispatch(setReportDownloadDate(serverId, scriptExecutionId)),
    downloadFile: (fileId: NodeId) => dispatch(fileDownload(fileId)),
    downloadLogFile: (operationId: string, serverId: string) => dispatch(downloadLogFile(operationId, serverId)),});

export const ScriptDashboardContainer = connect(mapStateToProps, mapDispatchToProps)(ScriptDashboard);
