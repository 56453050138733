import { put, select, takeEvery } from 'redux-saga/effects';
import { openDialog } from '../actions/dialogs.actions';
import { openNode } from '../actions/openNode.actions';
import { TNewTabOpened } from '../actions/tabsBus.actions.types';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { getIsAuthorized } from '../selectors/authorization.selectors';
import { ExternalLinkBLLService } from '../services/bll/ExternalLinkBLLService';
import { OpenTabStatus } from '../services/dao/TabsBusDAOService';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { moveToDirectAction } from '../actions/editor.actions';
import { TreeNode } from '../models/tree.types';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
import { CustomObjectTypesForLink } from '@/services/bll/ExternalLinkBLLService.types';
import { TreeItemType } from '@/modules/Tree/models/tree';

function* newTabOpened({ payload: { externalLink } }: TNewTabOpened) {
    const servers: string[] = yield select(ServerSelectors.connected);
    const isAuthorized: boolean = yield select(getIsAuthorized);
    if (isAuthorized) {
        if (externalLink) {
            const { nodeId, type, elementId } = ExternalLinkBLLService.parseExternalLink(externalLink, servers[0]);
            yield put(openNode({ nodeId, type, elementIds: [elementId] }));
            const nodeType = Object.values(CustomObjectTypesForLink).includes(type as CustomObjectTypesForLink)
                ? TreeItemType.Default
                : (type as TreeItemType);
            const treeNode: TreeNode = {
                nodeId,
                type: nodeType,
                name: '',
                countChildren: 0,
                hasChildren: false,
            };
            yield put(moveToDirectAction(treeNode));
        } else {
            LocalStorageDaoService.setTabsBusAction(TabsBusActions.APP_HAVE_AUTHORIZED_TAB);
        }
    }
}

function* nodeOpenSuccessful() {
    const isAuthorized: boolean = yield select(getIsAuthorized);
    if (!isAuthorized) {
        yield put(openDialog(DialogType.MODEL_LINK_DIALOG, { status: OpenTabStatus.SUCCESS }));
    }
}

function* nodeOpenFailed() {
    const isAuthorized: boolean = yield select(getIsAuthorized);
    if (!isAuthorized) {
        yield put(openDialog(DialogType.MODEL_LINK_DIALOG, { status: OpenTabStatus.FAIL }));
    }
}

function* appHaveAuthTab() {
    const isAuthorized: boolean = yield select(getIsAuthorized);
    if (!isAuthorized) {
        yield put(openDialog(DialogType.MODEL_LINK_DIALOG, { status: OpenTabStatus.HAVE_AUTH_TAB }));
    }
}

function appExitRequest() {
    LocalStorageDaoService.setTabsBusAction(TabsBusActions.TABS_BUS_APP_EXIT_REQUEST);
}

export function* tabsBusSaga() {
    yield takeEvery(TabsBusActions.NEW_APP_INSTANCE_IS_OPENED, newTabOpened);
    yield takeEvery(TabsBusActions.NODE_OPEN_SUCCESSFUL, nodeOpenSuccessful);
    yield takeEvery(TabsBusActions.NODE_OPEN_FAILED, nodeOpenFailed);
    yield takeEvery(TabsBusActions.TABS_BUS_APP_EXIT_REQUEST, appExitRequest);
    yield takeEvery(TabsBusActions.APP_HAVE_AUTHORIZED_TAB, appHaveAuthTab);
}
