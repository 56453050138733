import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { AppContainer } from './modules/App/containers/App.container';
import configureStore from './configureStore';
import { appLoaded, bootstrapRequest, loadModelDefinitionSuccess } from './actions/app.actions';
import './styles/index.scss';
import { setServerIdToModelDefinition } from './utils/nodeId.utils';
import { LocalesService } from './services/LocalesService';
import { DEFAULT_LOCALE, preloadFonts } from './config/config';
import { localeSelect } from './actions/locale.actions';
import { FullModelDefinition } from './serverapi/api';

const store = configureStore();
store.dispatch(appLoaded());
store.dispatch(bootstrapRequest());

const changeMode = (json: string, localeCode: string) => {
    // Бросаем событие с пустыми данными что бы размонтировать Editor
    store.dispatch(loadModelDefinitionSuccess(undefined));

    // Монтируем компонент с новыми данными
    // зачем setTimeout:
    // Если бросить событие loadModelDefinitionSuccess(undefined) а за ним сразу loadModelDefinitionSuccess(modelDefinition)
    // редюсер отработает корректно, сначала установит fullModelDefinition в undefined а потом в modelDefinition
    // но react объединяет события (не совсем ясно как он это делает, события Redux а объединяет React)
    // https://react-redux.js.org/api/batch
    setTimeout(() => {
        const modelDefinition: FullModelDefinition | undefined = json
            ? setServerIdToModelDefinition(JSON.parse(json), '')
            : undefined;
        const locale = LocalesService.convertFromString(localeCode);
        LocalesService.setLocale(locale);
        store.dispatch(localeSelect(locale)); // из сторы надо убрать локаль
        store.dispatch(loadModelDefinitionSuccess(modelDefinition));
    }, 0);
};

const changeModeAsync = (json: string, localeCode: string) => {
     return new Promise<void>((resolve, reject) => {
           store.dispatch(loadModelDefinitionSuccess(undefined));
           setTimeout(() => {
               try{
                   const modelDefinition: FullModelDefinition | undefined = json
                       ? setServerIdToModelDefinition(JSON.parse(json), '')
                       : undefined;
                   const locale = LocalesService.convertFromString(localeCode);
                   LocalesService.setLocale(locale);
                   store.dispatch(localeSelect(locale)); // из сторы надо убрать локаль
                   store.dispatch(loadModelDefinitionSuccess(modelDefinition));
                } catch (e){
                    return reject(e);
                }
                return resolve();

           }, 100);
       });
};


// document.changeMode(json) переключает приложение в режим просмотра диаграммы, используется, например, в selenium
document['changeMode'] = (json: string, localeCode: string = DEFAULT_LOCALE) => changeMode(json, localeCode);
// используется в bpm-image c настройкой --IMG_MAKER=playwright
document['changeModeAsync'] = (json: string, localeCode: string = DEFAULT_LOCALE) => changeModeAsync(json, localeCode);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

preloadFonts()
    .catch((error) => {
        console.warn(error);
    })
    .finally(() => {
        root.render(<AppContainer store={store} />);
    });

// This is needed for HMR
if (module.hot) {
    module.hot.accept(() => {
        const NextAppContainer = require('./modules/App/containers/App.container').default;
        root.render(NextAppContainer);
    });
}
