import { defineMessages } from 'react-intl';

export default defineMessages({
    Name: {
        id: 'ObjectProperties.Name',
        defaultMessage: 'Наименование',
    },
    'Model Type': {
        id: 'ObjectProperties.ModelType',
        defaultMessage: 'Тип модели',
    },
    'Object Type': {
        id: 'ObjectProperties.ObjectType',
        defaultMessage: 'Тип объекта',
    },
    'Edge Type': {
        id: 'ObjectProperties.EdgeType',
        defaultMessage: 'Тип связи',
    },
    Description: {
        id: 'ObjectProperties.Description',
        defaultMessage: 'Описание',
    },
    Version: {
        id: 'ObjectProperties.Version',
        defaultMessage: 'Версия',
    },
    'Created At': {
        id: 'ObjectProperties.CreatedAt',
        defaultMessage: 'Дата создания',
    },
    'Created By': {
        id: 'ObjectProperties.CreatedBy',
        defaultMessage: 'Создал',
    },
    'Updated At': {
        id: 'ObjectProperties.UpdatedAt',
        defaultMessage: 'Дата изменения',
    },
    'Updated By': {
        id: 'ObjectProperties.UpdatedBy',
        defaultMessage: 'Изменил',
    },
    'Line Type': {
        id: 'ObjectProperties.LineType',
        defaultMessage: 'Тип линии',
    },
    Direction: {
        id: 'ObjectProperties.Direction',
        defaultMessage: 'Направленность связи',
    },
    'Linked Object Types': {
        id: 'ObjectProperties.LinkedObjectTypes',
        defaultMessage: 'Типы объектов',
    },
    'Script language': {
        id: 'ScriptProperties.scriptLanguage',
        defaultMessage: 'Скриптовый язык',
    },
    'Element Type': {
        id: 'ScriptProperties.elementType',
        defaultMessage: 'Тип элемента',
    },
    'Start marker': {
        id: 'EdgeProperties.startArrow',
        defaultMessage: 'Наконечник старта',
    },
    'End marker': {
        id: 'EdgeProperties.endArrow',
        defaultMessage: 'Наконечник конца',
    },
    Confidential: {
        id: 'CommonProperties.confidential',
        defaultMessage: 'Конфиденциально',
    },
    'Folder type': {
        id: 'CommonProperties.folderType',
        defaultMessage: 'Тип папки',
    },
    'Symbol type': {
        id: 'CommonProperties.Symbol',
        defaultMessage: 'Тип символа',
    },
    UserEditDisabled: {
        id: 'CommonProperties.UserEditDisabled',
        defaultMessage: 'Запрещено редактирование пользователю',
    },
    ScriptEngineEditDisabled: {
        id: 'CommonProperties.ScriptEngineEditDisabled',
        defaultMessage: 'Запрещено редактирование скрипту',
    },
});
