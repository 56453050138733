import type { TDeleteNodeDialogProps } from './DeleteNodeDialog.types';
import React from 'react';
import { Button, Alert } from 'antd';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './DeleteNodeDialog.messages';
import theme from './DeleteNodeDialog.component.scss';
import modelTypeMessages from '../../../models/modelType.messages';
import { ShowToBeClosedTabs } from './ShowToBeClosedTabs';
import { v4 as uuid } from 'uuid';
import { getAccusativeItemTypeName } from '../../../utils/treeItemTypeName';

// todo можно переписать на функциональный компонент
// eslint-disable-next-line react/prefer-stateless-function
class DeleteNodeDialog extends React.Component<TDeleteNodeDialogProps, { lotOfTabs: boolean }> {
    render() {
        const {
            intl,
            onClose,
            onSubmit,
            title,
            contentText,
            deleteNodeRequest: { nodeType, countChildren, nodeName, closeTabsNames, modelsWhereObjectToDelete, edgeInstancesToDelete },
        } = this.props;
        const typeName = intl.formatMessage(getAccusativeItemTypeName(nodeType));
        const footer = [
            <Button data-test="window-confirmation-deletion-cancel" key="cancel" size="large" onClick={onClose}>
                <div className={theme.cancel}>{intl.formatMessage(messages.cancel)}</div>
            </Button>,
            <Button
                data-test="window-confirmation-deletion-ok"
                danger
                key="ok"
                size="large"
                type="primary"
                onClick={onSubmit}
            >
                {intl.formatMessage(messages.deleteNode)}
            </Button>,
        ];

        const hasChildren = countChildren > 0;
        const hasModels: boolean = !!modelsWhereObjectToDelete?.length;
        const hasEdges: boolean = !!edgeInstancesToDelete?.length;
        const modelsWithObject = modelsWhereObjectToDelete || [];
        const modelsWithEdges = edgeInstancesToDelete || [];

        return (
            <Dialog
                open
                onOk={onSubmit}
                onCancel={onClose}
                title={intl.formatMessage(title)}
                footer={footer}
                className={theme.dialog}
            >
                <div className={theme.content}>
                    {intl.formatMessage(contentText, { type: typeName, name: nodeName })}
                </div>
                {hasChildren && (
                    <div className={theme.alert}>
                        <Alert
                            className={theme.alert}
                            message={intl.formatMessage(messages.hasChildren, {
                                type: intl.formatMessage(modelTypeMessages[nodeType]),
                                count: countChildren,
                            })}
                            type="error"
                        />
                    </div>
                )}

                {hasModels && (
                    <div>
                        <Alert
                            message={intl.formatMessage(messages.hasObjectEntries, {
                                entriesCount: modelsWithObject.length,
                            })}
                            type="error"
                        />
                        <div className={theme.removableElements}>
                            {modelsWithObject.map((objectEntry) => {
                                return (
                                    <Alert
                                        className={theme.objectEntry}
                                        message={`${objectEntry.modelName}`}
                                        key={uuid()}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}

                {hasEdges && (
                    <div>
                        <Alert
                            message={intl.formatMessage(messages.hasObjectEntries, {
                                entriesCount: modelsWithEdges.length,
                            })}
                            type="error"
                        />
                        <div className={theme.removableElements}>
                            {modelsWithEdges.map((edgeEntry) => {
                                return (
                                    <Alert
                                        className={theme.edgeEntry}
                                        message={`${edgeEntry.modelName} ${`(${intl.formatMessage(messages.entryDeleteMessage)} ${edgeEntry.entryCount})`} `}
                                        key={uuid()}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}

                {closeTabsNames.length > 0 && (
                    <div className={theme.alert}>
                        <Alert
                            message={
                                <>
                                    <div className={theme.alertTitle}>
                                        {intl.formatMessage(messages.tabsToBeClosed)}
                                    </div>
                                    <ShowToBeClosedTabs tabsToBeClosed={closeTabsNames} intl={intl} />
                                </>
                            }
                            type="warning"
                        />
                    </div>
                )}
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(DeleteNodeDialog);

export { IntlComponent as DeleteNodeDialog };
