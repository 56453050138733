export enum ObjectPropertiesDialogActiveTab {
    NameAndAttributes = 'NameAndAttributes',
    MethodologySelection = 'MethodologySelection',
    Additional = 'Additional',
    ObjectInstanceAttributes = 'ObjectInstanceAttributes',
    ObjectInstances = 'ObjectInstances',
    Detailing = 'Detailing',
    ParentObjects = 'ParentObjects',
    Settings = 'Settings',
    FloatingAttributes = 'FloatingAttributes',
    ClassProperties = 'ClassProperties',
    ClassMethods = 'ClassMethods',
    ClassReceptions = 'ClassReceptions',
    EdgeInstances = 'EdgeInstances',
    ScriptRunningContext = 'ScriptRunningContext',
    Approvals = 'Approvals',
}
