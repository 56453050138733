import {TRootState} from '../reducers/root.reducer.types';
import {createSelector} from 'reselect';

const serverProfileStateSelector = (state: TRootState) => state.serverProfiles;

export namespace ServerProfileSelectors {

    export const byServerId = (serverId: string) =>
        createSelector(serverProfileStateSelector, (state) => {
            return state.byServerId[serverId] || [];
        });

    export const activeProfileName = (serverId: string) =>
        createSelector(byServerId(serverId), serverProfileStateSelector, (state, fullState) => {
            return state.serverProfiles?.find(sp => sp.id === fullState.activeServerProfileId)?.name;
        });

    export const activeProfileId = createSelector(serverProfileStateSelector, (state) => state.activeServerProfileId);

    export const getAllServerProfilesByServerId = (serverId: string) => {
        return createSelector(byServerId(serverId), (state) => (state && state.serverProfiles ? state.serverProfiles : []));
    };
}
