import { defineMessages } from 'react-intl';

export default defineMessages({
    newRowTitle1: {
        id: 'EPCSelectRowTitleDialog.newRowTitle1',
        defaultMessage: 'Выполняет/поддерживает',
    },
    newRowTitle2: {
        id: 'EPCSelectRowTitleDialog.newRowTitle2',
        defaultMessage: 'Должен быть информирован о',
    },
    newRowTitle3: {
        id: 'EPCSelectRowTitleDialog.newRowTitle3',
        defaultMessage: 'Должен быть информирован о нестандартном завершении',
    },
    newRowTitle4: {
        id: 'EPCSelectRowTitleDialog.newRowTitle4',
        defaultMessage: 'Должен информировать о результате',
    },
    newRowTitle5: {
        id: 'EPCSelectRowTitleDialog.newRowTitle5',
        defaultMessage: 'Отвечает за техническую часть',
    },
    newRowTitle6: {
        id: 'EPCSelectRowTitleDialog.newRowTitle6',
        defaultMessage: 'Отвечает по ИТ за',
    },
    newRowTitle7: {
        id: 'EPCSelectRowTitleDialog.newRowTitle7',
        defaultMessage: 'Принимает',
    },
    newRowTitle8: {
        id: 'EPCSelectRowTitleDialog.newRowTitle8',
        defaultMessage: 'Принимает решение по',
    },
    newRowTitle9: {
        id: 'EPCSelectRowTitleDialog.newRowTitle9',
        defaultMessage: 'Прочее',
    },
    newRowTitle10: {
        id: 'EPCSelectRowTitleDialog.newRowTitle10',
        defaultMessage: 'Связан(а) с',
    },
    newRowTitle11: {
        id: 'EPCSelectRowTitleDialog.newRowTitle11',
        defaultMessage: 'Способствует при выполнении',
    },
    newRowTitle12: {
        id: 'EPCSelectRowTitleDialog.newRowTitle12',
        defaultMessage: 'Участвует в качестве консультанта',
    },
    add: {
        id: 'EPCSelectRowTitleDialog.save',
        defaultMessage: 'Добавить',
    },
    cancel: {
        id: 'EPCSelectRowTitleDialog.cancel',
        defaultMessage: 'Отменить',
    },
});
