import { defineMessages } from 'react-intl';

export default defineMessages({
    getImage: {
        id: 'image.getImage',
        defaultMessage: 'Получить изображение',
    },
    imagePng: {
        id: 'image.imagePng',
        defaultMessage: 'PNG',
    },
    imageJpeg: {
        id: 'image.imageJpeg',
        defaultMessage: 'JPG',
    },
    imageSvg: {
        id: 'image.imageSvg',
        defaultMessage: 'SVG',
    },
    imagePdf: {
        id: 'image.imagePdf',
        defaultMessage: 'PDF',
    },
    imagePrint: {
        id: 'image.imagePrint',
        defaultMessage: 'Распечатать',
    },
    imageToClipboard: {
        id: 'image.imageToClipboard',
        defaultMessage: 'В буфер обмена',
    },
    getImageSettings: {
        id: 'image.getImageSettings',
        defaultMessage: 'Настройки',
    },
    grayScale: {
        id: 'image.grayScale',
        defaultMessage: 'В градациях серого',
    },
    hideDecomposition: {
        id: 'image.hideDecomposition',
        defaultMessage: 'Скрыть декомпозиции',
    },
    settingsDownloadingImage: {
        id: 'image.settingsDownloadingImage',
        defaultMessage: 'Настройка изображений',
    },
    save: {
        id: 'image.save',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'image.cancel',
        defaultMessage: 'Отмена',
    },
    copyLink: {
        id: 'itemLink.copyLink',
        defaultMessage: 'Копировать ссылку',
    },
    historyLog: {
        id: 'historyLog.history',
        defaultMessage: 'История изменений',
    },
    space: {
        id: 'EditorPopupMenu.space',
        defaultMessage: 'Пространство',
    },
    insertSpaceVertical: {
        id: 'EditorPopupMenu.insertSpaceVertical',
        defaultMessage: 'По вертикали',
    },
    insertSpaceHorizontally: {
        id: 'EditorPopupMenu.insertSpaceHorizontally',
        defaultMessage: 'По горизонтали',
    },
    deleteSpaceVertical: {
        id: 'EditorPopupMenu.deleteSpaceVertical',
        defaultMessage: 'Удалить по вертикали',
    },
    deleteSpaceHorizontally: {
        id: 'EditorPopupMenu.deleteSpaceHorizontally',
        defaultMessage: 'Удалить по горизонтали',
    },
    decomposition: {
        id: 'EditorPopupMenu.decomposition',
        defaultMessage: 'Декомпозиция',
    },
    delete: {
        id: 'EditorPopupMenu.delete',
        defaultMessage: 'Удалить',
    },
    paste: {
        id: 'EditorPopupMenu.paste',
        defaultMessage: 'Вставить',
    },
    pasteAs: {
        id: 'EditorPopupMenu.pasteAs',
        defaultMessage: 'Вставить как',
    },
    pasteAsDefinitionCopy: {
        id: 'EditorPopupMenu.pasteAsDefinitionCopy',
        defaultMessage: 'Копии определений',
    },
    pasteAsInstanceCopy: {
        id: 'EditorPopupMenu.pasteAsInstanceCopy',
        defaultMessage: 'Копии экземпляров',
    },
    objectInTree: {
        id: 'EditorPopupMenu.objectInTree',
        defaultMessage: 'Найти в Навигаторе',
    },
    copy: {
        id: 'EditorPopupMenu.copy',
        defaultMessage: 'Копировать',
    },
    copyID: {
        id: 'EditorPopupMenu.copyID',
        defaultMessage: 'Копировать ID',
    },
    createConnectedElement: {
        id: 'EditorPopupMenu.createConnectedElement',
        defaultMessage: 'Создать связанный элемент',
    },
    replaceSymbol: {
        id: 'EditorPopupMenu.replaceSymbol',
        defaultMessage: 'Изменить символ',
    },
    properties: {
        id: 'EditorPopupMenu.properties',
        defaultMessage: 'Свойства',
    },
    hotKeys: {
        id: 'EditorPopupMenu.hotKeys',
        defaultMessage: '[Alt+Enter]',
    },
    renameObject: {
        id: 'EditorPopupMenu.renameObject',
        defaultMessage: 'Переименовать',
    },
    editAttributes: {
        id: 'EditorPopupMenu.editAttributes',
        defaultMessage: 'Редактировать атрибуты',
    },
    deleteCommentMarker: {
        id: 'EditorPopupMenu.deleteCommentMarker',
        defaultMessage: 'Удалить метку',
    },
    scriptMenuName: {
        id: 'EditorPopupMenu.scriptMenuName',
        defaultMessage: 'Cкрипт',
    },
    executeScript: {
        id: 'EditorPopupMenu.executeScript',
        defaultMessage: 'Запустить скрипт',
    },
    scheduleScript: {
        id: 'EditorPopupMenu.scheduleScript',
        defaultMessage: 'Запланировать',
    },
    psdAddColumn: {
        id: 'EditorPopupMenu.psdAddColumn',
        defaultMessage: 'Добавить колонку',
    },
    psdRemoveColumn: {
        id: 'EditorPopupMenu.psdRemoveColumn',
        defaultMessage: 'Удалить колонку',
    },
    psdAddRow: {
        id: 'EditorPopupMenu.psdAddRow',
        defaultMessage: 'Добавить строку',
    },
    psdRemoveRow: {
        id: 'EditorPopupMenu.psdRemoveRow',
        defaultMessage: 'Удалить строку',
    },
    bpmnAddRow: {
        id: 'EditorPopupMenu.bpmnAddRow',
        defaultMessage: 'Добавить строку',
    },
    bpmnRemoveRow: {
        id: 'EditorPopupMenu.bpmnRemoveRow',
        defaultMessage: 'Удалить строку',
    },
    yellow: {
        id: 'EditorPopupMenu.yellow',
        defaultMessage: 'Желтый',
    },
    red: {
        id: 'EditorPopupMenu.red',
        defaultMessage: 'Красный',
    },
    green: {
        id: 'EditorPopupMenu.green',
        defaultMessage: 'Зеленый',
    },
    blue: {
        id: 'EditorPopupMenu.blue',
        defaultMessage: 'Синий',
    },
    black: {
        id: 'EditorPopupMenu.black',
        defaultMessage: 'Черный',
    },
    typeEdge: {
        id: 'EditorPopupMenu.typeEdge',
        defaultMessage: 'Тип связи',
    },
    edgeFork: {
        id: 'EditorPopupMenu.edgeFork',
        defaultMessage: 'Ветвление',
    },
    floatingAttributes: {
        id: 'EditorPopupMenu.floatingAttributes',
        defaultMessage: 'Атрибуты вокруг объекта',
    },
    floatingEdgeAttributes: {
        id: 'EditorPopupMenu.floatingEdgeAttributes',
        defaultMessage: 'Атрибуты около связи',
    },
    createEdgeDefinition: {
        id: 'EditorPopupMenu.createEdgeDefinition',
        defaultMessage: 'Создать определение',
    },
    createElement: {
        id: 'EditorPopupMenu.createElement',
        defaultMessage: 'Создать',
    },
    createExecutionSymbol: {
        id: 'EditorPopupMenu.createExecutionSymbol',
        defaultMessage: 'Символ исполнения',
    },
    createDestroySymbol: {
        id: 'EditorPopupMenu.createDestroySymbol',
        defaultMessage: 'Признак "Уничтожение"',
    },
    deleteDestroySymbol: {
        id: 'EditorPopupMenu.deleteDestroySymbol',
        defaultMessage: 'Удалить Признак "Уничтожение"',
    },
    onlyInEditModeAvailable: {
        id: 'EditorPopupMenu.onlyInEditModeAvailable',
        defaultMessage: 'Доступно только в режиме редактирования',
    },
    edgeDefinitionExist: {
        id: 'EditorPopupMenu.edgeDefinitionExist',
        defaultMessage: 'Определение связи уже создано',
    },
    deniedByProfile: {
        id: 'EditorPopupMenu.deniedByProfile',
        defaultMessage: 'Действие запрещено профилем пользователя',
    },
    deniedByEdgeType: {
        id: 'EditorPopupMenu.deniedByEdgeType',
        defaultMessage: 'Действие недоступно для такого типа связи',
    },
    edgeManagement: {
        id: 'EditorPopupMenu.edgeManagement',
        defaultMessage: 'Управление связями',
    },
    splitExecutionSymbol: {
        id: 'EditorPopupMenu.splitExecutionSymbol',
        defaultMessage: 'Разделить',
    },
    edgeTypeAuto: {
        id: 'EditorPopupMenu.edgeTypeAuto',
        defaultMessage: 'Автоматически',
    },
    edgeDefinitionTypeWarning: {
        id: 'EditorPopupMenu.edgeDefinitionTypeWarning',
        defaultMessage: 'Смена типа невозможна, для связи есть определение',
    },
    edgeProfileTypeWarning: {
        id: 'EditorPopupMenu.edgeProfileTypeWarning',
        defaultMessage: 'Смена типа невозможна, редактирование связи заблокировано профилем',
    },
    edgesWithoutOtherTypesWarning: {
        id: 'EditorPopupMenu.edgesWithoutOtherTypesWarning',
        defaultMessage: 'Смена типа невозможна, у выделенной связи не может быть другого типа',
    },
    noCommonEdgeTypesWarning: {
        id: 'EditorPopupMenu.noCommonEdgeTypesWarning',
        defaultMessage: 'Выделены связи, у которых нет общих типов для изменения',
    },
});
