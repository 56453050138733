import React, { FC } from 'react';
import messages from './CopyDatabaseDialog.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { NodeId } from '../../serverapi/api';
import { CommonCreateDialog } from '../CommonCreateDialog/CommonCreateDialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { TreeItemType } from '../Tree/models/tree';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { copyDatabaseDialogSubmit } from '@/actions/databaseDialog.actions';

type TCopyDatabaseDialogProps = {
    nodeId: NodeId;
};

const CopyDatabaseDialog: FC<TCopyDatabaseDialogProps> = (props) => {
    const { nodeId } = props;
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);
    const dispatch = useDispatch();

    return (
        <CommonCreateDialog
            type={TreeItemType.Repository}
            title={intl.formatMessage(messages.copyDbFormName)}
            placeholder={intl.formatMessage(messages.copyDbPlaceholder)}
            cancelText={<span>{intl.formatMessage(messages.copyDbFormDeclineButton)}</span>}
            okText={<span>{intl.formatMessage(messages.copyDbFormConfirmButton)}</span>}
            requiredErrorMsg={intl.formatMessage(messages.copyDbNameRequiredError)}
            labelName={
                <FormattedMessage id={messages.copyDbLabel.id} defaultMessage={messages.copyDbLabel.defaultMessage} />
            }
            onSubmit={(name: string) => {
                dispatch(
                    copyDatabaseDialogSubmit({
                        id: nodeId,
                        name: { [currentLocale]: name },
                    }),
                );
            }}
            onClose={() => dispatch(closeDialog(DialogType.COPY_DATABASE_DIALOG))}
        />
    );
};

export default CopyDatabaseDialog;
