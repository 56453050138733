import { Button, ConfigProvider, DatePicker, Form, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { injectIntl, MessageDescriptor } from 'react-intl';
import messages from './ClearJournal.messages';
import style from './ClearJournal.scss';
import { TClearJournalFullProps } from './ClearJournal.types';
import ruLocale from 'antd/es/locale/ru_RU';
import enLocale from 'antd/es/locale/en_US';
import { Locale } from '../../../Header/components/Header/header.types';
import { showDeleteConfirmation } from '../../Button/DeleteButton';
import { RangeValue } from 'rc-picker/lib/interface';

const { RangePicker } = DatePicker;

const HALF_YEAR = 2592000000 * 6;

const ClearJournalComponent = (props: TClearJournalFullProps): JSX.Element => {
    const { intl } = props;
    const [auditStart, setAuditStartDate] = useState<undefined | number>(undefined);
    const [auditEnd, setAuditEndDate] = useState<undefined | null | number>(undefined);
    const [sessionStart, setSessionsStartDate] = useState<undefined | number>(undefined);
    const [sessionEnd, setSessionsEndDate] = useState<undefined | null | number>(undefined);
    const [password, setPassword] = useState('');
    const [auditHasErrors, setAuditHasErrors] = useState<boolean>(false);
    const [sessionHasErrors, setSessionHasErrors] = useState<boolean>(false);
    const [form] = Form.useForm();

    const setDateRange = (
        datesArr: RangeValue<Dayjs>,
        setStart: React.Dispatch<React.SetStateAction<number | undefined>>,
        setEnd: React.Dispatch<React.SetStateAction<number | null | undefined>>,
        setHasErrors: React.Dispatch<React.SetStateAction<boolean>>,
    ): void => {
        if (datesArr) {
            const [startRange, endRange] = datesArr;
            setStart(startRange?.valueOf());
            setEnd(endRange?.valueOf());
        } else {
            setStart(1);
            setEnd(null);
        }
        setHasErrors(false);
    };

    const handleChangeAuditDateRange = (datesArr: RangeValue<Dayjs>): void => {
        setDateRange(datesArr, setAuditStartDate, setAuditEndDate, setAuditHasErrors);
    };

    const handleChangeSessionsDateRange = (datesArr: RangeValue<Dayjs>): void => {
        setDateRange(datesArr, setSessionsStartDate, setSessionsEndDate, setSessionHasErrors);
    };

    const setPasswordError = () => {
        form.setFields([
            {
                name: 'password',
                errors: [intl.formatMessage(messages.missingRequiredParameter)],
            },
        ]);
    };

    const dateComponentOnClick = (
        validateRangeInput: () => boolean,
        onDelete: () => void,
        deleteTitle: MessageDescriptor,
        deleteBtnTitle: MessageDescriptor,
    ): void => {
        if (!password) {
            setPasswordError();
            return;
        }
        if (validateRangeInput()) {
            showDeleteConfirmation({
                onDelete,
                deleteQuestion: intl.formatMessage(deleteTitle),
                dialogContent: intl.formatMessage(deleteBtnTitle),
            });
        }
    };

    const dateComponent = (
        hasError: boolean,
        deleteTitle: MessageDescriptor,
        deleteBtnTitle: MessageDescriptor,
        validateRangeInput: () => boolean,
        onChange: (datesArr: RangeValue<Dayjs>) => void,
        onDelete: () => void,
    ): JSX.Element => (
        <div className={style.rangePicker}>
            <div>{intl.formatMessage(deleteTitle)}</div>
            <ConfigProvider locale={intl.locale === Locale.ru ? ruLocale : enLocale}>
                <Form.Item
                    help={hasError ? intl.formatMessage(messages.rangeError) : ''}
                    validateStatus={hasError ? 'error' : 'success'}
                >
                    <RangePicker
                        className={style.inputEl}
                        format="DD-MM-YYYY"
                        size="middle"
                        ranges={{
                            [intl.formatMessage(messages.today)]: [dayjs(), dayjs()],
                            [intl.formatMessage(messages.currentMonth)]: [
                                dayjs().startOf('month'),
                                dayjs().endOf('month'),
                            ],
                        }}
                        onChange={onChange}
                        placeholder={[intl.formatMessage(messages.startDate), intl.formatMessage(messages.endDate)]}
                    />
                </Form.Item>
                <Button
                    className={style.buttonEl}
                    onClick={(): void => dateComponentOnClick(validateRangeInput, onDelete, deleteTitle, deleteBtnTitle)}
                    type="link"
                >
                    {intl.formatMessage(deleteBtnTitle)}
                </Button>
            </ConfigProvider>
        </div>
    );

    const validateAuditRangeInput = (): boolean => {
        const currentTimeInMills = new Date().valueOf();
        if (
            auditEnd &&
            auditStart &&
            // @ts-ignore
            auditStart + HALF_YEAR < currentTimeInMills &&
            // @ts-ignore
            auditEnd + HALF_YEAR < currentTimeInMills
        ) {
            return true;
        }
        setAuditHasErrors(true);

        return false;
    };

    const validateSessionsRangeInput = (): boolean => {
        const currentTimeInMills = new Date().valueOf();
        if (
            sessionStart &&
            sessionEnd &&
            // @ts-ignore
            sessionStart + HALF_YEAR < currentTimeInMills &&
            // @ts-ignore
            sessionEnd + HALF_YEAR < currentTimeInMills
        ) {
            return true;
        }
        setSessionHasErrors(true);

        return false;
    };

    return (
        <div className={style.container} data-test="clear-journal-tab_container">
            <div>
                <div>{intl.formatMessage(messages.password)}</div>
                <Form form={form}>
                    <Form.Item
                        rules={[{ required: true, message: intl.formatMessage(messages.missingRequiredParameter) }]}
                        initialValue={password}
                        name="password"
                    >
                        <Input
                            className={style.inputEl}
                            type="password"
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </Form.Item>
                </Form>
            </div>
            {dateComponent(
                auditHasErrors,
                messages.deleteAuditTitle,
                messages.deleteAudit,
                validateAuditRangeInput,
                handleChangeAuditDateRange,
                (): void | 0 | null | undefined =>
                    auditEnd &&
                    auditStart &&
                    props.deleteAudit({
                        start: auditStart!,
                        end: auditEnd!,
                        serverId: props.serverId,
                        password,
                    }),
            )}
            {dateComponent(
                sessionHasErrors,
                messages.deleteSessionTitle,
                messages.deleteSession,
                validateSessionsRangeInput,
                handleChangeSessionsDateRange,
                (): void | 0 | null | undefined =>
                    sessionStart &&
                    sessionEnd &&
                    props.deleteSession({
                        start: sessionStart!,
                        end: sessionEnd!,
                        serverId: props.serverId,
                        password,
                    }),
            )}
        </div>
    );
};

const IntlComponent = injectIntl(ClearJournalComponent);

export { IntlComponent as ClearJournal };
