import { EditorMode } from '@/models/editorMode';
import { CustomMxEvent } from '@/sagas/editor.saga.constants';
import { MxEventObject, MxEvent, MxCell } from 'MxGraph';
import { createGridCellLayout } from '../../grid/GridCellLayout';
import { GridDiagram } from '../../grid/GridDiagram';
import { showRenameDialog } from '../../grid/SideEffects';
import { GridCell } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { groupBy, filter } from 'lodash-es';
import { MessageDescriptor } from 'react-intl';
import { v4 as uuid } from 'uuid';
import messages from './PSDGrid.messages';
import {
    contentAvailableSymbols,
    firstColumnAvailableSymbols,
    firstRowAvailableSymbols,
} from './PSDGridInitial.config';

class PSDGrid extends GridDiagram {
    isRenderEdges: boolean = false;
    public isGridValidTarget(source: MxCell[], target: MxCell, symbolId: string) {
        const { gridRowPosition, gridColumnPosition } = this.getCellGridPosition(target);

        if (!gridRowPosition || !gridColumnPosition) {
            return false;
        }

        if (gridColumnPosition === 1 && firstColumnAvailableSymbols.includes(symbolId)) {
            return true;
        }

        if (gridRowPosition === 1 && firstRowAvailableSymbols.includes(symbolId)) {
            return true;
        }

        if (gridColumnPosition > 1 && gridRowPosition > 1 && contentAvailableSymbols.includes(symbolId)) {
            return true;
        }

        return false;
    }

    private getCellGridPosition(cell: MxCell): {
        gridRowPosition: number | undefined;
        gridColumnPosition: number | undefined;
    } {
        try {
            const gridColumnPosition = this.graph.indexOfTableCell(cell);
            const gridRowPosition = this.graph.indexOfTableCell(cell.getParent());

            return { gridRowPosition, gridColumnPosition };
        } catch (e) {
            return { gridRowPosition: undefined, gridColumnPosition: undefined };
        }
    }
    protected addLayout() {
        createGridCellLayout(this.graph);
    }
    protected addKeyBindings() {
        const { graph } = this;

        graph.getModel().addListener(CustomMxEvent.DROP_ELEMENT, (sender, event: MxEventObject) => {
            requestAnimationFrame(() => {
                this.renderEdges();
            });
        });

        const endUpdateListener = (me) => {
            if (me.updateLevel === 0) {
                this.renderEdges();
                graph.getModel().removeListener(endUpdateListener);
            }
        };
        graph.getModel().addListener(MxEvent.END_UPDATE, endUpdateListener);
        const doubleClickListener = (sender, event: MxEventObject) => {
            const targetCell = event.getProperty('cell');
            if (this.graph.mode !== EditorMode.Edit) {
                event.consume();
                return;
            }
            if (this.graph.isTable(targetCell) || this.graph.isTableCell(targetCell)) {
                event.consume();
            }

            const style = targetCell.getStyle();
            if (style.includes(this.headHorizontalStyle) || style.includes(this.headVerticalStyle)) {
                showRenameDialog(targetCell.getValue(), (value) => {
                    targetCell.setValue(value);
                    this.graph.refresh();
                });
            }
        };
        graph.addListener(MxEvent.DOUBLE_CLICK, doubleClickListener);
    }

    public renderEdges() {
        if (!this.isRenderEdges) {
            return;
        }
        const { cells, rows, columns } = this.serialize();
        const firstRowId = rows[0]?.id;
        const firstColumnId = columns[0]?.id;

        const model = this.graph.getModel();

        this.clearEdges();

        const getPositionByCell = (tableCell: MxCell): GridCell | undefined => {
            const id = tableCell.getId();
            return cells.find((c) => {
                return c.id === id;
            });
        };

        const modelObjects = filter(model.cells, (cell: MxCell) => {
            return this.isSupportedCell(cell);
        });

        const filledTableCells = modelObjects.map((c) => {
            return getPositionByCell(c.getParent());
        });

        if (!filledTableCells.length) {
            return;
        }

        const byRowId = groupBy(filledTableCells, 'rowId');
        const byColumnId = groupBy(filledTableCells, 'columnId');

        this.connectColumns(byRowId, byColumnId, firstRowId, firstColumnId);
        this.connectRows(byRowId, byColumnId, firstRowId, firstColumnId);
    }

    private clearEdges() {
        const model = this.graph.getModel();
        const edges = filter(model.cells, (c) => c.isEdge()) || [];

        if (edges.length) {
            this.graph.removeCells(edges);
        }
    }

    private connectColumns(byRowId, byColumnId, firstRowId, firstColumnId) {
        const connectColumn = (tableCells: GridCell[], firstRowId: string) => {
            const firstTableCell = tableCells.find((c) => {
                if (c.rowId === firstRowId) {
                    return model.getCell(c.id);
                }
                return false;
            });

            if (!firstTableCell) {
                return;
            }

            const filledFirstTableChildren =
                model.getCell(firstTableCell.id)?.children?.filter(this.isSupportedCell) || [];

            if (!filledFirstTableChildren.length) {
                return;
            }

            tableCells.forEach((c) => {
                if (c.rowId === firstRowId) {
                    return;
                }

                const tableCell = model.getCell(c.id);

                if (!tableCell) {
                    return;
                }
                const cell = tableCell.children[0];

                this.connectTableCellChildren(cell, filledFirstTableChildren[0], messages.consistOf, ';horizontal=0;');
            });
        };

        const model = this.graph.getModel();
        (byRowId[firstRowId] || []).forEach((c) => {
            if (c.columnId === firstColumnId) {
                return;
            }
            const tableCell = model.getCell(c.id);
            if (tableCell?.children?.length) {
                connectColumn(byColumnId[c.columnId], firstRowId);
            }
        });
    }

    private connectRows(byRowId, byColumnId, firstRowId, firstColumnId) {
        const connectRow = (tableCells: GridCell[], firstColumnId: string) => {
            const firstTableCell = tableCells.find((c) => {
                if (c.columnId === firstColumnId) {
                    return model.getCell(c.id);
                }
                return false;
            });

            if (!firstTableCell) {
                return;
            }

            const filledFirstTableChildren =
                model.getCell(firstTableCell.id)?.children?.filter(this.isSupportedCell) || [];

            if (!filledFirstTableChildren.length) {
                return;
            }
            tableCells.forEach((c) => {
                if (c.columnId === firstColumnId) {
                    return;
                }

                const tableCell = model.getCell(c.id);

                if (!tableCell) {
                    return;
                }
                const cell = tableCell.children[0];

                this.connectTableCellChildren(cell, filledFirstTableChildren[0], messages.referTo);
            });
        };
        const model = this.graph.getModel();
        (byColumnId[firstColumnId] || []).forEach((c) => {
            if (c.rowId === firstRowId) {
                return;
            }
            const tableCell = model.getCell(c.id);
            if (tableCell?.children?.length) {
                connectRow(byRowId[c.rowId], firstColumnId);
            }
        });
    }

    private connectTableCellChildren(
        sourceGridCell: GridCell,
        targetGridCell: GridCell,
        title: MessageDescriptor,
        style: string = '',
    ) {
        const model = this.graph.getModel();
        const sourceTableCell = model.getCell(sourceGridCell.id);
        const targetTableCell = model.getCell(targetGridCell.id);
        const parent = this.graph.getDefaultParent();
        const intl = LocalesService.useIntl();

        const edge = this.graph.insertEdge(parent, uuid(), intl.formatMessage(title), sourceTableCell, targetTableCell);
        edge.setStyle(`${edge.getStyle()}${style}`);
    }

    private isSupportedCell(cell: MxCell): boolean {
        return cell.getValue()?.type === 'object';
    }
    getNewColumnTitle() {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(messages.newColumnConsistOf);
    }
    getNewRowTitle() {
        const intl = LocalesService.useIntl();
        return intl.formatMessage(messages.newRowReferTo);
    }
}

export default PSDGrid;

