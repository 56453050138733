import React, { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ModelTypeGroup, Symbol, KanbanBoardType, KanbanCardType, InternationalString } from '../../../../../../serverapi/api';
import messages from './KanbanModelType.messages';
import { Form, FormInstance, Tabs } from 'antd';
import { KanbanModelTypeGeneralTab } from './KanbanModelTypeGeneralTab/KanbanModelTypeGeneralTab.component';
import { EditorFooterButtons } from '../Footer/EditorFooterButtons.component';
import { workspaceRemoveTabByNodeId } from '../../../../../../actions/tabs.actions';
import { WorkspaceTabDataSelectors } from '../../../../../../selectors/workspaceTabData.selectors';
import { withWindowResize } from '../../../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import theme from '../Presets.scss';
import { showNotificationByType } from '../../../../../../actions/notification.actions';
import { NotificationType } from '../../../../../../models/notificationType';
import { TWithWindowResizeProps } from '../../../../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import { IWorkspaceTabItemModelEditParams, TWorkspaceTab } from '../../../../../../models/tab.types';
import { TSubmitKanbanModelTypePayload } from '../../../../../../actions/presetSettings/presetSettingsKanbanModelType.actions.types';
import {
    deleteKanbanModelType,
    submitKanbanModelType,
} from '../../../../../../actions/presetSettings/presetSettingsKanbanModelType.actions';
import { SymbolSelectors } from '../../../../../../selectors/symbol.selectors';
import { ObjectsCardsTab } from './ObjectsCardsTab/ObjectsCardsTab.component';
import { PresetSettingsKanbanCardTypeSelectors } from '../../../../../../selectors/presetSettings/presetSettingsKanbanCardType.selectors';
import { ModelTypeGroupSelectors } from '../../../../../../selectors/modelTypeGroup.selectors';
import { ColumnsSettingsTab } from './ColumnsSettingsTab/ColumnsSettingsTab.component';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { AutomationTab } from './AutomationTab/AutomationTab.component';
import { createButtonsCompositeDataTestId } from '../util/createButtonsCompositeDataTestId.utils';
import { TButtonProps } from '../../../../../UIKit/components/Button/Button.types';
import type { Tab } from 'rc-tabs/lib/interface';

type TKanbanModelTypeEditorProps = TWithWindowResizeProps & {
    tab: TWorkspaceTab;
};

const KanbanModelTypeEditor: FC<TKanbanModelTypeEditorProps> = (props) => {
    const params = props.tab.params as IWorkspaceTabItemModelEditParams;
    const { serverNode, preset, createMode, modelTypeId } = params;
    const { serverId } = serverNode.nodeId;
    const presetId = preset.id;

    const formRef = useRef<FormInstance>(null);
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);

    const modelType: KanbanBoardType = useSelector(
        WorkspaceTabDataSelectors.getEditKanbanModelType(presetId, modelTypeId),
    );
    const multilingualName: InternationalString = modelType.multilingualName!;
    const modelTypeGroups: ModelTypeGroup[] = useSelector(
        ModelTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId: preset.id,
        }),
    );

    const symbols: Symbol[] = useSelector(SymbolSelectors.byServerIdPresetId(serverId, preset.id)) || [];
    const modelTypeSymbols: Symbol[] =
        SymbolSelectors.chooseModelTypeSymbols(
            symbols.filter((symbol) => modelType.availableSymbolsCardTypesIds.symbolsIds.includes(symbol.id)) || [],
            symbols,
        ) || [];

    const cards: KanbanCardType[] = useSelector(PresetSettingsKanbanCardTypeSelectors.listByPresetId(preset.id)) || [];
    const modelTypeCards = cards.filter((card) =>
        modelType.availableSymbolsCardTypesIds.cardTypesIds.includes(card.id),
    );

    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (!modelType) {
            dispatch(showNotificationByType(NotificationType.MODEL_TO_EDIT_NOT_FOUND));
        }
    }, [modelType]);

    const buttons: TButtonProps[] = [
        {
            children: intl.formatMessage(messages.cancel),
            onClick: () => dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId)),
        },
        {
            children: intl.formatMessage(messages.remove),
            visualStyle: 'primary',
            disabled: createMode,
            onClick: () => {
                dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId));
                dispatch(
                    deleteKanbanModelType({
                        presetId,
                        kanbanModelTypesForDelete: [modelType],
                    }),
                );
            },
        },
        {
            children: createMode ? intl.formatMessage(messages.create) : intl.formatMessage(messages.save),
            visualStyle: 'primary',
            disabled: isSaveDisabled,
            onClick: () => {
                const form = formRef.current;
                if (form) {
                    form.validateFields()
                        .then(() => {
                            const payload: TSubmitKanbanModelTypePayload = {
                                requestBody: { body: modelType },
                                presetId: preset.id,
                                createMode,
                                needTabClose: true,
                                tabNodeId: props.tab.nodeId,
                            };
                            dispatch(submitKanbanModelType(payload));
                        })
                        .catch(() => undefined);
                }
            },
        },
    ];

    const items: Tab[] = [
        {
            label: intl.formatMessage(messages.generalSettings),
            key: "GeneralSettings",
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <Form ref={formRef} layout="vertical">
                        <KanbanModelTypeGeneralTab
                            modelType={modelType}
                            createMode={createMode}
                            generalForm={formRef.current!}
                            modelTypeGroups={modelTypeGroups}
                        />
                    </Form>
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')} />
                </div>
            ),
        },
        {
            label: intl.formatMessage(messages.objectsAndCards),
            key: "KanbanModelTypeObjectsCards",
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <ObjectsCardsTab
                        modelTypeSymbols={modelTypeSymbols}
                        modelType={modelType}
                        symbols={symbols}
                        serverId={serverId}
                        preset={preset}
                        serverNode={serverNode}
                        cards={cards}
                        modelTypeCards={modelTypeCards}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'object-cards_tab')} />
                </div>
            ),
        },
        {
            label: intl.formatMessage(messages.columnsSettings),
            key: "KanbanModelTypeColumnsSettings",
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <ColumnsSettingsTab
                        setIsSaveDisabled={setIsSaveDisabled}
                        isSaveDisabled={isSaveDisabled}
                        serverId={serverId}
                        presetId={preset.id}
                        modelType={modelType}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'model-types-column-settings_tab')} />
                </div>
            ),
        },
        {
            label: intl.formatMessage(messages.automation),
            key: "KanbanModelTypeAutomation",
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <AutomationTab
                        serverId={serverId}
                        presetId={preset.id}
                        modelType={modelType}
                        modelTypeSymbols={modelTypeSymbols}
                        modelTypeCards={modelTypeCards}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'model-types-automation_tab')} />
                </div>
            ),
        },
    ];

    return (
        <div className={theme.container}>
            {modelType ? (
                <>
                    <span className={theme.navigationTitle}>{`${preset.name} > ${intl.formatMessage(
                        messages.modelType,
                    )} > ${multilingualName[currentLocale]}`}</span>
                    <Tabs className={theme.tabs} tabPosition="left" defaultActiveKey="GeneralSettings" items={items} />
                </>
            ) : null}
        </div>
    );
};

export default withWindowResize(KanbanModelTypeEditor);
