import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import icEdit from '../../../resources/icons/ic-edit.svg';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from './RowButtons.messages';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TEditButton = {
    onClick: () => void;
};

const EditButton: FC<TEditButton> = (props) => {
    const { onClick } = props;
    const intl = useIntl();

    return (
        <Button tooltip={intl.formatMessage(messages.edit)} size="small" dataTest="row-button_EDIT" visualStyle="text" onClick={onClick}>
            <Icon spriteSymbol={icEdit} />
        </Button>
    );
};

export default EditButton;
