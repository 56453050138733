// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PsdTableDeleteConfirmationDialog__dialog__cCtGk .ant-modal-footer .ant-btn{margin-top:0;vertical-align:top}.PsdTableDeleteConfirmationDialog__content___kNEF{max-width:402px;color:var(--text);font-size:14px}.PsdTableDeleteConfirmationDialog__cancel__N4xGo{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.PsdTableDeleteConfirmationDialog__cancelIcon__LTZXn{margin-right:5px}", "",{"version":3,"sources":["webpack://./src/modules/PsdTableDeleteConfirmationDialog/components/PsdTableDeleteConfirmationDialog.scss"],"names":[],"mappings":"AAEQ,4EACI,YAAA,CACA,kBAAA,CAIZ,kDACI,eAAA,CACA,iBAAA,CACA,cAAA,CAGJ,iDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGJ,qDACI,gBAAA","sourcesContent":[".dialog {\r\n    :global {\r\n        .ant-modal-footer .ant-btn {\r\n            margin-top: 0;\r\n            vertical-align: top;\r\n        }\r\n    }\r\n}\r\n.content {\r\n    max-width: 402px;\r\n    color: var(--text);\r\n    font-size: 14px;\r\n}\r\n\r\n.cancel {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.cancelIcon {\r\n    margin-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "PsdTableDeleteConfirmationDialog__dialog__cCtGk",
	"content": "PsdTableDeleteConfirmationDialog__content___kNEF",
	"cancel": "PsdTableDeleteConfirmationDialog__cancel__N4xGo",
	"cancelIcon": "PsdTableDeleteConfirmationDialog__cancelIcon__LTZXn"
};
export default ___CSS_LOADER_EXPORT___;
