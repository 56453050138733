import { EdgeDefinitionNode, NodeId } from '../../serverapi/api';
import { setServerIdToNodeInterface } from '../../utils/nodeId.utils';
import { apiBundle } from '../api/api-bundle';

export class EdgeDefinitionDAOService {
    public static async createEdgeDefinition(
        serverId: string,
        edgeDefinition: EdgeDefinitionNode,
    ): Promise<EdgeDefinitionNode> {
        const newEdgeDefinition = await apiBundle(serverId).edges.create({
            body: edgeDefinition,
        });

        return setServerIdToNodeInterface(newEdgeDefinition, serverId);
    }

    public static async searchExistingEdgeDefinitions(
        graphId: NodeId,
        sourceObjectDefinitionIds: string[],
        targetObjectDefinitionIds: string[],
    ): Promise<EdgeDefinitionNode[]> {
        const { serverId, repositoryId } = graphId;

        if (sourceObjectDefinitionIds.length === 0 && targetObjectDefinitionIds.length === 0) {
            return [];
        }

        return apiBundle(serverId).edges.search({
            body: {
                repositoryId,
                startObjectsIds: sourceObjectDefinitionIds,
                endObjectsIds: targetObjectDefinitionIds,
            },
        });
    }

    public static async getEdgeDefinition(nodeId: NodeId): Promise<EdgeDefinitionNode> {
        const { serverId, repositoryId, id } = nodeId;
        const edgeDefinition = await apiBundle(serverId).edges.getEdgeDefinition({
            repositoryId,
            edgeDefinitionId: id,
        });

        return setServerIdToNodeInterface(edgeDefinition, serverId);
    }
}
