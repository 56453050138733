import React, { FC, useState } from 'react';
import theme from './ApprovalsTab.scss';
import messages from './ApprovalsTab.messages';
import {
    ApprovalStageDTOStatusEnum,
    ApprovalUserDTO,
    ApprovalUserDTOVoteEnum,
    PrincipalDescriptor,
} from '@/serverapi/api';
import { ApprovalStageDTOStatus, ApprovalUserDTOVote } from '@/modules/ApprovalDialog/ApprovalDialog.types';
import { timestampToStringDate } from '@/utils/date.time.utils';
import { Icon } from '@/modules/UIKit';
import icApprovalStageApproved from '../../../../resources/icons/ic-approval-stage-approved.svg';
import icApprovalStageNotApproved from '../../../../resources/icons/ic-approval-stage-not-approved.svg';
import icAddAssistant from '../../../../resources/icons/ic-add-approval-stage.svg';
import icArrowDownDark from '../../../../resources/icons/icArrowDownDark.svg';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import { getDisplayName, sortUsers } from '@/modules/ApprovalDialog/utils/approval.utils';
import { useSelector } from 'react-redux';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import { getAntdPopupContainer } from '@/utils/getAntdPopupContainer';

type TApprovalStageUserStatus = {
    stageStatus: ApprovalStageDTOStatusEnum | undefined;
    userVote: ApprovalUserDTOVoteEnum | undefined;
    userVotedAt: number | undefined;
    principleId: number;
    approvalUsers: ApprovalUserDTO[] | undefined;
    addAssistant: (approvalUserId: number, assistantId: number) => void;
};

export const ApprovalStageUserStatus: FC<TApprovalStageUserStatus> = ({
    stageStatus,
    userVote,
    userVotedAt,
    principleId,
    approvalUsers,
    addAssistant,
}) => {
    const intl = useIntl();
    const [isAddAssistantSelectVisible, setIsAddAssistantSelectVisible] = useState<boolean>(false);
    const users: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getUsers);
    const notSelectedUsers = users.filter((user) => {
        if (!approvalUsers) return true;

        const isUserInApprovalUsers = approvalUsers.find((userDTO) => userDTO.principalId === user.id);

        const approvalUserAssistantIds: number[] | undefined = approvalUsers
            .find((userDTO) => userDTO.principalId === principleId)
            ?.assistants?.map((assistant) => assistant.assistantId);
        if (!approvalUserAssistantIds || !approvalUserAssistantIds.length) return !isUserInApprovalUsers;

        return !approvalUserAssistantIds.includes(user.id) && !isUserInApprovalUsers;
    });

    const onAddAssistant = (id: number) => {
        addAssistant(principleId, id);
        setIsAddAssistantSelectVisible(false);
    };

    const renderVoteInfo = () => {
        let voteInfo: React.ReactNode;

        if (userVote === ApprovalUserDTOVote.APPROVED || userVote === ApprovalUserDTOVote.NOT_APPROVED) {
            const [date, time] = timestampToStringDate(userVotedAt!, 'DD.MM.YYYY HH:mm').split(' ');
            voteInfo = (
                <div className={theme.flexCenter}>
                    {!stageStatus || stageStatus === ApprovalStageDTOStatus.IN_PROCESS ? (
                        isAddAssistantSelectVisible ? (
                            <Select
                                open={isAddAssistantSelectVisible}
                                autoFocus
                                onBlur={() => {
                                    setIsAddAssistantSelectVisible(false);
                                }}
                                suffixIcon={<Icon spriteSymbol={icArrowDownDark} />}
                                placeholder={intl.formatMessage(messages.selectUser)}
                                style={{ marginRight: '5px' }}
                                onChange={onAddAssistant}
                                virtual={false}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return ((option?.children ?? '') as string)
                                        .toLowerCase()
                                        .includes(input.toLocaleLowerCase().trim());
                                }}
                            >
                                {sortUsers(notSelectedUsers).map((user: PrincipalDescriptor) => {
                                    const { id } = user;
                                    const displayName = getDisplayName(user);

                                    return (
                                        <Select.Option value={id} key={id}>
                                            {displayName}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        ) : (
                            <button
                                type="button"
                                className={theme.addAssistantBtn}
                                onClick={() => setIsAddAssistantSelectVisible(true)}
                            >
                                <Icon spriteSymbol={icAddAssistant} />
                                <span>{intl.formatMessage(messages.addAssistant)}</span>
                            </button>
                        )
                    ) : null}
                    <div className={theme.voteStatus}>
                        {userVote === ApprovalUserDTOVote.APPROVED ? (
                            <div>
                                <Icon spriteSymbol={icApprovalStageApproved} />
                                <span className={theme.voteApproved}>{intl.formatMessage(messages.approved)}</span>
                            </div>
                        ) : (
                            <div>
                                <Icon spriteSymbol={icApprovalStageNotApproved} />
                                <span className={theme.voteNotApproved}>
                                    {intl.formatMessage(messages.notApproved)}
                                </span>
                            </div>
                        )}
                        <div>{`${date} ${intl.formatMessage(messages.at)} ${time}`}</div>
                    </div>
                </div>
            );
        }

        if (!userVote) {
            voteInfo =
                stageStatus === ApprovalStageDTOStatus.APPROVED ||
                stageStatus === ApprovalStageDTOStatus.NOT_APPROVED ? (
                    <div className={theme.voteWaiting}>{intl.formatMessage(messages.notVoted)}</div>
                ) : (
                    <div className={theme.flexCenter}>
                        {isAddAssistantSelectVisible ? (
                            <Select
                                open={isAddAssistantSelectVisible}
                                autoFocus
                                onBlur={() => {
                                    setIsAddAssistantSelectVisible(false);
                                }}
                                getPopupContainer={getAntdPopupContainer}
                                suffixIcon={<Icon spriteSymbol={icArrowDownDark} />}
                                placeholder={intl.formatMessage(messages.selectUser)}
                                style={{ marginRight: '5px' }}
                                onChange={onAddAssistant}
                                virtual={false}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return ((option?.children ?? '') as string)
                                        .toLowerCase()
                                        .includes(input.toLocaleLowerCase().trim());
                                }}
                            >
                                {sortUsers(notSelectedUsers).map((user: PrincipalDescriptor) => {
                                    const { id } = user;
                                    const displayName = getDisplayName(user);

                                    return (
                                        <Select.Option value={id} key={id}>
                                            {displayName}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        ) : (
                            <button
                                type="button"
                                className={theme.addAssistantBtn}
                                onClick={() => setIsAddAssistantSelectVisible(true)}
                            >
                                <Icon spriteSymbol={icAddAssistant} />
                                <span>{intl.formatMessage(messages.addAssistant)}</span>
                            </button>
                        )}

                        {stageStatus ? (
                            <div className={theme.voteWaiting}>{intl.formatMessage(messages.waitingForVote)}</div>
                        ) : null}
                    </div>
                );
        }
        return voteInfo;
    };

    return <>{renderVoteInfo()}</>;
};
