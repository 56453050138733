// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttributeTypeSelector__formatRow___6_uy{width:calc(100% - 32px);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}.AttributeTypeSelector__formatValue__rKVvf{margin-left:5px;height:30px}.AttributeTypeSelector__selectAttributeWrapper__Xw5QT{display:-webkit-box;display:-ms-flexbox;display:flex}.AttributeTypeSelector__attributeSelect__cy_n_{width:calc(100% - 32px) !important}.AttributeTypeSelector__attributeSelect__cy_n_{width:calc(100% - 32px) !important}", "",{"version":3,"sources":["webpack://./src/modules/FloatingAttributes/components/AttributesEditor/AttributeTypeSelector/AttributeTypeSelector.scss"],"names":[],"mappings":"AAAA,yCACI,uBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,0BAAA,CAGJ,2CACI,eAAA,CACA,WAAA,CAGJ,sDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGJ,+CACI,kCAAA,CAGJ,+CACI,kCAAA","sourcesContent":[".formatRow {\r\n    width: calc(100% - 32px);\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: flex-start;\r\n}\r\n\r\n.formatValue {\r\n    margin-left: 5px;\r\n    height: 30px;\r\n}\r\n\r\n.selectAttributeWrapper {\r\n    display: flex;\r\n}\r\n\r\n.attributeSelect {\r\n    width: calc(100% - 32px) !important;\r\n}\r\n\r\n.attributeSelect {\r\n    width: calc(100% - 32px) !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formatRow": "AttributeTypeSelector__formatRow___6_uy",
	"formatValue": "AttributeTypeSelector__formatValue__rKVvf",
	"selectAttributeWrapper": "AttributeTypeSelector__selectAttributeWrapper__Xw5QT",
	"attributeSelect": "AttributeTypeSelector__attributeSelect__cy_n_"
};
export default ___CSS_LOADER_EXPORT___;
