// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserManagment__container__r3Hd8{background:#fff;height:100%;padding:15px 0px;border-radius:4px}.UserManagment__groupDataContainer__YMNKM{padding:10px;overflow:auto;height:100%;width:250}.UserManagment__rightBorder__Ngg3Y{border-right:1px solid #e9e9e9;width:250px;min-width:250px;height:100%}.UserManagment__permissionsDataContainer__X1D5L{border:1px solid #e9e9e9;border-radius:5px;height:100px;width:300px;overflow-y:auto;word-break:break-word;white-space:pre-wrap;padding:1px 10px;margin:7px 0px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/UserManagement/components/UserManagment.scss"],"names":[],"mappings":"AAEA,iCACI,eAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CAEJ,0CACI,YAAA,CACA,aAAA,CACA,WAAA,CACA,SAAA,CAGJ,mCACI,8BAhBK,CAiBL,WAAA,CACA,eAAA,CACA,WAAA,CAGJ,gDACI,wBAvBK,CAwBL,iBAAA,CACA,YAAA,CACA,WAAA,CACA,eAAA,CACA,qBAAA,CACA,oBAAA,CACA,gBAAA,CACA,cAAA","sourcesContent":["$border: 1px solid #e9e9e9;\r\n\r\n.container {\r\n    background: #fff;\r\n    height: 100%;\r\n    padding: 15px 0px;\r\n    border-radius: 4px;\r\n}\r\n.groupDataContainer {\r\n    padding: 10px;\r\n    overflow: auto;\r\n    height: 100%;\r\n    width: 250\r\n}\r\n\r\n.rightBorder {\r\n    border-right: $border;\r\n    width: 250px;\r\n    min-width: 250px;\r\n    height: 100%;\r\n}\r\n\r\n.permissionsDataContainer {\r\n    border: $border;\r\n    border-radius: 5px;\r\n    height: 100px;\r\n    width: 300px;\r\n    overflow-y: auto;\r\n    word-break: break-word;\r\n    white-space: pre-wrap;\r\n    padding: 1px 10px;\r\n    margin: 7px 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UserManagment__container__r3Hd8",
	"groupDataContainer": "UserManagment__groupDataContainer__YMNKM",
	"rightBorder": "UserManagment__rightBorder__Ngg3Y",
	"permissionsDataContainer": "UserManagment__permissionsDataContainer__X1D5L"
};
export default ___CSS_LOADER_EXPORT___;
