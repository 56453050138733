// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GroupSelectionDialog__selectGroupType__HHFJQ{width:300px;margin-top:15px}.GroupSelectionDialog__selectGroupType__HHFJQ .ant-select{width:300px !important}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/Dialog/GroupSelectionDialog/GroupSelectionDialog.scss"],"names":[],"mappings":"AAAA,8CACI,WAAA,CACA,eAAA,CAGI,0DACI,sBAAA","sourcesContent":[".selectGroupType {\r\n    width: 300px;\r\n    margin-top: 15px;\r\n\r\n    :global {\r\n        .ant-select {\r\n            width: 300px !important;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectGroupType": "GroupSelectionDialog__selectGroupType__HHFJQ"
};
export default ___CSS_LOADER_EXPORT___;
