import type { NodeViewProps } from '@tiptap/core';
import { Resizable } from 're-resizable';
import React, { useState } from 'react';
import theme from './ImageRenderer.styles.scss';
import classnames from 'classnames';
import { Card } from 'antd';
import { NodeViewWrapper } from '@tiptap/react';

export const ImageRenderer = (props: NodeViewProps) => {
    const { node, updateAttributes } = props;
    const { src, width: imageWidth, height: imageHeight } = node.attrs;

    const [width, setWidth] = useState(imageWidth);
    const [height, setHeight] = useState(imageHeight);

    const onResizeEnd = (event: MouseEvent | TouchEvent, direction, elementRef: HTMLDivElement) => {
        const rect = elementRef.getBoundingClientRect();

        setWidth(rect.width);
        setHeight(rect.height);
        updateAttributes({ width: rect.width, height: rect.height });
    };

    const imageSrc = src;

    return (
        <NodeViewWrapper>
            <Resizable
                size={{ width, height }}
                onResizeStop={onResizeEnd}
                className={theme.resizeContainer}
                bounds="parent"
                maxWidth="100%"
            >
                <Card className={theme.imageCard}>
                    <img {...node.attrs} src={imageSrc} />
                </Card>
                <span className={classnames(theme.resizePoints, theme.resizePointsTop)} />
                <span className={classnames(theme.resizePoints, theme.resizePointsBottom)} />
                <span className={classnames(theme.resizePoints, theme.resizePointsMidH)} />
                <span className={classnames(theme.resizePoints, theme.resizePointsMidV)} />
            </Resizable>
        </NodeViewWrapper>
    );
};
