import { defineMessages } from 'react-intl';

export default defineMessages({
    defaultModelName: {
        id: 'GridDiagram.modelName',
        defaultMessage: 'gridModel',
    },
    addColumn: {
        id: 'GridDiagram.AddColumn',
        defaultMessage: 'Добавить колонку',
    },
    removeColumn: {
        id: 'GridDiagram.RemoveColumn',
        defaultMessage: 'Удалить колонку',
    },
    addRow: {
        id: 'GridDiagram.AddRow',
        defaultMessage: 'Добавить строку',
    },
    removeRow: {
        id: 'GridDiagram.RemoveRow',
        defaultMessage: 'Удалить строку',
    },
    consistOf: {
        id: 'GridDiagram.consistOf',
        defaultMessage: 'состоит из',
    },
    newColumnConsistOf: {
        id: 'GridDiagram.newColumnConsistOf',
        defaultMessage: 'Состоит из',
    },
    referTo: {
        id: 'GridDiagram.referTo',
        defaultMessage: 'принадлежит',
    },
    newRowReferTo: {
        id: 'GridDiagram.newRowReferTo',
        defaultMessage: 'Принадлежит к',
    },
});
