import { HeaderType } from '@/modules/Matrix/NewMatrixEditor/NewMatrix.types';
import { TReducer } from '../../utils/types';
import {
    MATRIX_CLEAR_SELECTED_CELLS,
    MATRIX_SELECT_HEADER_CELLS,
    MATRIX_SELECT_CELLS,
} from '@/actionsTypes/entities/newMatrix.actionTypes';
import { TNewMatrixState } from './newMatrix.reducer.types';
import {
    CHANGE_MATRIX_PROPERTIES,
    MATRIX_REQUEST_SUCCESS,
    MATRIX_SAVE_REQUEST_SUCCESS,
} from '@/actionsTypes/entities/matrix.actionTypes';

const INITIAL_MATRIX_STATE: TNewMatrixState = {};

export const newMatrixReducer: TReducer<TNewMatrixState> = (state = INITIAL_MATRIX_STATE, action) => {
    switch (action.type) {
        case MATRIX_SELECT_HEADER_CELLS: {
            const {
                payload: {
                    nodeId: { id, repositoryId },
                    ids,
                    type,
                },
            } = action;

            if (
                ids.length === 0 &&
                state[repositoryId]?.[id]?.selectedHeaderCells?.ids.length === 0 &&
                state[repositoryId]?.[id]?.selectedCells.length === 0
            )
                return state;

            return {
                ...state,
                [repositoryId]: {
                    ...(state[repositoryId] || {}),
                    [id]: {
                        ...(state[repositoryId]?.[id] || {}),
                        selectedHeaderCells: {
                            type,
                            ids,
                        },
                        selectedCells: [],
                    },
                },
            };
        }
        case MATRIX_CLEAR_SELECTED_CELLS: {
            const {
                payload: {
                    nodeId: { id, repositoryId },
                },
            } = action;

            if (
                state[repositoryId]?.[id]?.selectedHeaderCells?.ids.length === 0 &&
                state[repositoryId]?.[id]?.selectedCells.length === 0
            )
                return state;

            return {
                ...state,
                [repositoryId]: {
                    ...(state[repositoryId] || {}),
                    [id]: {
                        ...(state[repositoryId]?.[id] || {}),
                        selectedHeaderCells: {
                            type: HeaderType.column,
                            ids: [],
                        },
                        selectedCells: [],
                    },
                },
            };
        }

        case MATRIX_SELECT_CELLS: {
            const {
                payload: {
                    nodeId: { id, repositoryId },
                    ids,
                },
            } = action;

            if (
                ids.length === 0 &&
                state[repositoryId]?.[id]?.selectedHeaderCells?.ids.length === 0 &&
                state[repositoryId]?.[id]?.selectedCells.length === 0
            )
                return state;

            return {
                ...state,
                [repositoryId]: {
                    ...(state[repositoryId] || {}),
                    [id]: {
                        ...(state[repositoryId]?.[id] || {}),
                        selectedHeaderCells: {
                            type: HeaderType.column,
                            ids: [],
                        },
                        selectedCells: ids,
                    },
                },
            };
        }

        case CHANGE_MATRIX_PROPERTIES: {
            const {
                payload: {
                    nodeId: { id, repositoryId },
                },
            } = action;

            return {
                ...state,
                [repositoryId]: {
                    ...(state[repositoryId] || {}),
                    [id]: {
                        ...(state[repositoryId]?.[id] || {}),
                        unsaved: true,
                    },
                },
            };
        }

        case MATRIX_SAVE_REQUEST_SUCCESS:
        case MATRIX_REQUEST_SUCCESS: {
            const {
                payload: {
                    matrix: {
                        nodeId: { repositoryId, id },
                    },
                },
            } = action;

            return {
                ...state,
                [repositoryId]: {
                    ...(state[repositoryId] || {}),
                    [id]: {
                        ...(state[repositoryId]?.[id] || {}),
                        unsaved: false,
                    },
                },
            };
        }

        default:
            return state;
    }
};
