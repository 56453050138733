import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../messages/ImportAris.messages';
import style from './ImportAris.scss';
import { Button, Steps } from 'antd';
import SelectFileStep from '../SelectFileStep/SelectFileStep.component';
import AnalysisStep from '../AnalysisStep/AnalysisStep.component';
import SelectDataStep from '../SelectDataStep/SelectDataStep.component';
import MethodologyStep from '../MethodologyStep/MethodologyStep.component';
import ParamsStep from '../ParamsStep/ParamsStep.component';
import ImportStep from '../ImportStep/ImportStep.component';
import FinishStep from '../FinishStep/FinishStep.component';
import { ArisImportDescriptor, ArisImportDescriptorStageEnum } from '../../../../../serverapi/api';
import { ImportArisSelectors } from '../../../../../selectors/importAris.selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeImportArisStage,
    saveArisInExcel,
    sendAnalysisArisFile,
} from '../../../../../actions/importAris.actions';
import { workspaceRemoveTabByNodeId } from '../../../../../actions/tabs.actions';
import { IWorkspaceImportArisTabItemParams, TWorkspaceTab } from '../../../../../models/tab.types';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';

const { Step } = Steps;

type TImportArisProps = {
    tab: TWorkspaceTab;
};

const ImportAris: FC<TImportArisProps> = ({ tab }: TImportArisProps) => {
    const params = tab.params as IWorkspaceImportArisTabItemParams;
    const { nodeId } = params;
    const { serverId, repositoryId, id } = nodeId;

    const dispatch = useDispatch();
    const intl = useIntl();

    const descriptor: ArisImportDescriptor = useSelector(
        ImportArisSelectors.getArisDescriptor(serverId, repositoryId, id),
    );

    const path = useSelector(ImportArisSelectors.getPath(serverId, repositoryId, id));

    const { stage } = descriptor;

    const getCurrentStepNumber = () => {
        const stepNumber: { [key in ArisImportDescriptorStageEnum]: number } = {
            UPLOAD: 0,
            ANALYSIS: 1,
            DATA_SELECT: 2,
            METHODOLOGY: 3,
            PARAMETERS: 4,
            IMPORT: 5,
            FINISH: 6,
        };

        return stepNumber[stage];
    };

    const [currentStep, setCurrentStep] = useState<number>(getCurrentStepNumber());

    const steps = [
        {
            title: intl.formatMessage(messages.selectFileStep),
            content: <SelectFileStep nodeId={nodeId} />,
        },
        {
            title: intl.formatMessage(messages.analysisStep),
            content: <AnalysisStep nodeId={nodeId} />,
        },
        {
            title: intl.formatMessage(messages.selectDataStep),
            content: <SelectDataStep nodeId={nodeId} />,
        },
        {
            title: intl.formatMessage(messages.methodologyStep),
            content: <MethodologyStep nodeId={nodeId} />,
        },
        {
            title: intl.formatMessage(messages.paramsStep),
            content: <ParamsStep nodeId={nodeId} />,
        },
        {
            title: intl.formatMessage(messages.importStep),
            content: <ImportStep nodeId={nodeId} />,
        },
        {
            title: intl.formatMessage(messages.finishStep),
            content: <FinishStep />,
        },
    ];

    useEffect(() => {
        setCurrentStep(getCurrentStepNumber());
    }, [descriptor.stage]);

    const handleNextStepButton = () => {
        if (stage === 'UPLOAD' && descriptor.fileId) {
            dispatch(changeImportArisStage({ nodeId, stage: 'ANALYSIS' }));
            dispatch(sendAnalysisArisFile({ nodeId, fileId: descriptor.fileId }));
        }
        if (stage === 'ANALYSIS') {
            dispatch(changeImportArisStage({ nodeId, stage: 'DATA_SELECT' }));
        }
        if (stage === 'DATA_SELECT') {
            dispatch(changeImportArisStage({ nodeId, stage: 'METHODOLOGY' }));
        }
        if (stage === 'METHODOLOGY') {
            dispatch(changeImportArisStage({ nodeId, stage: 'PARAMETERS' }));
        }
        if (stage === 'PARAMETERS') {
            dispatch(changeImportArisStage({ nodeId, stage: 'IMPORT' }));
        }
        if (stage === 'IMPORT') {
            dispatch(changeImportArisStage({ nodeId, stage: 'FINISH' }));
        }
    };

    const handlePreviousStepButton = () => {
        if (stage === 'ANALYSIS') {
            dispatch(changeImportArisStage({ nodeId, stage: 'UPLOAD' }));
        }
        if (stage === 'DATA_SELECT') {
            dispatch(changeImportArisStage({ nodeId, stage: 'ANALYSIS' }));
        }
        if (stage === 'METHODOLOGY') {
            dispatch(changeImportArisStage({ nodeId, stage: 'DATA_SELECT' }));
        }
        if (stage === 'PARAMETERS') {
            dispatch(changeImportArisStage({ nodeId, stage: 'METHODOLOGY' }));
        }
        if (stage === 'IMPORT') {
            dispatch(changeImportArisStage({ nodeId, stage: 'PARAMETERS' }));
        }
        if (stage === 'FINISH') {
            dispatch(changeImportArisStage({ nodeId, stage: 'IMPORT' }));
        }
    };

    const checkIsActiveNextButton = (): boolean => {
        if (stage === 'UPLOAD') {
            return !descriptor.fileId;
        }
        if (stage === 'ANALYSIS') {
            return !descriptor.arisData;
        }
        if (stage === 'DATA_SELECT') {
            return !descriptor.nodeIdsForImport?.length;
        }
        if (stage === 'METHODOLOGY') {
            return false;
        }
        if (stage === 'PARAMETERS') {
            return !descriptor.conflictResolutionStrategy && !descriptor.fileLocation;
        }
        if (stage === 'IMPORT') {
            return true;
        }

        return true;
    };

    const handleLoadArisFromExcel = () => {
        dispatch(openDialog(DialogType.LOAD_ARIS_FROM_EXCEL, { nodeId: { ...nodeId } }));
    };

    const handleSaveArisInExcel = () => {
        if (descriptor.arisData)
            dispatch(saveArisInExcel({ nodeId }));
    };

    return (
        <div className={style.container} data-test="import-ARIS_container">
            <h1 className={style.path_title}>{intl.formatMessage(messages.importArisTitle)}</h1>
            <div className={style.path}>
                {`${intl.formatMessage(messages.pathImport)}:
                ${path}`}
            </div>
            <div className={style.selectContent}>
                <div className={style.steps}>
                    <Steps current={currentStep}>
                        {steps.map((item) => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                </div>
                <div className={style.stepsContent}>{steps[currentStep].content}</div>
                <div className={style.stepsAction}>
                {currentStep === 3 && (
                    <div className={style.saveAndLoadButtonsContainer}>
                        <Button onClick={handleSaveArisInExcel}>{intl.formatMessage(messages.saveArisInExcel)}</Button>
                        <Button onClick={handleLoadArisFromExcel}>
                            {intl.formatMessage(messages.loadArisFromExcel)}{' '}
                        </Button>
                    </div>
                )}
                    {currentStep > 0 && currentStep !== 6 && (
                        <Button onClick={() => handlePreviousStepButton()} data-test="import-ARIS_previous-step-button">
                            {intl.formatMessage(messages.buttonPrevious)}
                        </Button>
                    )}
                    {currentStep === 6 && (
                        <Button
                            type="primary"
                            onClick={() => dispatch(workspaceRemoveTabByNodeId(tab.nodeId))}
                            data-test="import-ARIS_close-button"
                        >
                            {intl.formatMessage(messages.buttonClose)}
                        </Button>
                    )}
                    {currentStep < steps.length - 1 && (
                        <Button
                            data-test="import-ARIS_next-step-button"
                            type="primary"
                            onClick={() => handleNextStepButton()}
                            disabled={checkIsActiveNextButton()}
                        >
                            {intl.formatMessage(messages.buttonNext)}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImportAris;
