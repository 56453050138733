import { ApprovalDTO, NodeId } from '@/serverapi/api';
import { cloneDeep } from 'lodash-es';
import { TApprovalState } from './approval.reducer.types';
import { TReducer } from '@/utils/types';
import {
    ADD_APPROVALS,
    ADD_MY_APPROVALS,
    CHANGE_CHECK_ALL_APPROVALS,
    CHECK_APPROVALS,
    CLEAR_APPROVALS,
    DELETE_APPROVALS_FROM_STORE,
    EDIT_APPROVAL,
    UPDATE_APPROVAL,
} from '@/actionsTypes/approval.actionTypes';
import { compareNodeIds } from '../utils/nodeId.utils';
import { getCheckedApprovalIds } from './utils/approval.reducer.utils';
import { getApprovalsArray } from '../services/bll/ApprovalBLLService';

const initial: TApprovalState = {
    byRepositoryId: {},
    editingApproval: {} as ApprovalDTO,
    checkedApprovals: [],
    myApprovals: [],
};

export const approvalReducer: TReducer<TApprovalState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_APPROVALS: {
            const {
                payload: { approvals },
            } = action;
            const stateClone: TApprovalState = cloneDeep(state);
            approvals.forEach((approval) => {
                if (!stateClone.byRepositoryId[approval.id.repositoryId])
                    stateClone.byRepositoryId[approval.id.repositoryId] = { byId: {} };
                stateClone.byRepositoryId[approval.id.repositoryId].byId[approval.id.id] = { ...approval };
            });
            stateClone.editingApproval = {} as ApprovalDTO;
            return stateClone;
        }

        case EDIT_APPROVAL: {
            const {
                payload: { approval },
            } = action;

            const stateClone: TApprovalState = cloneDeep(state);

            return { ...stateClone, editingApproval: approval };
        }

        case DELETE_APPROVALS_FROM_STORE: {
            const {
                payload: { approvalNodeIds },
            } = action;

            const stateClone: TApprovalState = cloneDeep(state);

            const checkedApprovals: NodeId[] = state.checkedApprovals;
            approvalNodeIds.forEach((approvalNodeId) => {
                delete stateClone.byRepositoryId[approvalNodeId.repositoryId]?.byId[approvalNodeId.id];
            });
            stateClone.checkedApprovals = checkedApprovals.filter(
                (approval) => !approvalNodeIds.find((nodeId) => compareNodeIds(nodeId, approval)),
            );
            stateClone.myApprovals = stateClone.myApprovals.filter(
                (approval) => !approvalNodeIds.find((nodeId) => compareNodeIds(nodeId, approval.approval?.id)),
            );

            return stateClone;
        }

        case CHECK_APPROVALS: {
            const {
                payload: { nodeIds },
            } = action;
            const stateClone: TApprovalState = cloneDeep(state);
            const checkedApprovals: NodeId[] = stateClone.checkedApprovals;

            const newApprovalIds: NodeId[] = getCheckedApprovalIds(checkedApprovals, nodeIds);
            stateClone.checkedApprovals = newApprovalIds;
            return stateClone;
        }
        case CHANGE_CHECK_ALL_APPROVALS: {
            const stateClone: TApprovalState = cloneDeep(state);
            const checkedApprovals: NodeId[] = stateClone.checkedApprovals;
            const noDraftApprovals = getApprovalsArray(stateClone.byRepositoryId).filter(
                (approval) => approval.status !== 'DRAFT',
            );
            const newApprovalIds: NodeId[] =
                checkedApprovals.length !== noDraftApprovals.length ? noDraftApprovals.map((appr) => appr.id) : [];
            stateClone.checkedApprovals = newApprovalIds;
            return stateClone;
        }

        case UPDATE_APPROVAL: {
            const {
                payload: { approval },
            } = action;
            const stateClone: TApprovalState = cloneDeep(state);
            stateClone.byRepositoryId[approval.id.repositoryId].byId[approval.id.id] = approval;

            return stateClone;
        }

        case CLEAR_APPROVALS: {
            return initial;
        }

        case ADD_MY_APPROVALS: {
            const {
                payload: { searchResult },
            } = action;

            return { ...state, myApprovals: searchResult };
        }

        default:
            return state;
    }
};
