import { InternationalString } from '../../../../../../serverapi/api';
import { attributeTypeFormatMessage } from '../AttributeTypesTab/util/attributeTypesTab.utils';
import { TTableDataType } from './GroupedTypesTable.types';

export const getMultilingualNameWithType = (
    multilingualName: InternationalString,
    attributeType: string,
): InternationalString =>
    Object.keys(multilingualName).reduce(
        (newMultilingualName, key) => ({
            ...newMultilingualName,
            [key]: `${multilingualName[key]}${attributeType}`,
        }),
        {} as InternationalString,
    );

export const useAttributeNameWithType = (attributes: TTableDataType[]): TTableDataType[] => {
    return attributes.map((attribute: TTableDataType) => {
        const attributeType: string = attribute?.valueType
            ? ` [${attributeTypeFormatMessage(attribute?.valueType)}]`
            : '';

        return {
            ...attribute,
            name: `${attribute.name}${attributeType}`,
            multilingualName:
                attribute.multilingualName && getMultilingualNameWithType(attribute.multilingualName, attributeType),
        };
    });
};
