// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeleteNodeDialog-component__dialog__a2HSm{-webkit-animation-duration:0s !important;animation-duration:0s !important}.DeleteNodeDialog-component__dialog__a2HSm .ant-modal-body{overflow:hidden}.DeleteNodeDialog-component__alert__AZYCo{width:100%}.DeleteNodeDialog-component__alertTitle__fezLq{margin-bottom:12px}.DeleteNodeDialog-component__removableElements__S8uUp{max-height:200px;overflow-y:auto;margin-bottom:10px}.DeleteNodeDialog-component__tab__u_Uqy{height:22px;font-weight:bold}.DeleteNodeDialog-component__button__z9QZq{padding-left:0px}.DeleteNodeDialog-component__content__A4niD{padding-bottom:5px}.DeleteNodeDialog-component__objectEntry__BMcAS,.DeleteNodeDialog-component__edgeEntry__XF_lE{padding:2px 4px}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/DeleteNodeDialog/DeleteNodeDialog.component.scss"],"names":[],"mappings":"AAAA,2CACI,wCAAA,CAAA,gCAAA,CAEA,2DACI,eAAA,CAIR,0CACI,UAAA,CAGJ,+CACI,kBAAA,CAGJ,sDACI,gBAAA,CACA,eAAA,CACA,kBAAA,CAGJ,wCACI,WAAA,CACA,gBAAA,CAGJ,2CACI,gBAAA,CAGJ,4CACI,kBAAA,CAGJ,8FAEI,eAAA","sourcesContent":[".dialog {\r\n    animation-duration: 0s !important;\r\n\r\n    :global(.ant-modal-body){\r\n        overflow: hidden;\r\n    }\r\n}\r\n\r\n.alert {\r\n    width: 100%;\r\n}\r\n\r\n.alertTitle {\r\n    margin-bottom: 12px;\r\n}\r\n\r\n.removableElements {\r\n    max-height: 200px;\r\n    overflow-y: auto;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.tab {\r\n    height: 22px;\r\n    font-weight: bold;\r\n}\r\n\r\n.button {\r\n    padding-left: 0px;\r\n}\r\n\r\n.content {\r\n    padding-bottom: 5px;\r\n}\r\n\r\n.objectEntry,\r\n.edgeEntry {\r\n    padding: 2px 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "DeleteNodeDialog-component__dialog__a2HSm",
	"alert": "DeleteNodeDialog-component__alert__AZYCo",
	"alertTitle": "DeleteNodeDialog-component__alertTitle__fezLq",
	"removableElements": "DeleteNodeDialog-component__removableElements__S8uUp",
	"tab": "DeleteNodeDialog-component__tab__u_Uqy",
	"button": "DeleteNodeDialog-component__button__z9QZq",
	"content": "DeleteNodeDialog-component__content__A4niD",
	"objectEntry": "DeleteNodeDialog-component__objectEntry__BMcAS",
	"edgeEntry": "DeleteNodeDialog-component__edgeEntry__XF_lE"
};
export default ___CSS_LOADER_EXPORT___;
