import { MatrixCellBPM8764, MatrixNode } from '../../serverapi/api';

export class MatrixBllService {
    public static addStyleIdsToCells = (matrix: MatrixNode): MatrixNode => {
        matrix?.data2?.cells?.forEach((cell: MatrixCellBPM8764) => {
            if (!cell?.styleIds?.length) {
                cell.styleIds = [];
            }
        });

        return matrix;
    };
}
