import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../selectors/authorization.selectors';
import { PrincipalDescriptor, UserDTO } from '../../../../serverapi/api';
import { getUserInitials } from '../../../../sagas/utils';
import { getCircleBackgroundColor } from '../../../Comments/utils/colorsAndLetterMatching';
import { UserIcon } from '../UserIcon/UserIcon.component';

type TInitialsCircleProps = {
    circleSize?: number;
    fontSize?: number;
    click?: () => void;
};

export const InitialsCircle = (props: TInitialsCircleProps) => {
    const { circleSize, fontSize, click } = props;
    const user: UserDTO | undefined = useSelector(getUser);
    const circleBackgroundColor: string = getCircleBackgroundColor(user?.login || '');
    const initials: string = getUserInitials({
        name: user?.firstName,
        lastname: user?.lastName,
    } as PrincipalDescriptor);

    if (!user) {
        return null;
    }

    return (
        <UserIcon
            circleBackgroundColor={circleBackgroundColor}
            initials={initials}
            circleSize={circleSize}
            fontSize={fontSize}
            handleClick={click}
        />
    );
};
