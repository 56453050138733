import React, { useState, useCallback, useEffect } from 'react';
import { Alert, Dropdown, MenuProps } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import theme from './MethodologySetting.scss';
import { useIntl } from 'react-intl';
import messages from '../../messages/MethodologySetting.messages';
import edit from '../../../../../resources/icons/edit.svg';
import check from '../../../../../resources/icons/ic-check.svg';
import copy from '../../../../../resources/icons/ic-copy.svg';
import icExport from '../../../../../resources/icons/export.svg';
import icExportExcel from '../../../../../resources/icons/file-excel.svg';
import Deleted from '../../../../../resources/icons/Deleted.svg';
import dots from '../../../../../resources/icons/dots.svg';
import dotsGrey from '../../../../../resources/icons/dotsGrey.svg';
import { getMenuItem } from '../../../../../utils/antdMenuItem.utils';
import { Icon } from '../../../../UIKit';
import { NodeId, RepositoryNode } from '../../../../../serverapi/api';
import { TPreset } from '../../../../../models/preset.types';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearPresetRequest,
    copyPreset,
    deletePresetAction,
    editPresetRequest,
    exportExcelPreset,
    exportPreset,
    makeDefault,
} from '../../../../../actions/methodologySetting.actions';
import { TreeNode } from '../../../../../models/tree.types';
import { PresetSelectors } from '../../../../../selectors/preset.selectors';
import { showDeleteConfirmation } from '../../../Button/DeleteButton';
import { LocalesService } from '../../../../../services/LocalesService';
import { isEmpty } from 'lodash-es';
import { NotificationType } from '../../../../../models/notificationType';
import { showNotificationByType } from '../../../../../actions/notification.actions';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { TWorkspaceTab } from '../../../../../models/tab.types';
import { TPresetId } from '../Presets/TPresetId.types';

type TMethodologySettingActionsButtonProps = {
    repositories: RepositoryNode[] | undefined;
    record: TPreset;
    serverNode: TreeNode;
    nodeId: NodeId;
    isClosedAllMenu: boolean;
};

export const MethodologySettingActionsButton = (props: TMethodologySettingActionsButtonProps) => {
    const { repositories, record, serverNode, nodeId, isClosedAllMenu } = props;
    const intl = useIntl();
    const loading: boolean = useSelector(PresetSelectors.loading());
    const methodologyName: string = LocalesService.internationalStringToString(record.multilingualName);
    const dbNames: string = LocalesService.internationalStringArrayToString(repositories);
    const openedTabs: (TWorkspaceTab & TPresetId)[] = useSelector(TabsSelectors.getTabList);

    const deleteMessage = dbNames ? (
        <Alert message={intl.formatMessage(messages.deleteMethodologyDialogContent, { dbNames })} type="warning" />
    ) : (
        ''
    );

    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isClosedAllMenu) setOpen(false);
    }, [isClosedAllMenu]);

    const toggleOpen = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const dispatch = useDispatch();

    const editPreset = (preset: TPreset) => dispatch(editPresetRequest(preset, serverNode, nodeId));
    const onClickMakeDefault = (preset: TPreset) => dispatch(makeDefault({ preset, serverNode }));
    const onClickCopyPreset = (preset: TPreset) => dispatch(copyPreset(preset, serverNode));
    const onClickExportPreset = (preset: TPreset, serverId: string) => dispatch(exportPreset(preset, serverId));
    const onClickExportExcelPreset = (preset: TPreset, serverId: string) =>
        dispatch(exportExcelPreset(preset, serverId));
    const onRemove = (editablePreset: TPreset) => {
        const isOpenTab = !isEmpty(openedTabs.filter((tab) => editablePreset.id === tab.presetId));

        if (isOpenTab) {
            dispatch(showNotificationByType(NotificationType.METHODOLOGY_IS_OPENED));
        } else {
            dispatch(deletePresetAction(serverNode, editablePreset));
            if (editablePreset.id) {
                dispatch(clearPresetRequest({ serverId: serverNode.nodeId.serverId, presetId: editablePreset.id }));
            }
        }
    };
    const onDelete = () =>
        showDeleteConfirmation({
            dialogContent: deleteMessage,
            onDelete: () => onRemove(record),
            deleteQuestion: intl.formatMessage(messages.deleteMethodologyDialogTitle, { methodologyName }),
        });

    const onClick = (param: MenuInfo) => {
        switch (param.key) {
            case `${record.id}edit`:
                editPreset(record);
                break;
            case `${record.id}makeDefault`:
                onClickMakeDefault(record);
                break;
            case `${record.id}copyPreset`:
                onClickCopyPreset(record);
                break;
            case `${record.id}export`:
                onClickExportPreset(record, serverNode.nodeId.serverId);
                break;
            case `${record.id}export_excel`:
                onClickExportExcelPreset(record, serverNode.nodeId.serverId);
                break;
            case `${record.id}delete`:
                onDelete();
                break;
            default:
                break;
        }

        setOpen(false);
    };

    const items: MenuProps['items'] = [
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.edit)}</div>,
            `${record.id}edit`,
            loading,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={edit} />
            </div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.makeDefault)}</div>,
            `${record.id}makeDefault`,
            false,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={check} />
            </div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.copyPreset)}</div>,
            `${record.id}copyPreset`,
            false,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={copy} />
            </div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.export)}</div>,
            `${record.id}export`,
            false,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={icExport} />
            </div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.excelExport)}</div>,
            `${record.id}export_excel`,
            false,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={icExportExcel} />
            </div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.delete)}</div>,
            `${record.id}delete`,
            false,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={Deleted} />
            </div>,
        ),
    ];

    return (
        <Dropdown
            onOpenChange={toggleOpen}
            open={open}
            menu={{ onClick, items }}
            trigger={['click']}
            destroyPopupOnHide
            overlayClassName={theme.dropdown}
        >
            <Icon className={theme.dropdownTriggerButton} spriteSymbol={open ? dots : dotsGrey} />
        </Dropdown>
    );
};
