import React, { FC } from 'react';

type TIconInputServerProps = {
    className?: string;
};

export const IconDeleteBin: FC<TIconInputServerProps> = ({  className }) => {
    return (
        <div className={className}>
            <svg width="16" height="18" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.1775 5C9.96239 4.27713 9.29276 3.75 8.5 3.75H7.5C6.70724 3.75 6.03761 4.27713 5.82247 5H3.75C3.33579 5 3 5.33579 3 5.75C3 6.16421 3.33579 6.5 3.75 6.5H12.25C12.6642 6.5 13 6.16421 13 5.75C13 5.33579 12.6642 5 12.25 5H10.1775Z" />
                <path d="M3.75 13V7.5H5.25V13C5.25 13.6904 5.80964 14.25 6.5 14.25H9.5C10.1904 14.25 10.75 13.6904 10.75 13V7.5H12.25V13C12.25 14.5188 11.0188 15.75 9.5 15.75H6.5C4.98122 15.75 3.75 14.5188 3.75 13Z" />
            </svg>
        </div>
    );
};
