import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemFetchChildSuccess, treePartFetchSuccess } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import {
    TMatrixChangeDataRequestAction,
    TMatrixDefaultAction,
    TMatrixDefaultRequestAction,
    TMatrixEditorModeChangedAction,
    TMatrixOpenByNodeIdAction,
    TMatrixRequestAction,
    TMatrixUploadFileAction,
} from '../actions/entities/matrix.actions.types';
import {
    matrixCreateSuccess,
    matrixOpen,
    matrixRequest,
    matrixRequestSuccess,
    matrixSaveRequest,
    matrixSaveRequestFailure,
    matrixSaveRequestSuccess,
} from '../actions/entities/matrix.actions';
import { getNodeWithChildren, getTreeItems, TreeSelectors } from '../selectors/tree.selectors';
import { v4 as uuid } from 'uuid';
import { IMatrixNode } from '../models/bpm/bpm-model-impl.types';
import { MATRIX_DIAGRAM_TYPE_ID } from '../models/tree';
import { TreeNode, TTreeEntityState } from '../models/tree.types';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../actionsTypes/tabs.actionTypes';
import { workspaceActivateTab, workspaceAddTab, workspaceRemoveTab } from '../actions/tabs.actions';
import { TWorkspaceTabsRemoveAction } from '../actions/tabs.actions.types';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { MatrixSelectors } from '../selectors/entities/matrix.selectors';
import { FileNodeDTO, LockInfoDTO, MatrixHeader, MatrixLane, Node, NodeId, NodeMatrixHeader } from '../serverapi/api';
import { showNotification } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { matrixService } from '../services/MatrixService';
import { getActiveModelContext, getContentLoadingPageTab } from './utils';
import { IModelContext } from './utils.types';
import { EditorMode } from '../models/editorMode';
import { IDiagramLockError } from '../models/notification/diagramLockError.types';
import { unlock } from '../actions/lock.actions';
import { editorModeChangedAction } from '../actions/editor.actions';
import { recentAddModel } from '../actions/recent.actions';
import { IWorkspaceTabItemMatrixParams, TMatrixTabType, TWorkspaceTab } from '../models/tab.types';
import { setServerIdToNodeInterface, setServerIdToNodeOriginal } from '../utils/nodeId.utils';
import {
    MATRIX_CHANGE_DATA_REQUEST,
    MATRIX_CREATE,
    MATRIX_EDITOR_MODE_CHANGED,
    MATRIX_OPEN,
    MATRIX_OPEN_BY_NODE_ID,
    MATRIX_REQUEST,
    MATRIX_SAVE_REQUEST,
    MATRIX_SAVE_REQUEST_FAILURE,
    MATRIX_SAVE_REQUEST_SUCCESS,
    MATRIX_UPLOAD_FILE,
} from '../actionsTypes/entities/matrix.actionTypes';
import { fileUpload } from '../actions/uploader.actions';
import { FILE_UPLOAD_FAIL, FILE_UPLOAD_SUCCESS } from '../actionsTypes/uploader.actionTypes';
import { TFileUploadSuccessAction } from '../actions/uploader.actions.types';
import { isImageFile } from '../utils/files.utils';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import messages from '../modules/Matrix/MatrixEditor/messages/MatrixEditor.messages';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { presetLoadModelTypes } from './notation.saga';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
import { newMatrixSaveRequest } from '@/actions/entities/newMatrix.actions';
import { MatrixDaoService } from '@/services/dao/MatrixDaoService';

function* handleMatrixOpen(action: TMatrixDefaultAction) {
    const { matrix } = action.payload;
    const workspaceTab: TMatrixTabType = <TMatrixTabType>{
        title: matrix.name,
        type: MATRIX_DIAGRAM_TYPE_ID,
        nodeId: matrix.nodeId,
        content: matrix,
        mode: EditorMode.Read,
        params: <IWorkspaceTabItemMatrixParams>{ content: matrix },
    };
    yield put(workspaceAddTab(workspaceTab));

    yield put(
        recentAddModel({
            nodeId: matrix.nodeId,
            type: TreeItemType.Matrix,
            parentId: matrix.parentNodeId || null,
            createdAt: new Date().toISOString(),
            title: matrix.name,
            modelTypeId: MATRIX_DIAGRAM_TYPE_ID,
            modelTypeName: LocalesService.useIntl(yield select(getCurrentLocale)).formatMessage(messages.matrixModel),
            messageDescriptor: messages.matrixModel,
        }),
    );
}

function* handleTreeItemsMatrixAdd({ payload: { nodeId, type, action } }: TTreeItemContextMenuAction) {
    if (
        (type === TreeItemType.Folder || type === TreeItemType.Repository) &&
        action === TreeItemContextMenuAction.ADD_MATRIX
    ) {
        yield put(openDialog(DialogType.MATRIX_CREATE_DIALOG, { parentNodeId: nodeId }));
    }
}

export function* handleMatrixCreate(action: TMatrixDefaultAction) {
    let { matrix } = action.payload;
    const columns: MatrixLane[] = [];
    const rows: MatrixLane[] = [];
    for (let i = 0; i < 15; i++) {
        columns.push({ id: uuid() });
        rows.push({ id: uuid() });
    }

    matrix = {
        ...matrix,
        data2: {
            cells: [],
            columns,
            rows,
            rowHeaderWidth: 165,
            columnHeaderHeight: 165,
            cellSettings: {
                id: uuid(),
                isAutomatic: false,
            },
            columnSettings: {
                id: uuid(),
                isAutomatic: false,
            },
            rowSettings: {
                id: uuid(),
                isAutomatic: false,
            },
        },
        nodeId: {
            ...matrix.parentNodeId,
            id: uuid(),
        },
        type: TreeItemType.Matrix,
        isDirty: false,
        isNew: false,
        serverId: matrix.parentNodeId?.serverId,
    } as IMatrixNode;

    const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
        title: matrix.name,
        type: MATRIX_DIAGRAM_TYPE_ID,
        nodeId: matrix.nodeId,
        content: matrix,
        mode: EditorMode.Edit,
        params: <IWorkspaceTabItemMatrixParams>{ content: matrix },
    };

    const newTreeNode: TreeNode = {
        nodeId: matrix.nodeId,
        name: matrix.name,
        parentNodeId: matrix.parentNodeId && matrix.parentNodeId,
        type: TreeItemType.Matrix,
        hasChildren: !!(matrix.children && matrix.children.length > 0),
        countChildren: matrix?.children?.length || 0,
    };
    const parentNodeWithChildren: TTreeEntityState = yield select(
        getNodeWithChildren(yield select(TreeSelectors.itemById(matrix.nodeId))),
    );

    if (parentNodeWithChildren.childrenIds) {
        parentNodeWithChildren.childrenIds.push(matrix.nodeId.id);
    } else {
        parentNodeWithChildren.childrenIds = [matrix.nodeId.id];
    }
    yield put(matrixSaveRequest(matrix));
    const { success } = yield race({
        success: take(MATRIX_SAVE_REQUEST_SUCCESS),
        failure: take(MATRIX_SAVE_REQUEST_FAILURE),
    });
    if (success) {
        yield put(
            treeItemFetchChildSuccess({
                parentNodeId: matrix.parentNodeId!,
                child: [newTreeNode],
            }),
        );
        yield put(workspaceAddTab(workspaceTab));
        yield put(
            recentAddModel({
                nodeId: matrix.nodeId,
                type: TreeItemType.Matrix,
                parentId: matrix.parentNodeId || null,
                createdAt: new Date().toISOString(),
                title: matrix.name,
                modelTypeId: MATRIX_DIAGRAM_TYPE_ID,
                modelTypeName: LocalesService.useIntl(yield select(getCurrentLocale)).formatMessage(
                    messages.matrixModel,
                ),
                messageDescriptor: messages.matrixModel,
            }),
        );
        yield put(matrixCreateSuccess(matrix));
    }
    yield put(closeDialog(DialogType.MATRIX_CREATE_DIALOG)); // todo этого тут быть не должно, диалог надо закрывать там где открывали
}

function* handleMatrixSaveRequest(action: TMatrixDefaultRequestAction) {
    const {
        matrix,
        matrix: {
            nodeId: { serverId },
        },
    } = action.payload;

    if (matrix) {
        const server: TServerEntity = yield select(ServerSelectors.server(serverId));
        try {
            const data: IMatrixNode = yield call(() => server.api.matrix.save({ body: matrix }));
            setServerIdToNodeOriginal(data, serverId);
            yield put(matrixSaveRequestSuccess(data));
        } catch (e) {
            yield put(matrixSaveRequestFailure(serverId, e.message));
            throw e;
        }
    }
}

function* handleMatrixOpenByNodeId(action: TMatrixOpenByNodeIdAction) {
    const { nodeId } = action.payload;

    yield put(matrixRequest({ nodeId }));
}

function* handleMatrixRequest(action: TMatrixRequestAction) {
    const { nodeId } = action.payload;
    const tab: TWorkspaceTab = yield select(TabsSelectors.byId(nodeId));
    const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);

    if (tab) {
        yield put(workspaceActivateTab(tab));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);

        return;
    }

    try {
        const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
        const presetId: string = yield select(TreeSelectors.presetById(nodeId));

        yield put(workspaceAddTab(contentLoadingPageTab));
        yield presetId && presetLoadModelTypes(nodeId.serverId, presetId);

        const matrix: IMatrixNode = yield MatrixDaoService.getMatrix(nodeId.repositoryId, nodeId.id);

        setServerIdToNodeOriginal(matrix, nodeId.serverId);

        let treeItemsById: { [id: string]: TTreeEntityState } = yield select(
            getTreeItems(nodeId.serverId, nodeId.repositoryId),
        );
        treeItemsById = treeItemsById || {};

        if (!matrix.data) {
            throw new Error('Matrix data is empty');
        }
        const absentNodesId: NodeId[] = [...matrix.data.columns, ...matrix.data.rows].reduce(
            (summ: NodeId[], current: MatrixHeader) => {
                if (current.type === 'NODE') {
                    const id = (current as NodeMatrixHeader).nodeId;
                    if (!treeItemsById[id]) {
                        summ.push({ ...nodeId, id });
                    }
                }

                return summ;
            },
            [],
        );

        if (absentNodesId.length) {
            const absentNodes: Node[] = yield call(() => server.api.tree.loadBulk({ body: absentNodesId }));
            absentNodes.forEach((n) => setServerIdToNodeInterface(n, nodeId.serverId));
            yield put(treePartFetchSuccess(undefined, absentNodes, nodeId.serverId));
        }
        yield put(matrixRequestSuccess(matrix));
        yield put(matrixOpen(matrix));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    } finally {
        yield put(workspaceRemoveTab(contentLoadingPageTab));
    }
}

function* handleTabMatrixClose(action: TWorkspaceTabsRemoveAction) {
    const { workspaceTab } = action.payload;

    if (workspaceTab.content && workspaceTab.content.type === TreeItemType.Matrix) {
        yield put(workspaceRemoveTab(workspaceTab));
        if (workspaceTab.mode === EditorMode.Edit) {
            yield put(newMatrixSaveRequest(workspaceTab.nodeId));
            yield put(unlock(workspaceTab.nodeId, 'MATRIX'));
        }
    }
}

function* handleMatrixChangeDataRequest(action: TMatrixChangeDataRequestAction) {
    const { nodeId, data, data2 } = action.payload;
    const matrix: IMatrixNode = yield select(MatrixSelectors.byId(nodeId));
    matrix.data = data;
    matrix.data2 = data2;
    yield put(matrixSaveRequest(matrix));
}

function* handleMatrixUploadFile(action: TMatrixUploadFileAction) {
    const { nodeId, data, serverId, draggedFile, description, setContent } = action.payload;
    const uploadResultId = uuid();
    yield put(fileUpload(draggedFile, { serverId, id: uploadResultId, repositoryId: nodeId.repositoryId }));
    const { success }: { success: TFileUploadSuccessAction | undefined } = yield race({
        success: take(FILE_UPLOAD_SUCCESS),
        fail: take(FILE_UPLOAD_FAIL),
    });

    const uploadedFileNode: FileNodeDTO | undefined = success?.payload?.result;

    if (!uploadedFileNode || !isImageFile(uploadedFileNode)) {
        return;
    }

    if (uploadedFileNode?.nodeId) {
        const matrixData = matrixService().addMatrixUserIconState(
            data,
            `${uploadedFileNode?.nodeId?.repositoryId}/${uploadedFileNode?.nodeId?.id}`,
            description,
        );
        setContent(matrixData);
        const matrix: IMatrixNode = yield select(MatrixSelectors.byId(nodeId));
        matrix.data = data;
        yield put(matrixSaveRequest(matrix));
    }
}

function* handleModeChanged({ payload: { mode } }: TMatrixEditorModeChangedAction) {
    const activeModelContext: IModelContext = yield getActiveModelContext();
    if (activeModelContext && activeModelContext.schema && activeModelContext.schema.type === MATRIX_DIAGRAM_TYPE_ID) {
        const graphId = activeModelContext.schema.nodeId;
        if (mode === EditorMode.Edit && activeModelContext.schema.mode !== EditorMode.Edit) {
            // todo: 1647
            const lock: LockInfoDTO = yield matrixService().lockMatrix(graphId);
            if (lock.locked) {
                yield put(
                    showNotification({
                        id: uuid(),
                        type: NotificationType.DIAGRAM_LOCK_ERROR,
                        data: {
                            lockOwner: lock.ownerName,
                        } as IDiagramLockError,
                    }),
                );

                return;
            }
        } else if (mode !== EditorMode.Edit && activeModelContext.schema.mode === EditorMode.Edit) {
            yield put(newMatrixSaveRequest(graphId));
            yield put(unlock(graphId, 'MATRIX'));
        }
        // yield put(workspaceTabSetParams(graphId, {}));
        yield put(editorModeChangedAction(mode));
    }
}

export function* matrixSaga() {
    yield takeEvery(MATRIX_OPEN, handleMatrixOpen);
    yield takeEvery(MATRIX_CREATE, handleMatrixCreate);
    yield takeEvery(MATRIX_SAVE_REQUEST, handleMatrixSaveRequest);

    yield takeEvery(MATRIX_REQUEST, handleMatrixRequest);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleTreeItemsMatrixAdd);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabMatrixClose);

    yield takeEvery(MATRIX_CHANGE_DATA_REQUEST, handleMatrixChangeDataRequest);
    yield takeEvery(MATRIX_UPLOAD_FILE, handleMatrixUploadFile);
    yield takeEvery(MATRIX_EDITOR_MODE_CHANGED, handleModeChanged);
    yield takeEvery(MATRIX_OPEN_BY_NODE_ID, handleMatrixOpenByNodeId);
}
