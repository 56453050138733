import { TWorkspaceTab } from "@/models/tab.types";
import { WorkSpaceTabTypes } from "@/modules/Workspace/WorkSpaceTabTypesEnum";

export const isTabSearchable = (tab: TWorkspaceTab): boolean => {
    // Поиск по БД доступен только для элементов, которые могут быть открыты из БД
    const searchableTabTypes = [
        WorkSpaceTabTypes.EDITOR,
        WorkSpaceTabTypes.WIKI_EDITOR,
        WorkSpaceTabTypes.MARTIX_EDITOR,
        WorkSpaceTabTypes.NEW_MARTIX_EDITOR,
        WorkSpaceTabTypes.SPREADSHEET,
        WorkSpaceTabTypes.KANBAN,
        WorkSpaceTabTypes.DASHBOARD,
        WorkSpaceTabTypes.SIMULATION_MODELING,
    ];

    return searchableTabTypes.includes(tab.type);
}
