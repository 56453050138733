import type { NodeId, Symbol } from '@/serverapi/api';
import type { TTreeNodeWithLevel } from '../../Tree.types';
import { ExpandStatus } from '@/reducers/tree.reducer.types';
import React, { FC } from 'react';
import { TreeItemType } from '../../models/tree';
import { FILE_ICON_TYPES } from '../../TreeItemIcons/treeItemIcons';
import theme from './SimpleTreeItem.scss';
import classnames from 'classnames';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { useSelector } from 'react-redux';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { NavigatorTreeSearchSelector } from '@/selectors/navigatorTreeSearch.selectors';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import TreeItemIcon from '../TreeItem/TreeItemIcon.component';
import { TreeItemToggler } from '../TreeItem/TreeItemToggler.component';
import { useIntl } from 'react-intl';
import modelTypeMessages from '../../../../models/modelType.messages';
import SimulationModelingMessages from '../../../../modules/SimulationModeling/messages/SimulationModeling.messages';
import { ObjectTypeSelectors } from '@/selectors/objectType.selectors';
import { EdgeTypeSelectors } from '@/selectors/edgeType.selectors';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';
import { LocalesService } from '@/services/LocalesService';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { Locale } from '../../../Header/components/Header/header.types';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { LEVEL_PADDING, getFileType } from '../TreeItem/TreeItem.utils';
import { ExpandedStatusSelector } from '@/selectors/expandedStatus.selectors ';

type TSimpleTreeItemProps = {
    rowData: TTreeNodeWithLevel;
    selectedFoundNodeId: NodeId | undefined;
    treeName: string;
    isNavigatorStructure: boolean;
};

export const SimpleTreeItem: FC<TSimpleTreeItemProps> = (props) => {
    const { selectedFoundNodeId, isNavigatorStructure, treeName, rowData } = props;
    const {
        name,
        nodeId,
        type,
        hasChildren,
        modelTypeId,
        idSymbol,
        language,
        objectTypeId,
        edgeTypeId,
        extension,
        folderType,
        deleted,
        level,
    } = rowData;
    const { id, serverId } = nodeId;
    const intl = useIntl();
    const connected = useSelector(TreeSelectors.connected);
    const connectedServerId = connected[0];
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const currentLocale: Locale = useSelector(getCurrentLocale);
    const modelType = useSelector(ModelTypeSelectors.byId({ modelTypeId: modelTypeId || '', serverId }, presetId));
    const objectType = useSelector(ObjectTypeSelectors.byId({ objectTypeId: objectTypeId || '', presetId, serverId }));
    const edgeType = useSelector(EdgeTypeSelectors.byId({ edgeTypeId: edgeTypeId || '', presetId, serverId }));

    const isSearchActive: boolean = useSelector(NavigatorTreeSearchSelector.getIsSearchActive);

    const expandStatus: ExpandStatus = useSelector(ExpandedStatusSelector.expandStatus(treeName, nodeId));
    const isItemSelected: boolean = useSelector(SelectedNodesSelector.isNodeSelected(nodeId, treeName));
    const isFoundedNodeSelected: boolean = useSelector(
        SelectedNodesSelector.isNodeSelected(selectedFoundNodeId, treeName),
    );

    const isAddFavoriteTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG;
    const isApprovalCopyTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG;
    const foundNodeIds: NodeId[] = useSelector(NavigatorTreeSearchSelector.getFoundedNodeIds);

    const symbol: Symbol | undefined = useSelector(SymbolSelectors.byNodeIdAndSymbolId(nodeId, idSymbol));

    const itemClassName = classnames(theme.item, {
        [theme.item_selected]: isItemSelected,
    });

    let specialIcon: string = '';
    if (symbol) {
        specialIcon = symbol ? symbol.icon : '';
    }

    if (type === TreeItemType.Script && language) {
        specialIcon = language;
    }
    if (type === TreeItemType.File && extension) {
        specialIcon = FILE_ICON_TYPES[getFileType(extension)];
    }

    const isFound =
        ((isNavigatorStructure && isSearchActive) || isApprovalCopyTree || isAddFavoriteTree) &&
        foundNodeIds.find((foundNodeId) => compareNodeIds(foundNodeId, nodeId)) &&
        !isItemSelected;

    const isSelectedFound =
        isNavigatorStructure && isSearchActive && compareNodeIds(nodeId, selectedFoundNodeId) && !isFoundedNodeSelected;

    const dataTest = `tree-menu_item${type === TreeItemType.Server ? '-server' : ''}${isFound ? '-found' : ''}${
        isSelectedFound ? '-selected' : ''
    }${deleted ? '-deleted' : ''}`;

    const isConnected: boolean = !!connectedServerId;
    const hasToggler: boolean =
        (type === TreeItemType.Server && isConnected) || (type !== TreeItemType.Server && hasChildren);

    const createSuffix = (): string => {
        let valueTypeName: string | undefined;

        switch (type) {
            case TreeItemType.Model:
                valueTypeName = LocalesService.internationalStringToString(modelType?.multilingualName, currentLocale);
                break;
            case TreeItemType.ObjectDefinition:
                valueTypeName = objectType?.name || '';
                break;
            case TreeItemType.SimulationModeling:
                valueTypeName = intl.formatMessage(SimulationModelingMessages.simulationModel);
                break;
            case TreeItemType.Matrix:
                valueTypeName = intl.formatMessage(modelTypeMessages.MATRIX);
                break;
            case TreeItemType.Wiki:
                valueTypeName = intl.formatMessage(modelTypeMessages.WIKI);
                break;
            case TreeItemType.Kanban:
                valueTypeName = intl.formatMessage(modelTypeMessages.KANBAN);
                break;
            case TreeItemType.EdgeDefinition:
                valueTypeName = edgeType?.name || '';
                break;
            default:
                valueTypeName = '';
        }
        if (valueTypeName) {
            return ` [${valueTypeName}]`;
        }

        return '';
    };

    return (
        <div
            key={id}
            id={`tree-item-selected_${isItemSelected}`}
            data-test={dataTest}
            style={{ paddingLeft: `${(level + 1) * LEVEL_PADDING}px` }}
            className={classnames(
                itemClassName,
                {
                    [theme.item_collapsed]: expandStatus === ExpandStatus.CLOSED,
                },
                {
                    [theme.isFound]: isFound,
                },
                {
                    [theme.isSelectedFoundNode]: isSelectedFound,
                },
            )}
        >
            <div>
                <div
                    className={classnames(theme.dropdownContainer, theme.container, {
                        [theme.container_expandable]: hasToggler,
                    })}
                >
                    {!hasToggler && type === TreeItemType.Server && <div className={theme.fakeToggler} />}
                    {hasToggler && <TreeItemToggler expandStatus={expandStatus} />}
                    <div className={theme.entityIconContainer}>
                        <TreeItemIcon {...{ nodeId, type, isConnected, specialIcon, folderType }} />
                    </div>
                    <div
                        className={classnames(theme.title, {
                            [theme.deletedTreeItem]: !!deleted,
                        })}
                    >
                        <div className={theme.titleContainer}>{(name || '').concat(createSuffix() || '')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
