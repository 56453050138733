import React from 'react';
import messages from '../SystemProperties.messages';
import theme from '../SystemProperties.scss';
import { useIntl } from 'react-intl';
import { Alert, Modal } from 'antd';
import { FooterAgreement } from './FooterAgreement.component';

type TAgreementResetConfirmProps = {
    handleDelete: () => void;
    onHideAgreement: () => void;
    contentMessage: string;
};

export const AgreementResetConfirm = ({
    onHideAgreement,
    handleDelete,
    contentMessage,
}: TAgreementResetConfirmProps) => {
    const intl = useIntl();

    return (
        <div>
            <Modal
                title={intl.formatMessage(messages.resetAgreement)}
                open
                onCancel={onHideAgreement}
                footer={<FooterAgreement onHideAgreement={onHideAgreement} handleDelete={handleDelete} />}
            >
                <div className={theme.content}>{contentMessage}</div>
                <div className={theme.alert}>
                    <Alert
                        className={theme.alert}
                        message={intl.formatMessage(messages.resetAgreementWarningConfirm)}
                        type="error"
                    />
                </div>
            </Modal>
        </div>
    );
};
