import { call, put, select, takeEvery } from 'redux-saga/effects';
import { TreeItemType } from '../modules/Tree/models/tree';
import {
    matrixChangeDataRequest,
    matrixSaveRequestFailure,
    matrixSaveRequestSuccess,
} from '../actions/entities/matrix.actions';
import { getTreeItems } from '../selectors/tree.selectors';
import { v4 as uuid } from 'uuid';
import { IMatrixNode } from '../models/bpm/bpm-model-impl.types';
import { TTreeEntityState } from '../models/tree.types';
import { MatrixSelectors } from '../selectors/entities/matrix.selectors';
import { DiagramElement, MatrixDataBPM8764, MatrixLane, ObjectInstance } from '../serverapi/api';
import { showNotification } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { getCopiedElements, getWhereCopiedFrom } from '@/selectors/editor.selectors';
import { cloneDeep } from 'lodash-es';
import { HeaderType } from '@/modules/Matrix/NewMatrixEditor/NewMatrix.types';
import {
    MATRIX_PAST_OBJECTS,
    NEW_MATRIX_SAVE_REQUEST,
    REFRESH_NEW_MATRIX,
} from '@/actionsTypes/entities/newMatrix.actionTypes';
import { NewMatrixSelectors } from '@/selectors/entities/newMatrix.selectors';
import { TSelectedHeadersCells } from '@/reducers/entities/newMatrix.reducer.types';
import { TMatrixPastObjectsAction, TNewMatrixSaveRequestAction } from '@/actions/entities/newMatrix.actions.types';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { TServerEntity } from '@/models/entities.types';
import { setServerIdToNodeOriginal } from '@/utils/nodeId.utils';
import { MatrixDaoService } from '@/services/dao/MatrixDaoService';

function* handleMatrixPastObjects({ payload: { nodeId } }: TMatrixPastObjectsAction) {
    const matrix: IMatrixNode = yield select(MatrixSelectors.byId(nodeId));
    const selectedHeaderCells: TSelectedHeadersCells = yield select(NewMatrixSelectors.getSelectedHeaderCells(nodeId));
    const { ids: selectedCellsId, type: headerType } = selectedHeaderCells;
    const copiedElements = yield select(getCopiedElements);
    const whereCopiedFrom = yield select(getWhereCopiedFrom);
    const objectDefinitions: TTreeEntityState[] = yield select(getTreeItems(nodeId.serverId, nodeId.repositoryId));

    const matrixData: MatrixDataBPM8764 | undefined = cloneDeep(matrix.data2);

    if (!matrixData) return;

    const colsHeaders = matrixData.columns;
    const rowsHeaders = matrixData.rows;

    if (
        selectedCellsId.length > 0 &&
        whereCopiedFrom &&
        whereCopiedFrom.repositoryId === nodeId.repositoryId &&
        whereCopiedFrom.serverId === nodeId.serverId
    ) {
        const objectsToAdd: DiagramElement[] = copiedElements.filter((elem) => elem.type === 'object');

        const currentLanes = headerType === HeaderType.column ? [...colsHeaders] : [...rowsHeaders];
        let minIndex = currentLanes.length - 1;
        selectedCellsId.forEach((cellId) => {
            const laneIndex = currentLanes.findIndex((lane) => lane.id === cellId);
            minIndex = Math.min(minIndex, laneIndex);
        });

        const parentId: string | undefined = currentLanes[minIndex]?.parentId;

        const objectDefinitionsToAdd: TTreeEntityState[] = objectsToAdd.map((object) => {
            return objectDefinitions[(object as ObjectInstance).objectDefinitionId || ''];
        });

        const checkObjectDefinition = (objectDefinition: TTreeEntityState) => {
            return (
                !objectDefinition ||
                objectDefinition.type !== TreeItemType.ObjectDefinition ||
                objectDefinition.nodeId.repositoryId !== nodeId.repositoryId ||
                objectDefinition.nodeId.serverId !== nodeId.serverId
            );
        };

        const wrongObjectDefinition = objectDefinitionsToAdd.find((objectDefinition) =>
            checkObjectDefinition(objectDefinition),
        );
        if (wrongObjectDefinition) {
            yield put(
                showNotification({
                    id: uuid(),
                    type: NotificationType.DND_ERROR_WRONG_NODE_TYPE,
                    data: wrongObjectDefinition.type,
                }),
            );

            return;
        }

        const newMatrixLanes: MatrixLane[] = objectDefinitionsToAdd.map((objectDefinition) => {
            return {
                id: uuid(),
                linkedNodeId: objectDefinition.nodeId.id,
                symbolId: objectDefinition.idSymbol,
                text: objectDefinition.multilingualName,
                parentId,
            };
        });
        currentLanes.splice(minIndex, 0, ...newMatrixLanes);
        if (headerType === HeaderType.column) {
            matrixData.columns = currentLanes;
        }
        if (headerType === HeaderType.row) {
            matrixData.rows = currentLanes;
        }

        yield put(matrixChangeDataRequest(nodeId, undefined, matrixData));
    }
}

function* handleNewMatrixSaveRequest(action: TNewMatrixSaveRequestAction) {
    const { nodeId } = action.payload;

    const isMatrixUnsaved: IMatrixNode = yield select(NewMatrixSelectors.isMatrixUnsaved(nodeId));
    if (!isMatrixUnsaved) return;

    const matrix: IMatrixNode = yield select(MatrixSelectors.byId(nodeId));

    if (matrix) {
        const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
        try {
            const data: IMatrixNode = yield call(() => server.api.matrix.save({ body: matrix }));
            setServerIdToNodeOriginal(data, nodeId.serverId);
            yield put(matrixSaveRequestSuccess(data));
        } catch (e) {
            yield put(matrixSaveRequestFailure(nodeId.serverId, e.message));
            throw e;
        }
    }
}

function* handleRefreshNewMatrix(action: TNewMatrixSaveRequestAction) {
    const { nodeId } = action.payload;

    try {
        const matrix: IMatrixNode = yield MatrixDaoService.getMatrix(nodeId.repositoryId, nodeId.id);

        setServerIdToNodeOriginal(matrix, nodeId.serverId);
        yield put(matrixSaveRequestSuccess(matrix));
    } catch (e) {
        yield put(matrixSaveRequestFailure(nodeId.serverId, e.message));
        throw e;
    }
}

export function* newMatrixSaga() {
    yield takeEvery(MATRIX_PAST_OBJECTS, handleMatrixPastObjects);
    yield takeEvery(NEW_MATRIX_SAVE_REQUEST, handleNewMatrixSaveRequest);
    yield takeEvery(REFRESH_NEW_MATRIX, handleRefreshNewMatrix);
}
