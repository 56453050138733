import * as React from 'react';
import { appData } from '../../../../utils/AppService';
import classnames from 'classnames';
import theme from '../items/MenuItem.scss';
import { Button } from 'antd';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import icClose from '../../../../resources/icons/ic-ribbon-close.svg';
import icArrowDown from '../../../../resources/icons/ic-ribbon-arrow-down.svg';
import icArrowUp from '../../../../resources/icons/ic-ribbon-arrow-up.svg';
import { MainMenuItems } from '../../../../models/mainMenu';
import { NodeId } from '../../../../serverapi/api';

type ToolbarType = {
    itemKey?: MainMenuItems;
    compact?: boolean;
    toolbarComponent: string;
    activeKey: NodeId;

    onMenuExpandClick?: () => void;
    onMenuCollapseClick?: () => void;
    onMenuCloseClick?: () => void;
};

class AdjustToolbarSizeComponent<T> extends React.Component<T & ToolbarType> {
    static defaultProps = {
        compact: false,
    };

    element: HTMLDivElement;
    groupElements: Array<{ element: Element; size: ClientRect }> = [];

    constructor(props: T & ToolbarType) {
        super(props);

        this.onResize = this.onResize.bind(this);
        appData.events.addListener('resize', this.onResize);
    }

    onResize(sizes: ClientRect) {
        if (this.element) {
            const elementRect = this.element.getBoundingClientRect();

            if (elementRect.width > sizes.width) {
                this.minimizeGroups(sizes);
            } else if (
                this.groupElements.length &&
                sizes.width >= elementRect.width + this.groupElements[this.groupElements.length - 1].size.width
            ) {
                this.maximizeGroups(sizes);
            }
        }
    }

    minimizeGroups(sizes: ClientRect) {
        const groupElement = this.element.querySelectorAll('.group:not(.group-mini)');
        if (groupElement && groupElement.length) {
            this.groupElements.push({
                element: groupElement[groupElement.length - 1],
                size: groupElement[groupElement.length - 1].getBoundingClientRect(),
            });
            groupElement[groupElement.length - 1].classList.add('group-mini');
            this.onResize(sizes);
        }
    }

    maximizeGroups(sizes: ClientRect) {
        if (this.groupElements.length) {
            this.groupElements[this.groupElements.length - 1].element.classList.remove('group-mini');
            this.groupElements.pop();
            this.onResize(sizes);
        }
    }

    componentDidMount(): void {
        this.onResize(appData.sizes as ClientRect);
    }

    getToolbar(toolbarName: string) {
        return <div />;
    }

    render() {
        const { compact, toolbarComponent, onMenuCloseClick, onMenuExpandClick, onMenuCollapseClick } = this.props;

        const className = classnames(theme.mainMenu, {
            [theme.container_compact]: compact,
        });

        return (
            <div className={className}>
                <div className={theme.container_inner} ref={(element) => (this.element = element!)}>
                    {this.getToolbar(toolbarComponent || 'DEFAULT')}
                </div>
                <div className={theme.actions}>
                    <Button data-test="graph-general_button-arrow-close" onClick={onMenuCloseClick} className={theme.action}>
                        <Icon className={theme.actionIcon} spriteSymbol={icClose}/>
                    </Button>
                    <Button data-test="graph-general_button_arrow-down-up" onClick={compact ? onMenuExpandClick : onMenuCollapseClick} className={theme.action}>
                        <Icon className={theme.actionIcon} spriteSymbol={compact ? icArrowDown : icArrowUp}/>
                    </Button>
                </div>
            </div>
        );
    }
}

export { AdjustToolbarSizeComponent };
