import { openDialog } from '@/actions/dialogs.actions';
import { showNotification } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { getStore } from '@/store';
import { v4 as uuid } from 'uuid';

export const showGridNotification = (type: NotificationType) => {
    const { dispatch } = getStore();
    dispatch(
        showNotification({
            type,
            id: uuid(),
        }),
    );
};

type TOnRenameCallback = (value: string) => void;

export const showRenameDialog = (initialValue: string, onRename: TOnRenameCallback) => {
    const { dispatch } = getStore();
    dispatch(openDialog(DialogType.RENAME_GRAPH_CELL_DIALOG, { value: initialValue, onRename }));
};
